import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionFilter'
})
export class PermissionFilterPipe implements PipeTransform {
  transform(data: any[], permissionFormId: number,permissionType:string) {
    const filteredData =  data.filter(item => this.callPermissionFilter(item,permissionFormId,permissionType));

    return filteredData.length > 0;
//    return data.filter(item => item.permission_form_id === permissionFormId);
  }

  private callPermissionFilter(item, permissionFormId, permissionType) {
    if (item.permission_form_id === permissionFormId) {
      switch (permissionType) {
        case 'view':
          return !!item.is_view;
        case 'edit':
          return !!item.is_edit;
        case 'delete':
          return !!item.is_delete;
        default:
          return false; // Return false if permissionType is not recognized
      }
    }
    return false; // Return false if permissionFormId doesn't match
  }

}
