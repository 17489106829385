import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import {API_BASE_URL,
  ConfigFactory,
  ConfigService,
  PLACES_API_KEY, RE_CAPTCHA_KEY, IS_PRODUCTION, IS_MAINTENANCE
} from "./shared/config.service";
import {AuthInterceptor} from "./shared/interceptors/auth-interceptor";
import {AuthGuard} from "./shared/auth.guard";
//import {JwtModule} from "@auth0/angular-jwt";
import {SharedModule} from "./shared/shared.module";
import {SimpleNotificationsModule} from "angular2-notifications";
import {MaintenanceGuard} from "./shared/maintenance.guard";
import {NgbDateCustomParserFormatter} from "./shared/ngb.date.custom.parser.formatter";
import {NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {CacheService} from "./shared/cache.service";

export function tokenGetter() {
  return localStorage.getItem("authToken");
}
export function clearIndexHtmlCache(cacheService: CacheService) {
  return () => cacheService.clearCacheForFile('index.html');
}
@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    SharedModule,
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: tokenGetter,
    //     allowedDomains: ["example.com"],
    //   },
    // }),
    SimpleNotificationsModule.forRoot(),
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    ConfigService,
    AuthGuard,
    {provide: "CONFIG.JSON", useValue: "./assets/config.json"},
    {provide: "BASE-API-VARIABLE", useValue: "API_URL"},
    {provide: "BASE-PLACE-API-VARIABLE", useValue: "PLACES_API_KEY"},
    {provide: "RE-CAPTCHA-KEY-VARIABLE", useValue: "RE_CAPTCHA_KEY"},
    {provide: "IS_PRODUCTION", useValue: "IS_PRODUCTION"},
    {provide: "IS_MAINTENANCE", useValue: "IS_MAINTENANCE"},
    {
      provide: API_BASE_URL,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIG.JSON", "BASE-API-VARIABLE"],
    },
    {
      provide: PLACES_API_KEY,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIG.JSON", "BASE-PLACE-API-VARIABLE"],
    },
    {
      provide: RE_CAPTCHA_KEY,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIG.JSON", "RE-CAPTCHA-KEY-VARIABLE"],
    },
    {
      provide: IS_PRODUCTION,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIG.JSON", "IS_PRODUCTION"],
    },
    {
      provide: IS_MAINTENANCE,
      useFactory: ConfigFactory,
      deps: [ConfigService, "CONFIG.JSON", "IS_MAINTENANCE"],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    MaintenanceGuard,
    CacheService,
    {
      provide: APP_INITIALIZER,
      useFactory: clearIndexHtmlCache, //<--- calling function on Angular App init
      deps: [CacheService], //<---- Important deps
      multi: true,
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
