import {Component, OnInit, ViewChild} from '@angular/core';
import {PropertyGlobalService} from "../../../property-global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {GlobalService} from "../../../global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {NgForm} from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: 'app-building-materials',
  templateUrl: './building-materials.component.html',
  styleUrls: ['./building-materials.component.scss']
})
export class BuildingMaterialsComponent implements OnInit {
  buildingMaterials:any = [];
  tempBuildingMaterials:any = [];
  buildingMaterialKey = null;
  @ViewChild('buildingMaterialModel') buildingMaterialModel: ModalDirective;
  constructor(
    private propertyGlobalService: PropertyGlobalService,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService
  ) {
    this.propertyGlobalService.openBuildingMaterialModelEvent.subscribe(data=>{
      this.tempBuildingMaterials = [];
      this.buildingMaterialKey= null;
      this.buildingMaterials = [];

      this.getFormInitData();
    });
  }

  ngOnInit(): void {
  }

  public getFormInitData() {
    this.spinner.show();
    this.propertyGlobalService.propertyRegistrationFormLoad().subscribe(response => {
      this.spinner.hide();
      this.buildingMaterials = response['building_materials'];
      this.buildingMaterials.forEach((item, index) => {
        if(item['user_id'] != null){
          this.tempBuildingMaterials.push(item);
        }
      });

      this.buildingMaterialModel.show();
    }, error => {
      this.spinner.hide();
      this.globalService.onError(error.message);
    });
  }

  addNewBuildingMaterial(buildingMaterialForm: NgForm) {
      if(buildingMaterialForm.value['materialsNew'] == null || buildingMaterialForm.value['materialsNew'] =='')
        return;
      this.propertyGlobalService.saveBuildingMaterial('', buildingMaterialForm.value['materialsNew']).subscribe(res => {
        let materialValue = buildingMaterialForm.value['materialsNew'];
        this.spinner.hide();
          this.globalService.onSuccess('Building Material Add successful');
        this.buildingMaterials = res['building_materials'];
        this.tempBuildingMaterials  = [];
        this.buildingMaterials.forEach((item, index) => {
          if(item['user_id'] != null){
            this.tempBuildingMaterials.push(item);
          }
        });
          Swal.fire({
            title: 'Are you sure?',
            text: "you want to change current property building material to '"+ buildingMaterialForm.value['materialsNew']+"'",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((isConfirm) => {
            if (isConfirm.value) {
              // this.tempBuildingMaterials.forEach(item =>{
              //   if(item['building_material'] == buildingMaterialForm.value['materialsNew']){
              //
              //   }
              // });
              this.buildingMaterialModel.hide();
              this.propertyGlobalService.parentModelSwitch(false);
              let data = {
                buildingMaterial:buildingMaterialForm.value['materialsNew'],
                action:'add'
              }
              this.propertyGlobalService.closeBuildingMaterialModel(data);
            }
            else {
              buildingMaterialForm.reset();
            }
          }, function (desmiss) { });


      }, err => {
        this.spinner.hide();
        this.globalService.onError(err.message);
      });
    }

  closeBuildingMaterialModel() {
    this.buildingMaterialModel.hide();
    this.propertyGlobalService.parentModelSwitch(false);
    let data = {
      buildingMaterial:null,
      action:'close'
    }
    this.propertyGlobalService.closeBuildingMaterialModel(data);
  }

  updateBuildingMaterial(data, form: NgForm) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to save this Building Material changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.propertyGlobalService.saveBuildingMaterial(data['building_material_id'], form.value['building_material']).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.globalService.onSuccess('Building Material Edit successful');
            this.buildingMaterials = res['building_materials'];
            // if(this.properties['building_material_id'] == data['building_material_id'])
            //   this.properties['building_material'] = form.value['building_material'];
            this.buildingMaterialKey = null;
            this.tempBuildingMaterials = [];
            this.buildingMaterials.forEach((item, index) => {
              if(item['user_id'] != null){
                this.tempBuildingMaterials.push(item);
              }
            });
          }
        }, err => {
          this.spinner.hide();
          this.globalService.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }


  buildingMaterialCheck(building_id: any){
    this.spinner.show();
    this.propertyGlobalService.checkBuildingMaterial(building_id).subscribe(res => {
      this.spinner.hide();
      if (res['status'] == 200) {
        if(res['validate']){
          this.deleteBuildingMaterial(building_id);
        }
        else{
          Swal.fire({
            title: 'You can not delete this building material?',
            text: 'This building material used in another component!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }

      }
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err.message);
    });
  }
  deleteBuildingMaterial(building_id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete this building material !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.propertyGlobalService.deleteBuildingMaterial(building_id).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.globalService.onSuccess('Building Material Delete successful');
            this.buildingMaterials = res['building_materials'];
            // if(this.properties['building_material_id'] == building_id)
            //   this.properties['building_material'] = null;
            this.buildingMaterialKey = null;
            this.tempBuildingMaterials = [];
            for (let a = 0; a < this.buildingMaterials.length; a++) {
              if (this.buildingMaterials[a]['user_id'] != null) {
                this.tempBuildingMaterials.push(this.buildingMaterials[a]);
              }
            }
          }
        }, err => {
          this.spinner.hide();
          this.globalService.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }
}
