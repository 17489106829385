<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>

<div bsModal #editSupplierModel="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog"
     aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left ">Edit Supplier</h4>
        <button type="button" class="close pull-right"
                (click)="editSupplierModelClose(null)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="supplierDetailsForm" novalidate>
          <div class="row">
            <div class="col-md-6">
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-left">
                  <div class="input-group">
                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="company_name" formControlName="company_name">
                  </div>
                </div>
                <span>Company Name <span class="text-danger">*</span></span>
                <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This
                  field is required.
                </div>
              </label>
              <label class="form-group has-float-label">
                <div class="input-group">
                  <ng-select [items]="supplierTypes" class="flex-grow-1 explanation-link w-84"
                             [addTag]="pushSupplierType" [addTagText]="'Hit Enter to Add Item'"
                             aria-describedby="inputGroup-sizing-sm" [hideSelected]="true"
                             [multiple]="true" bindLabel="supplier_type" bindValue="supplier_type"
                             (change)="itemSelect($event)" (clear)="clearSupplierType()"
                             formControlName="supplier_type">
                  </ng-select>

                  <div class="input-group-append">
                    <button class="btn btn-primary" (click)="openSupplierTypeModel()"
                            style="border-radius: unset!important;">
                      <i class="fa fa-binoculars"></i>
                    </button>
                  </div>
                  <div *ngIf="submitted && supplierDetailsFormControls.supplier_type.errors" class="invalid-tooltip">
                    This field is required.
                  </div>
                </div>
                <span>Type <span class="text-danger">*</span></span>
              </label>
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-right">
                  <div class="input-group">
                    <input class="form-control" ngx-google-places-autocomplete [options]='options'
                           #placesRef="ngx-places"
                           formControlName="company_address" (onAddressChange)="handleAddressChange($event)"
                           [ngClass]="{ 'is-invalid': submitted && supplierDetailsFormControls.company_address.errors }"/>
                    <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
                    <!--                           name="company_address" formControlName="company_address" >-->
                  </div>
                  <!--                    <div class="form-control-position">-->
                  <!--                  <a href="{{supplierDetailsFormControls.company_address.value}}" target="_blank"> <i class="fa fa-map-marker text-info" style="font-size: 25px;margin-top: 3px;"></i></a>-->
                  <!--                  </div>-->

                </div>
                <span>Company Address </span>
                <!--                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>-->
              </label>

                            <label class="form-group has-float-label" *ngIf="isMultiSelect">
                              <div class="input-group">
                                <ng-select appearance="outline" labelForId="state" class="h-25 w-100" [multiple]="isMultiSelect" [searchable]="false" [clearable]="false" formControlName="state" (change)="selectLocationFromMultiSelect($event)">
              <!--                    <ng-option value="National">National</ng-option>-->
                                  <ng-option *ngFor="let list of locations" [value]="list.state">
                                    {{list.state}}
                                  </ng-option>
                                </ng-select>
                              </div>
                              <span>Location</span>
                              <div *ngIf="submitted && supplierDetailsFormControls.state.errors" class="invalid-tooltip">This field is required.</div>
                            </label>

              <label class="form-group has-float-label" *ngIf="!isMultiSelect">
                <div class="input-group">
                  <ng-select appearance="outline" labelForId="state" class="h-25 w-100" [searchable]="false"
                             [clearable]="false" formControlName="state" (change)="selectLocationFromSelectBox($event)">
                    <!--                    <ng-option value="National">National</ng-option>-->
                    <ng-option *ngFor="let list of locations" [value]="list.state">
                      {{list.state}}
                    </ng-option>
                  </ng-select>
                </div>
                <span>Location</span>
              </label>


<!--              <label class="form-group has-float-label">-->
<!--                <div class="position-relative has-icon-left">-->
<!--                  <div class="input-group">-->
<!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                           name="abn" formControlName="abn">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <span>ABN / ACN #</span>-->
<!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
<!--              </label>-->
            </div>
            <div class="col-md-6">
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-left">
                  <div class="input-group">
                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="main_contact_name" formControlName="main_contact_name">
                  </div>
                </div>
                <span>Main Contact name</span>
                <!--                  <div *ngIf="submitted && supplierDetailsFormControls.main_contact_name.errors" class="invalid-tooltip">This field is required.</div>-->
              </label>
<!--              <label class="form-group has-float-label">-->
<!--                <div class="position-relative has-icon-left">-->
<!--                  <div class="input-group">-->
<!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                           name="contact_phone" formControlName="contact_phone">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <span>Contact Phone </span>-->
<!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
<!--              </label>-->
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-left">
                  <div class="input-group">
                    <input type="tel" class="form-control" aria-describedby="inputGroup-sizing-sm" maxlength="12"
                           name="company_phone" formControlName="company_phone">
                  </div>
                </div>
                <span>Company Phone </span>
                <!--                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>-->
              </label>

              <label class="form-group has-float-label">
                <div class="position-relative has-icon-right">
                  <div class="input-group">
                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           pattern="[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"
                           name="contact_email" formControlName="contact_email">
                  </div>
                  <div class="form-control-position">
                    <a href="mailto:{{supplierDetailsForm.value['contact_email']}}"
                       class="fa fa-envelope f-18 pr-3 text-info cursor-pointer"></a>
                  </div>
                </div>
                <span>Contact Email</span>
                <div *ngIf="submitted && supplierDetailsFormControls.contact_email.errors" class="invalid-tooltip">
                  Invalid Email.
                </div>
                <!--                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>-->
              </label>
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-right">
                  <div class="input-group">
                    <textarea type="text" class="w-100"   aria-describedby="inputGroup-sizing-sm"
                              name="web_address" formControlName="web_address" rows="3" style="height: auto !important;">
                    </textarea>
                  </div>
                  <div class="form-control-position">
                    <img src="assets/png/internet.png" class="cursor-pointer" width="100%" (click)="openInWeb();">
                    <!--                    <i class="fa fa-map-marker text-info" style="font-size: 25px;margin-top: 3px;"></i>-->
                  </div>
                </div>
                <span>Web Address </span>
                <!--                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>-->
              </label>
<!--              <label class="form-group has-float-label">-->
<!--                <div class="position-relative has-icon-left">-->
<!--                  <div class="input-group">-->
<!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                           name="contact_2_name" formControlName="contact_2_name">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <span>Contact 2 name</span>-->
<!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.main_contact_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
<!--              </label>-->
<!--              <label class="form-group has-float-label">-->
<!--                <div class="position-relative has-icon-left">-->
<!--                  <div class="input-group">-->
<!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                           name="contact_2_phone" formControlName="contact_2_phone">-->
<!--                  </div>-->
<!--                </div>-->
<!--                <span>Contact 2 Phone </span>-->
<!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
<!--              </label>-->
<!--              <label class="form-group has-float-label">-->
<!--                <div class="position-relative has-icon-right">-->
<!--                  <div class="input-group">-->
<!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                           pattern="[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"-->
<!--                           name="contact_2_email" formControlName="contact_2_email">-->
<!--                  </div>-->
<!--                  <div class="form-control-position">-->
<!--                    <a href="mailto:{{supplierDetailsForm.value['contact_2_email']}}"-->
<!--                       class="fa fa-envelope f-18 pr-3 text-info cursor-pointer"></a>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <span>Contact 2 Email</span>-->
<!--                <div *ngIf="submitted && supplierDetailsFormControls.contact_2_email.errors" class="invalid-tooltip">-->
<!--                  Invalid Email.-->
<!--                </div>-->
<!--              </label>-->
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-left">
                  <div class="input-group">
                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="account_number" formControlName="account_number">
                  </div>
                </div>
                <span>My Account Number With Supplier (If Applicable)</span>
              </label>
              <div class="row">
                <div class=" col-md-12 col-sm-12 col-xs-12">
                  <label class="mb-0" style="font-size: 11.52px; color: lightslategrey;">
                    Amount Spent So Far with Supplier:
                    <span *ngIf="supplierDetailsForm.value['total_expenses'] != null" style="color: #006894; font-size: 0.8rem;">
                      ${{ supplierDetailsForm.value['total_expenses'] | number : '1.2-2'}}
                    </span>
                    <span *ngIf="supplierDetailsForm.value['total_expenses'] == null" style="color: #006894; font-size: 0.8rem;">
                      ${{ 0 | number : '1.2-2'}}
                   </span>
                  </label>
                </div>
              </div>



              <div class="custom-control custom-checkbox align-contact mr-2 mt-5">
                <input type="checkbox" class="custom-control-input" name="is_black_list" id="lease_my_self"
                       formControlName="is_black_list"
                       (click)="this.supplierDetailsForm.patchValue({is_black_list:$event.target.checked});">
                <label class="custom-control-label pt-0" for="lease_my_self"
                       style="color: lightslategrey!important; font-size: 95%">Black List Supplier for Future
                  Work</label>
              </div>
            </div>
            <div class="col-md-6">
              <label class="mb-0 pb-0" style="font-size: 11.52px;color: lightslategrey;">
                Rating :
              </label>
              <div class="col-md-12 pl-0 mb-4">
                <ul class="list-inline rating-list"
                    *ngFor="let star of stars" style="display: inline-block">
                  <li (click)="countStar(star)"
                      [ngClass]="{'selected': (star <= supplierDetailsForm.value['rate'])}">
                    <i class="fa fa-star"></i>
                  </li>
                </ul>
              </div>
              <label class="form-group has-float-label">
                <div class="input-group">
                  <textarea class="form-control" aria-describedby="inputGroup-sizing-sm" rows="5" name="rating_comment"
                            formControlName="rating_comment" style="height: auto !important;"></textarea>
                </div>
                <span>Rating Comment <span *ngIf="isRated" class="text-danger">*</span></span>
                <div class="invalid-tooltip" *ngIf="isRated && (supplierDetailsForm.value['rating_comment'] == null)">
                  This field is required.
                </div>
              </label>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec pull-right"
                (click)="editSupplierModelClose(null)">Close
        </button>
        <app-state-button btnClass="btn btn-primary btn-xs custom-btn-sec pull-right mr-2"
                          [currentState]="responseWaiting" message="false"
                          [isDisabled]="buttonDisabled" (click)="saveSupplierDetails()">Save
        </app-state-button>
        <!--        <button type="button" class="btn btn-primary btn-xs custom-btn-sec pull-right"-->
        <!--                (click)="saveSupplier()">Save</button>-->


      </div>
    </div>
  </div>
</div>

<div bsModal #supplierTypeModel="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Supplier Types</h4>
        <button type="button" class="close pull-right" (click)="closeSupplierTypeModel(supplierTypeModel)"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #supplierTypeForm="ngForm">
          <div class="col-md-12 col-sm-12 pl-0 pr-0">
            <div class="row">
              <div class="table-responsive">
                <label class="form-group has-float-label mt-2">
                  <div class="input-group input-group-sm">
                    <input type="text" class="form-control" name="supplier_type"   placeholder="Add New Supplier Type"  ngModel required (focus)="saveError = false" style="height: 100%">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button"  (click)="addSupplierType(supplierTypeForm)" style="border-radius:0px 11px 11px 0px !important;">
                        <i class="glyph-icon simple-icon-check f-15  text-white"></i></button>
                    </div>
                  </div>
<!--                  -->
<!--                  -->
<!--                  -->
<!--                  <div class="input-group">-->
<!--                    <input type="text" class="form-control pt-2 pb-2" name="supplier_type"-->
<!--                           placeholder="Add New Supplier Type"-->
<!--                           ngModel required (focus)="saveError = false">-->
<!--                    <div class="input-group-append">-->
<!--                      <button class="btn btn-primary default " type="button"-->
<!--                              (click)="addSupplierType(supplierTypeForm)">-->
<!--                        <i class=" fa fa-plus  text-white"></i></button>-->
<!--                    </div>-->
<!--                  </div>-->
                  <span>Supplier type</span>
                  <div *ngIf="saveError" class="invalid-tooltip">Supplier type can't be duplicate.</div>
                </label>
                <table class="table table-striped">
                  <tbody>
                  <tr *ngFor="let item of tempSupplierType;let key = index;">
                    <td *ngIf="supplierTypeKey != key">{{item.supplier_type}}</td>
                    <td *ngIf="supplierTypeKey != key"><a href="javaScript:void(0)" (click)="selectSupplierType(item)">Select</a>
                    </td>
                    <td class="pl-1" *ngIf="supplierTypeKey == key">
                      <label class="form-group has-float-label mb-1">
                        <div class="input-group input-group-sm">
                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="supplier_type" [ngModel]="item.supplier_type"
                                 #roof_material_edit="ngModel" (focus)="updateError = false" style="height: 100%">
                        </div>
                        <span>Supplier Type</span>
                      </label>
                    </td>
                    <td class="text-right pr-1">
                      <a *ngIf="supplierTypeKey == key" href="javascript:;" class="badge badge-danger mb-1 mr-1" (click)="supplierTypeKey = null">
                        <i  class="glyph-icon simple-icon-close text-white f-15 "></i>
                      </a>
                      <a *ngIf="supplierTypeKey == key" href="javascript:;" class="badge badge-primary mr-1 mb-1"  (click)="updateSupplierType(item,supplierTypeForm)">
                        <i  class="glyph-icon simple-icon-check f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-danger mb-1 mr-1"   (click)="supplierTypeCheck(item.supplier_type_id)">
                        <i *ngIf="supplierTypeKey != key" class="glyph-icon simple-icon-trash f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-primary mb-1" (click)="supplierTypeKey = key">
                        <i *ngIf="supplierTypeKey != key" class="glyph-icon simple-icon-note text-white f-15"></i>
                      </a>
                    </td>




<!--                    -->
<!--                    -->
<!--                    -->
<!--                    <td *ngIf="supplierTypeKey == key">-->
<!--                      <label class="form-group has-float-label">-->
<!--                        <div class="input-group">-->
<!--                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                                 name="supplier_type" [ngModel]="item.supplier_type"-->
<!--                                 #roof_material_edit="ngModel" (focus)="updateError = false">-->
<!--                        </div>-->
<!--                        <span>Supplier Type</span>-->
<!--                      </label>-->
<!--                      <span class="text-danger" *ngIf="updateError">Supplier type can't be duplicate.</span>-->
<!--                    </td>-->
<!--                    <td class="text-right">-->
<!--                      <i *ngIf="supplierTypeKey != key" class="fa fa-pencil f-15 pr-2"-->
<!--                         (click)="supplierTypeKey = key"></i>-->
<!--                      <i *ngIf="supplierTypeKey == key" class="fa fa-times f-15 pr-2"-->
<!--                         (click)="supplierTypeKey = null"></i>-->
<!--                      <i *ngIf="supplierTypeKey == key" class="fa fa-save f-15 pr-2"-->
<!--                         (click)="updateSupplierType(item,supplierTypeForm)"></i>-->
<!--                      <i *ngIf="supplierTypeKey != key" class="fa fa-trash f-15"-->
<!--                         (click)="supplierTypeCheck(item.supplier_type_id)"></i>-->
<!--                    </td>-->
                  </tr>
                  </tbody>
                  <tfoot *ngIf="tempSupplierType.length == 0">
                  <tr>
                    <td colspan="3">
                      No records found..
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm"
                (click)="closeSupplierTypeModel(supplierTypeModel)">Close
        </button>
      </div>
    </div>
  </div>
</div>


