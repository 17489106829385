import { Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';


export const  API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
export const PLACES_API_KEY = new InjectionToken<string>('PLACES_API_KEY');
export const RE_CAPTCHA_KEY = new InjectionToken<string>('RE_CAPTCHA_KEY');
export const IS_PRODUCTION = new InjectionToken<any>('IS_PRODUCTION');
export const IS_MAINTENANCE = new InjectionToken<string>('IS_MAINTENANCE');

export function ConfigFactory(configService: ConfigService, file: string, property: string) {
  return configService.loadJSON(file)[property];
}

@Injectable()
export class ConfigService {

  public config: any;
  public configUrl = 'assets/config.json';
  constructor(private http: HttpClient) {
  }

  loadJSON(filePath) {
    const json = this.loadTextFileAjaxSync(filePath, "application/json");
    return JSON.parse(json);
  }

  loadTextFileAjaxSync(filePath, mimeType) {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", filePath, false);
    if (mimeType != null) {
      if (xmlhttp.overrideMimeType) {
        xmlhttp.overrideMimeType(mimeType);
      }
    }
    xmlhttp.send();
    if (xmlhttp.status == 200) {
      return xmlhttp.responseText;
    }
    else {
      return null;
    }
  }
}
