import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-propple-policies',
  templateUrl: './propple-policies.component.html',
  styleUrls: ['./propple-policies.component.scss']
})
export class PropplePoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
