import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MortgageGlobalService {
  @Output() openSplitLoanModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() splitLoanModelDataEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadMortgageHeaderEvent: EventEmitter<any> = new EventEmitter();
  @Output() saveMortgageHeaderEvent: EventEmitter<any> = new EventEmitter();
  @Output() openArchiveDetailsEvent: EventEmitter<any> = new EventEmitter();
  @Output() archiveMortgageModelCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() addMortgageDetailsEvent: EventEmitter<any> = new EventEmitter();
  @Output() showMortgageMessageEvent: EventEmitter<any> = new EventEmitter();
  @Output() hideMortgageModelsEvent: EventEmitter<any> = new EventEmitter();
  @Output() openAddSplitMortgageModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() addMortgageDetailsModelCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() propertyAddressChangeInMortgageEvent: EventEmitter<string> = new EventEmitter();
  @Output() editSplitMortgageDetailsEvent: EventEmitter<any> = new EventEmitter();
  @Output() editMortgageDetailsModelCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() refreshArchiveMortgageListEvent: EventEmitter<any> = new EventEmitter();
  @Output() openAddMortgageDetailsModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() editMortgageDetailsEvent: EventEmitter<any> = new EventEmitter();
  @Output() openMortgageDetailsImportModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() saveMortgageHeaderDetailsAfterEvent: EventEmitter<any> = new EventEmitter();
  @Output() openSplitMortgageDetailsImportModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() returnRedrawEvent: EventEmitter<any> = new EventEmitter();
  @Output() loadMortgageSharedHeaderEvent: EventEmitter<any> = new EventEmitter();
  @Output() saveMortgageHeaderFormEvent: EventEmitter<any> = new EventEmitter();
  @Output() openMortgageHeaderModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openArchiveMortgageModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() splitLoanModelOpenEvent: EventEmitter<any> = new EventEmitter();
  @Output() openMortgageDetailsFormForArchiveEvent: EventEmitter<any> = new EventEmitter();
  constructor() { }

  openAddSplitMortgageDetailsModel(data){
    this.openAddSplitMortgageModalEvent.emit(data);
  }

  addMortgageDetailsModelClose(data) {
    this.addMortgageDetailsModelCloseEvent.emit(data);
  }

  hideModels(data){
    this.hideMortgageModelsEvent.emit(data);
  }

  openSplitLoanModel(data){
    this.openSplitLoanModelEvent.emit(data);
  }

  saveSplitMortgage(value: any) {
    this.splitLoanModelDataEvent.emit(value);
  }

  loadMortgageHeaderData(data) {
    this.loadMortgageHeaderEvent.emit(data);
  }

  saveMortgageHeaderData(data) {
    this.saveMortgageHeaderEvent.emit(data);
  }

  openArchiveDetailsModel(data){
    this.openArchiveDetailsEvent.emit(data);
  }

  archiveMortgageModelClose(data) {
    this.archiveMortgageModelCloseEvent.emit(data)
  }

  addMortgageDetails(data) {
    this.addMortgageDetailsEvent.emit(data)
  }

  showMortgageMessage(data) {
    this.showMortgageMessageEvent.emit(data)
  }

  mortgagePropertyAddressChange(property_ref){
    this.propertyAddressChangeInMortgageEvent.emit(property_ref);
  }

  openEditSplitMortgageModal(data){
    this.editSplitMortgageDetailsEvent.emit(data);
  }

  refreshArchiveMortgageList(data){
    this.refreshArchiveMortgageListEvent.emit(data);
  }

  openAddMortgageDetailsModal(data){
    this.openAddMortgageDetailsModalEvent.emit(data);
  }

  openEditMortgageDetailsModal(data){
    this.editMortgageDetailsEvent.emit(data);
  }

  openMortgageDetailsImportModel(data) {
    this.openMortgageDetailsImportModelEvent.emit(data);
  }


  saveMortgageHeaderDetails(data) {
    this.saveMortgageHeaderDetailsAfterEvent.emit(data)
  }

  openSplitMortgageDetailsImportModel(data) {
    this.openSplitMortgageDetailsImportModelEvent.emit(data);
  }

  returnRedraw(data){
    this.returnRedrawEvent.emit(data);
  }

  saveMortgageHeaderForm(data) {
    this.saveMortgageHeaderFormEvent.emit(data);
  }

  openMortgageHeaderModel(data) {
    this.openMortgageHeaderModelEvent.emit(data);
  }

  openArchiveMortgageModel(data) {
    this.openArchiveMortgageModelEvent.emit(data);
  }

  splitLoanModelOpen(data){
    this.splitLoanModelOpenEvent.emit(data);
  }

  openMortgageDetailsFormForArchive(data) {
    this.openMortgageDetailsFormForArchiveEvent.emit(data);
  }
}
