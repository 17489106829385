import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GlobalService} from "../../global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {HelperService} from "../../helper.service";
import {FormArray, FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {SupplierGlobalService} from "../../supplier-global.service";
import {PropertyService} from "../../../views/app/properties/property.service";
import {Supplier_list} from "../../../views/app/properties/property-rental/property-rental.component";
import {ModalDirective} from "ngx-bootstrap/modal";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-expense-payment-view',
  templateUrl: './expense-payment-view.component.html',
  styleUrls: ['./expense-payment-view.component.scss']
})
export class ExpensePaymentViewComponent implements OnInit,OnDestroy {
  expenseForm:FormGroup;
  submitted = false;
  is_custom_pick_list = false;
  category_type:any = [];
  @ViewChild('expenseModal') expenseModal: ModalDirective;
  @ViewChild('articleModel') articleModel: ModalDirective;
  suppliers:  any = [];
  responseWaiting = '';
  come_from = "";
  property_ref = null;
  expenseCategoryKey = null;
  tempExpenseCategories:any = [];
  isOpenSupplierModal = false;
  @ViewChild('expenseCategoryForm', {read: NgForm}) expenseCategoryForm: any;
  rowKey = null;
  isOpenSubModal = false;
  suppliersTemp: any = [];
  financial_year = null;
  property_address = null;
  property_key = null;
  excelFiles = [];
  expenseArray: any = [];
  isImageLoadingBarShow = false;
  showMsg = false;
  total_amount = 0;
  clickImport = false;
  eofy_report_ref = false;
  financial_year_position = 0;
  isSort = false;
  isFilter = false;
  expenseCategoryList: any = [];
  isExpenseChecked = false;
  articles: any = [];
  myWindow: any;
  subsVar:Subscription;
  constructor( private globalService: GlobalService,
               private spinner: NgxSpinnerService,
               private notifications: NotificationsService,
               private translate: TranslateService,
               private helperService: HelperService,
               private fb: FormBuilder,
               private supplierGlobalService: SupplierGlobalService,
               private propertyService: PropertyService) {
    this.initMortgageHeaderForm();
    this.total_amount = 0;
    this.property_key = null;
    this.isFilter = false;
    this.isSort = false;
  this.subsVar =   this.globalService.openExpenseViewModelForExpensePaymentEvent.subscribe(data=>{
      this.documentsControl.clear();
      this.submitted = false;
      this.come_from = data['come_from'];
      this.property_ref = data['property_ref'];

      if( data['come_from'] == 'eofy'){
        this.financial_year = data['financial_year'];
        this.property_address = data['property_address'];
        this.property_key = data['key'];
        this.total_amount = data['total_amount'];
        this.eofy_report_ref = data['eofy_report_ref'];
        this.financial_year_position = data['financial_year_position'];
        this.isExpenseChecked = data['is_expense_checked'];
        this.getExpensesForEofy();
      }
      else{
        this.generateExpense(data['expense_data']);
        this.getExpenseFormData(data['property_ref']);
      }

    });

    this.supplierGlobalService.selectSupplierEvent.subscribe(data => {
      if(data['comeFrom'] == 'mortgage-expense'){
        this.setSelectedExpenseSupplier(data);
      }
    });

    this.supplierGlobalService.customizeSupplierEvent.subscribe(data => {
      // if (data['comeFrom'] == 'rental-expense') {
      this.isOpenSubModal = data;
      // }
    });
    this.expenseForm.patchValue({
      filter_expense_category: 'Show All'
    });
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    if (this.subsVar){
      this.subsVar.unsubscribe();
    }
  }
  initMortgageHeaderForm(){
    this.expenseForm = this.fb.group({
      is_sort: [false],
      is_expense_checked: [false],
      filter_expense_category: [],
      expenseList: this.fb.array([])
    });
  }

  get documentsControl(): FormArray {
    return this.expenseForm.get("expenseList") as FormArray;
  }


  getExpenseFormData(property_ref) {
    if(property_ref != null){
      this.globalService.getExpenseFormData(property_ref).subscribe(
        response => {
          this.category_type = response['category'];
          this.suppliers = response['suppliers'];
          this.suppliersTemp = response['suppliers'];
          this.is_custom_pick_list = response['is_custom_pick_list'];

          if(this.come_from == 'eofy'){
            this.expenseModal.show();
          }
        },
        error => {
          this.onError(error);
        }
      );
    }
  }

  closeModel() {
    this.come_from = null;
    this.property_ref = null;
    this.expenseModal.hide();
    this.documentsControl.clear();
  }

  trackByFn(item: Supplier_list) {
    return item.supplier_id;
  }

  addNewBank(addedName) {
    return {business_name: addedName,showing_name:addedName, user_supplier_id: '', supplier_id: ''};
  }

  addExpenseCategory(addedName) {
    return {expense_category: addedName, expense_category_id: '', user_id: 'new'};
  }

  addNewExpenseRow(key: any) {
    if(this.expenseForm.value['expenseList'].length > 0){
      let pay_date = null;
      let pay_sdate = null;
      if(this.expenseForm.value['expenseList'][(this.expenseForm.value['expenseList'].length-1)].expense_date!=null){
        pay_date = this.expenseForm.value['expenseList'][(this.expenseForm.value['expenseList'].length-1)].expense_date;
        pay_sdate = this.helperService.extractDateString(this.expenseForm.value['expenseList'][(this.expenseForm.value['expenseList'].length-1)].expense_date)
      }
      this.documentsControl.insert(key+1,this.fb.group({
        'expense_date':[pay_date],
        'expense_category': [null],
        'expense_category_id':[null],
        'expense_amount': [null],
        'description': [null],
        'supplier_id':[null],
        'business_name': [null],
        'payment_date': pay_sdate,
        'expense_ref': '',
        'is_error': false,
        'error_message': '',
      }));
    }
    else {
      this.createEmptyRow();
    }
  }

  removeExpense(item,key) {
    console.log(item);
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete this expense payment!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        if(this.documentsControl.controls[key].get('expense_ref').value != ''){
          let data = {
            expense_ref: this.documentsControl.controls[key].get('expense_ref').value,
            property_ref : this.property_ref,
            financial_year: this.financial_year,
          }
          this.spinner.show();
          this.globalService.deleteExpenseEofy(data).subscribe(response => {
              this.spinner.hide();
              this.total_amount = Number(response['total_expense'].toFixed(2));
              this.financial_year_position = Number(response['financial_year_position'].toFixed(2));
              this.documentsControl.removeAt(key);
              this.onSuccess('Expense deleted successfully');
            },
            error => {
              this.spinner.hide();
              this.onError(error);
            }
          );
        }
        else{
          this.documentsControl.removeAt(key);
        }
      }
    }, function (desmiss) { });

  }

  copyRow(key: any) {
    this.documentsControl.insert(key+1,this.fb.group({
      'expense_date':[ this.documentsControl.value[key].expense_date],
      'expense_category': [this.documentsControl.value[key].expense_category],
      'expense_category_id':[this.documentsControl.value[key].expense_category_id],
      'expense_amount': [this.documentsControl.value[key].expense_amount],
      'description': [this.documentsControl.value[key].expense_note],
      'supplier_id':[this.documentsControl.value[key].supplier_id],
      'business_name': [this.documentsControl.value[key].business_name],
      'expense_ref': '',
      'payment_date': null,
      'is_error': false,
      'error_message': '',
    }));
  }

  addExpenseDetailSubmit() {
    this.submitted = true;
    if (!this.expenseForm.valid) {
      return;
    }

    let detailsArray = [];
    detailsArray = [...this.documentsControl.value];
    if (this.documentsControl.length > 0) {
      detailsArray.forEach(value => {
        if (value.expense_date != null) {
          value['expense_date_string'] = this.helperService.extractDateString(value.expense_date);
        }
      });
    }

    let data = {
      'expense_data': detailsArray,
      'property_ref': this.property_ref,
    }
    // let formData = new NgForm();
    // formData.append('expense_data', JSON.stringify(detailsArray));
    // formData.append('come_from', this.property_ref);
    // console.log(formData);
    this.globalService.saveNewExpensePayment(data).subscribe(res=>{
        this.onSuccess("Expense payment saved successfully");
        this.expenseModal.hide();
      },
      error => {
        this.onError(error);
      });
  }

  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  private generateExpense(data) {
    data.forEach((val, index) => {
      let date = null;
      if(val.expense_date != null){
        date = this.helperService.formDateObject(val.expense_date);
      }


      let data = {
        'expense_date':date,
        'expense_category': val.expense_category,
        'expense_category_id': val.expense_category_id,
        'expense_amount': val.expense_amount,
        'description': val.expense_note,
        'supplier_id': val.supplier_id,
        'business_name': val.business_name,
        'expense_ref': '',
        'payment_date': null,
        'is_error': false,
        'error_message': '',
      }
      this.documentsControl.push(this.createExpenseForEdit(data));
    });

    this.expenseModal.show();

  }

  createExpenseForEdit(data){
    return this.fb.group({
      'expense_date': [data.expense_date,Validators.required],
      'expense_category': [data.expense_category,Validators.required],
      'expense_category_id': [data.expense_category_id],
      'expense_amount': [data.expense_amount,Validators.required],
      'description': [data.description],
      'supplier_id': [data.supplier_id],
      'business_name': [data.business_name,Validators.required],
      'expense_ref': [data.expense_ref],
      'payment_date': data.payment_date,
      'is_error': [data.is_error],
      'error_message': [data.error_message],
    });
  }

  createEmptyRow(){
    return this.fb.group({
      'expense_date': [null,Validators.required],
      'expense_category': [null,Validators.required],
      'expense_category_id': [null],
      'expense_amount': [null,Validators.required],
      'description': [null],
      'supplier_id': [null],
      'business_name': [null,Validators.required],
      'expense_ref': '',
      'payment_date': null,
      'is_error': false,
      'error_message': '',
    });
  }

  openExpenseCategoryModel(expenseCategoryModel: ModalDirective, modelType, key) {
    this.isOpenSubModal = true;
    this.expenseCategoryForm.reset();
    this.expenseCategoryKey = null;
    this.tempExpenseCategories = [];
    for (let a = 0; a < this.category_type.length; a++) {
      //  this.category_type[a]['user_id'] = this.category_type[a]['created_user_id']
      if (this.category_type[a]['user_id'] != null) {
        this.tempExpenseCategories.push(this.category_type[a]);
      }
    }

    this.isOpenSupplierModal = true;
    this.rowKey = key;
    expenseCategoryModel.show();
  }

  closeExpenseCategoryModel(expenseCategoryModel: ModalDirective) {
    this.rowKey = null;
    this.expenseCategoryKey = null;
    this.tempExpenseCategories = [];
    this.isOpenSubModal = false;
    expenseCategoryModel.hide();
  }

  expensesFormSubmit(form,expenseCategoryModel) {
    var data = {
      expense_category: form.value['expense_category_new']
    }
    this.spinner.show();
    this.globalService.saveNewExpenseCategory(data).subscribe(res => {
      this.spinner.hide();
      if (res['status'] == 200) {
        this.onSuccess('Expense Category Save successful');
        this.category_type = res['category_list'];
        this.expenseCategoryKey = null;
        // this.documentsControl.value[this.rowKey].expense_category= form.value['expense_category_new'];
        ((this.expenseForm.get('expenseList') as FormArray).at(this.rowKey) as FormGroup).get('expense_category').patchValue(form.value['expense_category_new']);
        this.isOpenSubModal = false;
        this.closeExpenseCategoryModel(expenseCategoryModel);
      }
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
    });
  }

  deleteExpenseCategories(expense_category_id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete this expense category changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.globalService.deleteExpenseCategory(expense_category_id).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Expense Category Edit successful');
            this.category_type = res['category_list'];
            this.expenseCategoryKey = null;
            ((this.expenseForm.get('expenseList') as FormArray).at(this.rowKey) as FormGroup).get('expense_category').patchValue(null);
            this.documentsControl.value[this.rowKey].expense_category = null;
            this.tempExpenseCategories = [];
            for (let a = 0; a < this.category_type.length; a++) {
              if (this.category_type[a]['user_id'] != null) {
                this.tempExpenseCategories.push(this.category_type[a]);
              }
            }
          }
          else {
            this.onError(res['message']);
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
        });
      }
    }, function (dismiss) { });
  }

  saveExpenseCategories(data, form: NgForm, expenseCategoryModel: ModalDirective) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to save this expense category changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        let formData = {
          'expense_category_id': data['expense_category_id'],
          'expense_category': form.value['expense_category'],
        };
        this.globalService.saveNewExpenseCategory(formData).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Expense Category Edit successful');
            this.category_type = res['category_list'];
            this.expenseCategoryKey = null;
            ((this.expenseForm.get('expenseList') as FormArray).at(this.rowKey) as FormGroup).get('expense_category').patchValue(form.value['expense_category']);
            this.isOpenSubModal = false;

            if( res['category_list'].length > 0){
              let cat_list = res['category_list'];
              cat_list.forEach((element) => {

                let index1 = this.expenseCategoryList.findIndex(x => x['expense_category_name'] == element['expense_category_name']);
                if(index1 == -1){
                  this.expenseCategoryList.push(element);
                }
              });
            }
            this.closeExpenseCategoryModel(expenseCategoryModel);
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }

  openSupplierListModel(key) {
    let data = {
      'comeFrom':'mortgage-expense',
      'supplier_type':'all',
      'property_ref':this.property_ref,
      'key':key
    }

    this.supplierGlobalService.openSupplierListModel(data);
  }

  setSelectedExpenseSupplier(data) {
    if (data['supplier'] != null) {
      let agent_data = {
        'company_name': data['supplier'].company_name,
        'user_supplier_id': data['supplier'].user_supplier_id,
        'supplier_id': data['supplier'].supplier_id,
        'supplier_type':'all'
      }

      this.globalService.getSupplierList(agent_data).subscribe(res=>{
          this.suppliers = res['suppliers'];
          ((this.expenseForm.get('expenseList') as FormArray).at(data['key']) as FormGroup).get('business_name').patchValue(data['supplier'].company_name);
          // this.expensesData[data['key']]['supplier_id'] = data['supplier'].supplier_id;
          // this.expensesData[data['key']]['full_name'] = data['supplier'].company_name;
          // this.supplier = data['supplier'].company_name;
        },
        error=>{
          this.onError(error.message);
        });
    }
    else {
      // this.expensesData[data['key']]['supplier_id'] = null;
      ((this.expenseForm.get('expenseList') as FormArray).at(data['key']) as FormGroup).get('business_name').patchValue(null);
    }
  }

 async itemChange(event,key) {
    if(event != undefined){
      if(event.supplier_id == ""){
        let data = {
          'comeFrom':'mortgage',
          'supplier_type_id':7,
          'supplier_type':'Banks & Financial Institutions',
          'supplier_name':event['business_name'],
          'property_ref':this.property_ref,
          'key':key
        }
        this.supplierGlobalService.openNewSupplierModel(data);
      }
      else {
        let details = {
          'business_name': event['business_name'],
          'user_supplier_id': event['user_supplier_id'],
          'supplier_id': event['supplier_id'],
        }
        this.suppliersTemp.push(event);
        ((this.expenseForm.get('expenseList') as FormArray).at(key) as FormGroup).get('business_name').patchValue(event['business_name']);
      }
    }

    this.suppliers = [...this.suppliersTemp];
  }

  searchAgent(event) {
    if (event['srcElement']['value'] != "") {
      // console.log(event['srcElement']['value'])
      // this.spinner.show();
      let data = {
        'searchKey': event['srcElement']['value'],
        'supplier_type': 'all',
      }
      this.globalService.searchAgent(data).subscribe(response => {
        this.suppliers = response['agents'];
        // this.selectedSupplierMerge();
        // this.spinner.hide();
      }, err => {
        this.onError(err.message);
        // this.spinner.hide();
      });
    }
  }

  getExpensesForEofy() {
    this.spinner.show();
    this.globalService.getExpensesForEofy(this.property_ref,this.financial_year).subscribe(res => {
      this.spinner.hide();
      this.expenseModal.show();
      this.expenseForm.reset();
      this.documentsControl.clear();
      this.category_type = [];
      this.suppliers = [];
      this.category_type = res['category'];
      this.suppliers = res['suppliers'];
      this.suppliersTemp = res['suppliers'];
      this.appendExpense(res['expenses']);
      this.is_custom_pick_list = res['is_custom_pick_list'];
      this.expenseCategoryList = res['category'];

      this.expenseForm.patchValue({ filter_expense_category:'Show All' });
      this.expenseForm.patchValue({ is_expense_checked: this.isExpenseChecked });

    }, err => {
      this.spinner.hide();
      this.onError(err.message);
    });
  }

  async importExcel(event) {
    this.excelFiles = [];
    this.excelFiles.push(...event.addedFiles);
    await this.addSelectedExcelFile(this.excelFiles)
  }

  async addSelectedExcelFile(files) {
    let selectedExFile = [];
    // selectedExFile = files;
    this.expenseArray = [];
    let formData = new FormData();
    let i;
    for (i = 0; i < files.length; i++) {
      formData.append('file', files[i], files[i].name);
      await this.uploadExcel(formData);
    }
  }

  uploadExcel(formData) {
    this.isImageLoadingBarShow = true;
    this.showMsg = false;
    this.expenseArray = [];
    formData.append('property_ref', this.property_ref);
    formData.append('financial_year', this.financial_year);
    this.globalService.expenseUploadFile(formData).subscribe(response => {
        // if (response['status'] === 200) {
        this.isImageLoadingBarShow = false;
        if(response['expense_list'].length > 0){
          this.appendExpense(response['expense_list']);
        }

        let sup_list = response['suppliers'];
        if(response['suppliers'].length > 0){
          sup_list.forEach((element) => {
            let index = this.suppliersTemp.findIndex(x => x['business_name'] == element['business_name']);
            if(index == -1){
              this.suppliers.push(element);
              this.suppliersTemp.push(element);
            }
          });
        }

        let cat_list = response['category_type'];
        if(response['category_type'].length > 0){
          cat_list.forEach((element) => {
            let index = this.category_type.findIndex(x => x['expense_category_name'] == element['expense_category_name']);
            if(index == -1){
              this.category_type.push(element);
            }

            let index1 = this.expenseCategoryList.findIndex(x => x['expense_category_name'] == element['expense_category_name']);
            if(index1 == -1){
              this.expenseCategoryList.push(element);
            }
          });
        }
        // // this.expenseArray = response['expense_list'];
        // this.expenseArray = response['expense_list'];
        // this.category_type = response['category_type'];
        // this.suppliers = response['suppliers'];
        // this.expenseArray.forEach((val, index) => {
        //   if (val.expense_date != null) {
        //     var d = new Date(val.expense_date);
        //     // val['expense_date'] = d.getDate() + '/' + (d.getMonth() + 1) + '/' +  d.getFullYear();
        //     val['payment_date'] = d;
        //   }
        //   else {
        //     val['payment_date'] = null;
        //   }
        // });
        // }
        // else if (response['status'] === 201) {
        //   this.isImageLoadingBarShow = false;
        //   // this.message=response['message'];
        //   this.showMsg = true;
        //
        // }
      },
      err => {
        this.isImageLoadingBarShow = false;
        this.onError(err.message);
      });
  }

  appendExpense(data) {
    data.forEach((val, index) => {
      let date = null;
      let payDate = null;
      if(val.expense_date != null){
        date = this.helperService.formDateObject(val.expense_date);
        let sdate = val.expense_date.split(' ');
        payDate = sdate[0];
      }
      let data = {
        'expense_date':date,
        'expense_category': val.expense_category,
        'expense_category_id': val.expense_category_id,
        'expense_amount': val.expense_amount,
        'description': val.expense_note,
        'supplier_id': val.supplier_id,
        'business_name': val.business_name,
        'expense_ref': val.expense_ref,
        'payment_date': payDate,
        'is_error': false,
        'error_message': '',
      }
      this.documentsControl.push(this.createExpenseForEdit(data));
    });
  }

  getLastExpense(item, index) {
    let categoryId;
    this.category_type.forEach(category => {
      if (category.expense_category == item.get('expense_category').value) {
        categoryId = category.expense_category_id;
      }
    });

    // this.expenseCategoryList.forEach(category => {
    let index1 = this.category_type.findIndex(x => x['expense_category_name'] == item.get('expense_category').value);
    if(index1 != -1){
      this.expenseCategoryList.push(item);
    }
    // });

    let details = {
      property_ref:this.property_ref,
      expense_category_id: categoryId,
      supplier_temp: this.suppliersTemp,
    }

    this.globalService.getLastExpenseDetail(details).subscribe(response => {
      if (response['status'] === 200) {
        this.suppliers = response['suppliers'];
        if(response['last_expense_details'] != null){
          this.documentsControl.controls[index].get('supplier_id').setValue(response['last_expense_details']['supplier_id']);
          this.documentsControl.controls[index].get('business_name').setValue(response['last_expense_details']['business_name']);
          if(index == -1) {
            let state = null;
            if(response['last_expense_details']['state'] != null){
              state = '('+response['last_expense_details']['state']+')';
            }

            let data = {
              'business_name': response['last_expense_details']['business_name'],
              'supplier_id': response['last_expense_details']['supplier_id'],
              'user_supplier_id': response['last_expense_details']['supplier_id'],
              'showing_name':response['last_expense_details']['business_name'] + ' ' + state,
            }
            this.suppliersTemp.push(data);
            // this.selectedSupplierMerge();
          }
        }

        this.spinner.hide();
      }

    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
    });
  }

  addExpenseDetailSubmitForEofy() {

    this.submitted = true;
    if(!this.expenseForm.valid){
      return;
    }

    let duplicateCount = 0;
    this.documentsControl.controls.forEach((val, key) => {
      let val_date = new Date(val.get('expense_date').value);
      this.documentsControl.controls.forEach((item, index) => {
        let date_1 = new Date(item.get('expense_date').value);
        if(key != index){
          if(val.get('expense_category').value == item.get('expense_category').value && val.get('expense_amount').value == item.get('expense_amount').value && val.get('business_name').value == item.get('business_name').value && val.get('description').value == item.get('description').value &&val_date.getTime() == date_1.getTime()){
            duplicateCount++;
            this.documentsControl.controls[index].get('is_error').setValue(true);
            this.documentsControl.controls[index].get('error_message').setValue("duplicate with row " + (index + 1));
          }
        }
      });
    });


    if(duplicateCount > 0){
      return;
    }


    let count = 0;
    this.documentsControl.controls.forEach((val, key) => {
      if(this.isDateValidationBoolean(val)){
        val.get('is_error').setValue(true);
        val.get('error_message').setValue("Invalid date format");
        count++;
      }
    });

    if(count > 0){
      return;
    }

    this.documentsControl.controls.forEach((val, key) => {
      val.get('payment_date').setValue(this.helperService.extractDateString(val.get('expense_date').value));
    });

    let data = {
      'property_ref': this.property_ref,
      'data_array': this.documentsControl.value,
      'eofy_report_ref': this.eofy_report_ref,
      'financial_year': this.financial_year,
      'is_expense_checked': this.isExpenseChecked,
    }

    this.spinner.show();
    this.globalService.addExpenseDetailSubmitForEofy(data).subscribe(response => {
      this.spinner.hide();
      if(response['duplicate_expense']!= null){
        this.expenseForm.reset();
        this.documentsControl.clear();
        this.appendExpense(response['duplicate_expense']);
        this.total_amount = Number(response['expense_sum']);
        this.financial_year_position = Number(response['financial_year_position'].toFixed(2));
        this.onWarning('These expenses are already added for this property. Please check and try again.')
      }
      else{
        this.expenseForm.reset();
        this.documentsControl.clear();
        this.expenseModal.hide();
        this.total_amount = Number(response['expense_sum']);
        this.financial_year_position = Number(response['financial_year_position'].toFixed(2));
        this.onSuccess('Expense added successfully');
        this.closeModelForEofy();
      }
    }, err => {
      this.spinner.hide();
    });

  }

  isDateValidationBoolean(data){
    let year = new Date(this.helperService.extractDateString(data.get('expense_date').value)).getFullYear().toString();

    if((data.get('expense_date').value!= '' || data.get('expense_date').value!= null) && (year.length < 4)){
      return true;
    }else {
      return false;
    }
  }

  isDateValidation(data, key) {
    if(this.isDateValidationBoolean(data)){
      data.get('is_error').setValue(true);
      data.get('error_message').setValue("Invalid date format");
    }
  }

  closeModelForEofy() {
    this.expenseModal.hide();
    let data = {
      'expense_sum': this.total_amount,
      'selected_row': this.property_key,
      'financial_year_position': this.financial_year_position,
      'is_expense_checked' : this.isExpenseChecked
    };
    this.globalService.closeExpenseForEofyModal(data);
  }

  sortExpense(event) {
    if( event){
      this.documentsControl.controls.sort((a, b) => {
        let dateA = a.get('payment_date').value, dateB = b.get('payment_date').value;
        let categoryA = a.get('expense_category').value, categoryB = b.get('expense_category').value;
        let supplierA = a.get('business_name').value, supplierB = b.get('business_name').value;
        if (categoryA < categoryB) return -1;
        if (categoryA > categoryB) return 1;
        if (supplierA < supplierB) return -1;
        if (supplierA > supplierB) return 1;
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        return 0;
      });
    }
    else {
      this.documentsControl.controls.sort((a, b) => {
        let dateA = a.get('payment_date').value, dateB = b.get('payment_date').value;
        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        return 0;
      });
    }
  }


  dateChange(item, index) {
    if( this.documentsControl.controls[index].get('expense_date').value != null && this.documentsControl.controls[index].get('expense_date').value != ''){
      this.documentsControl.controls[index].get('payment_date').setValue(this.helperService.extractDateString(this.documentsControl.controls[index].get('expense_date').value));

      if(!this.helperService.dateValidateForEOFYear(this.documentsControl.controls[index].get('expense_date').value, this.financial_year)){
        Swal.fire({
          title: 'Out of Financial Year!',
          text: 'The entry you just added is not for the this financial year, and will not show after save',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((isConfirm) => {
        });
      }
    }
  }

  addNewRow() {
    let data = {
      'expense_date':null,
      'expense_category': null,
      'expense_category_id': null,
      'expense_amount': null,
      'description': null,
      'supplier_id': null,
      'business_name': null,
      'expense_ref': '',
      'payment_date': null,
      'is_error': false,
      'error_message': '',
    }
    this.documentsControl.push(this.createExpenseForEdit(data));
  }

  filterExpense() {
    if(this.expenseForm.get('filter_expense_category').value != ''){
      this.isFilter = true;
    }
    else {
      this.isFilter = false;
    }
  }

  cancelFilter() {
    this.expenseForm.get('filter_expense_category').setValue('');
    this.isFilter = false;
  }

  getArticleDetails() {
    this.isOpenSubModal = true;
    this.spinner.show();
    this.globalService.getArticleDetails(23).subscribe(response => {
      this.spinner.hide();
      this.articles = response['learning_articles'];
      this.articleModel.show();
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
    });
  }
  alertWindowOpen(data) {
    // this.articleModel.hide();
    this.myWindow = window.open("", "MsgWindow", "width=800,height=600",);
    this.myWindow.document.write(data['content']);

    let articleData = {
      'keyWord': null,
      'form_id': 23,
      'article_ref': data['article_ref'],
      'from_form': 'form',
    };

    this.propertyService.countProppelLearningArticleView(articleData).subscribe(res => {
      this.articles = res['learning_articles'];
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
    });
  }

  validateNumberFormat(index) {
    let value = this.documentsControl.controls[index].get('expense_amount').value;
    console.log(value);
    let newValue = this.globalService.validateNumberFormat(value);
    this.documentsControl.controls[index].get('expense_amount').setValue(newValue);
  }
}
