import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {NgxSpinnerService} from "ngx-spinner";
import { PropertyCalculationService } from 'src/app/shared/property-calculation.service';
import {GlobalService}from "../../../../shared/global.service";
import {PropertyService} from "../../../../views/app/properties/property.service";


@Component({
  selector: 'app-mortgage-total',
  templateUrl: './mortgage-total.component.html',
  styleUrls: ['./mortgage-total.component.scss']
})
export class MortgageTotalComponent implements OnInit {
  mortgageListForm: FormGroup;
  responseWaiting = '';
  buttonDisabled = false;
  @ViewChild('mortgageModel') mortgageModel: ModalDirective;

  constructor(private propertyCalculationService: PropertyCalculationService,
              private modalService: BsModalService,
              private notifications: NotificationsService,
              private spinner: NgxSpinnerService,
              private propertyService: PropertyService,
              private fb: FormBuilder,
              private globalService: GlobalService) {
    this.initForm();
    this.propertyCalculationService.openModelEvent.subscribe(data => {
      this.getMortgageList();
    });
  }

  initForm() {
    this.mortgageListForm = this.fb.group({
      total_current_loan: [''],
      total_monthly_repayment: [''],
      mortgageList: this.fb.array([]),
    });
  }

  get addMortgageListList(): any {
    return this.mortgageListForm.get('mortgageList') as FormArray;
  }


  ngOnInit(): void {
  }

  closeModel() {
    this.mortgageModel.hide();
    const data = {
      default:true,
      submit:false
    }
    this.propertyCalculationService.closeListOfPropertyValueModel(data);
  }

  private getMortgageList() {
    this.addMortgageListList.clear();
    this.mortgageListForm.reset();
    this.spinner.show();
    this.propertyService.getMortgageList().subscribe(response => {
      var property_list = response['mortgage_list'];
      property_list.forEach((data) => {
        this.addMortgageListList.push(this.createMortgageList(data));
      });

      this.mortgageListForm.patchValue(response);
      this.spinner.hide();
      this.mortgageModel.show();
    }, error => {
      this.onError(error);
      this.spinner.hide();
    });

  }

  onSuccess(content) {
    this.notifications.create('Success', content, NotificationType.Success, {
      theClass: 'outline primary',
      timeOut: 6000,
      showProgressBar: true
    });
  }

  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, {
      theClass: 'outline primary',
      timeOut: 6000,
      showProgressBar: true
    });
  }

  createMortgageList(data){
    return this.fb.group({
      'monthly_repayments': [this.globalService.validateNumberFormat(data.monthly_repayments),Validators.required],
      'current_loan_balance': [this.globalService.validateNumberFormat(data.current_loan_balance),Validators.required],
      'fixed_current_balance': [this.globalService.validateNumberFormat(data.fixed_current_balance)],
      'business_name': [data.business_name],
      'property_address': [data.property_address],
      'property_ref': [data.property_ref],
      'mortgage_type_id': [data.mortgage_type_id],
      'fixed_interest_rate': [data.fixed_interest_rate],
      'fixed_monthly_repayment': [this.globalService.validateNumberFormat(data.fixed_monthly_repayment)],
      'loan_interest_rate': [data.loan_interest_rate],
      'mortgage_id': [data.mortgage_id],
      'mortgage_ref': [data.mortgage_ref],
      'variable_current_balance': [this.globalService.validateNumberFormat(data.variable_current_balance)],
      'variable_interest_rate': [data.variable_interest_rate],
      'variable_monthly_repayment': [this.globalService.validateNumberFormat(data.variable_monthly_repayment)],
      'fixed_mortgage_type': [data.fixed_mortgage_type],
      'variable_mortgage_type': [data.variable_mortgage_type],
      'loan_term': [data.loan_term],
    });
  }

  calculateTotalLoanBalance(index) {
    if(this.mortgageListForm.value['mortgageList'][index].current_loan_balance!=null && this.mortgageListForm.value['mortgageList'][index].current_loan_balance!=''){
      let total_balance = 0;
      this.mortgageListForm.value.mortgageList.forEach((data) => {
        total_balance += data.current_loan_balance;
      });

      this.mortgageListForm.patchValue({total_current_loan: total_balance});
    }

  }

  calculateTotalRepayment(index) {
    if(this.mortgageListForm.value['mortgageList'][index].monthly_repayments!=null && this.mortgageListForm.value['mortgageList'][index].monthly_repayments!=''){
      let total_repayments = 0;
      this.mortgageListForm.value.mortgageList.forEach((data) => {
        total_repayments += data.monthly_repayments;
      });

      this.mortgageListForm.patchValue({total_monthly_repayment: total_repayments});
    }
  }

  mortgageValueSubmit() {
    this.buttonDisabled = false;
    this.responseWaiting = 'show-spinner';
    this.spinner.show();
    this.propertyService.updateMortgageList(this.mortgageListForm.value).subscribe(response => {
        this.responseWaiting = '';
        this.spinner.hide();
        this.onSuccess("saved successfully");
        this.mortgageModel.hide();
        const data = {
          default:true,
          submit:true
        }
        this.propertyCalculationService.closeListOfPropertyValueModel(data);
      },
      error => {
        this.spinner.hide();
        this.onError(error);
      });
  }

  calculateMonthlyRepayment(index,rateType) {


  if(rateType != null  && this.addMortgageListList.controls[index].get('mortgage_type_id').value != 3){
      let loanBalance =  this.addMortgageListList.controls[index].get('current_loan_balance').value;
      let interestRate = this.addMortgageListList.controls[index].get('loan_interest_rate').value;
      let mortgage_type = this.addMortgageListList.controls[index].get('mortgage_type_id').value != null?this.addMortgageListList.controls[index].get('mortgage_type_id').value:1;
      if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
        let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, mortgage_type,this.addMortgageListList.controls[index].get('loan_term').value);
        this.addMortgageListList.controls[index].get('monthly_repayments').setValue(monthlyRepayment == 0?null:this.globalService.validateNumberFormat(monthlyRepayment));
        // this.mortgageListForm.patchValue({monthly_repayments:  monthlyRepayment == 0?null:monthlyRepayment});
      }
    }
  else{
    if(rateType != null && rateType == 'Variable'){
      let loanBalance =  this.addMortgageListList.controls[index].get('variable_current_balance').value;
      let interestRate = this.addMortgageListList.controls[index].get('variable_interest_rate').value;
      let mortgage_type = this.addMortgageListList.controls[index].get('variable_mortgage_type').value != null?this.addMortgageListList.controls[index].get('variable_mortgage_type').value:1;
      if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
        let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, mortgage_type,this.addMortgageListList.controls[index].get('loan_term').value);
        this.addMortgageListList.controls[index].get('variable_monthly_repayment').setValue(monthlyRepayment == 0?null:this.globalService.validateNumberFormat(monthlyRepayment));
        // this.mortgageListForm.patchValue({monthly_repayments:  monthlyRepayment == 0?null:monthlyRepayment});
      }
    }
    else if(rateType != null && rateType == 'fixed'){
      let loanBalance =  this.addMortgageListList.controls[index].get('fixed_current_balance').value;
      let interestRate = this.addMortgageListList.controls[index].get('fixed_interest_rate').value;
      let mortgage_type = this.addMortgageListList.controls[index].get('fixed_mortgage_type').value != null?this.addMortgageListList.controls[index].get('fixed_mortgage_type').value:1;
      if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
        let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, mortgage_type,this.addMortgageListList.controls[index].get('loan_term').value);
        this.addMortgageListList.controls[index].get('fixed_monthly_repayment').setValue(monthlyRepayment == 0?null:this.globalService.validateNumberFormat(monthlyRepayment));
        // this.mortgageListForm.patchValue({monthly_repayments:  monthlyRepayment == 0?null:monthlyRepayment});
      }
    }
  }

    this.calculateTotalRepayment(index);
  }
}
