import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";
import {PropertyGlobalService} from "../../property-global.service";

@Component({
  selector: 'app-add-expense-payment-model',
  templateUrl: './add-expense-payment-model.component.html',
  styleUrls: ['./add-expense-payment-model.component.scss']
})
export class AddExpensePaymentModelComponent implements OnInit {
  expenseForm:FormGroup;
  come_from = "";
  property_ref = null;
  constructor(
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private propertyGlobalService: PropertyGlobalService
  ) {
    this.initForm();
  }

  initForm(){
    this.expenseForm = this.fb.group({
   data_array: this.fb.array([])
    })
  }
  ngOnInit(): void {

  }

  get documentsControl(): FormArray {
    return this.expenseForm.get("data_array") as FormArray;
  }
  createExpenseForEdit(data){
    return this.fb.group({
      'expense_date': [data.expense_date,Validators.required],
      'expense_category': [data.expense_category,Validators.required],
      'expense_category_id': [data.expense_category_id],
      'expense_amount': [data.expense_amount,Validators.required],
      'description': [data.description],
      'supplier_id': [data.supplier_id],
      'business_name': [data.business_name,Validators.required],
      'expense_ref': [data.expense_ref],
      'payment_date': data.payment_date,
      'is_error': [data.is_error],
      'error_message': [data.error_message],
      'pdfs': this.fb.array([])
    });
  }

  createEmptyRow(){
    return this.fb.group({
      'expense_date': [null,Validators.required],
      'expense_category': [null,Validators.required],
      'expense_category_id': [null],
      'expense_amount': [null,Validators.required],
      'description': [null],
      'supplier_id': [null],
      'business_name': [null,Validators.required],
      'expense_ref': '',
      'payment_date': null,
      'is_error': false,
      'error_message': '',
      'pdfs': this.fb.array([])
    });
  }

  public getExpenseInitFormData(data){
    this.spinner.show();
    this.propertyGlobalService
  }
}
