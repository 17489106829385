import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";
import {NewInDevelopementService} from "../new-in-developement.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  filterForm :FormGroup;
  List :any;
  userName = localStorage.getItem('full_name') != undefined ? localStorage.getItem('full_name') : '';
  lastLoginAt = localStorage.getItem('lastLoginAt') != undefined ? localStorage.getItem('lastLoginAt') : '';
  learningArticle: any = [];
  inDevelopmentNotes:any;
  modelRef: NgbModalRef | undefined;
  memo:any;
  name:any;
  percentage :any;
  category:any;
  inCompletedNotes:any;
  is_view = false

  constructor(
    private spinner: NgxSpinnerService,
    private NewInDevelopmentService:NewInDevelopementService,
    private fb : FormBuilder,
    private modalService: NgbModal,
  ) {
    this.filterForm = this.fb.group({
      keyword:['']
    });
  }

  ngOnInit(): void {
    this.getNewInDevelopmentNotes()
  }

  getNewInDevelopmentNotes() {
    this.spinner.show();
    this.NewInDevelopmentService.getNewInDevelopment(this.filterForm.value).subscribe(res=>{
      this.inDevelopmentNotes = res['development_notes'];
      this.inCompletedNotes = res['completed_notes'];
      this.spinner.hide();
    },error => {
      this.spinner.hide();
    })
  }

  clearFilter() {
    this.filterForm.reset();
    this.getNewInDevelopmentNotes();
  }

  viewDevelopmentModal(viewIssuesModal: any, inDevelopmentNote: any) {
    this.name = inDevelopmentNote.name;
    this.memo = inDevelopmentNote.memo;
    this.percentage = inDevelopmentNote.complete;
    this.category = inDevelopmentNote.category;
    this.modelRef = this.modalService.open(viewIssuesModal, {size: 'lg', keyboard: false, backdrop: 'static'});
  }

  viewCompleted() {
    this.is_view = true;
  }

  hideCompleted() {
    this.is_view = false;
  }
}
