import { Injectable } from '@angular/core';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  extractDateString(dateObj) {

    if (dateObj != null)
      return dateObj.year + "-" + dateObj.month + "-" + dateObj.day;
    return "";
  }

  formDateObject(dateStr) {
    let date = new Date(dateStr);
    return { year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate() };
  }

  formDateUtc(dateStr) {
//     if(dateStr != null){
//       console.log('ggg',moment(dateStr));
//       let d = moment(dateStr);
//       console.log(d['Moment']['_i']);
//       return d['Moment']['_i'];
//     }
// console.log('ggg',moment(dateStr));
    return moment(dateStr);
  }

  formDateObjectAddOneMonth(dateStr) {
    let date = new Date(dateStr);
    return { year: date.getFullYear(), month: (date.getMonth() + 2), day: date.getDate() };
  }

  extractTimeString(timeObj) {
    if (timeObj != null)
      return timeObj.hour + ":" + timeObj.minute + ":" + timeObj.seconds
  }

  convert24HrTimeStringToNormal(time) {
    // Check correct time format and split into components
    if (time == null)
      return "";

    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time.splice(3, 1);
    }
    return time.join(''); // return adjusted time or original string
  }

  formTimeObject(time) {

    if (time == null)
      return null;

    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      return { hour: parseInt(time[1]), minute: parseInt(time[3]) }
    }
    return null;
  }

  convertDateTimeToLocal(datetime: Date) {
    let local = moment.utc(datetime).toDate();
    return moment(local).format('YYYY-MM-DD HH:mm:ss');
  }

  addSuffixToDate(day) {
    if (day > 3 && day < 21) return 'th'; // thanks kennebec
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  }

  checkDateEquality(date1: Date, date2: Date) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return (date1.valueOf() == date2.valueOf());
  }

  checkDateTodayOrPassed(date: Date) {
    date = new Date(date);
    let today = new Date();
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (date <= today)
      return true;
    else
      return false;
  }

  calculateLoanEndDate(loan_term, s_date) {
   let start_date = null
    if(s_date!= null){
      let sdate = this.extractDateString(s_date);
      let str_date = new Date(sdate);
      str_date.setMonth((str_date.getMonth() + (loan_term * 12)));
      start_date = this.formDateObject(str_date);
    }

    return  start_date;
    // let start_date = new Date(s_date);
    // // start_date.setMonth((loan_term*12));
    // start_date.setMonth((start_date.getMonth() + (loan_term * 12)));
    // return  start_date;
  }

  calculateCurrentLoanBalance(isRedrawFacility,totalLoanAmount,redrawAmount) {
    // console.log(isRedrawFacility);
    let redraw = 0;
    let currentLoanBalance = null;
    if (totalLoanAmount != null){
      if (isRedrawFacility == 1) {
        if (redrawAmount != null && redrawAmount != undefined) {
          redraw = redrawAmount;
        }
      }

      currentLoanBalance = totalLoanAmount - redraw;
    }
    return currentLoanBalance;
  }

  calculateRedraw(isRedrawFacility,totalLoanAmount,currentLoanBalance){
    let redraw = null;
    if (totalLoanAmount != null){
      if (isRedrawFacility == 1) {
        redraw = totalLoanAmount - currentLoanBalance;
      }
      else {
        redraw = 0;
      }
    }

    return redraw;
  }

  dateToISOString(date) {
    let strDate = null;
    let d = new Date(date);
    if(d.toString() == 'Invalid Date'){
      let splitted = date.split("/");
      let stDate = splitted[2]+'/'+ splitted[1]+'/'+splitted[0];
      let a = new Date(stDate);
      a.setDate(a.getDate() + 1);
      strDate = a.toISOString();
    }
    else {
      d.setDate(d.getDate() + 1);
      strDate = d.toISOString();
    }
    return strDate;
    // let strDate = null;
    // console.log('dddd',date);
    // let d = new Date(date);
    // let dstr = d.toString();
    // console.log('ccd',dstr);
    // if(dstr.indexOf('/')!=-1){
    //   let d = new Date(date);
    //   strDate = d.getFullYear()+'/'+(d.getMonth()+1)+'/'+d.getDate();
    // }
    // else {
    //   let strD = dstr.split('/');
    //   strDate = strD[2]+'/'+strD[1]+'/'+strD[0];
    // }
    // // var date1 =  new Date(date),
    // //   mnth = ("0" + (date1.getMonth() + 1)).slice(-2),
    // //   day = ("0" + date1.getDate()).slice(-2);
    // // strDate = [date1.getFullYear(), mnth, day].join("-");
    // // return [date1.getFullYear(), mnth, day].join("-");
    // // console.log('dddd',date);
    // // let d = new Date(date);
    // // console.log(d);
    // //   // this.datePipe.transform(date,'yyyy-MM-dd h:mm:ss');
    // //   // // d.setDate(d.getDate() + 1);
    // //   // // console.log('yyyy',d.getFullYear()); console.log('mmmm',d.getUTCMonth()+1); console.log('dddd',d.getDate());
    // //   strDate = d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate();
    //   //// strDate =  this.datePipe.transform(date,'yyyy-MM-dd h:mm:ss');
    // return strDate;
  }

  dateValidateForEOFYear(date,financialYear) {
    let strD = financialYear.split('-');
    var from = new Date(strD[0], 6, 1);
    var to = new Date(strD[1], 5, 31);
    var check = new Date(date.year, (date.month)-1, date.day);
console.log('from',from,'to',to,'check',check);
    return (check <= to && check >= from);
  }

}
