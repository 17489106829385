<ngx-spinner></ngx-spinner>
<div class="card mb-4">
<div class="container col-12 col-md-12 col-lg-12 mb-4 table-responsive ">
  <table class="table table-striped " *ngIf="rows.length>0">
    <thead>
    <tr>
      <th width="40%" class="f-18"> Address</th>
      <th width="15%" class="f-18"> Type </th>
      <th width="15%" class="text-right f-18">Purchase($) </th>
      <th width="15%" class="text-right f-18">Value($)</th>
      <th width="10%" class="text-right f-18">Gain(%) </th>
      <th width="5%"></th>
    </tr>


    </thead>

    <tbody  cdkDropList [sortablejs]="rows" [sortablejsOptions]="optionsUpdates" (cdkDropListDropped)="drop($event,rows)">

    <tr *ngFor="let row of rows; let key = index" cdkDrag  style="background-color: white;" [sortablejs]="rows" [sortablejsOptions]="optionsUpdates">
      <div class="custom-table-placeholder row no-gutters" *cdkDragPlaceholder></div>
      <td>
        <img *ngIf="isSold" class="sold-property-list" src="../../../../../assets/img/sold-banner.png" style="width: 52px;
    transform: translate(-12px, -12px);">
        <a href="javascript:void(0);" (click)="navigateRoute(row)"
              class="c-pointer text-primary font-weight-bold">{{row.property_address }}</a></td>
      <td class="text-left pt-3 ">{{row.property_type }}</td>
      <td class="text-right pt-3  ">${{row.purchase_price |number: '1.2-2'}}</td>
      <td class="text-right pt-3 ">$ {{row.estimated_value_now |number: '1.2-2'}}</td>
      <td class="text-right pt-3 " *ngIf="!isSold">{{row.capital_growth_percentage |number: '1.2-2'}} </td>
      <td class="text-right pt-3 " *ngIf="isSold">{{row.sold_capital_growth_percentage |number: '1.2-2'}} </td>

      <td>
        <div class="btn-group handle" dropdown placement="bottom right">
          <i dropdownToggle class="fa fa-ellipsis-h pb-0 mb-0 mt-1"
             style="font-size: 15px; cursor: pointer"></i>
          <ul id="dropdown-alignment_3" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
              aria-labelledby="button-alignment">
            <li role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0" href="javascript:void(0);"
                                        (click)="hideProperty(row.property_ref)">Hide this Property</a></li>
            <li *ngIf="hiddenProperties.length > 0" role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0"
                                                                            href="javascript:void(0);" (click)="openHiddenPropertyModal()">Restore Property
              ({{hiddenProperties.length}})</a></li>
            <li role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0" href="javascript:void(0);"
                                        (click)="shareProperty(row)">Share this Property</a></li>
            <li role="menuitem mb-0"><a class="dropdown-item pb-0 pt-0" href="javascript:void(0);"
                                        (click)="removeProperty(row.property_ref)">Remove this Property</a></li>
          </ul>
        </div>
      </td>
    </tr>
    <tr>
      <td class="text-bold-500 ">Totals</td>
      <td class="text-left text-bold-500 ">{{totalProperties}}</td>
      <td class="text-right text-bold-500 ">${{totalPropertyPurchase|number: '1.2-2' }}</td>
      <td class="text-right text-bold-500 ">${{totalPropertyMarketValue|number: '1.2-2' }}</td>
      <td class="text-right text-bold-500 ">{{totalPropertyGain|number: '1.2-2' }}</td>
      <td></td>
    </tr>
    </tbody>
  </table>
</div>
</div>

<div bsModal #unshare_model="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <!--unshare property-->
  <!--     [class.modal-backdrop]="isOpenShareModal">-->
  <div class="modal-dialog modal-lg">
    <div class="modal-content ">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left">Property Shared Users</h4>
        <button type="button" class="close pull-right" (click)="closeunShareModel(unshare_model,unshareForm)"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #unshareForm="ngForm">
          <div class="row">
            <div class="col-md-9 col-sm-9">
            </div>
            <div class="col-md-3 col-sm-3 pull-right mb-1">
              <button type="button" class="btn btn-primary btn-xs pull-right custom-btn-primary"
                      (click)="openPropertyShareModal()">Share Property
              </button>
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col pt-1"> User </th>
                <th scope="col pt-1"> Email </th>
                <th></th>
              </tr>
              </thead>
              <tbody *ngFor="let data of sharedProperties; index as i">
              <tr>
                <td class="">
                  <label class="form-group mb-0 ">
                    <span>{{data.first_name}} </span>
                  </label>
                </td>
                <td class="">
                  <label class="form-group mb-0 ">
                    <span>{{data.email}} </span>
                  </label>
                </td>
                <td class="">
                  <button type="button" class="btn btn-primary btn-xs mr-2 custom-btn-sec"  (click)="unshareTo(unshare_model,unshareForm,data)" >Unshare
                  </button>
                  <button type="button" class="btn btn-success btn-xs custom-btn-primary" (click)="openEditSharePermissions(edit_share_model,data.permissions,data)">Edit
                  </button>
                </td>
              </tr>
              </tbody>
              <tfoot *ngIf="sharedProperties.length == 0">
              <tr>
                <td colspan="6">
                  No records found..
                </td>
              </tr>
              </tfoot>
            </table>

          </div>

        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec"
                (click)="closeunShareModel(unshare_model,unshareForm)">Close</button>
      </div>
    </div>
  </div>
</div>
<!--unshare property-->


<!--edit share permissions-->
<div bsModal #edit_share_model="bs-modal" class="modal fade custom-modal" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left"> Edit Property Shared Permissions </h4>
        <button type="button" class="close pull-right"
                (click)="closeShareModel(edit_share_model,editShareForm);isOpenShareModal=false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #editShareForm="ngForm">
          <div class="col-md-12 col-sm-12">
            <div class="row ml-1">
              <div class="col-md-12">
                <div class=" form-group row mb-0">
                  <div class="col-md-2 col-2">
                    <label class="font-weight-bold text-small">User name</label>
                  </div>
                  <div class="col-md-1 col-1">
                    <span class="dots">:</span>
                  </div>
                  <div class="col-md-9 col-9">
                    <span class="text-small">{{userName}}</span>
                  </div>
                </div>
                <div class=" form-group row mb-0">
                  <div class="col-md-2 col-2">
                    <label class="font-weight-bold text-small">Email</label>
                  </div>
                  <div class="col-md-1 col-1">
                    <span class="dots">:</span>
                  </div>
                  <div class="col-md-9 col-9">
                    <span class="text-small">{{userEmail}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col pt-1"> Menu </th>
                  <th scope="col pt-1"> Permissions </th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <div class="row">
                      <div class="col-md-4">
                        <a *ngIf="showSelectAllView" class="cursor-pointer text-primary " href='javascript:void(0)'
                           (click)="tickAllCheckBoxViewInEdit()">Select All</a>
                        <a *ngIf="!showSelectAllView" class="cursor-pointer text-primary " href='javascript:void(0)'
                           (click)="tickAllCheckBoxViewInEdit()">Deselect All</a>
                      </div>
                      <div class="col-md-4">
                        <a *ngIf="showSelectAllEdit" class="cursor-pointer text-primary " href='javascript:void(0)'
                           (click)="tickAllCheckBoxEditInEdit(true)">Select All</a>
                        <a *ngIf="!showSelectAllEdit" class="cursor-pointer text-primary " href='javascript:void(0)'
                           (click)="tickAllCheckBoxEditInEdit(false)">Deselect All</a>
                      </div>
                      <div class="col-md-4">
                        <a *ngIf="showSelectAllDelete" class="cursor-pointer text-primary " href='javascript:void(0)'
                           (click)="tickAllCheckBoxDeleteInEdit(true)">Select All</a>
                        <a *ngIf="!showSelectAllDelete" class="cursor-pointer text-primary " href='javascript:void(0)'
                           (click)="tickAllCheckBoxDeleteInEdit(false)">Deselect All</a>
                      </div>
                    </div>
                  </th>


                </tr>
                </thead>
                <tbody *ngFor="let data of selectedPermissionList; index as i">
                <tr>
                  <td class="pb-0">
                    <label class="form-group mb-0 ">
                      <span>{{data.permission_form}} </span>
                    </label>
                  </td>
                  <td class="pb-0">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox align-contact ">
                          <input type="checkbox" class="custom-control-input" name="is_view{{data.random}}"
                                 [id]="'view_e' +i" [(ngModel)]="data.view" (click)="permissionViewClick($event.target.checked,i)">
                          <label class="custom-control-label pt-0" [for]="'view_e'+i"
                                 style="color: lightslategrey!important; font-size: 95%">View</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox align-contact ">
                          <input type="checkbox" class="custom-control-input" name="is_edit{{data.random}}"
                                 [id]="'edit_e'+i" [(ngModel)]="data.edit" (click)="permissionClick($event.target.checked,i)">
                          <label class="custom-control-label pt-0" [for]="'edit_e'+i"
                                 style="color: lightslategrey!important; font-size: 95%">Edit</label>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="custom-control custom-checkbox align-contact ">
                          <input type="checkbox" class="custom-control-input" name="is_delete{{data.random}}"
                                 [id]="'delete_e'+i" [(ngModel)]="data.delete" (click)="permissionClick($event.target.checked,i)">
                          <label class="custom-control-label pt-0" [for]="'delete_e'+i"
                                 style="color: lightslategrey!important; font-size: 95%">Delete</label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <app-state-button btnClass="btn btn-primary btn-xs custom-btn-primary" [currentState]="responseWaiting" message="false"
                          (click)="shareToEdit(edit_share_model,editShareForm);isOpenShareModal=false"
                          [isDisabled]="shareButtonDisabled">Save
        </app-state-button>
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec"
                (click)="closeShareModel(edit_share_model,editShareForm);isOpenShareModal=false">Close</button>
      </div>
    </div>
  </div>
</div>
<!--edit share permissions-->
