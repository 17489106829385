import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {GlobalService} from "../../../../shared/global.service";
import {NewInDevelopementService} from "../new-in-developement.service";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-new-in-development',
  templateUrl: './new-in-development.component.html',
  styleUrls: ['./new-in-development.component.scss']
})
export class NewInDevelopmentComponent implements OnInit {
  @ViewChild('newInDevelopmentModal') newInDevelopmentModal: ModalDirective;
  @ViewChild('newInDevelopmentContentModal') newInDevelopmentContentModal: ModalDirective;
  hiddenModal = false;
  newInDevelopmentList:any = [];
  newInDevelopmentContent:any = [];
  constructor(
    private globalService: GlobalService,
    private NewInDevelopementService: NewInDevelopementService,
    private notifications: NotificationsService,
    private translate: TranslateService,
  ) {

  }

  ngOnInit(): void {
    this.getFormData()
    this.globalService.openNewInDevelopmentModelEvent.subscribe(data => {
      this.newInDevelopmentModal.show();
    })
  }

  closeNewInDevelopmentModal(){
    this.newInDevelopmentModal.hide();
  }

  closeViewIssueModal() {
    this.newInDevelopmentModal.hide();
  }

  getFormData() {
    this.NewInDevelopementService.getFormData().subscribe(response => {
      this.newInDevelopmentList = response['new_in_development_list'];
    }, err => {
      this.onError(err.message);
    });
  }

  openNewInDevelopmentContentModal(data){
    this.newInDevelopmentContent = data;
    this.newInDevelopmentContentModal.show();
  }

  closeNewInDevelopmentContentModal(){
    this.newInDevelopmentContentModal.hide();
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

}
