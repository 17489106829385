<div class="menu" (click)="menuClicked($event)">
  <div id="mainMenu1" class="main-menu" style="margin-top: 5px!important;">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
      <ul class="list-unstyled new-menu">
        <ng-container *ngFor="let item of filteredMenuItems(menuItems)">
        <li [hidden]="!item.isVisible" (click)="checkHasSubmenu(item);"
            [ngClass]="{'active-li': (selectedParentMenu === item.to && viewingParentMenu === '') || viewingParentMenu === item.to}">
          <a *ngIf="item.newWindow" [href]="item.to" rel="noopener noreferrer" target="_blank" >
            <i [ngClass]="item.icon" [ngbTooltip]="item.label" placement="bottom-left"  container="body"></i>
            <span class="menu-title display-contents">
              {{item.label | translate}}
              <span *ngIf="item.hasOwnProperty('badge')" class="badge badge-light rounded ml-2">{{ownPropertyCount}}</span>
              <i *ngIf="item.subs.length > 0" class="glyph-icon simple-icon-arrow-down float-right"></i>
            </span>
          </a>
          <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0"
             (click)="openSubMenu($event,item); false" [href]="item.to" >
            <i [ngClass]="item.icon" [ngbTooltip]="item.label" placement="bottom-left"  container="body"></i>
            <span class="menu-title display-contents">
              {{item.label | translate}}
              <span *ngIf="item.hasOwnProperty('badge')" class="badge badge-light rounded ml-2">{{ownPropertyCount}}</span>
              <i *ngIf="item.subs.length > 0" class="glyph-icon simple-icon-arrow-down float-right"></i>
            </span>
          </a>
          <a *ngIf="!item.newWindow && (!item.subs || (item.subs &&  item.subs.length===0))"
             (click)="changeSelectedParentHasNoSubmenu(item.to); false" [routerLink]="item.to" >
            <i [ngClass]="item.icon" [ngbTooltip]="item.label" placement="bottom-left"  container="body"></i>
            <span class="menu-title display-contents">
              {{item.label | translate}}
              <span *ngIf="item.hasOwnProperty('badge')" class="badge badge-light rounded ml-2">{{ownPropertyCount}}</span>
              <i *ngIf="item.subs.length > 0" class="glyph-icon simple-icon-arrow-down float-right"></i>
            </span>
          </a>
        </li>
        </ng-container>
      </ul>
    </perfect-scrollbar>

  </div>

  <!--  <div id="mainMenu2" class="main-menu sub-open display-none">-->
  <!--    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">-->
  <!--      <ul class="list-unstyled new-menu">-->
  <!--        <li *ngFor="let item of filteredMenuItems(menuItems)"-->
  <!--            [ngClass]="{'active-li': (selectedParentMenu === item.to && viewingParentMenu === '') || viewingParentMenu === item.to}">-->
  <!--          <a *ngIf="item.newWindow" [href]="item.to" rel="noopener noreferrer" target="_blank">-->
  <!--            <i [ngClass]="item.icon"></i>-->
  <!--&lt;!&ndash;            {{item.label | translate}}&ndash;&gt;-->
  <!--&lt;!&ndash;            <span *ngIf="item.hasOwnProperty('badge')" class="badge badge-light rounded ml-2">9</span>&ndash;&gt;-->
  <!--&lt;!&ndash;            <i *ngIf="item.subs.length > 0" class="glyph-icon simple-icon-arrow-down float-right"></i>&ndash;&gt;-->
  <!--          </a>-->
  <!--          <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0"-->
  <!--             (click)="openSubMenu($event,item); false" [href]="item.to">-->
  <!--            <i [ngClass]="item.icon"></i>-->
  <!--&lt;!&ndash;            {{item.label | translate}}&ndash;&gt;-->
  <!--&lt;!&ndash;            <span *ngIf="item.hasOwnProperty('badge')" class="badge badge-light rounded ml-2">9</span>&ndash;&gt;-->
  <!--&lt;!&ndash;            <i *ngIf="item.subs.length > 0" class="glyph-icon simple-icon-arrow-down float-right"></i>&ndash;&gt;-->
  <!--          </a>-->
  <!--          <a *ngIf="!item.newWindow && (!item.subs || (item.subs &&  item.subs.length===0))"-->
  <!--             (click)="changeSelectedParentHasNoSubmenu(item.to); false" [routerLink]="item.to">-->
  <!--            <i [ngClass]="item.icon"></i>-->
  <!--&lt;!&ndash;            {{item.label | translate}}&ndash;&gt;-->
  <!--&lt;!&ndash;            <span *ngIf="item.hasOwnProperty('badge')" class="badge badge-light rounded ml-2">9</span>&ndash;&gt;-->
  <!--&lt;!&ndash;            <i *ngIf="item.subs.length > 0" class="glyph-icon simple-icon-arrow-down float-right"></i>&ndash;&gt;-->
  <!--          </a>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </perfect-scrollbar>-->
  <!--  </div>-->

  <div class="sub-menu"  [class.mobile-remove-subs]="!haveSubmenu">
    <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
      <ul class="list-unstyled" *ngFor="let item of filteredMenuItems(menuItems)" [attr.data-parent]="item.to"
          [ngClass]="{'d-block': (selectedParentMenu === item.to && viewingParentMenu==='') || viewingParentMenu === item.to}">
        <ng-container *ngFor="let sub of filteredMenuItems(item.subs)">
        <li [hidden]="!sub.isVisible"
            [ngClass]="{'active': currentUrl === sub.to ,'has-sub-item' : sub.subs&& sub.subs.length>0 }">
<!--          <a *ngIf="sub.newWindow" [href]="sub.to" rel="noopener noreferrer" target="_blank">-->
<!--            <i [ngClass]="sub.icon"></i>-->
<!--            <span class="d-inline-block">{{sub.label | translate}}</span>-->
<!--          </a>-->
<!--          <a *ngIf="!sub.newWindow && (!sub.subs || sub.subs.length<=0)" [routerLink]="[sub.to]"-->
<!--             routerLinkActive="active">-->
<!--            <i [ngClass]="sub.icon"></i>-->
<!--            <span class="d-inline-block">{{sub.label | translate}}</span>-->
<!--          </a>  -->
          <a *ngIf="!sub.newWindow && (!sub.subs || sub.subs.length<=0) && checkCalculator(sub.label)"  href="javaScript:void(0)" (click)="submenuClick($event,sub.to); false" >
            <i [ngClass]="sub.icon" [ngbTooltip]="sub.label" placement="bottom-left"  container="body"></i>
            <span class="d-inline-block submenu-label1">{{sub.label | translate}}</span>
          </a>
<!--          <a (click)="toggleCollapse(sub.to)" *ngIf="sub.subs && sub.subs.length > 0"-->
<!--             [ngClass]="{'rotate-arrow-icon opacity-50 c-pointer': true, 'collapsed': closedCollapseList.includes(sub.to)}">-->
<!--            <i class="simple-icon-arrow-down"></i>-->
<!--            <span class="d-inline-block">{{sub.label | translate}}</span>-->
<!--          </a>-->
<!--          <div [collapse]="closedCollapseList.includes(sub.to)"  [isAnimated]="true">-->
<!--            <div *ngIf="sub.subs && sub.subs.length > 0">-->
<!--              <ul class="list-unstyled inner-level-menu">-->
<!--                <li *ngFor="let deepSub of filteredMenuItems(sub.subs)" [ngClass]="{'active': currentUrl === deepSub.to}">-->
<!--                  <a *ngIf="deepSub.newWindow" [href]="deepSub.to" rel="noopener noreferrer"-->
<!--                     target="_blank">-->
<!--                    <i [ngClass]="deepSub.icon"></i>-->
<!--                    <span class="d-inline-block">{{deepSub.label | translate}}</span>-->
<!--                  </a>-->
<!--                  <a *ngIf="!deepSub.newWindow && (!deepSub.subs || deepSub.subs.length<=0)"-->
<!--                     [routerLink]="[deepSub.to]" routerLinkActive="active">-->
<!--                    <i [ngClass]="deepSub.icon"></i>-->
<!--                    <span class="d-inline-block">{{deepSub.label | translate}}</span>-->
<!--                  </a>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
        </li>
        </ng-container>
      </ul>
    </perfect-scrollbar>
  </div>
</div>
