import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {Lightbox} from "ngx-lightbox";
import {GlobalService} from "../../../global.service";
import {PropertyGlobalService} from "../../../property-global.service";

@Component({
  selector: 'app-view-more-images',
  templateUrl: './view-more-images.component.html',
  styleUrls: ['./view-more-images.component.scss']
})
export class ViewMoreImagesComponent implements OnInit {
  images:any = [];
  comeFrom:any = null;
  @ViewChild('propertyImageModel') propertyImageModel: ModalDirective;
  constructor(
    private lightbox: Lightbox,
    private globalService:GlobalService,
    private propertyGlobalService: PropertyGlobalService
    ) {
    this.propertyGlobalService.loadPropertyImageViewEvent.subscribe(data=>{
      console.log("views-image",data);
      if(data['images'].length > 0){
        this.images = data['images'];
        this.comeFrom = data['comeFrom'];
        this.propertyImageModel.show();
      }
    })
  }

  ngOnInit(): void {
  }

  processImageClick(image,key) {
    this.openLightbox(this.images, key);
  }

  openLightbox(array: any, index: number): void {
    this.lightbox.open(array, index, {
      centerVertically: true,
      positionFromTop: 0,
      disableScrolling: true,
      wrapAround: true,
      showImageNumberLabel: true,
      alwaysShowNavOnTouchDevices: true
    });
  }

  close(): void {
    this.lightbox.close();
  }

  closePropertyImageModel() {
    this.propertyImageModel.hide();
    let data  = {
      images: this.images
    }
    this.propertyGlobalService.propertyImageViewModelClose(data);
    this.propertyGlobalService.parentModelSwitch(false);
  }

  removeImage(item, i) {
    this.images.splice(i,1);
  }



}
