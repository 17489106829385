import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewInDevelopmentRoutingModule } from './new-in-development-routing.module';
import { NewInDevelopmentComponent } from './new-in-development/new-in-development.component';
import {ModalModule} from "ngx-bootstrap/modal";
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import {ReactiveFormsModule} from "@angular/forms";
import {NgxSpinnerModule} from "ngx-spinner";


@NgModule({
  declarations: [NewInDevelopmentComponent, ComingSoonComponent],
  exports: [
    NewInDevelopmentComponent
  ],
  imports: [
    CommonModule,
    NewInDevelopmentRoutingModule,
    ModalModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ]
})
export class NewInDevelopmentModule { }
