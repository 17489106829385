import { Component, OnInit,ViewChild } from '@angular/core';
import {GlobalService} from "../../global.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalDirective} from "ngx-bootstrap/modal";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import Swal from "sweetalert2";
import {ReportIssuesService} from "../../../views/app/report-issues/report-issues.service";

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.scss']
})
export class ReportIssueComponent implements OnInit {
  @ViewChild('viewIssuesModal') viewIssuesModal: ModalDirective;
  @ViewChild('IssuesModal') IssuesModal: ModalDirective;
  is_main = false;
  is_second:boolean = false;
  reportNewIssueSectionForm :FormGroup;
  reportIssueSectionViewForm :FormGroup;
  submitted = false;
  similar_issues : any = [];
  report_issue_section = '';
  validateSimillerIssue = false;
  buttonDisabled = false;
  responseWaiting = '';
  formList:any = [];
  hiddenModal = false;
  user_ref = localStorage.getItem('Cache'); // get user ref from local storage
  issue_user_ref = '';
  constructor(
    private globalService: GlobalService,
    private  fb : FormBuilder,
    private spinner: NgxSpinnerService,
    private notifications: NotificationsService,
    private reportIssuesService: ReportIssuesService
  ) {
    this.globalService.reportNewIssueEvent.subscribe(data => {
      this.report_issue_section = data.section;
      console.log(data)
      this.openIssueModal();
      this.reportNewIssueSectionForm.patchValue({report_section:data.section})
      this.reportNewIssueSectionForm.patchValue({form_code:data.section})
    });
  }

  initForm(){

    this.reportNewIssueSectionForm =   this.fb.group({
      summary_description:['',[Validators.required]],
      form_code:['',[Validators.required]],
      issue_detail:['',[Validators.required]],
      report_section:['']
    });

    this.reportIssueSectionViewForm =   this.fb.group({
      summary_description:[{value:'',disabled:true}],
      issue_detail:[{value:'',disabled:true}],
      issue_detail_hidden:[''],
      proppel_issue_status_name:[{value:'',disabled:true}],
      resolved_date:[{value:'',disabled:true}],
      created_at:[{value:'',disabled:true}],
      effected_user_count:[{value:'',disabled:true}],
      resolution:[{value:'',disabled:true}],
      form_code:[{value:'',disabled:true}],
      form_name:[{value:'',disabled:true}],
      resolution_hidden:[''],
      proppel_issue_ref:[''],
      effected_user_count_hidden:[''],
    });
    this.submitted = false;
    this.buttonDisabled = false;
    this.responseWaiting = '';
    this.similar_issues = [];
  }
  ngOnInit(): void {
    this.initForm()
  }

  get f() { return this.reportNewIssueSectionForm.controls; }
  openIssueModal() {
    this.is_main = true;
    this.validateSimillerIssue = false;
    this.spinner.show();
    this.globalService.getIssuesForms().subscribe(res=>{
      this.formList = res['form_list'];
      this.reportNewIssueSectionForm.value['form_code'] = this.report_issue_section;
      this.spinner.hide();
    },error => {
      this.onError(error.message);
      this.spinner.hide();
    });

    this.IssuesModal.show();
  }

  closeViewIssueModal() {
    // this.is_second = false;
    // this.is_main = true;
    this.hiddenModal = false;
    this.viewIssuesModal.hide();
    let data = {
      section :this.report_issue_section,
      action:false
    };
    this.globalService.reportNewIssueModalClose(data);
  }

  viewformSubmit(viewForm: FormGroup) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You will be added to the list of proppel users with this issue.\n" +
        "Do you want to continue ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.reportIssuesService.updateIssue(this.reportIssueSectionViewForm.value).subscribe(res=>{
          this.spinner.hide();
          if(res['result'].status == 0){
            Swal.fire({
              icon: 'info',
              title: '',
              text: res['result'].message,

            })

          }else if(res['result'].status == 1){
            var effected_user = parseInt(this.reportIssueSectionViewForm.value['effected_user_count_hidden'])+1;
            this.reportIssueSectionViewForm.patchValue({effected_user_count:effected_user});
            this.closeViewIssueModal()
            this.onSuccess(res['result'].message)
          }

          // this.reportIssueSectionViewForm = res['issue_list'];

        },error=>{
          this.spinner.hide();
          this.onError(error.message);
        })
      }
    })
  }

  viewLarge(name: string,form) {
    var detail = '';
    switch (name){
      case 'resolution':{
        detail =  form['value'].resolution_hidden;
        break;
      }
      case 'issue details':{
        detail =  form['value'].issue_detail_hidden;
        break;
      }
      default : {
        detail = '';
      }
    }

    this.reportIssuesService.passReportIssueData([name,detail])

  }
  searchSimilarIssues() {

    if(this.reportNewIssueSectionForm.value['summary_description'].length+1 > 3){
      this.validateSimillerIssue = false;
      this.spinner.show();
      this.reportIssuesService.getSimilarIssues(this.reportNewIssueSectionForm.value).subscribe(res=>{
        this.spinner.hide();
        this.similar_issues = res['similar_issues']
      },error => {
        this.spinner.hide();
        this.onError(error.message);
      })
    }else{
      this.validateSimillerIssue = true;

      this.onError('Please enter atleast 4 characters')
    }

  }

  formSubmit(form: any) {
    this.submitted = true;
    if (!this.reportNewIssueSectionForm.valid) {
      return;
    }
    this.buttonDisabled = true;
    this.responseWaiting = 'show-spinner';
    this.reportNewIssueSectionForm.value['created_at'] = this.globalService.getTodayDate();
    this.reportIssuesService.saveNewIssue(this.reportNewIssueSectionForm.value).subscribe(res=>{
      this.responseWaiting = ''
      this.onSuccess('Issue Added Successfully');
      this.submitted = false;
      this.buttonDisabled = false;
      // this.issuesList = res['issue_list'];
      this.closeIssueModal();
    },error =>{
      this.submitted = false;
      this.buttonDisabled = false;
      this.responseWaiting = ''
      this.onError(error.message)
    });

  }
  viewIssueModal(viewIssuesModal: ModalDirective, issue: any) {
    this.hiddenModal = true;
    // this.is_main = false;
    // this.is_second = true;
    this.viewIssuesModal.show();
    this.reportIssueSectionViewForm.patchValue(issue)
    this.reportIssueSectionViewForm.patchValue({effected_user_count_hidden:issue.effected_user_count})
    this.reportIssueSectionViewForm.patchValue({issue_detail_hidden:issue.issue_detail})
    this.reportIssueSectionViewForm.patchValue({resolution_hidden:issue.resolution})
    this.issue_user_ref = issue.created_user_ref;
  }


  keyPress() {
    this.validateSimillerIssue = false;
  }

  closeIssueModal() {
    this.initForm();
    this.IssuesModal.hide();
    let data = {
      section :this.report_issue_section,
      action:false
    };
    this.globalService.reportNewIssueModalClose(data);
  }
  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  onSuccess(message) {

    this.notifications.create('Success', message, NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }
}
