import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid'; // Or another UUID library
@Injectable({
  providedIn: 'root'
})
export class CacheService {
  async clearCacheForFile(filename: string): Promise<any> {
    //generate random name for cache
    const cacheName = Math.random().toString(36).substring(2);

    const cache = await caches.open(cacheName); // Replace 'cache-buster' with a unique name for your cache.
console.log('cache',cache);
    try {
      const keys = await cache.keys();

      for (const key of keys) {
        if (key.url.includes(filename)) {
          await cache.delete(key);
        }
      }
    } catch (error) {
      console.error('Error clearing cache:', error);
    }
  }
}
