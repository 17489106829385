import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";
import {GlobalService} from "../../../global.service";
import {HelperService} from "../../../helper.service";
import {MortgageGlobalService} from "../../../mortgage-global.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mortgage-details-view',
  templateUrl: './mortgage-details-view.component.html',
  styleUrls: ['./mortgage-details-view.component.scss']
})
export class MortgageDetailsViewComponent implements OnInit ,OnDestroy{
  mortgageHeaderForm:FormGroup;
  mortgage_ref = null;
  property_ref = null;
  hiddenModal = false;
  isEdit = false;
  submitted = false;
  responseWaiting = '';
  buttonDisabled = false;
  isMissingMortgage = false;
  isCreateExpense = false;
  comeFrom = "";
  excelFiles: any = [];
  isImageLoadingBarShow = false;
  showMsg = false;
  isImport = false;
  isHeaderEdit = false;
  missingMonth = [];
  @ViewChild('mortgageModel') mortgageModel: ModalDirective;
  private subscription: Subscription[] = [];
  constructor( private globalService: GlobalService,
               private spinner: NgxSpinnerService,
               private notifications: NotificationsService,
               private translate: TranslateService,
               private modalService: BsModalService,
               private helperService: HelperService,
               private fb: FormBuilder,
               private mortgage_service:MortgageGlobalService,
               private router: Router,) {

    this.initMortgageHeaderForm();
    this.subscription.push(this.globalService.closeMainModalEvent.subscribe(data => {
      this.hiddenModal = false;
    }));

    this.subscription.push(this.mortgage_service.openAddMortgageDetailsModalEvent.subscribe(
      data => {
        this.mortgageHeaderForm.reset();
        this.documentsControl.clear();
        this.comeFrom = data.comeFrom;
        this.isCreateExpense = false;
        this.isMissingMortgage = false;
        this.isEdit = data.isEdit;
        this.mortgage_ref = data.mortgage_ref;
        this.property_ref = data.property_ref;
        this.isImport = false;
        this.isHeaderEdit = data.isHeaderEdit;
        this.missingMonth = [];
        this.getMortgageHeaderDetails();
      }
    ));

    this.subscription.push(this.mortgage_service.editMortgageDetailsEvent.subscribe(data => {
      this.isImport = false;
      this.isMissingMortgage = false;
      this.mortgageHeaderForm.reset();
      this.documentsControl.clear();
      this.initMortgageHeaderForm();
      this.submitted = false;
      this.comeFrom = data.comeFrom;
      this.isCreateExpense = false;
      this.isEdit = true;
      this.mortgage_ref = data.mortgage_ref;
      this.property_ref = data.property_ref;
      this.isHeaderEdit = data.isHeaderEdit;
      this.missingMonth = [];
      this.getMortgageHeaderDetailsForEditMortgage(data['mortgage_detail']);
    }));

    this.subscription.push(this.mortgage_service.openMortgageDetailsImportModelEvent.subscribe(data => {
      this.isImport = true;
      this.comeFrom = data.comeFrom;
      this.isMissingMortgage = false;
      this.mortgageHeaderForm.reset();
      this.documentsControl.clear();
      this.initMortgageHeaderForm();
      this.submitted = false;
      this.isCreateExpense = false;
      this.isEdit = true;
      this.mortgage_ref = data.mortgage_ref;
      this.property_ref = data.property_ref;
      this.isHeaderEdit = data.isHeaderEdit;
      this.missingMonth = [];
      this.getMortgageHeaderDetailsForImport();
    }));


  }

  initMortgageHeaderForm(){
    this.mortgageHeaderForm = this.fb.group({
      loan_start_date: [null],
      loan_end_date: [null],
      redraw: [null],
      redraw_offset_facility: [null],
      last_payment_date: [null],
      last_payment_amount: [null],
      loan_interest_rate: [null],
      current_loan_balance: [null],
      total_loan_amount :[null],
      mortgage_type: [null],
      loan_count: [null],
      mortgageRow: this.fb.array([])
    });
  }

  get documentsControl(): FormArray {
    return this.mortgageHeaderForm.get("mortgageRow") as FormArray;
  }





  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }



  private getMortgageHeaderDetails() {
    this.spinner.show();
    let data = {
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref
    };
    this.globalService.getMortgageHeaderAndMortgageDetails(data).subscribe(response=>{
      this.spinner.hide();
      this.mortgageHeaderForm.patchValue(response['mortgage_data']);
      this.setTwoNumberDecimal('redraw');
      this.setTwoNumberDecimal('current_loan_balance');
      this.setTwoNumberDecimal('last_payment_amount');
      this.isMissingMortgage = response['is_missing_mortgage'];
      this.missingMonth = response['missing_month'];
      // this.checkMortgageDetailsMissingInfo();
      if(this.comeFrom == "Archive"){
        this.mortgage_service.openMortgageDetailsFormForArchive(true);
      }
      this.addNewRow(0);
      this.mortgageModel.show();
    },error=>{
      this.spinner.hide();
      this.onError(error);
    });
  }

  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  // checkMortgageDetailsMissingInfo(){
  //   if (this.mortgageHeaderForm.value['loan_count'] == 0 && this.documentsControl.length == 0) {
  //     var current_date = new Date();
  //     var last_payment_date = new Date(this.mortgageHeaderForm.value['last_payment_date']);
  //     var endDate = new Date(this.mortgageHeaderForm.value['loan_end_date']);
  //     var startDate = new Date(this.mortgageHeaderForm.value['loan_start_date']);
  //     if(this.mortgageHeaderForm.value['last_payment_date'] != null){
  //       if(startDate < current_date && endDate >= current_date){
  //         this.isMissingMortgage = false;
  //       }
  //
  //       last_payment_date.setMonth(last_payment_date.getMonth() + 1)
  //       if(last_payment_date < current_date){
  //         this.isMissingMortgage = true;
  //       }
  //       else {
  //         this.isMissingMortgage = false;
  //       }
  //     }
  //     else {
  //       if(startDate < current_date && endDate >= current_date){
  //         this.isMissingMortgage = true;
  //       }
  //       else {
  //         this.isMissingMortgage = false;
  //       }
  //     }
  //   }
  //
  //
  //   if(!this.isMissingMortgage){
  //     this.createMortgageRaw()
  //   }
  // }

  createMortgageRaw() {
    let date = null;
    let amount = null;

    if(this.mortgageHeaderForm.value['last_payment_date']!=null){
      let d = new Date(this.mortgageHeaderForm.value['last_payment_date']);
      d.setMonth(d.getMonth()+1);
      date = { year: d.getFullYear(), month: (d.getMonth() + 1), day: d.getDate() };
    }
    amount = this.mortgageHeaderForm.value['last_payment_amount']!=null?this.mortgageHeaderForm.value['last_payment_amount']:null;


    let data = {
      'paid_date': date,
      'amount': this.globalService.validateNumberFormat(amount),
      'mortgage_detail_ref': null,
      'loan_rate_type': "",
      'error_count': 0,
    };

    this.documentsControl.push(this.createMortgageDetailsForEdit(data));
  }

  createMortgageDetailsForEdit(data){
    return this.fb.group({
      'date_s': [data.paid_date,Validators.required],
      'amount': [this.globalService.validateNumberFormat(data.amount),Validators.required],
      'mortgage_detail_ref': [data.mortgage_detail_ref],
      'loan_rate_type': [data.loan_rate_type],
      'error_count': 1,
    });
  }

  calculateRedraw() {
    let result = this.helperService.calculateRedraw(this.mortgageHeaderForm.get('redraw_offset_facility').value,this.mortgageHeaderForm.get('total_loan_amount').value,this.mortgageHeaderForm.get('current_loan_balance').value);
    this.mortgageHeaderForm.patchValue({redraw:result});
    this.setTwoNumberDecimal('redraw');
  }

  calculateCurrentBalance() {
    let result = this.helperService.calculateCurrentLoanBalance(this.mortgageHeaderForm.get('redraw_offset_facility').value,this.mortgageHeaderForm.get('total_loan_amount').value,this.mortgageHeaderForm.get('redraw').value);
    this.mortgageHeaderForm.patchValue({current_loan_balance:result});
    this.setTwoNumberDecimal('current_loan_balance');
  }

  addNewRow(key) {
    if(this.mortgageHeaderForm.value['mortgageRow'].length > 0){
      let pay_date = null;
      if(this.mortgageHeaderForm.value['mortgageRow'][(this.mortgageHeaderForm.value['mortgageRow'].length-1)].date_s!=null){
        let date = new Date(this.helperService.extractDateString(this.mortgageHeaderForm.value['mortgageRow'][(this.mortgageHeaderForm.value['mortgageRow'].length-1)].date_s));
        date.setMonth(date.getMonth() + 1);
        pay_date = this.helperService.formDateObject(date);
      }
      if(this.mortgageHeaderForm.value['mortgageRow'][(this.mortgageHeaderForm.value['mortgageRow'].length-1)].amount!=null){
        this.mortgageHeaderForm.patchValue({last_payment_amount:this.mortgageHeaderForm.value['mortgageRow'][(this.mortgageHeaderForm.value['mortgageRow'].length-1)].amount});
      }

      this.documentsControl.insert(key+1,this.fb.group({
        'date_s': [pay_date,Validators.required],
        'amount': [this.globalService.validateNumberFormat(this.mortgageHeaderForm.get('last_payment_amount')).value,Validators.required],
        'mortgage_detail_ref': [null],
        'loan_rate_type': "",
        'error_count': 1,
      }));
    }
    else {
      this.createMortgageRaw();
    }
  }

  openIssueModel(section: string) {
    this.hiddenModal = true;
    var data = {
      section: section
    }
    this.globalService.reportNewIssueModal(data);
  }

  amountChange(event: any, key: any) {
    this.mortgageHeaderForm.patchValue({variable_last_payment_amount: event});
  }

  addNewRowAfter(key: any) {
    if(key+1 == this.documentsControl.length){
      this.addNewRow(key);
    }
    else {
      this.documentsControl.insert(key + 1, this.fb.group({
        'date_s': [null, Validators.required],
        'amount': [this.mortgageHeaderForm.value['last_payment_amount'] != null ? this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['last_payment_amount']) : null, Validators.required],
        'mortgage_detail_ref': [null],
        'loan_rate_type': "",
        'error_count': 1,
      }));
    }
  }

  removeRecord(index) {
    this.documentsControl.removeAt(index);
  }

  closeModel() {
    this.mortgageHeaderForm.reset();
    this.mortgageModel.hide();
    if(this.comeFrom == "property"){
      this.router.navigate(['/app/properties/list']);
    }
    else if(this.comeFrom == "Archive"){
      this.mortgage_service.refreshArchiveMortgageList(true);
      this.mortgage_service.openMortgageDetailsFormForArchive(false);
    }
    else {
      this.mortgage_service.addMortgageDetailsModelClose({'comeFrom':this.comeFrom});
    }

  }

  getMortgageHeaderDetailsForEditMortgage(data) {
    this.spinner.show();
    let details = {
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref
    };
    this.globalService.getMortgageHeaderAndMortgageDetails(details).subscribe(response=>{
      this.spinner.hide();
      this.mortgageHeaderForm.patchValue(response['mortgage_data']);
      let rawData = {
        'paid_date': data['paid_date']!=null?this.helperService.formDateObject(data['paid_date']):null,
        'amount': this.globalService.validateNumberFormat(data['amount']),
        'mortgage_detail_ref': data['mortgage_detail_ref'],
        'loan_rate_type': "",
        'error_count': 0,
      };
      this.documentsControl.push(this.createMortgageDetailsForEdit(rawData));
      this.spinner.hide();
      this.mortgageModel.show();
    },error=>{
      this.notifications.error('Error', error);
    });
  }

  createMissingMortgage() {
    // this.spinner.show();
    // let data = {
    //   'mortgage_ref': this.mortgage_ref,
    //   'property_ref': this.property_ref,
    //   'loan_rate_type': 'all',
    // }
    // this.globalService.createMissingVariableMortgage(data).subscribe(
    //   response => {
    //     this.spinner.hide();
        this.generateMortgageDetails(this.missingMonth);
    //   },
    //   error => {
    //     this.spinner.hide();
    //     this.onError(error);
    //   }
    // );
  }

  private generateMortgageDetails(data) {

    data.forEach(element => {
      let rawData = {
        'paid_date': this.helperService.formDateObject(element),
        'amount': this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['last_payment_amount']),
        'mortgage_detail_ref': null,
        'loan_rate_type': "",
        'error_count': 0,
      };
      this.documentsControl.push(this.createMortgageDetailsForEdit(rawData));
    });
  }

  saveMortgageDetails() {

    this.submitted = true;
    if (!this.mortgageHeaderForm.valid) {
      return;
    }

    this.spinner.show();
    let detailsArray = [];
    detailsArray = [...this.documentsControl.value];
    if(this.documentsControl.length > 0){
      detailsArray.forEach(value => {
        if(value.date_s!=null){
          value['date_string'] = this.helperService.extractDateString(value.date_s);
        }
      });
    }

    let data = {
      'form_data': this.mortgageHeaderForm.value,
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref,
      'is_create_expense': this.isCreateExpense,
      'details_array': detailsArray,
    }

    this.globalService.saveMortgageDetails(data).subscribe(res => {
        this.spinner.hide();
        this.mortgage_ref = res['mortgage_ref'];
        if(this.isCreateExpense == true){
          let data = {
            'expense_data' : res['expense_list'],
            'come_from' : this.comeFrom,
            'property_ref' : this.property_ref,
          }
          this.globalService.openExpenseViewModelForExpensePayment(data);
        }

        this.onSuccess("Mortgage Details Saved Successfully");
        this.mortgageModel.hide();
        if(this.comeFrom == "property"){
          this.router.navigate(['/app/properties/list']);
        }
        else if(this.comeFrom == "Archive" && this.mortgage_ref!= null){
          let data = {
            'mortgage_ref': this.mortgage_ref,
            'property_ref': this.property_ref,
            'isHeaderEdit': this.isHeaderEdit,
            'comeFrom': 'Archive',
          };
          console.log('m details 1');
          this.mortgage_service.loadMortgageHeaderData(data);
          this.mortgage_service.refreshArchiveMortgageList(false);
          this.mortgage_service.openMortgageDetailsFormForArchive(false);
        }
        else{
          if(this.mortgage_ref!= null){
            let data = {
              'mortgage_ref': this.mortgage_ref,
              'property_ref': this.property_ref,
              'isHeaderEdit': this.isHeaderEdit,
              'comeFrom': this.comeFrom,
            };
            console.log('m details 2');
            this.mortgage_service.loadMortgageHeaderData(data);
            this.mortgage_service.addMortgageDetailsModelClose({'comeFrom':this.comeFrom});
          }

        }

        this.mortgageHeaderForm.reset();

      },
      error => {
        this.spinner.hide();
        this.onError(error);
      });
  }

  async importExcel(event) {
    this.showMsg = false;
    this.excelFiles = [];
    this.excelFiles.push(...event.addedFiles);
    await this.addSelectedExcelFile(this.excelFiles)
  }

  async addSelectedExcelFile(files) {
    let selectedExFile = [];
    // selectedExFile = files;
    let formData = new FormData();
    let i;
    for (i = 0; i < files.length; i++) {
      formData.append('file', files[i], files[i].name);
      await this.uploadExcel(formData);
    }
  }

  uploadExcel(formData) {
    this.spinner.show();
    this.isImageLoadingBarShow = true;
    formData.append('property_ref', this.property_ref);
    formData.append('mortgage_ref', this.mortgage_ref);
    this.globalService.mortgageUploadFile(formData).subscribe(response => {
        this.spinner.hide();
        this.isImageLoadingBarShow = false;
        if(response['mortgage_list'] != null){
          response['mortgage_list'].forEach((val, index) => {
            let date = null;
            if (val.paid_date != null) {
              date = this.helperService.formDateObject(val.paid_date);
            }

            let rawData = {
              'paid_date': date,
              'amount': this.globalService.validateNumberFormat(val.amount),
              'mortgage_detail_ref': null,
              'loan_rate_type': "",
              'error_count': 0,
            };
            this.documentsControl.push(this.createMortgageDetailsForEdit(rawData));

          });
        }
      },
      err => {
        this.spinner.hide();
        this.showMsg = true;
        this.onError(err.message);
      });
  }

  getMortgageHeaderDetailsForImport() {
    this.spinner.show();
    let data = {
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref
    };
    this.globalService.getMortgageHeaderAndMortgageDetails(data).subscribe(response=>{
      this.spinner.hide();
      this.mortgageHeaderForm.patchValue(response['mortgage_data']);
      this.mortgageModel.show();
    },error=>{
      this.spinner.hide();
      this.onError(error);
    });
  }

  setTwoNumberDecimal(field) {
    if(this.mortgageHeaderForm.get(field).value != null && this.mortgageHeaderForm.get(field).value != '' && this.mortgageHeaderForm.get(field).value != undefined){
      let resultValue = this.mortgageHeaderForm.get(field).value;
      resultValue = this.globalService.validateNumberFormat(resultValue);
      this.mortgageHeaderForm.get(field).setValue(resultValue);
    }
    //
    // let regexp = new RegExp('^(\\d{1,5}|\\d{0,5}\\.\\d{1,2})$');
    // let result =  regexp.test(this.propertyMortgageForm.get(field).value);
    // if(result == false){
    //   let repayment = (this.propertyMortgageForm.get(field).value).toFixed(2);
    //   this.propertyMortgageForm.get(field).patchValue(repayment);
    // }
  }
}
