import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../../shared/global.service";

@Injectable({
  providedIn: 'root'
})
export class NewInDevelopementService {

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) {
  }


  getFormData() {
    return this.http.get(this.globalService.getAPIUrl() + 'property/get-form-data-top-nav' , this.globalService.getHttpOptions());
  }

  getNewInDevelopment(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'development/get-new-development-notes' ,data, this.globalService.getHttpOptions());
  }

}
