import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PropertyCalculationService {
  @Output() openPropertyValueModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openRentalIncomeModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openPropertyCashflowEvent: EventEmitter<any> = new EventEmitter();
  @Output() openActiveInsuranceModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeListOfPropertyValueModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() reloardInsuranceEvent: EventEmitter<any> = new EventEmitter();
  @Output() openModelEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  openPropertyValueModel(data){
    this.openPropertyValueModelEvent.emit(data);
  }

  openRentalIncomeModel(data){
    this.openRentalIncomeModelEvent.emit(data);
  }

  openPropertyCashflowModel(data) {
    this.openPropertyCashflowEvent.emit(data);
  }

  openActiveInsuranceModel(data) {
    this.openActiveInsuranceModelEvent.emit(data);
  }

  closeListOfPropertyValueModel(data){
    this.closeListOfPropertyValueModelEvent.emit(data);
  }

  reloardInsurance(data) {
    this.reloardInsuranceEvent.emit(data);
  }

  openModelModel(data) {
    this.openModelEvent.emit(data);
  }
}
