<div bsModal #newInDevelopmentModal="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1" [config]="{backdrop: 'static', ignoreBackdropClick: true}"
     role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">New In Development</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hiddenModal=false; closeNewInDevelopmentModal();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-sm-12 col-md-12">
          <div class="row">
            <p>Propple Property Management is currently a beta product. There are several key functions that we need to complete before
              we can officially launch. if you would like to know the new functions in development - click below.</p>
          </div>
          <div class="row">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                <tr>
                  <th width="15%">Category</th>
                  <th width="70%">New Function</th>
                  <th width="15%" class="text-align-right">% Complete</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of newInDevelopmentList;">
                  <td>{{item.category}}</td>
                  <td>
                    <a href="javaScript:void(0);" (click)="hiddenModal=true;openNewInDevelopmentContentModal(item)" class="btn btn-link p-0">{{item.new_in_development_title}}</a>
                  </td>
                  <td class="text-align-right">{{item.completion}}%</td>
                </tr>
                <tr *ngIf="newInDevelopmentList.length < 1">
                  <td colspan="3">No Records</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-secondary pull-right" (click)="closeNewInDevelopmentModal()">Close</button>
      </div>
    </div>
  </div>
</div>

<div bsModal #newInDevelopmentContentModal="bs-modal" class="modal fade" tabindex="-1" [config]="{backdrop: 'static', ignoreBackdropClick: true}"
     role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{newInDevelopmentContent.new_in_development_title}} - {{newInDevelopmentContent.completion}}% Complete</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hiddenModal=false;closeNewInDevelopmentContentModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-sm-12 col-md-12">
          <div class="row">
            <div *ngIf="newInDevelopmentContent.content" [innerHTML]="newInDevelopmentContent.content"></div>
            <div *ngIf="!newInDevelopmentContent.content">
              <p>No Records</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-secondary pull-right btn-xs" (click)="closeNewInDevelopmentContentModal();hiddenModal=false;">Close</button>
      </div>
    </div>
  </div>
</div>
