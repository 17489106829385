import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {NgForm} from "@angular/forms";
import {PropertyService} from "../../../../views/app/properties/property.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {GlobalService} from "../../../global.service";
import {Subscription} from "rxjs";
import {PropertyGlobalService} from "../../../property-global.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-property-share-model',
  templateUrl: './property-share-model.component.html',
  styleUrls: ['./property-share-model.component.scss']
})
export class PropertyShareModelComponent implements OnInit {

  permissionList: any = [];
  showSelectAllView = false;
  showSelectAllEdit = false;
  showSelectAllDelete = false;
  shareButtonDisabled = false;
  isOpenShareModal = false;
  isShare = false;
  errorList: any = [];
  sharedPropertiesTemp: any = [];
  sharedProperties: any = [];
  tagsInput = [];
  responseWaiting = '';
  buttonDisabled = false;
  isWaiting = '';
  header = 'Email'
  placeholder = "Add Email Address of Proppel User & Hit Enter";
  limit = 5;
  email = '';
  comeFrom = '';

  propertyRef: string = '';
  propertyDetails: any = [];
  routeParamsSubscription: Subscription;
  images: any = [];
  propertyNameOnTitles: any = [];
  volumeOrFolios: any = [];
  imageItems: any[] = [
    {
      "id": "large-0",
      "img": "https://strgpmsdevelopment.blob.core.windows.net/pmsimages/53/2018654772_13_3_230719_065046-w2480-h3508.jpeg"
    },
  ];

  imageItemsThumbs: any[]  = [
    {
      "id": "thumb-0",
      "img": "https://strgpmsdevelopment.blob.core.windows.net/pmsimages/53/2018654772_13_3_230719_065046-w2480-h3508.jpeg"
    },
  ];
  showDetails = true;

  @ViewChild('shareForm') shareForm: NgForm;
  @ViewChild('share_model') shareModel: ModalDirective;

isPropertyListTileView = false;
propertyList = [];
selectedShareProperties = [];
  constructor(
    private propertyService: PropertyService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private notifications: NotificationsService,
    private globalService: GlobalService,
    private propertyGlobalService: PropertyGlobalService,
  ) {

    this.propertyGlobalService.openPropertyShareModelEvent.subscribe(data => {
      this.propertyList = [];
      this.selectedShareProperties = [];
      this.isShare = data.isShare;
      this.propertyRef = data.propertyRef;
      this.permissionList = data.permissionList;
      this.comeFrom = data.comeFrom;
      this.isPropertyListTileView = this.comeFrom == 'property-list-tile-view' ?? false;
      this.propertyList = data.propertyList ? data.propertyList : [];
      this.selectedShareProperties.push(this.propertyRef);
      this.shareModel.show();
    });
  }

  ngOnInit(): void {
  }

  shareTo(modal: ModalDirective, form: NgForm) {
    if (!this.shareForm.valid || this.shareButtonDisabled) {
      return;
    }
    this.spinner.show('propertyShareSpinner');
    this.shareButtonDisabled = true;
    this.isOpenShareModal = false;
    let data = {
      'propertyRef': this.propertyRef,
      'email': this.tagsInput,
      'permissionList': this.permissionList,
      'property_list': this.selectedShareProperties ?? [],
      'is_property_list_tile_view': this.isPropertyListTileView
    };
    this.propertyService.shareTo(data).subscribe(res => {
      this.spinner.hide('propertyShareSpinner');
      this.shareButtonDisabled = true;
      if (res['status'] == 200) {
        this.permissionList = [];
        this.selectedShareProperties = [];
        this.sharedProperties = [];
        this.isShare = false;
        this.shareButtonDisabled = false;
        this.isOpenShareModal = false;
        modal.hide();
       if (this.comeFrom == 'property-list-tile-view') {
         let data1 = {
           'shared_users': res['shared_users'],
         }

          this.propertyGlobalService.showShareModel(data1);
       }
        this.errorList = res['errors_list'];
        this.errorList.forEach((val, index) => {
          this.onError(val['message']);
        });
        this.getPropertyDetails();
        this.sharedPropertiesTemp = res['shared_users'];
        this.sharedProperties = this.sharedPropertiesTemp;
      }
      else {
        this.shareButtonDisabled = false;
        this.onError(res['message']);
      }
    }, err => {
      this.spinner.hide('propertyShareSpinner');
      this.onError(err.message);
      this.onError(err.status);
      this.shareButtonDisabled = false;
    });
  }
  tickAllCheckBoxView() {
    if (this.permissionList.every(val => val.view == true)) {
      this.permissionList.forEach(val => { val.view = false});
      this.showSelectAllView = true;
    }
    else {
      this.permissionList.forEach(val => { val.view = true });
      this.showSelectAllView = false;
    }
  }

  closeShareModel(modal: ModalDirective, form: NgForm) {
    this.isShare = false;
    modal.hide();
    if (this.comeFrom == 'property-list-tile-view') {
      let data1 = {
        'shared_users': '',
      }
      this.propertyGlobalService.showShareModel(data1);
    }
  }


  tickAllCheckBoxEdit(isSelectAll) {
    let count = 0;
    this.permissionList.forEach(val => {
      if(  val.view){
        count++;
      }
    });

    if(count>0){
      if(isSelectAll){
        this.showSelectAllEdit = false;
        this.permissionList.forEach(val => {
          if(val.view){
            val.edit = true;
          }
        });
      }
      else {
        this.showSelectAllEdit = true;
        this.permissionList.forEach(val => { val.edit = false});
      }
    }
    else{
      this.permissionList.forEach(val => { val.edit = true, val.view = true});
      this.showSelectAllView = false;
      this.showSelectAllEdit = false;
    }
  }

  tickAllCheckBoxDelete(isSelectAll) {

    let count = 0;
    this.permissionList.forEach(val => {
      if(  val.view){
        count++;
      }
    });

    if(count>0){
      if(isSelectAll){
        this.showSelectAllDelete = false;
        this.permissionList.forEach(val => {
          if(val.view){
            val.delete = true;
          }
        });
      }
      else {
        this.showSelectAllDelete = true;
        this.permissionList.forEach(val => { val.delete = false});
      }
    }
    else{
      this.permissionList.forEach(val => { val.delete = true, val.view = true});
      this.showSelectAllView = false;
      this.showSelectAllDelete = false;
    }
  }


  newPermissionViewClick(checked, i) {
    if(checked == false){
      this.permissionList[i]['edit'] = false;
      this.permissionList[i]['delete'] = false;
    }
  }

  newPermissionClick(checked, i) {
    if(checked == true){
      this.permissionList[i]['view'] = true;
    }
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  async  getPropertyDetails() {
    this.spinner.show();
    await this.propertyService.getPropertyDetails(this.propertyRef,'propertyEdit').subscribe(response => {
        this.spinner.hide();
        try{
          this.propertyDetails = response['properties'];
          this.images = response['images_lightbox'];
          this.imageItems = response['imageItems'];
          this.imageItemsThumbs = response['imageItemsThumbs'];
          this.propertyNameOnTitles = response['name_on_titles'];
          this.volumeOrFolios = response['volume_or_folios'];
          this.permissionList = response['permission_list'];
          this.validatePropertyPurpose();
        } catch (e) {
          this.globalService.onError(e.message) ;
        }

      },
      err => {
        this.spinner.hide();
      });
  }

  validatePropertyPurpose() {
    if (this.propertyDetails['purpose'] != null) {
      if (this.propertyDetails['purpose'] != 'Land Bank') {
        this.showDetails = true;
      }
      else {
        this.showDetails = false;
      }
    }
    else {
      this.showDetails = true;
    }
  }

  transferTo(model: ModalDirective, form: NgForm) {
    if (!this.shareForm.valid || this.shareButtonDisabled) {
      return;
    }
    this.shareButtonDisabled = false;
    this.propertyService.transferTo(this.propertyRef, form.value['email']).subscribe(res => {
      this.spinner.hide();
      this.shareButtonDisabled = false;
      if (res['status'] == 200) {
        this.onSuccess('Property Transferred successful');
        this.isShare = false;
        form.resetForm();
        model.hide();
      }
      else {
        this.onError(res['message']);
      }
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
      this.shareButtonDisabled = false;
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  checkEmails() {
    let data = {
      'email': this.tagsInput[this.tagsInput.length - 1],
    };
    this.responseWaiting = 'show-spinner';
    this.propertyService.checkEmail(data).subscribe(res => {
      this.spinner.hide();
      this.responseWaiting = '';
      if (res['is_valid'] == true) {
        this.onSuccess(res['message']);
      }
      else {
        Swal.fire({
          title: '',
          text: this.tagsInput[this.tagsInput.length - 1] + " is not a Proppel user email address. You cannot share to this user",
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.value) {
            this.tagsInput.splice(this.tagsInput.length - 1);
          }
          if (result.isDismissed == true) {
            this.tagsInput.splice(this.tagsInput.length - 1);
          }
        });
      }
    }, err => {
      this.responseWaiting = '';
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
      this.shareButtonDisabled = false;
    });
  }

  async displayTags(tag) {
    this.tagsInput = tag;
  }

  addPropertyShare(event,property_ref) {
    //console.log(event);
   // console.log(property_ref);

    if(event == true){
      this.selectedShareProperties.push(property_ref);
    } else {
      this.selectedShareProperties.splice(this.selectedShareProperties.indexOf(property_ref), 1);

    }

  // console.log("selectedShareProperties",this.selectedShareProperties)
  }
}
