import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlaceSuggestService {

  constructor() { }

  placeToAddress(place: any){
    console.log(place);
    let name = '';
    let website = '';
    let phone_number = '';
    let address_array  = null;
    if ('name' in place){
      name = place.name;
    }

    if ('website' in place){
      website = place.website;
    }

    if ('international_phone_number' in place){
      phone_number = place.international_phone_number;
    }

    if('address_components' in place){
      address_array = place.address_components;
    }
    var address = {
      formatted_address: place.formatted_address,
      place_id: place.place_id,
      subpremise: '',
      street_number: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      lan: '',
      lot: '',
      name: name,
      website: website,
      phone_number: phone_number,
      address_array: address_array
    };
    place.address_components.forEach(function(c: any) {
      switch(c.types[0]){
        case 'subpremise':
          address.subpremise = c.long_name;
          break;
        case 'street_number':
          address.street_number = c.long_name;
          break;
        case 'route':
          address.street = c.long_name;
          break;
        case 'neighborhood': case 'locality':
          address.city = c.long_name;
          break;
        case 'administrative_area_level_1':
          address.state = c.short_name;
          break;
        case 'postal_code':
          address.zip = c.long_name;
          break;
        case 'country':
          address.country = c.long_name;
          break;
      }
    });
    return address;
  }

}
