
import {map} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {AuthService} from "../auth.service";


@Injectable()
export class ResponseBodyChangeInterceptorService implements HttpInterceptor {
  apiCallsNotToAddContentType = [];
  constructor() {
    this.apiCallsNotToAddContentType = ['file/images_download_as_zip','file/get-file-download','https://api.domain.com.au/v1','file/document-download-zip'];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if(this.addContentType(req.url)){
          event = event.clone({body: this.modifyBody(event.body)});
        }
      }

      return event;
    }));
  }

  private  modifyBody(body: any) {
    /*
    * write your logic to modify the body
    * */
    body['data']['status'] = body['status'];
    body['data']['message'] = body['message'];
    return  body['data'];
  }

  private addContentType(requestUrl: string): boolean {
    for (let address of this.apiCallsNotToAddContentType) {
      if (requestUrl.indexOf(address) !== -1) {
        return false;
      }
    }
    return true;
  }
}
