import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PropertyService} from "../../../../views/app/properties/property.service";
import {GlobalService} from "../../../global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PropertyGlobalService} from "../../../property-global.service";
import {HelperService} from "../../../helper.service";
import Swal from "sweetalert2";
import {validate} from "codelyzer/walkerFactory/walkerFn";
import {Subscription} from "rxjs";
import {Lightbox} from "ngx-lightbox";

@Component({
  selector: 'app-property-detail-edit-view',
  templateUrl: './property-detail-edit-view.component.html',
  styleUrls: ['./property-detail-edit-view.component.scss']
})
export class PropertyDetailEditViewComponent implements OnInit,OnDestroy {
  @Input() propertyRef: any = 'new';
  @Input() comeFrom: any = null;
  @Output() propertyDetailsModelClose = new EventEmitter<any>();
  propertyDetailsForm: FormGroup;
  propertyTypes:any =[];
  images: any = [];
  submitted = false;
  propertyPurposeTypes :any = [];
  tempPurposeTypes: any = [];
  isCapitalImprovementAdd = 0;
  buttonDisabled = false;
  buttonWaiting = '';
  buildingMaterials:any  = [];
  roofMaterials:any  = [];
  isSold = '0';
  articles:any = [];
  ownerList:any = [];
  ownerListTemp:any = [];
  headerImageSrc = '';
  imageCount = 0;
  imageType = '';
  headerFiles: any = [];
  selectedFile: any;
  selectedFileLength: any;
  temUploading = [];
  isImageLoadingBarShow = true;
  imageLoading = false;
  showingImageCount = 0;
  tempImages:any = [];
  responseWaiting = '';
  subsVar: Subscription;
  sell_expenses: any = [];
  expenseCategories: any = [];
  supplierList: any = [];
  otherFiles: File[] = [];
  constructor(
    private propertyService : PropertyService,
    private globalService : GlobalService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private propertyGlobalService : PropertyGlobalService,
    private helperService: HelperService,
    private lightbox: Lightbox,
  ) {
    this.initForm();
    this.propertyGlobalService.loadPropertyDetailsEvent.subscribe((data:any)=>{
      this.propertyRef = data['propertyRef'];
      this.comeFrom = data['comeFrom'];
      this.getPropertyDetails();
    });

    this.propertyGlobalService.closePropertyPurposeModelEvent.subscribe(data => {
      this.getPropertyPurposeTypes(data);
    });

    this.propertyGlobalService.closeBuildingMaterialModelEvent.subscribe(data =>{
      this.getBuildingMaterials(data);
    });

    this.propertyGlobalService.closeRoofMaterialModelEvent.subscribe(data=>{
      this.getRoofMaterials(data);
    });

    this.propertyGlobalService.propertyImageViewModelCloseEvent.subscribe(data => {
      if(data['images'].length > 0){
        let list = data['images'];
        list.forEach(item =>{
          if(item.is_delete == true){
            this.images.forEach((image, index) => {
              if(item.media_ref == image.media_ref){
                if(image.is_new == true){
                  this.images.splice(index,1);
                }
                else{
                  image.is_delete = true;
                }
              }
            });
          }
        })
      }

      this.getImageCount();
    });

    this.propertyGlobalService.salesExpenseModelCloseEvent.subscribe(data => {
      this.propertyDetailsForm.get('sell_cost').setValue(data['salesCost']);
      this.sell_expenses = data['expenseList'];
      this.propertyGlobalService.parentModelSwitch(false);
      this.calculateProfit();
    });
  }

  initForm() {
    this.propertyDetailsForm = this.fb.group({
      unit_no: [''],
      address_one: [''],
      suburbAuto: [],
      suburb: [''],
      state_id: [null],
      property_type: [null,[Validators.required]],
      property_purpose: [null,[Validators.required]],
      building_material: [null],
      property_ref: [null],
      roof_material: [null],
      purchase_price: [null,[Validators.required]],
      sold_price: [null],
      contract_s_date:[null],
      contract_sale_date:[null],// data base saving field
      sold:[0],
      sell_cost:[null],
      estimated_value_now:[null,[Validators.required]],
      unimproved_land_value:[null],
      bedrooms:[null],
      bathrooms:[null],
      car_spaces:[null],
      living_spaces:[null],
      building_size:[null],
      land_size:[null],
      distance_to_city:[null],
      year_built:[null],
      other_features:[null],
      capital_improvement:[null],
      capital_improvement_add_to_purchase_price:[null],
      p_settlement_date:[null],
      purchase_settlement_date:[null],// data base saving field
      local_council_name:[null],
      property_address:[null,[Validators.required]],
      settlement_date_obj:[null],
      settlement_date:[null],// data base saving field
      profit:[null],
      name_on_titles_data:this.fb.array([]),
      volume_or_folio_data:this.fb.array([]),
      isSold:['0']
      // p_settlement_date_string:[null],
      // contract_s_date_string:[null],
      // property_address:[null],
      // domain_address_id:[null],
      // domain_flat_number:[null],
      // domain_id:[null],
      // domain_suburb_id:[null],
      // is_residential:[null],
      // domain_plan_number:[null],
      // category_text:[null],
      // domain_property_url:[null],
      // bathrooms:[null],
      // bedrooms:[null],
      // car_spaces:[null],
      // land_size:[null],
      // postcode:[null],
      // state:[null],
      // street_address:[null],
      // street_name:[null],
      // street_number:[null],
      // street_type:[null],
      // street_type_long:[null],
      // other_features:[null],
      // capital_growth_percentage:[null],
      // building_size:[null],
      // contract_sale_date:[null],
      // purchase_settlement_date:[null],
      // property_registration_type:[null],
      // is_not_rent:[0],
      // is_no_lease:[0],
      // lease_my_self:[0],
      // living_spaces:[null],
      // lat:[null],
      // lon:[null],
      // is_not_mortgage:[0],
    });
  }
  ngOnInit(): void {
    // this.getPropertyDetails();
  }

  get propertyDetailsFormControls() {
    return this.propertyDetailsForm.controls;
  }

  get nameOnTitleControlle(): FormArray {
    return this.propertyDetailsForm.get('name_on_titles_data') as FormArray;
  }

  get volumeOrFoliosControlle(): FormArray {
    return this.propertyDetailsForm.get('volume_or_folio_data') as FormArray;
  }

  get profit(){
    return this.propertyDetailsForm.get('profit').value;
  }

  addOwner(addedName) {
    return { name_on_title: addedName,};
  }

  searchOwner(event: KeyboardEvent) {
    if (event['srcElement']['value'] != "") {
      this.globalService.getOwnerList(event['srcElement']['value']).subscribe(response => {
        this.ownerList = response['owner'];
      }, err => {
        this.globalService.onError(err.message);
      });
    }else{
      this.ownerList = [...this.ownerListTemp];
    }
  }

  clearOwner(){
    this.ownerList = [...this.ownerListTemp];
  }


  createOwner(data){
    return this.fb.group({
      'name_on_title_ref': [data.name_on_title_ref],
      'name_on_title': [data.name_on_title],
      'share': [data.share],
      'error':[null]
    });
  }
  addNameOnTitle() {
    return this.fb.group({
      'name_on_title_ref': [null],
      'name_on_title': [null],
      'share': [null],
      'error':[null]
    });
  }

  createVolumeOrFolios(data){
    return this.fb.group({
      'volume_or_folio_ref': [data.volume_or_folio_ref],
      // 'description': [data.description],
      'volume_or_folio': [data.volume_or_folio],
      'error':[null]
    });
  }
  addvolumeOrFolios() {
    return this.fb.group({
      'volume_or_folio_ref': [null],
      // 'description': [null],
      'volume_or_folio': [null],
      'error':[null]
    });
  }

  public getPropertyDetails() {
    this.spinner.show();
    this.subsVar =  this.propertyService.getPropertyDetails(this.propertyRef,this.comeFrom).subscribe(response => {
      this.nameOnTitleControlle.clear();
      this.volumeOrFoliosControlle.clear();
      this.propertyDetailsForm.reset();

      this.spinner.hide();
      // if (response['properties']) {
      if (response != null) {
        this.propertyDetailsForm.patchValue(response['properties']);
        this.images = response['images'];
        this.propertyTypes = response['property_types'];
        this.ownerList = response['property_users'];
        this.sell_expenses = response['sell_expenses'];
        this.expenseCategories = response['category_type'];
        this.supplierList = response['suppliers'];
        this.roofMaterials = response['roof_materials'];
        this.buildingMaterials = response['building_materials'];
        this.propertyPurposeTypes = response['property_purposes'];
        if (response['properties'].length != 0) {
          let nameOnTitle = response['name_on_titles'];
          if(nameOnTitle.length > 0){
            nameOnTitle.forEach((item, index) => {
              this.nameOnTitleControlle.push(this.createOwner(item));
            });
          }
          else {
            this.nameOnTitleControlle.push(this.addNameOnTitle());
          }

          let volumeOfFolio = response['volume_or_folios'];
          if(volumeOfFolio.length > 0){
            volumeOfFolio.forEach((item, index) => {
              this.volumeOrFoliosControlle.push(this.createVolumeOrFolios(item));
            });
          } else {
            this.addvolumeOrFolios();
            this.volumeOrFoliosControlle.push(this.addvolumeOrFolios());
          }

          if (response['properties']['contract_sale_date'] != null) {
            this.propertyDetailsForm.get('contract_s_date').setValue(this.helperService.formDateObject(response['properties']['contract_sale_date']));
          }

          if (response['properties']['settlement_date'] != null) {
            this.propertyDetailsForm.get('settlement_date_obj').setValue(this.helperService.formDateObject(response['properties']['settlement_date']));
          }

          if (response['properties']['purchase_settlement_date'] != null) {
            this.propertyDetailsForm.get('p_settlement_date').setValue(this.helperService.formDateObject(response['properties']['purchase_settlement_date']));
          }

          if (response['properties']['sold'] == 1) {
            this.propertyDetailsForm.get('isSold').setValue('1');
            this.isSold = '1';
          }
          else {
            this.propertyDetailsForm.get('isSold').setValue('0');
            this.isSold = '0';
          }

          this.propertyDetailsForm.get('property_purpose').setValue(response['properties']['purpose']);

          this.images = response['images_lightbox'];
          if(response['images'].length > 0){
            this.images.forEach((item, index) => {
              if(item['type'] == 1){  // header image type
                this.headerImageSrc = item['src'];
              }
            });

            this.getImageCount();
          }
        }
        this.spinner.hide();
      } else {
        // Handle the case where response['properties'] is null or undefined
        // For example, you may want to log an error message or perform other actions.
        // console.error('Response does not contain properties');
      }

    },error => {
      this.spinner.hide();
      this.globalService.onError(error);
    });
  }

  addNameOnTitleRow() {
    this.nameOnTitleControlle.push(this.addNameOnTitle());
    this.setNameOnTitleShare();
  }

  addVolumeOfFolioRow() {
    this.volumeOrFoliosControlle.push(this.addvolumeOrFolios());
  }

  setNameOnTitleShare() {
    if (this.nameOnTitleControlle.controls.length == 1) {
      this.nameOnTitleControlle.controls[0].get('share').setValue(  (this.nameOnTitleControlle.controls[0].get('name_on_title').value != "" && this.nameOnTitleControlle.controls[0].get('name_on_title').value != null) ? 100 : null);
    }
    if(this.nameOnTitleControlle.controls.length == 2){
      this.nameOnTitleControlle.controls[0].get('share').setValue((this.nameOnTitleControlle.controls[0].get('name_on_title').value != ""  && this.nameOnTitleControlle.controls[0].get('name_on_title').value != null)? 50 : null);
      this.nameOnTitleControlle.controls[1].get('share').setValue((this.nameOnTitleControlle.controls[0].get('name_on_title').value != ""  && this.nameOnTitleControlle.controls[0].get('name_on_title').value != null) ? 50 : null);
    }
  }
  addPropertyPurpose(addedName) {
    return { purpose: addedName, property_purpose_id: '', user_id: 'new' };
  }

  openPropertyPurposeModel() {
    this.propertyGlobalService.parentModelSwitch(true);
    let data = {
      comeFrom : this.comeFrom,
      action:true
    }
    this.propertyGlobalService.openPropertyPurposeModel(data);
  }

  public getPropertyPurposeTypes(data) {
    this.spinner.show();
    this.propertyGlobalService.propertyRegistrationFormLoad().subscribe(response => {
      this.propertyPurposeTypes = response['property_purposes'];

      if(data['action'] == 'add'){
        this.propertyDetailsForm.patchValue({purpose: data['purpose']});
      }
      if(data['action'] == 'update'){
        this.propertyPurposeTypes.forEach(item => {
          if(item.property_purpose_id == data['property_purpose_id']){
            this.propertyDetailsForm.patchValue({purpose: data['purpose']});
          }
        });
      }
      if(data['action'] == 'delete'){
        this.propertyPurposeTypes.forEach(item => {
          if(item.property_purpose_id == data['property_purpose_id']){
            this.propertyDetailsForm.patchValue({purpose: null});
          }
        });
      }

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.globalService.onError(error);
    })
  }
  closeAddNewPropertyModel() {
    console.log(this.propertyDetailsForm.get('contract_s_date').value)
  }


  isCapitalImprovementAddToPurchasePrice() {
    // poh ask to this

    // if(this.propertyDetailsForm.get('capital_improvement').value != null || this.propertyDetailsForm.get('capital_improvement').value != undefined){
    //   Swal.fire({
    //     title: 'Please Tell Us Your Preference',
    //     text: 'Would you like to add the Capital Improvement Cost to the Purchase Price Total? This is a personal preference per Investor.',
    //     icon: 'question',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, Add it!',
    //     cancelButtonText: 'No'
    //   }).then((isConfirm) => {
    //     if (isConfirm.value) {
    //       this.isCapitalImprovementAdd = 1;
    //       this.updatePropertyDetails();
    //     }
    //     else {
    //       this.isCapitalImprovementAdd = 0;
    //       this.updatePropertyDetails();
    //     }
    //   }, function (desmiss) { });
    // }
  }

  updatePropertyDetails(){
    this.spinner.show();
    this.submitted = true;
    if (!this.propertyDetailsForm.valid) {
      this.spinner.hide();
      return;
    }

    if(this.nameOnTitleControlle.controls.length > 1){
      let count= 0;
      this.nameOnTitleControlle.controls.forEach((item, index) => {
        if((item.get('name_on_title').value != "" || item.get('name_on_title').value != null) && (item.get('share').value == "" || item.get('share').value == null)){
          item.get('error').setValue('Share is required');
          count++;
        }
      });

      if(count > 0){
        return;
      }
    }

    this.buttonDisabled = false;
    this.buttonWaiting = '';

    if (this.propertyDetailsForm.get('contract_s_date').value != null || this.propertyDetailsForm.get('contract_s_date').value  != undefined) {
      this.propertyDetailsForm.get('contract_sale_date').setValue(this.helperService.extractDateString(this.propertyDetailsForm.get('contract_s_date').value ));
    }

    if (this.propertyDetailsForm.get('settlement_date_obj').value != null || this.propertyDetailsForm.get('settlement_date_obj').value  != undefined) {
      this.propertyDetailsForm.get('settlement_date').setValue(this.helperService.extractDateString(this.propertyDetailsForm.get('settlement_date_obj').value ));
    }

    if (this.propertyDetailsForm.get('p_settlement_date').value != null || this.propertyDetailsForm.get('p_settlement_date').value  != undefined) {
      this.propertyDetailsForm.get('purchase_settlement_date').setValue(this.helperService.extractDateString(this.propertyDetailsForm.get('p_settlement_date').value ));
    }

    if (this.propertyDetailsForm.get('contract_s_date').value == null || this.propertyDetailsForm.get('contract_s_date').value  == undefined) {
      this.propertyDetailsForm.get('contract_sale_date').setValue(null);
    }

    if (this.propertyDetailsForm.get('settlement_date_obj').value == null || this.propertyDetailsForm.get('settlement_date_obj').value  == undefined) {
      this.propertyDetailsForm.get('settlement_date').setValue(null);
    }

    if (this.propertyDetailsForm.get('p_settlement_date').value == null || this.propertyDetailsForm.get('p_settlement_date').value  == undefined) {
      this.propertyDetailsForm.get('purchase_settlement_date').setValue(null);
    }

    if(!this.propertyDetailsForm.hasOwnProperty('images')){
      this.propertyDetailsForm.addControl('images',this.fb.array(this.images));
    }
    else {
      this.propertyDetailsForm.get('images').setValue(this.images);
    }


    this.propertyGlobalService.updatePropertyDetails(this.propertyDetailsForm.value).subscribe(response => {
      this.spinner.hide();
      this.nameOnTitleControlle.clear();
      this.volumeOrFoliosControlle.clear();
      this.propertyDetailsForm.reset();
      this.images = [];
      this.headerImageSrc = '';
      this.imageCount = 0;
      this.tempImages = [];
      this.showingImageCount = 0;
      this.close();
    },error => {
      this.globalService.onError(error);
    });
  }

  openMyModal(label: string) {
    this.spinner.show();
    this.propertyService.getKnowledgeDescription(label).subscribe(res => {
      this.spinner.hide();
      let knowledgeData = null;
      if (res['knowledge_data'] != null) {
        knowledgeData = res['knowledge_data'];
      }

      let knowledgeDataTemp = {
        'content' : knowledgeData ? knowledgeData.knowledge_description: null
      };

      this.globalService.alertWindowOpen(knowledgeDataTemp);
      this.alertWindowOpen(knowledgeDataTemp);
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err);
    });
  }

  alertWindowOpen(data) {
    // this.articleModel.hide();
    let articleData = {
      'keyWord': null,
      'form_id':2,
      'article_ref':data['article_ref'],
      'from_form':'form',
    };

    this.propertyService.countProppelLearningArticleView(articleData).subscribe(res => {
      this.articles =  res['learning_articles'];
    }, err => {
      this.globalService.onError(err);
    });
  }

  validateInterestRate(share, key) {
    if (share > 100 || share < 0) {
      this.nameOnTitleControlle.controls[key].get('error').setValue('value should be between 100 to 0');
    }
    else {
      this.nameOnTitleControlle.controls[key].get('error').setValue(null);
    }
    if (this.nameOnTitleControlle.controls.length == 1) {
      this.nameOnTitleControlle.controls[0].get('share').setValue(100);

    }
    if (this.nameOnTitleControlle.controls.length == 2 ){
      this.nameOnTitleControlle.controls[0].get('share').setValue(50);
      this.nameOnTitleControlle.controls[1].get('share').setValue(50);

    }
    // if (key == 0 && share != "") {
    //   if (this.nameOnTitleControlle.controls.length > 1) {
    //     this.nameOnTitleControlle.controls[key].get('share').setValue(100 - share);
    //   }
    // }
    // if (key == 1 && share != "") {
    //   if (this.nameOnTitleControlle.controls.length > 1) {
    //     this.nameOnTitleControlle.controls[key].get('share').setValue(100 - share);
    //   }
    // }
  }

  removeNameOnTitle(key: number) {
    this.nameOnTitleControlle.removeAt(key);
    this.setNameOnTitleShare();
  }

  removeVolumeOfFolio(key: number) {
    this.volumeOrFoliosControlle.removeAt(key);
  }

  calculateProfit() {
    //sold price - sell cost = profit
    let profit = 0;
    console.log(this.propertyDetailsForm.get('sold_price').value);
    console.log(this.propertyDetailsForm.get('sell_cost').value);
    this.propertyDetailsForm.get('profit').setValue(null);
    if (this.propertyDetailsForm.get('sold_price').value != null && this.propertyDetailsForm.get('sell_cost').value != '') {
      profit = this.propertyDetailsForm.get('sold_price').value - this.propertyDetailsForm.get('sell_cost').value;

      this.propertyDetailsForm.get('profit').setValue(this.globalService.validateNumberFormat(profit));
    }


    // let purchase_price = null;
    // let sold_price = 0;
    // let c_improvment_price = 0;
    // if (this.propertyDetailsForm.get('sold_price').value != null && this.propertyDetailsForm.get('sold_price').value != '') {
    //   sold_price = this.propertyDetailsForm.get('sold_price').value;
    //   if (this.propertyDetailsForm.get('purchase_price').value != null || this.propertyDetailsForm.get('purchase_price').value != '' || this.propertyDetailsForm.get('purchase_price').value != undefined) {
    //     purchase_price = this.propertyDetailsForm.get('purchase_price').value;
    //   }
    //   if (this.propertyDetailsForm.get('capital_improvement').value != null || this.propertyDetailsForm.get('capital_improvement').value != '' || this.propertyDetailsForm.get('capital_improvement').value != undefined) {
    //     c_improvment_price = this.propertyDetailsForm.get('capital_improvement').value;
    //   }
    //   this.propertyDetailsForm.get('profit').setValue(sold_price - c_improvment_price - this.propertyDetailsForm.get('sell_cost').value - purchase_price);
    // }
    // else {
    //   this.propertyDetailsForm.get('profit').setValue(null);
    // }
  }

  openBuildingMaterialModel() {
    this.propertyGlobalService.parentModelSwitch(true);
    this.propertyGlobalService.openBuildingMaterialModel();

  }

  private getBuildingMaterials(data) {
    this.spinner.show();
    this.propertyGlobalService.propertyRegistrationFormLoad().subscribe(response => {
      this.buildingMaterials = response['building_materials'];
      this.spinner.hide();
      if(data['action'] == 'add'){
        this.propertyDetailsForm.get('building_material').setValue(data['buildingMaterial']);
      }
    },error => {
      this.spinner.hide();
      this.globalService.onError(error);
    });
  }

  addBuildingMaterial(addedName) {
    return { building_material: addedName, building_material_id: '', user_id: 'new' };
  }

  addRoofMaterial(addedName) {
    return { roof_material: addedName, roof_material_id: '', user_id: 'new' };
  }

  openRoofMaterialModel(){
    this.propertyGlobalService.parentModelSwitch(true);
    this.propertyGlobalService.openRoofMaterialModel();
  }


  private getRoofMaterials(data) {
    this.spinner.show();
    this.propertyGlobalService.propertyRegistrationFormLoad().subscribe(response => {
      this.roofMaterials = response['roof_materials'];
      this.spinner.hide();
      if(data['action'] == 'add'){
        this.propertyDetailsForm.get('roof_material').setValue(data['materialValue']);
      }
    },error => {
      this.spinner.hide();
      this.globalService.onError(error);
    });
  }

  saveHeaderImage(event) {
    if (event.rejectedFiles.length > 0) {
      this.spinner.hide();
      this.globalService.onError("Invalid File Format. Supported file formats are jpg, jpeg and png");
    } else {
      // this.images = [];
      this.headerImageSrc = '';
      this.imageCount = 0;
      this.imageType = 'header';
      this.headerFiles = [...event.addedFiles];
      this.addPhotosSelected(event, this.headerFiles);
    }
  }

  async addPhotosSelected(event, files) {
    this.selectedFile = files;
    this.selectedFileLength = Object.keys(this.selectedFile).length;
    // this.imageCount = this.imageCount + this.selectedFileLength;
    let formData = new FormData();
    let i;
    for (i = 0; i < this.selectedFileLength; i++) {
      let rank = this.images.length > 0 ? (this.images[((this.images.length)-1)]['rank'])+1 : 1;
      let data = {
        media_ref: '',
        thumb: 'loading',
        src: '',
        title: '',
        type: '',
        // rank: rank,
        // imageType: '',
        is_delete: false,
        is_new: true,
      }
      this.images.push(data);
      formData.append('file', this.selectedFile[i], this.selectedFile[i].name);
      await this.pushImages(formData,this.selectedFileLength,i);
    }
  }

  pushImages(formData,count,position){
    // this.spinner.show();
    this.imageLoading = true;
    this.globalService.commonFileUploader(formData).subscribe(suc => {
        if(this.imageType == 'header'){
          this.headerImageSrc = suc['thumb'];
          this.images.forEach((item, index) => {
            if(item['type'] == 'header'){
              item['type'] = 'other';
              item['rank'] = (this.images[((this.images.length)-1)]['rank'])+1;
              item['title'] = 'other';
              item['imageType'] = 'Property';
            }
          });
        }
        // tempCount++;

        if((count - 1) == position){
          this.imageLoading = false;
        }


        this.images[((this.images.length)- count) + position]['src'] = suc['full_url'];
        this.images[((this.images.length)- count) + position]['media_ref'] =  suc['media_ref'];
        this.images[((this.images.length)- count) + position]['thumb'] =  suc['thumb'];
        this.images[((this.images.length)- count) + position]['type'] =  this.imageType;
        // this.images[((this.images.length)- count) + position]['imageType'] =  'Property';
        // this.images[((this.images.length)- count) + position]['title'] =  this.imageType == 'header'? 'Header' : 'Other';
        this.imageLoading = false;
        this.getImageCount();

      },
      err => {
        this.globalService.onError(err.statusText);
      });
  }

  getImageCount() {
    this.tempImages = [];
    this.showingImageCount = 0;
    if(this.images.length > 0){
      this.images.forEach((item, index) => {
        if((item['type'] == 2 || item['type'] =='other') && item['is_delete'] != true){   // 2 is for other images
          item['title'] = 'other';
          item['type'] = 2;
          this.showingImageCount++;
          this.tempImages.push(item);
        }
      });
    }
  }

  saveOtherImage(event) {
    if (event.rejectedFiles.length > 0) {
      this.spinner.hide();
      this.globalService.onError("Invalid File Format. Supported file formats are jpg, jpeg and png");
    } else {
      // this.images = [];
      // this.temUploading = [];
      // this.isImageLoadingBarShow = true;
      // this.imageCount = 0;
      this.imageType = 'other';
      this.imageLoading = true;
      event.addedFiles.forEach((obj, index) => {
        this.temUploading.push({ name: 'temporaryFile' });
      });
      this.otherFiles.push(...event.addedFiles);
      this.addPhotosSelected(event, this.otherFiles);
    }
  }

  vewImages() {
    let data = {
      comeFrom: 'property-details-edit-view',
      images: this.tempImages,
    }
    this.propertyGlobalService.loadPropertyImageView(data);
    this.propertyGlobalService.parentModelSwitch(true);
  }

  // savePropertyDetails() {
  //   this.submitted = true;
  //   if (!this.propertyDetailsForm.valid) {
  //     return;
  //   }
  //
  //   this.buttonWaiting = '';
  //   if (){
  //
  //   }
  // }

  close() {
    let data = {
      propertyRef: this.propertyRef
    }
    console.log("close",data);
    this.propertyDetailsModelClose.emit(data);
  }

  deleteImage(item: any,key) {
    console.log(item);
    this.images.forEach((image, index) => {
      if(item.media_ref == image.media_ref){
        if(image.is_new == true){
          this.images.splice(index,1);
        }
        else{
          image.is_delete = true;
        }
      }
    });

    this.getImageCount();
  }


  ngOnDestroy() {
    if (this.subsVar){
      this.subsVar.unsubscribe();
    }
  }



  processImageClick(image,key) {
    this.openLightbox(this.tempImages, key);
  }

  openLightbox(array: any, index: number): void {
    this.lightbox.open(array, index, {
      centerVertically: true,
      positionFromTop: 0,
      disableScrolling: true,
      wrapAround: true,
      showImageNumberLabel: true,
      alwaysShowNavOnTouchDevices: true
    });
  }

  openExpenseModel() {
    let data ={
      propertyRef: this.propertyRef,
      comeFrom: this.comeFrom,
      expense:this.sell_expenses,
      propertyAddress: this.propertyDetailsForm.get('property_address').value,
      salesCost: this.propertyDetailsForm.get('sell_cost').value,
      expenseCategories :  this.expenseCategories,
      supplierList :  this.supplierList,
    }
    this.propertyGlobalService.parentModelSwitch(true);
    this.propertyGlobalService.openPropertySalesExpenseModel(data);
  }
}
