import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {NgxSpinnerService} from "ngx-spinner";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PropertyCalculationService} from "../../../../shared/property-calculation.service";
import {GlobalService} from "../../../../shared/global.service";
import {PropertyService} from "../../../../views/app/properties/property.service";
declare var $: any;
@Component({
  selector: 'app-property-value',
  templateUrl: './property-value.component.html',
  styleUrls: ['./property-value.component.scss']
})
export class PropertyValueComponent implements OnInit {
  propertyListForm: FormGroup;
  responseWaiting = '';
  buttonDisabled = false;
  @ViewChild('propertyValueModel') propertyValueModel: ModalDirective;


  constructor(private propertyCalculationService:PropertyCalculationService,
              private modalService: BsModalService,
              private notifications: NotificationsService,
              private spinner: NgxSpinnerService,
              private propertyService : PropertyService,
              private fb: FormBuilder,
              private globalService : GlobalService
  ) {
    this.initForm();
    this.propertyCalculationService.openPropertyValueModelEvent.subscribe(data => {
      this.getPropertyList();
    });
  }

  initForm() {
    this.propertyListForm = this.fb.group({
      total_capital_gain: [''],
      total_capital_gain_percentage: [''],
      total_estimate_value: [''],
      total_purchase_value: [''],
      propertyList: this.fb.array([]),
    });
  }

  get addPropertyList(): any {
    return this.propertyListForm.get('propertyList') as FormArray;
  }


  ngOnInit(): void {
    $(document).ready(function () {
      let modalContent: any = $('.modal-content');
      modalContent.draggable({
        handle: '.modal-body, .modal-header, .modal-footer',
        revert: false,
        revertDuration: 300
      });
    });
  }

  getPropertyList(){
    this.spinner.show();
    this.propertyService.getPropertyListForPropertyValues().subscribe(response =>{
      var property_list = response['property_list'];
      property_list.forEach((data) => {
        this.addPropertyList.push(this.createPropertyList(data));
      });
      this.propertyListForm.patchValue(response);

      this.spinner.hide();
      this.propertyValueModel.show();
    },err=>{
      this.spinner.hide();
      this.onError(err.message);
    });
  }

  createPropertyList(data){
    return this.fb.group({
      'capital_growth': [data.capital_growth,Validators.required],
      'capital_growth_percentage': [data.capital_growth_percentage,Validators.required],
      'capital_improvement': [data.capital_improvement],
      'estimated_value_now': [this.globalService.validateNumberFormat(data.estimated_value_now)],
      'property_address': [data.property_address],
      'property_ref': [data.property_ref],
      'purchase_price': [data.purchase_price],
    });
  }

  onSuccess(content) {
    this.notifications.create('Success', content, NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }


  calculatePropertyValues(key) {
    if(this.propertyListForm.value['propertyList'][key].estimated_value_now!=null){
      var estimateValue = this.propertyListForm.value['propertyList'][key].estimated_value_now;
      var purchasePrice = this.propertyListForm.value['propertyList'][key].purchase_price;
      var capitalGrowth = estimateValue - purchasePrice;
      var capitalGrowthPercentage = ((estimateValue - purchasePrice)/purchasePrice)*100;

      ((this.propertyListForm.get('propertyList') as FormArray).at(key) as FormGroup).get('estimated_value_now').patchValue(this.globalService.validateNumberFormat(estimateValue));
      ((this.propertyListForm.get('propertyList') as FormArray).at(key) as FormGroup).get('capital_growth').patchValue(this.globalService.validateNumberFormat(capitalGrowth));
      ((this.propertyListForm.get('propertyList') as FormArray).at(key) as FormGroup).get('capital_growth_percentage').patchValue(capitalGrowthPercentage);
    }

    this.calculateTotalPropertyValue();
  }

  closeModel() {
    this.addPropertyList.clear();
    this.propertyListForm.reset();
    this.propertyValueModel.hide();
    const data = {
      default:true,
      submit:false
    }
    this.propertyCalculationService.closeListOfPropertyValueModel(data);
  }

  private calculateTotalPropertyValue() {
    var totalEstimateValue = 0;
    var totalCapitalGrowth = 0;
    var totalCapitalGrowthPercentage = 0;
    var totalPurchaseValue = this.propertyListForm.get('total_purchase_value').value;
    this.propertyListForm.value['propertyList'].forEach((currentValue,index)=>{
      totalEstimateValue = (totalEstimateValue + currentValue.estimated_value_now) ;
    });

    totalCapitalGrowth = totalEstimateValue - totalPurchaseValue;
    totalCapitalGrowthPercentage = ((totalEstimateValue - totalPurchaseValue)/totalPurchaseValue)*100;
    this.propertyListForm.get('total_estimate_value').setValue(totalEstimateValue);
    this.propertyListForm.get('total_capital_gain').patchValue(totalCapitalGrowth);
    // this.propertyListForm.patchValue({total_capital_gain: this.globalService.validateNumberFormat(totalCapitalGrowth)});
    this.propertyListForm.patchValue({total_capital_gain_percentage: totalCapitalGrowthPercentage});
  }

  propertyValueSubmit() {
    this.buttonDisabled = false;
    this.responseWaiting = 'show-spinner';
    this.spinner.show();
    this.propertyService.savePropertyValue(this.propertyListForm.value).subscribe(response => {
      this.responseWaiting = '';
      this.spinner.hide();
      this.onSuccess("saved successfully");
      this.closeModel();
      const data = {
        default:true,
        submit:true
      }
      this.propertyCalculationService.closeListOfPropertyValueModel(data);
    }, err => {
      this.responseWaiting = '';
      this.spinner.hide();
      this.onError(err.message);
    });
  }


}
