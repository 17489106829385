import {Component, OnInit, ViewChild} from '@angular/core';
import {PropertyGlobalService} from "../../../property-global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {GlobalService} from "../../../global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {NgForm} from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: 'app-roof-materials',
  templateUrl: './roof-materials.component.html',
  styleUrls: ['./roof-materials.component.scss']
})
export class RoofMaterialsComponent implements OnInit {
  roofMaterials:any = [];
  tempRoofMaterials:any = [];
  roofMaterialKey = null;
  @ViewChild('roofMaterialModel') roofMaterialModel: ModalDirective;
  constructor(
    private propertyGlobalService: PropertyGlobalService,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService
  ) {
    this.propertyGlobalService.openRoofMaterialModelEvent.subscribe(data =>{
      this.roofMaterialKey = null;
      this.roofMaterials = [];
      this.tempRoofMaterials = [];
      this.getFormInitData();
    });
  }

  ngOnInit(): void {
  }

  public getFormInitData() {
    this.spinner.show();
    this.propertyGlobalService.propertyRegistrationFormLoad().subscribe(response => {
      this.spinner.hide();
      this.roofMaterials = response['roof_materials'];
      this.roofMaterials.forEach((item, index) => {
        if(item['user_id'] != null){
          this.tempRoofMaterials.push(item);
        }
      });

      this.roofMaterialModel.show();
    }, error => {
      this.spinner.hide();
      this.globalService.onError(error.message);
    });
  }

  closeRoofMaterialModel() {
    this.roofMaterialModel.hide();
    this.propertyGlobalService.parentModelSwitch(false);
    this.propertyGlobalService.closeRoofMaterialModel({'materialValue':null,'action':'close'});
  }

  addNewRoofMaterial(roofMaterialForm: NgForm) {
    if(roofMaterialForm.value['roofMaterialNew'] == null || roofMaterialForm.value['roofMaterialNew'] =='')
      return;
    this.propertyGlobalService.saveRoofMaterial('', roofMaterialForm.value['roofMaterialNew']).subscribe(res => {
      let materialValue = roofMaterialForm.value['roofMaterialNew'];
      this.spinner.hide();
      if (res['status'] == 200) {
        this.globalService.onSuccess('Roof Material Add successful');
        this.roofMaterials = res['roof_materials'];
        Swal.fire({
          title: 'Are you sure?',
          text: "you want to change current property roof material to '"+ roofMaterialForm.value['roofMaterialNew']+"'",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
        }).then((isConfirm) => {
          if (isConfirm.value) {
            this.roofMaterialModel.hide();
            this.propertyGlobalService.parentModelSwitch(false);
            this.propertyGlobalService.closeRoofMaterialModel({'materialValue':materialValue,'action':'add'});
          }
        }, function (desmiss) { });

        roofMaterialForm.reset();
        this.roofMaterialKey = null;
        this.tempRoofMaterials = [];
        for (let a = 0; a < this.roofMaterials.length; a++) {
          if (this.roofMaterials[a]['user_id'] != null) {
            this.tempRoofMaterials.push(this.roofMaterials[a]);
          }
        }
      }
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err.message);
    });
  }

  updateRoofMaterial(data: any, form: NgForm) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to save this Roof Material changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((isConfirm) => {

      if (isConfirm.value) {
        this.propertyGlobalService.saveRoofMaterial(data['roof_material_id'], form.value['roof_material']).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.globalService.onSuccess('Roof Material Edit successful');
            this.roofMaterials = res['roof_materials'];
            // if(this.properties['roof_material_id'] == data['roof_material_id'])
            //   this.properties['roof_material'] = form.value['roof_material'];
            this.roofMaterialKey = null;
            this.tempRoofMaterials = [];
            for (let a = 0; a < this.roofMaterials.length; a++) {
              if (this.roofMaterials[a]['user_id'] != null) {
                this.tempRoofMaterials.push(this.roofMaterials[a]);
              }
            }
          }
        }, err => {
          this.spinner.hide();
          this.globalService.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }

  roofMaterialCheck(roof_material_id: any){
    this.spinner.show();
    this.propertyGlobalService.checkRoofMaterial(roof_material_id).subscribe(res => {
      this.spinner.hide();
      if (res['status'] == 200) {
        if(res['validate']){
          this.deleteRoofMaterial(roof_material_id);
        }
        else{
          Swal.fire({
            title: 'You can not delete this roof material?',
            text: 'This roof material used in another component!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }

      }
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err.message);
    });
  }

  deleteRoofMaterial(roof_material_id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete this roof material !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.propertyGlobalService.deleteRoofMaterial(roof_material_id).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.globalService.onSuccess('Roof Material Delete successful');
            this.roofMaterials = res['roof_materials'];
            // if(this.properties['roof_material_id'] == roof_material_id)
            //   this.properties['roof_material'] = null;
            this.roofMaterialKey = null;
            this.tempRoofMaterials = [];
            for (let a = 0; a < this.roofMaterials.length; a++) {
              if (this.roofMaterials[a]['user_id'] != null) {
                this.tempRoofMaterials.push(this.roofMaterials[a]);
              }
            }
          }
        }, err => {
          this.spinner.hide();
          this.globalService.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }
}
