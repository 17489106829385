
<!-- Submodal -->
<div bsModal #documentModal="bs-modal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <ngx-spinner
          [name]="'upload_document_spinner'"
          [fullScreen]="false"
          type="ball-scale-multiple"
          size="medium"
        >
        </ngx-spinner>
      <ng-container>
        <h3 class="mt-4 mb-4 text-dark text-center">Upload Document - Drop Your Files to Selected Folder</h3>
        <form [formGroup]="imageUpload">
          <div class="container">
            <div class="row">
              <div class="col-4 pb-4 folder-item" *ngFor="let folder of folders; let i = index">
                <div class="overflow-unset" ngx-dropzone [accept]="'application/*,image/*,text/*,video/*'" (change)="uploadFiles($event,folder.document_folder_id)"
                     style="height: 80px">
                  <ngx-dropzone-label>
                    <div class="pb-1 pt-1 fw-600 text-dark">
                      <h4 class="m-0">{{ folder.folder_name }} ({{folder.total_files}})</h4>
                      <small *ngIf="folder.last_updated != null">Last updated {{folder.last_updated}}</small>
                    </div>
                  </ngx-dropzone-label>
                </div>

              </div>
            </div>
          </div>
        </form>
      </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-xs" (click)="closeDocumentModal()">Close</button>
        <button [disabled]="isBtnDisabled" type="button" class="btn btn-primary btn-xs" (click)="saveFiles()">Save</button>
      </div>
    </div>
  </div>
</div>
