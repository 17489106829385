import {Component, OnInit, ViewChild} from '@angular/core';
import {CommunicationService} from "../../communication.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {PropertyService} from "../../../views/app/properties/property.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import Swal from "sweetalert2";
import {GlobalService} from "../../global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-document-window',
  templateUrl: './document-window.component.html',
  styleUrls: ['./document-window.component.scss']
})
export class DocumentWindowComponent implements OnInit {

  @ViewChild('documentModal') documentModal: ModalDirective;
  imageUpload: FormGroup;
  folders: any = [];
  allFiles : any = [];
  propertyRef: any = '';
  documents: any = [];
  selectedRow = null;
  isBtnDisabled = true;
  constructor(
    public communicationService: CommunicationService,
    public propertyService: PropertyService,
    public fb: FormBuilder,
    public globalService: GlobalService,
    protected spinner: NgxSpinnerService,
    private notifications: NotificationsService,
    private translate: TranslateService,
  ) {
    this.communicationService.documentModalOpenEvent.subscribe((data) => {
      this.propertyRef = data['property_ref'];
      this.selectedRow = data['key'];
      this.openDocumentModal(data);
    });

    this.imageUpload = this.fb.group({
      image: [null],
      folder_name: [''],
      property_ref: [''],
      folder_id: [''],
    });
  }

  ngOnInit(): void {
    console.log('Document Window');
  }

  openDocumentModal(data) {


    this.spinner.show('upload_document_spinner');
    this.propertyService.getFolders(data).subscribe(response => {
      this.folders = response['folders'];
      this.documentModal.show();
      this.spinner.hide('upload_document_spinner');
      //
    },error => {
      this.spinner.hide('upload_document_spinner');
      this.onError('Something went wrong');

    });
  }
  closeDocumentModal() {
    this.documents = [];
    this.selectedRow = null;
    var data ={
      'key': this.selectedRow,
      'pdfs': this.documents
    }
    this.documentModal.hide();
    this.communicationService.closeDocumentModal(data);
  }

  uploadFiles(event,folder_id) {
    this.isBtnDisabled = true;
    this.spinner.show('upload_document_spinner');
    this.allFiles = [];
    this.allFiles.push(...event.addedFiles);
    if(this.allFiles.length <21){

      let formData = new FormData();
      formData.append('propertyRef', this.propertyRef );
      let folderIndex = this.folders.findIndex(item => item.document_folder_id == folder_id);
      formData.append('folder_name', this.folders[folderIndex].folder_name );
      formData.append('folder_id', folder_id );
      formData.append('come_from_document_window', 'true' );
      if (event.addedFiles) {
        for (const file of event.addedFiles) {
          formData.append('file[]', file); // Append each file to the FormData object
        }
      }
      this.globalService.saveDocuments(formData).subscribe(response => {
        if (response['status'] === 200) {
          this.isBtnDisabled = false;
          this.folders = response['folders'];
          this.documents.push(response['pdfs']);
          this.spinner.hide('upload_document_spinner');
          this.onSuccess('Save Successful!');
        } else {
          this.spinner.hide('upload_document_spinner');
          this.onError('Something went wrong');
        }
      }, err => {

        this.spinner.hide('upload_document_spinner');
        this.onError(err.message);
        this.globalService.unauthorizedRequest(err.status);
      });
    }else{
      this.spinner.hide('upload_document_spinner');
      Swal.fire({
        title: '',
        text: "You can upload only 20 files per instance. Please retry with 20 or less files selected.",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.value) {
        }
      });
    }

  }

  saveFiles (){
    this.documentModal.hide();
    var data ={
      'key': this.selectedRow,
      'pdfs': this.documents
    }
    this.communicationService.closeDocumentModal(data);
    this.documents = [];
    this.selectedRow = null;
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }
}
