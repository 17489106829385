<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #rentalListModel="bs-modal" class="modal fade custom-modal" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left">List by Rental Income</h4>
        <button type="button" class="close pull-right"
                (click)="closeModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="rentalListForm" novalidate>
          <table class="table">
            <thead>
            <tr>
              <th width="30%">Address</th>
              <th width="15%">Rent PW</th>
              <th width="15%">Rent PM </th>
              <th width="15%">Lease Ends </th>
              <th width="25%">Agent</th>
            </tr>
            </thead>
            <tbody>
            <ng-container formArrayName="rentalList">
              <tr *ngFor="let control of rentalList.controls; let index = index" [formGroupName]='index'>
                <td>{{control.get('property_address').value}}</td>
                <td class="text-right">
                  <div class=" position-relative mb-0 reduce-width">
                    <label class="form-group has-float-label has-left-icon-append">
                      <div class="input-group">
                        <div class="input-group-prepend custom-input-group-append-icon-right-border">
                          <span class="input-group-text custom-input-group-append-icon ">$</span>
                        </div>
                        <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="rent_per_week"
                               name="rent_per_week" (blur)="calculateRentPM(index)">
                      </div>
                    </label>
                  </div>
                </td>
                <td class="text-right">
                  <div class=" position-relative mb-0 reduce-width">
                    <label class="form-group has-float-label has-left-icon-append">
                      <div class="input-group">
                        <div class="input-group-prepend custom-input-group-append-icon-right-border">
                          <span class="input-group-text custom-input-group-append-icon ">$</span>
                        </div>
                        <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="rent_per_month"
                               name="rent_per_month" (blur)="calculateRentPW(index)">
                      </div>
                    </label>
                  </div>
                </td>
                <td >
                  <label class="form-group has-float-label">
                    <div class="input-group">
                      <input class="form-control" placeholder="dd/mm/YYYY"
                             name="lease_end_date" formControlName="lease_end_date" ngbDatepicker #lease_end_date="ngbDatepicker"
                             (click)="lease_end_date.toggle();">
                    </div>
                  </label>
                </td>
                <td >{{control.get('company_name').value}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>

          <table class="table">
            <tbody>
            <tr>
              <td width="30%" class="font-weight-bold">Total</td>
              <td width="15%" >${{rentalListForm.get('total_rent_pw').value | number:'1.2-2'}}</td>
              <td width="15%" >${{rentalListForm.get('total_rent_pm').value  | number:'1.2-2'}}</td>
              <td width="15%" class="text-right"></td>
              <td width="25%" class="text-right"></td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-xs" (click)="closeModel()">Close</button>
        <app-state-button btnClass="btn btn-primary btn-xs custom-btn-primary" [currentState]="responseWaiting" message="false"
                          (click)="rentalValueSubmit()" [isDisabled]="buttonDisabled">Save
        </app-state-button>
      </div>
    </div>
  </div>
</div>
