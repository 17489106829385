<ngx-spinner></ngx-spinner>

<div bsModal #newModalTest="bs-modal" class="modal fade" tabindex="-1"
     [config]="{backdrop: 'static', ignoreBackdropClick: true}"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h3 id="dialog-sizes-name1" class="modal-title pull-left">Supplier Details</h3>
        <button type="button" class="close pull-right" (click)="newModalTest.hide()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="custom-list">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div class=" form-group row mb-0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <label class="font-weight-bold">Company Name :</label>
                </div>
                <!--                <div class="col-md-1 col-1">-->
                <!--                  <span class="dots">:</span>-->
                <!--                </div>-->
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6">
                  <span>{{supplierDetails['company_name']}}</span>
                </div>
              </div>

              <div class=" form-group row mb-0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <label class="font-weight-bold">Rating :</label>
                </div>
                <!--                <div class="col-md-1 col-1">-->
                <!--                  <span class="dots">:</span>-->
                <!--                </div>-->
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6" style="margin-top: -8px;">
                  <ul class="list-inline rating-list"
                      *ngFor="let star of stars" style="display: inline-block">
                    <li
                      [ngClass]="{'selected': (star <= supplierDetails['rate'])}">
                      <i class="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div class=" form-group row mb-0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <label class="font-weight-bold">Type :</label>
                </div>
                <!--                <div class="col-md-1 col-1">-->
                <!--                  <span class="dots">:</span>-->
                <!--                </div>-->
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6">
<!--                  <span>{{supplierDetails['supplier_type']}}</span>-->
                  <ng-container *ngIf="supplierDetails['supplier_types']">
                    <ul *ngFor="let supplier_type of supplierDetails['supplier_types']">
                      <li>{{supplier_type.supplier_type}}</li>
                    </ul>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class=" form-group row mb-0">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label class="font-weight-bold ">Rating Comment</label>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <span>{{supplierDetails['rating_comment']}}</span>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
<!--              <div class=" form-group row mb-0">-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <label class="font-weight-bold">Phone :</label>-->
<!--                </div>-->
<!--                &lt;!&ndash;                <div class="col-md-1 col-1">&ndash;&gt;-->
<!--                &lt;!&ndash;                  <span class="dots">:</span>&ndash;&gt;-->
<!--                &lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <span>{{supplierDetails['contact_phone']}}</span>-->
<!--                </div>-->
<!--              </div>-->
              <div class=" form-group row mb-0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <label class="font-weight-bold">Email:</label>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6">
                  <span  class="cursor-pointer" (click)="openEmail(supplierDetails['contact_email'])" style="overflow-wrap: break-word;">{{supplierDetails['contact_email']}}</span>
                </div>
              </div>
<!--              <div class=" form-group row mb-0">-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <label class="font-weight-bold">ABN / ACN :</label>-->
<!--                </div>-->
<!--                &lt;!&ndash;                <div class="col-md-1 col-1">&ndash;&gt;-->
<!--                &lt;!&ndash;                  <span class="dots">:</span>&ndash;&gt;-->
<!--                &lt;!&ndash;                </div>&ndash;&gt;-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <span>{{supplierDetails['abn']}}</span>-->
<!--                </div>-->
<!--              </div>-->
              <div class=" form-group row mb-0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <label class="font-weight-bold">Company Phone :</label>
                </div>
                <!--                <div class="col-md-1 col-1">-->
                <!--                  <span class="dots">:</span>-->
                <!--                </div>-->
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6">
                  <span>{{supplierDetails['company_phone']}}</span>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
<!--              <div class=" form-group row mb-0">-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <label class="font-weight-bold">Contact Email 2 :</label>-->
<!--                </div>-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <a [href]="'mailto:' + supplierDetails['contact_2_email']" class="cursor-pointer">{{supplierDetails['contact_2_email']}}</a>-->
<!--                </div>-->
<!--              </div>-->
              <div class=" form-group row mb-0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <label class="font-weight-bold">Company Address :</label>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6">
                  <span class="cursor-pointer" style="overflow-wrap: break-word;" (click)="redirectToGoogleSearch(supplierDetails['company_address'])">{{supplierDetails['company_address']}}</span>
                </div>
              </div>

              <div class=" form-group row mb-0">
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6">
                  <label class="font-weight-bold">Web Address :</label>
                </div>
                <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-xs-6">
                  <span class="cursor-pointer" style="overflow-wrap: break-word;" (click)="redirectToSupplierWebPage(supplierDetails['web_address'])">{{supplierDetails['web_address']}}</span>
                </div>
              </div>

<!--              <div class=" form-group row mb-0">-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <label class="font-weight-bold">Web Address:</label>-->
<!--                </div>-->
<!--                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">-->
<!--                  <a href="javascript:void(0);" class="cursor-pointer" style="overflow-wrap: break-word;" (click)="redirectToSupplierWebPage(supplierDetails['web_address'])">{{supplierDetails['web_address']}}</a>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>


          <!--            <div class="col-md-6">-->
          <!--              <div class=" form-group row mb-0">-->
          <!--                <div class="col-md-5 col-5">-->
          <!--                  <label class="font-weight-bold">Company Name</label>-->
          <!--                </div>-->
          <!--                <div class="col-md-1 col-1">-->
          <!--                  <span class="dots">:</span>-->
          <!--                </div>-->
          <!--                <div class="col-md-6 col-6">-->
          <!--                  <span>{{supplierDetails['company_name']}}</span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="row mb-0">-->
          <!--                <div class="col-5">-->
          <!--                  <label class="font-weight-bold">Rating</label>-->
          <!--                </div>-->
          <!--                <div class="col-1">-->
          <!--                  <span class="dots">:</span>-->
          <!--                </div>-->
          <!--                <div class="col-6">-->
          <!--                  <ul class="list-inline rating-list"-->
          <!--                      *ngFor="let star of stars" style="display: inline-block">-->
          <!--                    <li-->
          <!--                      [ngClass]="{'selected': (star <= supplierDetails['rate'])}">-->
          <!--                      <i class="fa fa-star"></i>-->
          <!--                    </li>-->
          <!--                  </ul>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="col-md-6">-->
          <!--              <div class=" form-group row mb-0">-->
          <!--                <div class="col-md-5 col-5">-->
          <!--                  <label class="font-weight-bold ">Type</label>-->
          <!--                </div>-->
          <!--                <div class="col-md-1 col-1">-->
          <!--                  <span class="dots">:</span>-->
          <!--                </div>-->
          <!--                <div class="col-md-6 col-6">-->
          <!--                  <span>{{supplierDetails['supplier_type']}}</span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="row">-->
          <!--            <div class="col-md-12">-->
          <!--              <div class=" form-group row mb-0">-->
          <!--                <div class="col-md-12 col-12">-->
          <!--                  <label class="font-weight-bold ">Rating Comment</label>-->
          <!--                </div>-->
          <!--                <div class="col-md-12 col-12">-->
          <!--                  <span>{{supplierDetails['rating_comment']}}</span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <hr/>-->
          <!--          <div class="row">-->
          <!--&lt;!&ndash;            <div class="col-md-6">&ndash;&gt;-->
          <!--&lt;!&ndash;              <div class=" form-group row mb-0">&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-5 col-5">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <label class="font-weight-bold">Phone</label>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-1 col-1">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span class="dots">:</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-6 col-6">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span>{{supplierDetails['contact_phone']}}</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->

          <!--&lt;!&ndash;              <div class=" form-group row mb-0">&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-5 col-5">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <label class="font-weight-bold ">Contact Email 1</label>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-1 col-1">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span class="dots">:</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-6 col-6">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span>{{supplierDetails['contact_email']}}</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->

          <!--&lt;!&ndash;              <div class=" form-group row mb-0">&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-5 col-5">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <label class="font-weight-bold ">ABN / ACN</label>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-1 col-1">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span class="dots">:</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-6 col-6">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span>{{supplierDetails['abn']}}</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->

          <!--&lt;!&ndash;              <div class=" form-group row mb-0">&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-5 col-5">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <label class="font-weight-bold ">Company Phone</label>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-1 col-1">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span class="dots">:</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;                <div class="col-md-6 col-6">&ndash;&gt;-->
          <!--&lt;!&ndash;                  <span>{{supplierDetails['company_phone']}}</span>&ndash;&gt;-->
          <!--&lt;!&ndash;                </div>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->
          <!--&lt;!&ndash;            </div>&ndash;&gt;-->

          <!--            <div class="col-md-6">-->
          <!--              <div class=" form-group row mb-0">-->
          <!--                <div class="col-md-5 col-5">-->
          <!--                  <label class="font-weight-bold">Contact Email 2</label>-->
          <!--                </div>-->
          <!--                <div class="col-md-1 col-1">-->
          <!--                  <span class="dots">:</span>-->
          <!--                </div>-->
          <!--                <div class="col-md-6 col-6">-->
          <!--                  <span>{{supplierDetails['contact_2_email']}}</span>-->
          <!--                </div>-->
          <!--              </div>-->

          <!--              <div class=" form-group row mb-0">-->
          <!--                <div class="col-md-5 col-5">-->
          <!--                  <label class="font-weight-bold ">Company Address</label>-->
          <!--                </div>-->
          <!--                <div class="col-md-1 col-1">-->
          <!--                  <span class="dots">:</span>-->
          <!--                </div>-->
          <!--                <div class="col-md-6 col-6">-->
          <!--                  <span>{{supplierDetails['company_address']}}</span>-->
          <!--                </div>-->
          <!--              </div>-->

          <!--              <div class=" form-group row mb-0">-->
          <!--                <div class="col-md-5 col-5">-->
          <!--                  <label class="font-weight-bold">Web Address</label>-->
          <!--                </div>-->
          <!--                <div class="col-md-1 col-1">-->
          <!--                  <span class="dots">:</span>-->
          <!--                </div>-->
          <!--                <div class="col-md-6 col-6">-->
          <!--                  <span>{{supplierDetails['web_address']}}</span>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <ng-container *ngIf="comeFrom == 'my-supplier'">
            <hr/>
          </ng-container>

          <div  *ngIf="comeFrom == 'my-supplier'" class="row">
            <div class="col-md-12">
              <div class=" form-group row mb-0">
                <div class="col-md-3 col-12">
                  <label class="font-weight-bold">Property List</label>
                </div>
                <div class="col-md-6 col-12">
                  <div *ngFor="let property of supplierDetails['supplier_properties']">
                    <ul>
                      <li>
                        <p class="address cursor-pointer" (click)="navigateProperty(property.property_ref)"> {{property.property_address}}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-xs" (click)="newModalTest.hide()">Close</button>
      </div>
    </div>
  </div>
</div>
