import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {variable} from "@angular/compiler/src/output/output_ast";
import {GlobalService} from "../../../global.service";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {HelperService} from "../../../helper.service";
import {MortgageGlobalService} from "../../../mortgage-global.service";

@Component({
  selector: 'app-split-mortgage-row-details',
  templateUrl: './split-mortgage-row-details.component.html',
  styleUrls: ['./split-mortgage-row-details.component.scss']
})
export class SplitMortgageRowDetailsComponent implements OnInit {
  mortgageHeaderForm:FormGroup;
  mortgageDetailsList:FormArray;
  mortgage_ref = null;
  property_ref = null;
  hiddenModal = false;
  isEdit = false;
  submitted = false;
  responseWaiting = '';
  buttonDisabled = false;
  isMissingVariableMortgage = false;
  isMissingFixedMortgage = false;
  isCreateExpense = false;
  comeFrom = "";
  mortgageEditDate: any = null;
  isHeaderEdit = false;
  variable_last_payment_amount = 0;
  fixed_last_payment_amount = 0;
  variable_missing_months = [];
  fixed_missing_months = [];
  isImport = false;
  excelFiles: any = [];
  isImageLoadingBarShow = false;
  showMsg = false;
  @ViewChild('splitModel') splitModel: ModalDirective;
  constructor( private globalService: GlobalService,
               private spinner: NgxSpinnerService,
               private notifications: NotificationsService,
               private translate: TranslateService,
               private modalService: BsModalService,
               private helperService: HelperService,
               private fb: FormBuilder,
               private mortgage_service:MortgageGlobalService,
               private router: Router,) {
    this.initMortgageHeaderForm();

    this.mortgage_service.openAddSplitMortgageModalEvent.subscribe(
      data => {
        this.isImport = false;
        this.comeFrom = data.comeFrom;
        this.isMissingVariableMortgage = false;
        this.isMissingFixedMortgage = false;
        this.initMortgageHeaderForm();
        this.isEdit = data.isEdit;
        this.mortgage_ref = data.mortgage_ref;
        this.property_ref = data.property_ref;
        this.isHeaderEdit = data.isHeaderEdit;
        this.getMortgageHeaderDetails();

      }
    );

    this.globalService.closeMainModalEvent.subscribe(data => {
      this.hiddenModal = false;
    });

    this.mortgage_service.editSplitMortgageDetailsEvent.subscribe(
      data => {
        this.isImport = false;
        this.comeFrom = data.comeFrom;
        this.isMissingVariableMortgage = false;
        this.isMissingFixedMortgage = false;
        this.mortgageHeaderForm.reset(this.mortgageHeaderForm.value);
        this.documentsControl.clear();
        this.initMortgageHeaderForm();
        this.submitted = false;
        this.isEdit = true;
        this.mortgage_ref = data.mortgage_ref;
        this.property_ref = data.property_ref;
        this.isHeaderEdit = data.isHeaderEdit;
        this.getMortgageHeaderDetailsForEditMortgage(data['mortgage_detail']);

      }
    );

    this.mortgage_service.openSplitMortgageDetailsImportModelEvent.subscribe(data => {
      this.isImport = true;
      this.comeFrom = data.comeFrom;
      this.isMissingVariableMortgage = false;
      this.isMissingFixedMortgage = false;
      this.mortgageHeaderForm.reset();
      this.documentsControl.clear();
      this.initMortgageHeaderForm();
      this.submitted = false;
      this.isCreateExpense = false;
      this.isEdit = true;
      this.mortgage_ref = data.mortgage_ref;
      this.property_ref = data.property_ref;
      this.isHeaderEdit = data.isHeaderEdit;
      this.variable_missing_months = [];
      this.fixed_missing_months = [];
      this.getMortgageHeaderDetailsForImport();
    });
  }

  initMortgageHeaderForm(){
    this.mortgageHeaderForm = this.fb.group({
      variable_last_payment_date: [null],
      variable_last_payment_amount: [null],
      fixed_last_payment_date: [null],
      fixed_last_payment_amount: [null],
      variable_interest_only_loan_start: [null],
      variable_interest_only_loan_end: [null],
      fixed_interest_only_loan_start: [null],
      fixed_interest_only_loan_end: [null],
      variable_current_loan_balance: [null],
      variable_interest_rate: [null],
      variable_redraw_available: [null],
      fixed_current_loan_balance: [null],
      fixed_interest_rate: [null],
      fixed_redraw_available: [null],
      variable_redraw_offset_facility: [null],
      fixed_redraw_offset_facility: [null],
      split_loan_ref: [null],
      mortgage_type: [null],
      total_fixed_amount: [null],
      total_variable_amount:  [null],
      variable_loan_count:  [null],
      fixed_loan_count:  [null],
      mortgageRow: this.fb.array([])
    });
  }

  get documentsControl(): FormArray {
    return this.mortgageHeaderForm.get("mortgageRow") as FormArray;
  }

  createMortgageDetailsForm(){
    return this.fb.group({
      'date_s': [null,Validators.required],
      'amount': [null,Validators.required],
      'mortgage_detail_ref': [null],
      'loan_rate_type': ['Variable'],
      'error_count': 1,
    });
  }


  createMortgageDetailsForEdit(data){
    return this.fb.group({
      'date_s': [data.paid_date,Validators.required],
      'amount': [data.amount,Validators.required],
      'mortgage_detail_ref': [data.mortgage_detail_ref],
      'loan_rate_type': [data.loan_rate_type],
      'error_count': 1,
      'couple_code': data.couple_code,
    });
  }

  ngOnInit(): void {
  }

  closeModel() {
    this.mortgageHeaderForm.reset();
    this.splitModel.hide();
    this.onModalHidden();
    if(this.comeFrom == "property"){
      this.router.navigate(['/app/properties/list']);
    }
    else if(this.comeFrom == "Archive"){
      this.mortgage_service.refreshArchiveMortgageList(true);
      this.mortgage_service.openMortgageDetailsFormForArchive(false);
    }
    else {
      this.mortgage_service.addMortgageDetailsModelClose({'comeFrom':this.comeFrom});
    }
  }

  getMortgageHeaderDetails(){
    this.spinner.show();
    let data = {
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref
    };
    this.globalService.getMortgageHeaderDetails(data).subscribe(response=>{
      this.spinner.hide();
      this.mortgageHeaderForm.patchValue(response['mortgage_data']);
      this.setTwoNumberDecimal('variable_last_payment_amount');
      this.setTwoNumberDecimal('fixed_last_payment_amount');
      this.setTwoNumberDecimal('variable_current_loan_balance');
      this.setTwoNumberDecimal('fixed_current_loan_balance');
      this.setTwoNumberDecimal('variable_redraw_available');
      this.setTwoNumberDecimal('fixed_redraw_available');


      this.variable_missing_months = response['variable_missing_months'];
      this.fixed_missing_months = response['fixed_missing_months'];
      this.isMissingFixedMortgage = response['is_fixed_missing_mortgage'];
      this.isMissingVariableMortgage = response['is_variable_missing_mortgage'];
      this.variable_last_payment_amount = response['mortgage_data'].variable_last_payment_amount;
      this.fixed_last_payment_amount = response['mortgage_data'].fixed_last_payment_amount;
      // this.checkMortgageDetailsMissingInfo();
      this.mortgage_service.openMortgageDetailsFormForArchive(true);
      this.addNewRow(0);
      this.splitModel.show();
    },error=>{
      this.spinner.hide();
      this.onError(error);
    });
  }

  calculateVariableRedraw() {
    let result = this.helperService.calculateRedraw(this.mortgageHeaderForm.get('variable_redraw_offset_facility').value,this.mortgageHeaderForm.get('total_variable_amount').value,this.mortgageHeaderForm.get('variable_current_loan_balance').value);
    this.mortgageHeaderForm.patchValue({variable_redraw_available:result});
    this.setTwoNumberDecimal('variable_redraw_available');
  }

  calculateVariableLoanCurrentBalance() {
    let result = this.helperService.calculateCurrentLoanBalance(this.mortgageHeaderForm.get('variable_redraw_offset_facility').value,this.mortgageHeaderForm.get('total_variable_amount').value,this.mortgageHeaderForm.get('variable_redraw_available').value);
    this.mortgageHeaderForm.patchValue({variable_current_loan_balance:result});
    this.setTwoNumberDecimal('variable_current_loan_balance');
  }


  calculateFixedRedraw() {
    let result = this.helperService.calculateRedraw(this.mortgageHeaderForm.get('fixed_redraw_offset_facility').value,this.mortgageHeaderForm.get('total_fixed_amount').value,this.mortgageHeaderForm.get('fixed_current_loan_balance').value);
    this.mortgageHeaderForm.patchValue({fixed_redraw_available:result});
    this.setTwoNumberDecimal('fixed_redraw_available');
  }

  calculateFixedLoanCurrentBalance() {
    let result = this.helperService.calculateCurrentLoanBalance(this.mortgageHeaderForm.get('fixed_redraw_offset_facility').value,this.mortgageHeaderForm.get('total_fixed_amount').value,this.mortgageHeaderForm.get('fixed_redraw_available').value);
    this.mortgageHeaderForm.patchValue({fixed_current_loan_balance:result});
    this.setTwoNumberDecimal('fixed_current_loan_balance');
  }

  openIssueModel(section: string) {
    this.hiddenModal = true;
    var data = {
      section: section
    }
    this.globalService.reportNewIssueModal(data);
  }

  createMortgageRaw(type = 'Variable',rendom_string = null) {
    let date = null;
    let amount = null;
    if(type == 'Variable'){
      if(this.mortgageHeaderForm.value['variable_last_payment_date']!=null){
        let d = new Date(this.mortgageHeaderForm.value['variable_last_payment_date']);
        d.setMonth(d.getMonth()+1);
        date = { year: d.getFullYear(), month: (d.getMonth() + 1), day: d.getDate() };
      }
      amount = this.mortgageHeaderForm.value['variable_last_payment_amount']!=null?this.mortgageHeaderForm.value['variable_last_payment_amount']:null;
    }
    else {
      if(this.mortgageHeaderForm.value['fixed_last_payment_date']!=null){
        let d = new Date(this.mortgageHeaderForm.value['fixed_last_payment_date']);
        d.setMonth(d.getMonth()+1);
        date = { year: d.getFullYear(), month: (d.getMonth() + 1), day: d.getDate() };
      }
      amount = this.mortgageHeaderForm.value['fixed_last_payment_amount']!=null?this.mortgageHeaderForm.value['fixed_last_payment_amount']:null;
    }


    let data = {
      'paid_date': date,
      'amount': this.globalService.validateNumberFormat(amount),
      'mortgage_detail_ref': null,
      'loan_rate_type': type,
      'error_count': 0,
      'couple_code': rendom_string,
    };

    this.documentsControl.push(this.createMortgageDetailsForEdit(data));
  }

  amountChange(event: any, key: any) {
    this.documentsControl.controls[key].get('amount').setValue(this.globalService.validateNumberFormat(this.documentsControl.controls[key].get('amount').value));
    this.mortgageHeaderForm.patchValue({variable_last_payment_amount: event});
    this.setTwoNumberDecimal('variable_last_payment_amount');
  }


  removeRecord(index) {
    this.documentsControl.removeAt(index);
  }

  addNewRowAfter(key: any) {
    if(key+1 == this.documentsControl.length){
      this.addNewRow(key);
    }
    else {
      this.documentsControl.insert(key + 1, this.fb.group({
        'date_s': [null, Validators.required],
        'amount': [this.mortgageHeaderForm.value['variable_last_payment_amount'] != null ? this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['variable_last_payment_amount']) : null, Validators.required],
        'mortgage_detail_ref': [null],
        'loan_rate_type': ['Variable'],
        'error_count': 1,
        'couple_code': this.globalService.randomStringGenerator(),
      }));
    }
  }


  addNewRow(key) {
    let rendom_string = this.globalService.randomStringGenerator();
    if(this.mortgageHeaderForm.value['mortgageRow'].length > 0) {
      let variable_array = [];
      let fixed_array = [];
      this.mortgageHeaderForm.value['mortgageRow'].forEach(element => {
        if (element['loan_rate_type'] == 'Fixed') {
          fixed_array.push(element);
        }

        if (element['loan_rate_type'] == 'Variable') {
          variable_array.push(element);
        }
      });

      if (fixed_array.length > 0) {
        let fixed_pay_date = null;
        if (fixed_array[(fixed_array.length - 1)].date_s != null) {
          let date = new Date(this.helperService.extractDateString(fixed_array[(fixed_array.length - 1)].date_s));
          date.setMonth(date.getMonth() + 1);
          fixed_pay_date = this.helperService.formDateObject(date);
        }

        if(fixed_array[(fixed_array.length - 1)].amount != null){
          this.mortgageHeaderForm.patchValue({fixed_last_payment_amount:fixed_array[(fixed_array.length - 1)].amount});
        }

        this.documentsControl.insert(key + 1, this.fb.group({
          'date_s': [fixed_pay_date, Validators.required],
          'amount': [this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['fixed_last_payment_amount']), Validators.required],
          'mortgage_detail_ref': [null],
          'loan_rate_type': ['Fixed'],
          'error_count': 1,
          'couple_code': rendom_string
          // 'couple_code': this.globalService.randomStringGenerator(),
        }));
      }
      else{
        // let rendom_string = this.globalService.randomStringGenerator();
        this.createMortgageRaw('Fixed',rendom_string);
      }

      if (variable_array.length > 0) {
        let variable_pay_date = null;
        if (variable_array[(variable_array.length - 1)].date_s != null) {
          let date = new Date(this.helperService.extractDateString(variable_array[(variable_array.length - 1)].date_s));
          date.setMonth(date.getMonth() + 1);
          variable_pay_date = this.helperService.formDateObject(date);
        }

        if(fixed_array[(fixed_array.length - 1)].amount != null){
          this.mortgageHeaderForm.patchValue({variable_last_payment_amount:variable_array[(variable_array.length - 1)].amount});
        }

        this.documentsControl.insert(key + 1, this.fb.group({
          'date_s': [variable_pay_date, Validators.required],
          'amount': [this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['variable_last_payment_amount']), Validators.required],
          'mortgage_detail_ref': [null],
          'loan_rate_type': ['Variable'],
          'error_count': 1,
          // 'couple_code': this.globalService.randomStringGenerator(),
          'couple_code': rendom_string
        }));
      }
      else {
        // let rendom_string = this.globalService.randomStringGenerator();
        this.createMortgageRaw('Variable', rendom_string);
      }

    }
    else {
      // let rendom_string = this.globalService.randomStringGenerator();
      this.createMortgageRaw('Fixed',rendom_string);
      this.createMortgageRaw('Variable', rendom_string);
    }
  }

  get vSplit() {
    return this.documentsControl.controls;
  }

  saveMortgageDetails() {

    this.submitted = true;
    if (!this.mortgageHeaderForm.valid) {
      return;
    }

    this.spinner.show();
    let detailsArray = [];
    detailsArray = [...this.documentsControl.value];
    if(this.documentsControl.length > 0){
      detailsArray.forEach(value => {
        if(value.date_s!=null){
          value['date_string'] = this.helperService.extractDateString(value.date_s);
        }
      });
    }

    let data = {
      'form_data': this.mortgageHeaderForm.value,
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref,
      'is_create_expense': this.isCreateExpense,
      'details_array': detailsArray,
    }

    this.globalService.saveSplitMortgageDetails(data).subscribe(res => {
        this.spinner.hide();
        if(this.isCreateExpense){
          let data = {
            'expense_data' : res['expense_list'],
            'come_from' : this.comeFrom,
            'property_ref' : this.property_ref,
          }
          this.globalService.openExpenseViewModelForExpensePayment(data);
        }
        this.onSuccess("Mortgage Details Saved Successfully");
        this.splitModel.hide();

        if(this.comeFrom == "property"){
          this.router.navigate(['/app/properties/list']);
        }
        else if(this.comeFrom == "Archive"){
          this.mortgage_service.refreshArchiveMortgageList(true);

          let data = {
            'mortgage_ref': this.mortgage_ref,
            'property_ref': this.property_ref,
            'isHeaderEdit': this.isHeaderEdit,
            'comeFrom': 'Archive',
          };
          console.log('split 1');
          this.mortgage_service.loadMortgageHeaderData(data);
          this.mortgage_service.openMortgageDetailsFormForArchive(false);
        }
        else{
          let data = {
            'mortgage_ref': this.mortgage_ref,
            'property_ref': this.property_ref,
            'isHeaderEdit': this.isHeaderEdit,
            'comeFrom': this.comeFrom,
          };
          console.log('split 2');
          this.mortgage_service.loadMortgageHeaderData(data);

          this.mortgage_service.addMortgageDetailsModelClose({'comeFrom':this.comeFrom});
        }



        this.mortgageHeaderForm.reset();

      },
      error => {
        this.spinner.hide();
        this.onError(error);
      });
  }

  createMissingVariableMortgage() {
    // this.spinner.show();
    // let data = {
    //   'mortgage_ref': this.mortgage_ref,
    //   'property_ref': this.property_ref,
    //   'loan_rate_type': 'Variable',
    // }
    // this.globalService.createMissingVariableMortgage(data).subscribe(
    //   response => {
    //     this.spinner.hide();
    this.generateMortgageDetails(this.variable_missing_months,'Variable');
    //
    //   },
    //   error => {
    //     this.spinner.hide();
    //     this.onError(error);
    //   }
    // );
  }

  createMissingFixedMortgage() {
    // this.spinner.show();
    // let data = {
    //   'mortgage_ref': this.mortgage_ref,
    //   'property_ref': this.property_ref,
    //   'loan_rate_type': 'Fixed',
    // }
    // this.globalService.createMissingVariableMortgage(data).subscribe(
    //   response => {
    //     this.spinner.hide();
    this.generateMortgageDetails(this.fixed_missing_months,'Fixed');

    //   },
    //   error => {
    //     this.spinner.hide();
    //     this.onError(error);
    //   }
    // );
  }

  getMortgageHeaderDetailsForEditMortgage(data) {
    this.spinner.show();
    let details = {
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref
    };
    this.globalService.getMortgageHeaderDetails(details).subscribe(response=>{
      this.spinner.hide();
      this.mortgageHeaderForm.patchValue(response['mortgage_data']);
      let rawData = {
        'paid_date': data['paid_date']!=null?this.helperService.formDateObject(data['paid_date']):null,
        'amount': this.globalService.validateNumberFormat(data['amount']),
        'mortgage_detail_ref': data['mortgage_detail_ref'],
        'loan_rate_type': data['loan_rate_type'],
        'error_count': 0,
        'couple_code': this.globalService.randomStringGenerator(),
      };
      this.documentsControl.push(this.createMortgageDetailsForEdit(rawData));

      this.splitModel.show();
    },error=>{
      this.spinner.hide();
      this.notifications.error('Error', error);
    });
  }

  // checkMortgageDetailsMissingInfo(){
  //   if (this.mortgageHeaderForm.value['variable_loan_count'] == 0 && this.documentsControl.length == 0) {
  //     var current_date = new Date();
  //     var last_payment_date = new Date(this.mortgageHeaderForm.value['variable_last_payment_date']);
  //     var endDate = new Date(this.mortgageHeaderForm.value['variable_interest_only_loan_end']);
  //     var startDate = new Date(this.mortgageHeaderForm.value['variable_interest_only_loan_start']);
  //     if(this.mortgageHeaderForm.value['variable_last_payment_date'] != null){
  //       if(startDate < current_date && endDate >= current_date){
  //         this.isMissingVariableMortgage = false;
  //       }
  //
  //       last_payment_date.setMonth(last_payment_date.getMonth() + 1)
  //       if(last_payment_date < current_date){
  //         this.isMissingVariableMortgage = true;
  //       }
  //       else {
  //         this.isMissingVariableMortgage = false;
  //       }
  //     }
  //     else {
  //       if(startDate < current_date && endDate >= current_date){
  //         this.isMissingVariableMortgage = true;
  //       }
  //       else {
  //         this.isMissingVariableMortgage = false;
  //       }
  //     }
  //   }
  //
  //   if (this.mortgageHeaderForm.value['fixed_loan_count'] == 0 && this.documentsControl.length == 0) {
  //     var current_date = new Date();
  //     var last_payment_date = new Date(this.mortgageHeaderForm.value['fixed_last_payment_date']);
  //     var endDate = new Date(this.mortgageHeaderForm.value['fixed_interest_only_loan_end']);
  //     var startDate = new Date(this.mortgageHeaderForm.value['fixed_interest_only_loan_start']);
  //     if(this.mortgageHeaderForm.value['fixed_last_payment_date'] != null){
  //
  //       if(startDate < current_date && endDate >= current_date){
  //         this.isMissingFixedMortgage = false;
  //       }
  //
  //       last_payment_date.setMonth(last_payment_date.getMonth() + 1)
  //       if(last_payment_date < current_date){
  //         this.isMissingFixedMortgage = true;
  //       }
  //       else {
  //         this.isMissingFixedMortgage = false;
  //       }
  //     }
  //     else {
  //       if(startDate < current_date && endDate >= current_date){
  //         this.isMissingFixedMortgage = true;
  //       }
  //       else {
  //         this.isMissingFixedMortgage = false;
  //       }
  //     }
  //   }
  //
  //
  //   if(!this.isMissingVariableMortgage && !this.isMissingFixedMortgage){
  //     this.createMortgageRaw('Variable');
  //     this.createMortgageRaw('Fixed');
  //   }
  // }

  dateChange(couple_code,loan_rate_type,date_s){
    if(loan_rate_type == 'Fixed' && date_s != null){

      this.documentsControl.controls.forEach((element, index) => {

        // Check if 'date_s' control exists in the current FormGroup
        if (element.get('date_s')) {
          const coupleCode = element.value['couple_code'];
          const loanRateType = 'Fixed';

          if (coupleCode === couple_code && loanRateType === 'Fixed') {
            ((this.mortgageHeaderForm.get('mortgageRow') as FormArray).at(index) as FormGroup).get('date_s')?.setValue(date_s);
          }
        }
      });
      // this.documentsControl.controls.forEach((element,index) => {
      //   if(element.value['couple_code'] == couple_code && loan_rate_type == 'Fixed'){
      //     ((this.mortgageHeaderForm.get('mortgageRow') as FormArray).at(index+1) as FormGroup).get('date_s')?.setValue(date_s);
      //   }
      // });
      //  console.log("hit me",this.mortgageHeaderForm.get('mortgageRow'));
    }
  }

  private generateMortgageDetails(data,rate_type) {

    data.forEach(element => {
      let rawData = {
        'paid_date': this.helperService.formDateObject(element),
        'amount': rate_type == 'Variable'?this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['variable_last_payment_amount']):this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['fixed_last_payment_amount']),
        'mortgage_detail_ref': null,
        'loan_rate_type': rate_type,
        'error_count': 0,
        'couple_code': this.globalService.randomStringGenerator(),
      };
      this.documentsControl.push(this.createMortgageDetailsForEdit(rawData));
    });
  }


  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  editMortgage(data){
    let rawData = {
      'paid_date': this.helperService.formDateObject(data.paid_date),
      'amount': this.globalService.validateNumberFormat(this.mortgageHeaderForm.value['variable_last_payment_amount']),
      'mortgage_detail_ref': data.mortgage_detail_ref,
      'loan_rate_type': data.loan_rate_type,
      'error_count': 0,
      'couple_code': this.globalService.randomStringGenerator(),
    };
    this.documentsControl.push(this.createMortgageDetailsForEdit(rawData));
  }

  getMortgageHeaderDetailsForImport() {
    this.spinner.show();
    let data = {
      'mortgage_ref': this.mortgage_ref,
      'property_ref': this.property_ref
    };
    this.globalService.getMortgageHeaderDetails(data).subscribe(response=>{
      this.spinner.hide();
      this.mortgageHeaderForm.patchValue(response['mortgage_data']);
      // this.checkMortgageDetailsMissingInfo();
      this.splitModel.show();
    },error=>{
      this.spinner.hide();
      this.onError(error);
    });
  }

  async importExcel(event) {
    this.showMsg = false;
    this.excelFiles = [];
    this.excelFiles.push(...event.addedFiles);
    await this.addSelectedExcelFile(this.excelFiles)
  }

  async addSelectedExcelFile(files) {
    let selectedExFile = [];
    // selectedExFile = files;
    let formData = new FormData();
    let i;
    for (i = 0; i < files.length; i++) {
      formData.append('file', files[i], files[i].name);
      await this.uploadExcel(formData);
    }
  }

  uploadExcel(formData) {
    this.spinner.show();
    this.isImageLoadingBarShow = true;
    formData.append('property_ref', this.property_ref);
    formData.append('mortgage_ref', this.mortgage_ref);
    this.globalService.splitMortgageUploadFile(formData).subscribe(response => {
        this.spinner.hide();
        this.isImageLoadingBarShow = false;
        if(response['mortgage_list'] != null){
          response['mortgage_list'].forEach((val, index) => {
            let date = null;
            if (val.paid_date != null) {
              date = this.helperService.formDateObject(val.paid_date);
            }

            let rawData = {
              'paid_date': date,
              'amount': this.globalService.validateNumberFormat(val.amount),
              'mortgage_detail_ref': null,
              'loan_rate_type': val.loan_rate_type,
              'error_count': 0,
              'couple_code': this.globalService.randomStringGenerator(),
            };
            this.documentsControl.push(this.createMortgageDetailsForEdit(rawData));

          });
        }
      },
      err => {
        this.spinner.hide();
        this.showMsg = true;
        this.onError(err.message);
      });
  }

  onModalHidden() {
    setTimeout(() => {
      const modalBackdrops = document.querySelectorAll('.modal-backdrop');
      if (modalBackdrops) {
        document.body.classList.remove('modal-open');
        modalBackdrops.forEach(backdrop => {
          backdrop.remove();
        });
      }
    },1000);

  }

  setTwoNumberDecimal(field) {
    if(this.mortgageHeaderForm.get(field).value != null && this.mortgageHeaderForm.get(field).value != '' && this.mortgageHeaderForm.get(field).value != undefined){
      let resultValue = this.mortgageHeaderForm.get(field).value;
      resultValue = this.globalService.validateNumberFormat(resultValue);
      this.mortgageHeaderForm.get(field).setValue(resultValue);
    }
    //
    // let regexp = new RegExp('^(\\d{1,5}|\\d{0,5}\\.\\d{1,2})$');
    // let result =  regexp.test(this.propertyMortgageForm.get(field).value);
    // if(result == false){
    //   let repayment = (this.propertyMortgageForm.get(field).value).toFixed(2);
    //   this.propertyMortgageForm.get(field).patchValue(repayment);
    // }
  }

}
