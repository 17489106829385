<div bsModal #roofMaterialModel="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="dialog-sizes-name1" [config]="{ ignoreBackdropClick: true}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Roof Materials</h4>
        <button type="button" class="close pull-right" (click)="closeRoofMaterialModel()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #roofMaterialForm="ngForm">
          <div class="col-md-12 col-sm-12 pl-0 pr-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td colspan="2" class="pl-3 pr-3">
                      <div class="input-group input-group-sm">
                        <input type="text" class="form-control" name="roofMaterialNew" placeholder="Add new roof material"
                               ngModel required style="height: 100%">
                        <div class="input-group-append">
                          <button class="btn btn-primary " type="button" (click)="addNewRoofMaterial(roofMaterialForm)" style="border-radius:0px 11px 11px 0px !important;">
                            <i class=" glyph-icon simple-icon-check f-15  text-white"></i></button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let tempRoofMaterial of tempRoofMaterials;let key = index;">
                    <td class="pl-1" *ngIf="roofMaterialKey != key">{{tempRoofMaterial.roof_material}}</td>
                    <td class="pl-1" *ngIf="roofMaterialKey == key">
                      <label class="form-group has-float-label mb-1">
                        <div class="input-group input-group-sm">
                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="roof_material" [ngModel]="tempRoofMaterial.roof_material"
                                 #roof_material_edit="ngModel" style="height: 100%">
                        </div>
                        <span>Building Material</span>
                      </label>
                    </td>
                    <td class="text-right pr-1">
                      <a *ngIf="roofMaterialKey == key" href="javascript:;" class="badge badge-danger mb-1 mr-1" (click)="roofMaterialKey = null">
                        <i  class="glyph-icon simple-icon-close text-white f-15 "></i>
                      </a>
                      <a *ngIf="roofMaterialKey == key" href="javascript:;" class="badge badge-primary mr-1 mb-1"  (click)="updateRoofMaterial(tempRoofMaterial,roofMaterialForm)">
                        <i  class="glyph-icon simple-icon-check f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-danger mb-1 mr-1"  (click)="roofMaterialCheck(tempRoofMaterial.roof_material_id)">
                        <i *ngIf="roofMaterialKey != key" class="glyph-icon simple-icon-trash f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-primary mb-1" (click)="roofMaterialKey = key">
                        <i *ngIf="roofMaterialKey != key" class="glyph-icon simple-icon-note text-white f-15"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot *ngIf="tempRoofMaterials.length == 0">
                  <tr>
                    <td colspan="3">
                      No records found..
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm"
                (click)="closeRoofMaterialModel()">Close</button>
      </div>
    </div>
  </div>
</div>
