import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {NgxSpinnerService} from "ngx-spinner";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PropertyCalculationService} from "../../../../shared/property-calculation.service";
import {GlobalService} from "../../../../shared/global.service";
import {PropertyService} from "../../../../views/app/properties/property.service";

@Component({
  selector: 'app-property-cashflow',
  templateUrl: './property-cashflow.component.html',
  styleUrls: ['./property-cashflow.component.scss']
})
export class PropertyCashflowComponent implements OnInit {
  cashflowListForm: FormGroup;
  responseWaiting = '';
  buttonDisabled = false;
  @ViewChild('cashflowModel') cashflowModel: ModalDirective;
  constructor(private propertyCalculationService:PropertyCalculationService,
              private modalService: BsModalService,
              private notifications: NotificationsService,
              private spinner: NgxSpinnerService,
              private propertyService : PropertyService,
              private fb: FormBuilder,
              private globalService : GlobalService) {
    this.initForm();
    this.propertyCalculationService.openPropertyCashflowEvent.subscribe(data => {
      this.getCashFlowList();
    });
  }

  ngOnInit(): void {

  }

  initForm(){
    this.cashflowListForm = this.fb.group({
      total_cash_flow: [''],
      total_monthly_repayment: [''],
      total_rent_per_month: [''],
      cashflowList: this.fb.array([]),
    });
  }

  get addCashflowList(): any {
    return this.cashflowListForm.get('cashflowList') as FormArray;
  }

  private getCashFlowList() {
    this.spinner.show();
    this.propertyService.getCashFlowList().subscribe(response => {
      var cash_flow_list = response['property_list'];
      cash_flow_list.forEach((data) => {
        this.addCashflowList.push(this.createCshflow(data));
      });
      this.cashflowListForm.patchValue(response)

      this.spinner.hide();
      this.cashflowModel.show();
    },err=>{
      this.spinner.hide();
      this.onError(err.message);
    });
  }

  createCshflow(data){
    return this.fb.group({
      'cash_flow': [data.cash_flow],
      'monthly_repayment': [data.monthly_repayment != null ? this.globalService.validateNumberFormat(data.monthly_repayment) : ''],
      'property_address': [data.property_address],
      'property_ref': [data.property_ref],
      'property_type': [data.property_type],
      'rent_per_month': [data.rent_per_month != null ? this.globalService.validateNumberFormat(data.rent_per_month) : ''],
      'rental_ref': [data.rental_ref],
      'mortgage_ref': [data.mortgage_ref],
      'is_mortgage_disable': [data.is_mortgage_disable],
    });
  }

  changeValue(index: number) {
    var cashflow = 0;
    var monthly_repayment = 0;
    var rent_per_month = 0;
    if(this.cashflowListForm.value.cashflowList[index].monthly_repayment != null && this.cashflowListForm.value.cashflowList[index].monthly_repayment != ''){
      monthly_repayment = this.cashflowListForm.value.cashflowList[index].monthly_repayment;
      this.addCashflowList.at(index).get('monthly_repayment').patchValue(this.globalService.validateNumberFormat(monthly_repayment));
    }

    if(this.cashflowListForm.value.cashflowList[index].rent_per_month != null && this.cashflowListForm.value.cashflowList[index].rent_per_month != ''){
      rent_per_month = this.cashflowListForm.value.cashflowList[index].rent_per_month;
      this.addCashflowList.at(index).get('rent_per_month').patchValue(this.globalService.validateNumberFormat(rent_per_month));
    }

    cashflow = rent_per_month - monthly_repayment;
    ((this.cashflowListForm.get('cashflowList') as FormArray).at(index) as FormGroup).get('cash_flow').patchValue(cashflow);

    this.calculateCashflow();
  }


  onSuccess(content) {
    this.notifications.create('Success', content, NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  closeModel() {
    this.addCashflowList.clear();
    this.cashflowListForm.reset();
    this.cashflowModel.hide();
    const data = {
      default:true,
      submit:false
    }
    this.propertyCalculationService.closeListOfPropertyValueModel(data);
  }

  calculateCashflow(){
    var total_cash_flow = 0;
    var total_monthly_repayment = 0;
    var total_rent_per_month = 0;

    this.cashflowListForm.value['cashflowList'].forEach((currentValue,index)=>{
      total_cash_flow += currentValue.cash_flow;
      if(currentValue.monthly_repayment == null || currentValue.monthly_repayment == ''){
        currentValue.monthly_repayment = 0;
      }
      if(currentValue.rent_per_month == null || currentValue.rent_per_month == ''){
        currentValue.rent_per_month = 0;
      }

      total_monthly_repayment += currentValue.monthly_repayment;
      total_rent_per_month += currentValue.rent_per_month;
    });

    this.cashflowListForm.patchValue({total_cash_flow: total_cash_flow,total_monthly_repayment: total_monthly_repayment,total_rent_per_month: total_rent_per_month});
  }

  cashflowSubmit() {
    this.spinner.show();
    this.responseWaiting = 'show-spinner';
    this.propertyService.updateCashFlow(this.cashflowListForm.value).subscribe(response => {
      this.spinner.hide();
      this.responseWaiting = '';
      this.onSuccess(response['message']);
      this.closeModel();
      const data = {
        default:true,
        submit:true
      }
      this.propertyCalculationService.closeListOfPropertyValueModel(data);
    },err=>{
      this.spinner.hide();
      this.responseWaiting = '';
      this.buttonDisabled = false;
      this.onError(err.message);
    });
  }
}
