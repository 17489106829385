import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PropertyGlobalService} from "../../../property-global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import Swal from "sweetalert2";
import {NgxSpinnerService} from "ngx-spinner";
import {PropertyService} from "../../../../views/app/properties/property.service";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {HelperService} from "../../../helper.service";
import {GlobalService} from "../../../global.service";

@Component({
  selector: 'app-property-hidden-model',
  templateUrl: './property-hidden-model.component.html',
  styleUrls: ['./property-hidden-model.component.scss']
})
export class PropertyHiddenModelComponent implements OnInit,OnDestroy {

  hiddenProperties: any = [];
  @ViewChild('hiddenPropertyModel') hiddenModel: ModalDirective;
  restoreList: any = [];
  responseWaiting = '';
  btnWaiting = '';
  buttonDisabled = false;
  subsVar: Subscription;
  LeaseMySelf = false;
  constructor(
    private propertyGlobalService: PropertyGlobalService,
    private spinner: NgxSpinnerService,
    private propertyService: PropertyService,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private globalService: GlobalService,
  ) {

   this.subsVar = this.propertyGlobalService.openHiddenPropertyModalEvent.subscribe(data => {
     console.log('hit');
     this.restoreList = [];
     if(data.hiddenProperties != null){
       let list = data.hiddenProperties;
       list.forEach((item, index) => {
         item['random_key'] = this.globalService.randomStringGenerator();
       });
     }
      this.hiddenProperties = data.hiddenProperties;
      this.hiddenModel.show();
    });
  }

  ngOnInit(): void {
  }

  closeHiddenPropertyModelModel(hiddenPropertyModel: ModalDirective) {
    hiddenPropertyModel.hide();
  }

  addPropertyToRestoreList(event, property_ref) {
    if (event['target']['checked'] == true) {
      this.restoreList.push({'property_ref': property_ref});
      // this.restoreList[this.restoreList.length] = {'property_ref': property_ref};
    } else {
      for (let i = 0; i < this.restoreList.length; i++) {
        if (this.restoreList[i]['property_ref'] == property_ref) {
          this.restoreList.splice(i, 1);
        }
      }
    }
  }

  deleteMultipleProperty(hiddenPropertyModel: ModalDirective) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'You want to permanently delete this property(s).',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete !'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let data = {
          'list': this.restoreList,
          'status': 0
        };

        this.propertyService.restoreProperty(data).subscribe(response => {

          if (response['status'] == 200) {
            this.onSuccess('Property Deleted');
            this.propertyGlobalService.propertyListChange(response);

          } else {
            this.onError(response['message']);
          }
          hiddenPropertyModel.hide();
        }, err => {
          this.spinner.hide();
          this.onError(err.message)
        });
      }
    });
  }

  restoreProperty(hiddenPropertyModel: ModalDirective) {
    Swal.fire({
      title: 'Are you sure ?',
      text: 'You want to activate this property(s).',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, activate !'
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        let data = {
          'list': this.restoreList,
          'status': 1
        };

        this.propertyService.restoreProperty(data).subscribe(response => {
          this.hiddenProperties = [];
          if(response['status'] == 200){
            this.onSuccess('Property Activated');
            this.propertyGlobalService.propertyListChange(response);
          }else{
            this.onError('Something went wrong');
          }
          this.restoreList = [];
          hiddenPropertyModel.hide();
        }, err => {
          this.spinner.hide();
          this.onError(err.message)
        });
      }
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  ngOnDestroy() {
    if (this.subsVar){
      this.subsVar.unsubscribe();
    }
  }
}
