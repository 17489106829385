<div bsModal #buildingMaterialModel="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Building Materials</h4>
<!--        <button type="button" class="close pull-right" (click)="closeBuildingMaterialModel(buildingMaterialModel)"-->
<!--                aria-label="Close">-->
<!--          <span aria-hidden="true">&times;</span>-->
<!--        </button>-->
      </div>
      <div class="modal-body">
        <form class="form" #buildingMaterialForm="ngForm">
          <div class="col-md-12 col-sm-12 pl-0 pr-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td colspan="2"  class="pl-1 pr-1">
                      <div class="input-group input-group-sm">
                        <input type="text" class="form-control" name="materialsNew" placeholder="Add new building materials"
                               ngModel required style="height: 100%">
                        <div class="input-group-append">
                          <button class="btn btn-primary " type="button" (click)="addNewBuildingMaterial(buildingMaterialForm)" style="border-radius:0px 11px 11px 0px !important;">
                            <i class="glyph-icon simple-icon-check f-15  text-white"></i></button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let tempBuildingMaterial of tempBuildingMaterials;let key = index;">
                    <td class="pl-1" *ngIf="buildingMaterialKey != key">{{tempBuildingMaterial.building_material}}</td>
                    <td class="pl-1" *ngIf="buildingMaterialKey == key">
                      <label class="form-group has-float-label mb-1 ">
                        <div class="input-group input-group-sm">
                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="building_material" [ngModel]="tempBuildingMaterial.building_material"
                                 #building_material_edit="ngModel" style="height: 100%">
                        </div>
                        <span>Building Material</span>
                      </label>
                    </td>
                    <td class="text-right pr-1">
                      <a *ngIf="buildingMaterialKey == key" href="javascript:;" class="badge badge-danger mb-1 mr-1" (click)="buildingMaterialKey = null">
                      <i  class="glyph-icon simple-icon-close text-white f-15" ></i>
                      </a>
                      <a *ngIf="buildingMaterialKey == key" href="javascript:;" class="badge badge-primary mr-1 mb-1"
                         (click)="updateBuildingMaterial(tempBuildingMaterial,buildingMaterialForm)">
                        <i  class="glyph-icon simple-icon-check f-15"></i>
                      </a>
                      <a *ngIf="buildingMaterialKey != key" href="javascript:;" class="badge badge-danger mb-1 mr-1"    (click)="buildingMaterialCheck(tempBuildingMaterial.building_material_id)">
                        <i  class="glyph-icon simple-icon-trash f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-primary mb-1" (click)="buildingMaterialKey = key">
                        <i *ngIf="buildingMaterialKey != key" class="glyph-icon simple-icon-note text-white f-15"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot *ngIf="tempBuildingMaterials.length == 0">
                  <tr>
                    <td colspan="3">
                      No records found..
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm"
                (click)="closeBuildingMaterialModel()">Close</button>
      </div>
    </div>
  </div>
</div>
