<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #splitModel="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 *ngIf="!isEdit && !isImport" class="modal-title pull-left col-md-9">Add New Mortgage</h4>
        <h4 *ngIf="isEdit && !isImport" class="modal-title pull-left col-md-9">Edit Mortgage</h4>
        <h4 *ngIf="isImport" class="modal-title pull-left col-md-9">Upload Mortgage</h4>

        <div class="col-md-3 pull-right">
        <button type="button" class="close pull-right"
                (click)="closeModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          <a href="javascript:void(0)" class=" pull-right text-danger " (click)="openIssueModel('mortgage')" style="margin-top: 5px;font-weight: bold;">Report an Issue</a>
        </div>
      </div>
      <div class="modal-body pl-2 pr-2">
        <form class="form" [formGroup]="mortgageHeaderForm" novalidate>
          <div class="col-xl-12 col-lg-12 co-md-12 col-sm-12 col-xs-12" *ngIf="!isEdit">
            <h4 class="mb-3">Variable Split</h4>
            <div class="row">
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12">
                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="variable_current_loan_balance" formControlName="variable_current_loan_balance" (blur)="calculateVariableRedraw()">
                  </div>
                  <span>Current Loan balance (Variable) <span class="text-danger">*</span></span>
                </label>
              </div>
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12" *ngIf="mortgageHeaderForm.value['variable_redraw_offset_facility']==1">
                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="variable_redraw_available" formControlName="variable_redraw_available" (blur)="calculateVariableLoanCurrentBalance()">
                  </div>
                  <span>Redraw Available (Variable)</span>
                </label>
              </div>
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12">
                <label class="form-group has-float-label has-right-icon-append">
                  <div class="input-group ">
                    <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                           name="variable_interest_rate" formControlName="variable_interest_rate">
                    <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                    </div>
                  </div>
                  <span>Interest Rate (Variable)</span>
                </label>
              </div>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12 co-md-12 col-sm-12 col-xs-12" *ngIf="!isEdit">
            <h4 class="mb-3">Fixed Split</h4>
            <div class="row">
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12">
                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_current_loan_balance" formControlName="fixed_current_loan_balance" (blur)="calculateFixedRedraw()">
                  </div>
                  <span>Current Loan balance (Fixed) <span class="text-danger">*</span></span>
                </label>
              </div>
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12" *ngIf="mortgageHeaderForm.value['fixed_redraw_offset_facility']==1">
                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_redraw_available" formControlName="fixed_redraw_available" (blur)="calculateFixedLoanCurrentBalance()">
                  </div>
                  <span>Redraw Available (Fixed)</span>
                </label>
              </div>
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12">
                <label class="form-group has-float-label has-right-icon-append">
                  <div class="input-group ">
                    <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_interest_rate" formControlName="fixed_interest_rate"  disabled>
                    <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                    </div>
                  </div>
                  <span>Interest Rate (Fixed)</span>
                </label>
              </div>
            </div>
          </div>

<!--     Import Mortgage     -->
          <div class="col-xl-12 col-lg-12 co-md-12 col-sm-12 col-xs-12" *ngIf="isImport">

            <div>
              <div class="overflow-unset" style="height: 55px;" [multiple]="false"  ngx-dropzone [accept]="'.xls,.xlsx,.csv'" #drop3 (change)="importExcel($event)">
                <ngx-dropzone-label>
                  <div class="drozone-label">
                    <h4>Drop File  Here</h4>
                  </div>
                </ngx-dropzone-label>
              </div>
            </div>

          </div>
<!--     Import Mortgage End  -->

        <div class="col-xl-12 col-lg-12 co-md-12 col-sm-12 col-xs-12">

<!--          <div class="row ">-->
<!--            <div class="offset-8 pull-right" *ngIf="!isEdit ">-->
<!--              <div class="row pull-right">-->
<!--                <button type="button" class="btn btn-xs btn-primary pull-right" (click)="addNewRow(documentsControl.controls.length)">New Mortgage Row</button>-->
<!--              </div>-->
<!--            </div>-->

            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button type="button" *ngIf="!isEdit" class="btn btn-primary pull-right mr-2" (click)="addNewRow(documentsControl.controls.length)">New Mortgage Row</button>
              </div>
              <!--          <div class="row">-->
              <!--            <div class="offset-8 pull-right">-->
              <!--                <button type="button" *ngIf="!isEdit" class="btn btn-primary pull-right mr-2" (click)="addNewRow(documentsControl.controls.length)">New Mortgage Row</button>-->
              <!--            </div>-->
              <!--            <form [formGroup]="mortgageDetailsForm" >-->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">

<!--            <form [formGroup]="mortgageDetailsForm" >-->
                <table class="table table-borderless mb-0" *ngIf="documentsControl.length >0">
                <thead>
                <tr>
                  <th>Loan Split Type</th>
                  <th>Paid Date</th>
                  <th>Paid</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <ng-container formArrayName="mortgageRow">
                  <tr *ngFor="let item of documentsControl.controls; let index = index" [formGroupName]='index'>
                    <td>
                      <label class="form-group has-float-label" >
                        <div class="input-group">
                          <ng-select appearance="outline" class="w-100"   [searchable]="false" [clearable]="false" name="loan_rate_type" formControlName="loan_rate_type">
                            <ng-option value="Variable">Variable</ng-option>
                            <ng-option value="Fixed">Fixed</ng-option>
                          </ng-select>
                        </div>
                      </label>
                    </td>
                    <td>
                      <label  class="form-group has-float-label ">
                        <div class="input-group ">
                          <input class="form-control" placeholder="dd/mm/YYYY" id="{{documentsControl.controls[index].get('couple_code').value}} "
                                 name="date_s" formControlName="date_s" ngbDatepicker #date_s="ngbDatepicker" placement="right"
                                 (dateSelect)="dateChange(documentsControl.controls[index].get('couple_code').value, documentsControl.controls[index].get('loan_rate_type').value, documentsControl.controls[index].get('date_s').value)"
                                 (click)="date_s.toggle()">
                        </div>
                        <div  *ngIf="submitted && documentsControl.controls[index].get('date_s').errors" class="text-danger">This field is required</div>
                      </label>
                    </td>
                    <td>
                      <label class="form-group has-float-label has-left-icon-append">
                        <div class="input-group">
                          <div class="input-group-prepend custom-input-group-append-icon-right-border">
                            <span class="input-group-text custom-input-group-append-icon ">$</span>
                          </div>
                          <input type="number" class="form-control"
                                 name="amount" formControlName="amount" (ngModelChange)="amountChange($event,index)"  >
                        </div>
                        <div  *ngIf="submitted && documentsControl.controls[index].get('amount').errors" class="text-danger">This field is required</div>
                      </label>
                    </td>
                    <td width="80px" class="pl-3 pb-0  ">
                      <div class="row" *ngIf="!isEdit">
                          <a *ngIf="documentsControl.controls.length > 1" href="javascript:;" class="badge badge-primary mb-1 mt-2 mr-1"  ngbTooltip="Add Row"   (click)="addNewRowAfter(index)">
                            <i class="fa fa-plus f-15" style="font-weight: 500;"></i>
                          </a>
                          <a *ngIf="documentsControl.controls.length > 1" href="javascript:;" class="badge badge-danger mb-1 mt-2 mr-1"  ngbTooltip="Delete"   (click)="removeRecord(index)">
                            <i class="glyph-icon simple-icon-trash f-15"></i>
                          </a>

                      </div>
                      <div class="row" *ngIf="isImport">
                        <a *ngIf="documentsControl.controls.length > 1" href="javascript:;" class="badge badge-danger mb-1 mt-2 mr-1"  ngbTooltip="Delete"   (click)="removeRecord(index)">
                          <i class="glyph-icon simple-icon-trash f-15"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                </tbody>
              </table>
              </div>
<!--            </form>-->
          </div>
        </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <div class="form-group row mb-0" *ngIf="!isEdit">
                <button type="button" class="btn btn-outline-primary mr-2 mb-2" *ngIf="isMissingVariableMortgage"
                        (click)="createMissingVariableMortgage()">Create Missing Mortgage - Variable</button>
                <button type="button" class="btn btn-outline-primary  mb-2" *ngIf="isMissingFixedMortgage"
                        (click)="createMissingFixedMortgage()">Create Missing Mortgage - Fixed</button>

                <!--            <button type="submit" class="btn btn-primary btn-xs custom-btn-sec pull-right mr-2">Save</button>-->
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <div class="form-group row pull-right mb-0">
<!--                <button *ngIf="!isEdit" type="button" class="btn btn-primary btn-xs custom-btn-sec pull-right mr-2" (click)="isCreateExpense = true;saveMortgageDetails()">Save & Create Expense</button>-->
                <button type="button" class="btn btn-outline-primary  pull-right mr-2 mb-2"
                        (click)="closeModel()">Close</button>
                <button type="button" class="btn btn-primary  pull-right mb-2" (click)="isCreateExpense = false;saveMortgageDetails()">Save</button>
                <!--                <app-state-button btnClass="btn btn-primary btn-xs custom-btn-sec pull-right mr-2" [currentState]="responseWaiting" message="false"-->
                <!--                                  [isDisabled]="buttonDisabled" >Save</app-state-button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


