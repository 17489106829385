<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>


<div class="col-md-12 col-sm-12">
    <div class="row">
        <div class="col-md-10 ">
            <div class="custom-list">
                <div class=" form-group row mb-0">
                    <div class="col-md-5 col-5">
                        <label class="text-primary">Paid Date</label>
                    </div>
                    <div class="col-md-1 col-1">
                        <span class="dots">:</span>
                    </div>
                    <div class="col-md-6 col-6">
                        <span class="value-field-color text-small">{{this.rentalDetail?.paid_date | date:'dd/MM/yyyy'}}</span>
                    </div>
                </div>
                <div class=" form-group row mb-0">
                    <div class="col-md-5 col-5">
                        <label class="text-primary ">Rental Payment(Gross)</label>
                    </div>
                    <div class="col-md-1 col-1">
                        <span class="dots">:</span>
                    </div>
                    <div class="col-md-6 col-6">
                        <span class="value-field-color text-small">${{this.rentalDetail?.amount  | number:'1.2-2'}}</span>
                    </div>
                </div>
                <div class=" form-group row mb-0">
                    <div class="col-md-5 col-5">
                        <label class="text-primary ">Tenant Name</label>
                    </div>
                    <div class="col-md-1 col-1">
                        <span class="dots">:</span>
                    </div>
                    <div class="col-md-6 col-6">
                        <span class="value-field-color text-small">{{this.rentalDetail?.tenant_name}}</span>
                    </div>
                </div>
            </div>
        </div>

         <h6 class="col-md-12 mb-3" *ngIf="this.rentalDetail?.media.length > 0 "><strong><u> Attachments</u>
            </strong></h6>
        <label class="col-md-12 font-weight-bold "
            *ngIf="this.rentalDetail?.media.length > 0 "><u>Documents</u></label>
        <div class="col-md-12">
            <div class=" ml-2 mt-2" *ngFor="let item of this.rentalDetail?.media; index as i"
                style="display: inline-block !important;">
                <div class="d-flex mb-3 img-box position-relative">
                    <img width="45%" class="cursor-pointer" src="{{item.thumb}}" style="height: 100%"
                        (click)="mediaDownload(item)" />
                </div>
                <a class="cursor-pointer " tooltip="{{item.real_name}}">
                    <span class="text-small" (click)="mediaDownload(item)">{{item.real_name | slice:0:10 }}</span></a>
            </div>
        </div>
    </div>
</div>
