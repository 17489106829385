<ngx-spinner></ngx-spinner>

<div bsModal #propertyDetails="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <app-property-detail-edit-view [propertyRef]="propertyRef" [comeFrom]="comeFrom" (propertyDetailsModelClose)="propertyDetailsModelClose($event)"></app-property-detail-edit-view>
</div>

<app-property-purpose></app-property-purpose>
<app-building-materials></app-building-materials>
<app-roof-materials></app-roof-materials>
<app-view-more-images></app-view-more-images>
<app-property-sales-expense-list></app-property-sales-expense-list>
