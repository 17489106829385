import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalService } from 'src/app/shared/global.service';
import { saveAs as importedSaveAs } from "file-saver";


@Component({
  selector: 'app-rental-details-view',
  templateUrl: './rental-details-view.component.html',
  styleUrls: ['./rental-details-view.component.scss']
})
export class RentalDetailsViewComponent implements OnInit {

  @Input() rentalDetail : any;
  constructor(
    private spinner: NgxSpinnerService,
    private notifications: NotificationsService,
    private globeService: GlobalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  mediaDownload(item: any) {
    let data = {
      'media_ref': item['media_ref']
    }
    this.spinner.show();
    this.globeService.mediaDownload(data).subscribe((data: Blob) => {
      var blob = new Blob([data], { type: item['type'] });
      this.spinner.hide();
      importedSaveAs(blob, item['real_name']);
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.globeService.unauthorizedRequest(err.status);
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, { timeOut: 3000, showProgressBar: true });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, { timeOut: 3000, showProgressBar: true });
  }


}
