import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SupplierGlobalService {
  @Output() openSupplierListModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openAddNewSupplierModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() newSupplierModelCloseEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectSupplierEvent: EventEmitter<any> = new EventEmitter();
  @Output() newSupplierModelByDirectEvent: EventEmitter<any> = new EventEmitter();
  @Output() customizeSupplierEvent: EventEmitter<any> = new EventEmitter();
  @Output() openSupplierEditModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeSupplierEditModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() openSupplierViewModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() closeSupplierViewModelEvent: EventEmitter<any> = new EventEmitter();
  @Output() getSupplierDataEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  openSupplierListModel(data) {
    this.openSupplierListModelEvent.emit(data);
  }

  openAddNewSupplierModel(data) {
    this.openAddNewSupplierModelEvent.emit(data);
  }

  newSupplierModelClose(data) {
    this.newSupplierModelCloseEvent.emit(data);
  }

  selectSupplier(supplier: any) {
    this.selectSupplierEvent.emit(supplier);
  }

  openNewSupplierModel(data) {
    this.newSupplierModelByDirectEvent.emit(data);
  }

  customizeSupplier(data) {
    this.customizeSupplierEvent.emit(data);
  }

  openSupplierEditModel(data) {
    this.openSupplierEditModelEvent.emit(data);
  }

  editSupplierModelClose(data) {
    this.closeSupplierEditModelEvent.emit(data);
  }

  openSupplierViewModel(data) {
    this.openSupplierViewModelEvent.emit(data);
  }

  closeSupplierViewModel(data) {
    this.closeSupplierViewModelEvent.emit(data);
  }

  getSupplierData(data) {
    this.getSupplierDataEvent.emit(data);
  }
}
