import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-thumb',
  templateUrl: './thumb.component.html',
  styleUrls: ['./thumb.component.scss']
})
export class ThumbComponent implements OnInit {
  @Input() srcUrl;
  @Input() currentSelectPicture;
  @Input() selected;
  @Input() numberOfItems;
  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  selectRightArrow(currentPicture: number) {
    console.log('currentPicture', currentPicture)
    this.currentSelectPicture = currentPicture-1;
  }
}
