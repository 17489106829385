import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { getUserRole } from 'src/app/utils/util';
//import {JwtHelperService} from "@auth0/angular-jwt";
// import {AngularFireAuth} from "@angular/fire/auth";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {GlobalService} from "./global.service";

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  token: string;
  constructor(private http: HttpClient, private globalService: GlobalService,
             // private jwtHelper: JwtHelperService
              ) {}

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }
  // tslint:disable-next-line:typedef
  signIn(credentials) {
    return this.http.post(this.globalService.apiURL + 'auth/login', credentials, this.getHttpOptions());
  }

  // tslint:disable-next-line:typedef
  register(credentials: ICreateCredentials) {
    return this.http.post(this.globalService.apiURL + 'auth/register', JSON.stringify(credentials), this.getHttpOptions());
  }

  // tslint:disable-next-line:typedef
  sendPasswordEmail(email) {
    return this.http.post(this.globalService.apiURL + 'auth/forgot-password', email, this.getHttpOptions());

  }

  // tslint:disable-next-line:typedef
  resetPassword(credentials) {
    return this.http.post(this.globalService.apiURL + 'auth/reset-password', credentials, this.getHttpOptions());
  }

  resetPasswordVerify(verificationCode: string) {
    return this.http.get(this.globalService.apiURL + 'auth/verify-reset-password-token/' + verificationCode, this.getHttpOptions());

  }
  emailVerify(verificationCode) {
    return this.http.get(this.globalService.apiURL + 'auth/email-verify?verificationCode=' + verificationCode, this.getHttpOptions());
  }
  demoUserRegister(value: any) {
    return this.http.post(this.globalService.apiURL + 'auth/demo-user-register', value, this.getHttpOptions());
  }

  createDemoUser() {
    return this.http.post(this.globalService.apiURL + 'auth/create-demo-user', this.getHttpOptions());
  }

  getCommunictionTypes() {
    return this.http.get(this.globalService.apiURL + 'auth/get-communication-types', this.getHttpOptions());
  }

  changeEmailVerify(verificationCode) {
    return this.http.get(this.globalService.apiURL + 'auth/change-email-verify?verificationCode=' + verificationCode, this.getHttpOptions());
  }

  // tslint:disable-next-line:typedef
  async getUser() {
    return true;
  }

  isAuthenticated() {
    this.token = localStorage.getItem('authToken');
    if (this.token === null || this.token === '') {
      return false;
    }
    // } else if (this.jwtHelper.isTokenExpired(this.token)) {
    //   return false;
    // }
    return true;
  }

  signOut() {

  }

  refreshAccessToken() {
    return this.http.get(this.globalService.apiURL + 'auth/refresh-token');
  }

  inMaintenance() {
    return  this.globalService.is_maintenance
  }

}
