import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {NgxSpinnerService} from "ngx-spinner";
import {GlobalService} from "../../../global.service";
import {PropertyService} from "../../../../views/app/properties/property.service";
import {PropertyGlobalService} from "../../../property-global.service";
import Swal from "sweetalert2";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-property-purpose',
  templateUrl: './property-purpose.component.html',
  styleUrls: ['./property-purpose.component.scss']
})
export class PropertyPurposeComponent implements OnInit {
  @ViewChild('propertyPurposeModel') propertyPurposeModel: ModalDirective;
  is_model_open = false;
  comeFrom = null;
  propertyPurposeTypes: any = [];
  temp: any = [];
  tempPropertyPurposeTypes: any = [];
  propertyPurposeKey = null;
  purpose = null;
  action = null;
  user_id = null;
  property_purpose_id = null;
  constructor(
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private propertyService: PropertyService,
    private propertyGlobalService: PropertyGlobalService
    ) {
    this.propertyGlobalService.openPropertyPurposeModelEvent.subscribe(data =>{
      this.comeFrom = data['comeFrom'];
      this.is_model_open = true;
      if(data['action']){
        this.propertyRegistrationFormLoad();
      }
    });
  }

  ngOnInit(): void {
  }

  propertyRegistrationFormLoad() {
    this.spinner.show();
    this.propertyGlobalService.propertyRegistrationFormLoad().subscribe(response => {
      this.spinner.hide();
      this.propertyPurposeTypes = response['property_purposes'];
      this.temp = response['property_purposes'];
      this.tempPropertyPurposeTypes = [];
      for (let a = 0; a < this.propertyPurposeTypes.length; a++) {
        if (this.propertyPurposeTypes[a]['user_id'] != null) {
          this.tempPropertyPurposeTypes.push(this.propertyPurposeTypes[a]);
        }
      }

      this.propertyPurposeModel.show();
    }, error => {
      this.spinner.hide();
      this.globalService.onError(error.message);
    });
  }

  addNewPropertyPurpose(propertyPurposeModel: NgForm) {
    if(propertyPurposeModel.value['purposeNew'] == null || propertyPurposeModel.value['purposeNew'] =='')
      return;

    this.spinner.show();
    this.propertyGlobalService.savePropertyPurpose('', propertyPurposeModel.value['purposeNew']).subscribe(res => {
      let purposeValue = propertyPurposeModel.value['purposeNew'];
      this.spinner.hide();
      if (res['status'] == 200) {
        this.globalService.onSuccess('Property Purpose Add successful');
        this.propertyPurposeTypes = res['property_purposes'];
        Swal.fire({
          title: 'Are you sure?',
          text: "you want to change current property purpose to '"+ propertyPurposeModel.value['purposeNew']+"'",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
        }).then((isConfirm) => {
          if (isConfirm.value) {
            this.purpose = purposeValue;
            this.user_id = res['user_id'];
            this.action = 'add';
          }
        }, function (desmiss) { });

        propertyPurposeModel.reset();
        this.propertyPurposeKey = null;
        this.tempPropertyPurposeTypes = [];
        for (let a = 0; a < this.propertyPurposeTypes.length; a++) {
          if (this.propertyPurposeTypes[a]['user_id'] != null) {
            this.tempPropertyPurposeTypes.push(this.propertyPurposeTypes[a]);
          }
        }
      }
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err.message);
    });
  }

  updatePropertyPurpose(data, form: NgForm) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to save this property purpose changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.propertyGlobalService.savePropertyPurpose(data['property_purpose_id'], form.value['property_purpose']).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.globalService.onSuccess('Property Purpose Edit successful');
            this.propertyPurposeTypes = res['property_purposes'];
            // if(this.properties['purpose_id'] == data['property_purpose_id'])
            this.purpose = form.value['property_purpose'];
            this.property_purpose_id = data['property_purpose_id'];
            this.action = 'update';
            this.propertyPurposeKey = null;
            this.tempPropertyPurposeTypes = [];
            for (let a = 0; a < this.propertyPurposeTypes.length; a++) {
              if (this.propertyPurposeTypes[a]['user_id'] != null) {
                this.tempPropertyPurposeTypes.push(this.propertyPurposeTypes[a]);
              }
            }
          }
        }, err => {
          this.spinner.hide();
          this.globalService.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }

  propertyPurposeCheck(data: any){
    this.spinner.show();
    this.propertyGlobalService.checkPropertyPurpose(data['property_purpose_id']).subscribe(res => {
      this.spinner.hide();
      if (res['status'] == 200) {
        if(res['validate']){
          this.deletePropertyPurpose(data);
        }
        else{
          Swal.fire({
            title: 'You can not delete this property purpose?',
            text: 'This property purpose used in another component!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }

      }
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err.message);
    });
  }

  deletePropertyPurpose(data) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete this property Purpose !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.propertyGlobalService.deletePropertyPurpose(data['property_purpose_id']).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.globalService.onSuccess('Property Purpose Delete successful');
            this.propertyPurposeTypes = res['property_purposes'];
            this.purpose = data['purpose'];
            this.property_purpose_id = data['property_purpose_id'];
            this.action = 'delete';
            this.propertyPurposeKey = null;
            this.tempPropertyPurposeTypes = [];
            for (let a = 0; a < this.propertyPurposeTypes.length; a++) {
              if (this.propertyPurposeTypes[a]['user_id'] != null) {
                this.tempPropertyPurposeTypes.push(this.propertyPurposeTypes[a]);
              }
            }
          }
        }, err => {
          this.spinner.hide();
          this.globalService.onError(err);
        });
      }
    }, function (desmiss) { });
  }

  closePropertyPurposeModel() {
    this.propertyPurposeKey = null;
    this.tempPropertyPurposeTypes = [];
    this.propertyPurposeModel.hide();
    let data = {
      comeFrom: this.comeFrom,
      purpose : this.purpose,
      property_purpose_id :this.property_purpose_id,
      action: this.action
    }
    this.propertyGlobalService.closePropertyPurposeModel(data);
    this.propertyGlobalService.parentModelSwitch(false);
    this.is_model_open = false;
  }
}


