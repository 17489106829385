<div bsModal #propertyPurposeModel="bs-modal" class="modal fade custom-modal" tabindex="-1" [config]="{ ignoreBackdropClick: true}"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Property Purpose</h4>
        <!--        <button type="button" class="close pull-right" (click)="closePropertyPurposeModel()"-->
        <!--                aria-label="Close">-->
        <!--          <span aria-hidden="true">&times;</span>-->
        <!--        </button>-->
      </div>
      <div class="modal-body">
        <form class="form" #propertyPurposeForm="ngForm">
          <div class="col-md-12 col-sm-12 pl-0 pr-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <td colspan="2" class="pl-1 pr-1">
                      <div class="input-group input-group-sm">
                        <input type="text" class="form-control" name="purposeNew" placeholder="Add new property purpose"  ngModel required style="height: 100%">
                        <div class="input-group-append">
                          <button class="btn btn-primary" type="button" (click)="addNewPropertyPurpose(propertyPurposeForm)" style="border-radius:0px 11px 11px 0px !important;">
                            <i class="glyph-icon simple-icon-check f-15  text-white"></i></button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngFor="let tempPropertyPurposeType of tempPropertyPurposeTypes;let key = index;">
                    <td class="pl-1" *ngIf="propertyPurposeKey != key">{{tempPropertyPurposeType.purpose}}</td>
                    <td class="pl-1" *ngIf="propertyPurposeKey == key">
                      <label class="form-group has-float-label mb-1">
                        <div class="input-group input-group-sm">
                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="property_purpose" [ngModel]="tempPropertyPurposeType.purpose"
                                 #purpose_edit="ngModel" style="height: 100%">
                        </div>
                        <span>Property Purpose</span>
                      </label>
                    </td>
                    <td class="text-right pr-1">
                      <a *ngIf="propertyPurposeKey == key" href="javascript:;" class="badge badge-danger mb-1 mr-1" (click)="propertyPurposeKey = null">
                        <i  class="glyph-icon simple-icon-close text-white f-15 "></i>
                      </a>
                      <a *ngIf="propertyPurposeKey == key" href="javascript:;" class="badge badge-primary mr-1 mb-1"  (click)="updatePropertyPurpose(tempPropertyPurposeType,propertyPurposeForm)">
                        <i  class="glyph-icon simple-icon-check f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-danger mb-1 mr-1"  (click)="propertyPurposeCheck(tempPropertyPurposeType)">
                      <i *ngIf="propertyPurposeKey != key" class="glyph-icon simple-icon-trash f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-primary mb-1" (click)="propertyPurposeKey = key">
                        <i *ngIf="propertyPurposeKey != key" class="glyph-icon simple-icon-note text-white f-15"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot *ngIf="tempPropertyPurposeTypes.length == 0">
                  <tr>
                    <td colspan="3">
                      No records found..
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light"
                (click)="closePropertyPurposeModel()">Close</button>
      </div>
    </div>
  </div>
</div>

