<div class="h-100">
  <app-color-switcher *ngIf="isMultiColorActive"></app-color-switcher>
  <router-outlet></router-outlet>
  <simple-notifications></simple-notifications>
</div>

<app-report-issue></app-report-issue>
<app-supplier-create></app-supplier-create>
<app-supplier-lookup-list></app-supplier-lookup-list>
<app-supplier-view></app-supplier-view>

