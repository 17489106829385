<div bsModal #hiddenPropertyModel="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Hidden or Deleted Properties</h4>
        <button type="button" class="close pull-right" (click)="closeHiddenPropertyModelModel(hiddenPropertyModel)"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #hiddenPropertyModelForm="ngForm">
          <div class="col-md-12 col-sm-12">
            <div class="row">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Property Address</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let hiddenProperty of hiddenProperties;let key = index;">
                  <td>
                    <div class="custom-control custom-checkbox align-contact mr-2">
                      <input type="checkbox" class="custom-control-input" name="agree{{hiddenProperty.key}}"
                             id="permission{{hiddenProperty.random_key}}"
                             (click)="addPropertyToRestoreList($event,hiddenProperty.property_ref)">
                      <label class="custom-control-label pt-0" for="permission{{hiddenProperty.random_key}}"></label>
                    </div>
                  </td>
                  <td>{{hiddenProperty.property_address}}</td>
                  <td *ngIf="hiddenProperty.status == 2"> Hidden</td>
                  <td *ngIf="hiddenProperty.status == 3"> Temporary Deleted</td>
                </tr>
                </tbody>
                <tfoot *ngIf="hiddenProperties.length == 0">
                <tr>
                  <td colspan="3">
                    No records Found..
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <app-state-button *ngIf="restoreList.length > 0" btnClass="btn btn-danger btn-xs " [currentState]="btnWaiting"
                          message="false" (click)="deleteMultipleProperty(hiddenPropertyModel)" [isDisabled]="buttonDisabled">Delete
        </app-state-button>
        <app-state-button *ngIf="restoreList.length > 0" btnClass="btn btn-primary btn-xs" [currentState]="btnWaiting"
                          message="false" (click)="restoreProperty(hiddenPropertyModel)" [isDisabled]="buttonDisabled">Restore
        </app-state-button>
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec"
                (click)="closeHiddenPropertyModelModel(hiddenPropertyModel)">Close</button>
      </div>
    </div>
  </div>
</div>
