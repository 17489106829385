import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TopnavComponent } from './topnav/topnav.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ColorSwitcherComponent } from './color-switcher/color-switcher.component';
import { FooterComponent } from './footer/footer.component';
import { HeadingComponent } from './heading/heading.component';
import { ApplicationMenuComponent } from './application-menu/application-menu.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {SupplierViewComponent} from "../../shared/componants/supplier/supplier-view/supplier-view.component";
import {SupplierCreateComponent} from "../../shared/componants/supplier/supplier-create/supplier-create.component";
import {
  SupplierLookupListComponent
} from "../../shared/componants/supplier/supplier-lookup-list/supplier-lookup-list.component";
import {SupplierEditComponent} from "../../shared/componants/supplier/supplier-edit/supplier-edit.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {BsModalService, ModalModule} from "ngx-bootstrap/modal";
import {ComponentsStateButtonModule} from "../../components/state-button/components.state-button.module";
import {NgxSpinnerModule} from "ngx-spinner";
import {SimpleNotificationsModule} from "angular2-notifications";
import {PaginationModule} from "ngx-bootstrap/pagination";
import {SharedModule} from "../../shared/shared.module";
import {NewInDevelopmentModule} from "../../views/app/new-in-development/new-in-development.module";
import { PropertyValueComponent } from './list-property-by/property-value/property-value.component';
import { RentalIncomeListComponent } from './list-property-by/rental-income-list/rental-income-list.component';
import { PropertyCashflowComponent } from './list-property-by/property-cashflow/property-cashflow.component';
import { PropertyInsuranceListComponent } from './list-property-by/property-insurance-list/property-insurance-list.component';
import {NgbDatepickerModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import { MortgageTotalComponent } from './list-property-by/mortgage-total/mortgage-total.component';
import {GooglePlaceModule} from "ngx-google-places-autocomplete";


@NgModule({
  declarations: [
    TopnavComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ColorSwitcherComponent,
    FooterComponent,
    HeadingComponent,
    ApplicationMenuComponent,
    SupplierViewComponent,
    SupplierCreateComponent,
    SupplierLookupListComponent,
    SupplierEditComponent,
    PropertyValueComponent,
    RentalIncomeListComponent,
    PropertyCashflowComponent,
    PropertyInsuranceListComponent,
    MortgageTotalComponent
  ],
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    TranslateModule,
    RouterModule,
    CollapseModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    NgSelectModule,
    ModalModule,
    FormsModule,
    ComponentsStateButtonModule,
    GooglePlaceModule,
    NgxSpinnerModule,
    SimpleNotificationsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    SharedModule,
    NewInDevelopmentModule,
    NgbDatepickerModule,
    NgbTooltipModule,

  ],
    exports: [
        TopnavComponent,
        SidebarComponent,
        BreadcrumbComponent,
        ColorSwitcherComponent,
        FooterComponent,
        HeadingComponent,
        ApplicationMenuComponent,
        SupplierCreateComponent,
        SupplierLookupListComponent,
        SupplierViewComponent
    ],
  providers: [
    BsModalService
  ]
})
export class LayoutContainersModule { }
