import {Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from "@angular/common";
import Swal from "sweetalert2";
import {fakeAsync} from "@angular/core/testing";
import {PropertyService} from "../../../../views/app/properties/property.service";
import {GlobalService} from "../../../global.service";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {HelperService} from "../../../helper.service";
import {MortgageGlobalService} from "../../../mortgage-global.service";
import {SupplierGlobalService} from "../../../supplier-global.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-mortgage-form',
  templateUrl: './mortgage-form.component.html',
  styleUrls: ['./mortgage-form.component.scss']
})
export class MortgageFormComponent implements OnChanges,OnDestroy {

  propertyMortgageForm: FormGroup;
  // financialInstitutes: any = [];
  selectTemp: any = [];
  submitted = false;
  fixedInterestLoanStartDateError = false;
  fixedInterestLoanTermError = false;
  splitLoanDetails: any = [];
  private subscription: Subscription[] = [];
  @Input() property_ref: string;
  @Input() mortgage_ref: string;
  @Input() comeFrom: string;
  @Input() lastSplitLoanUpdated: string;
  @Input() mortgageTypes: any = [];
  @Input() propertyDetails: any = [];
  @Input() mortgageHeader: any = [];
  @Input() financialInstitutes: any = [];
  loanTermValues: any[] = [{ value: '25' },{ value: '30' }, { value: '40' }, { value: '50' }];
  interestOnlyTermValues: any[] = [{ value: '1' }, { value: '3' }, { value: '5' }];
  fixedInterestTermValues: any[] = [{ value: '1' }, { value: '3' }, { value: '5' }];
  @Output() addMortgageHeaderClose = new EventEmitter<any>();
  @ViewChild('editMortgageHeaderDetailsModel') editMortgageHeaderDetailsModel: ModalDirective;
  hiddenModal = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private propertyService: PropertyService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private modalService: BsModalService,
    private helperService: HelperService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private mortgage_service: MortgageGlobalService,
    private supplierGlobalService: SupplierGlobalService,
  ) {
    this.initForm();

    this.subscription.push( this.mortgage_service.splitLoanModelDataEvent.subscribe(data => {
        // this.splitLoanDetails = data['split_loan_details'];
      console.log(data);
        if(data['comeFrom'] != 'add-mortgage-header'){
          if (data['last_updated_at'] != null) {
            this.lastSplitLoanUpdated = data['last_updated_at'];
            this.mortgageHeader = data['mortgage_header'];
            this.ngOnChanges();

          }

          if(data['comeFrom'] == 'add-mortgage-header'){
            this.mortgageHeader = data['mortgage_header'];
            this.ngOnChanges();
          }
        }
      })
    );

    this.subscription.push( this.supplierGlobalService.selectSupplierEvent.subscribe(data => {
        if (data['comeFrom'] == 'mortgage') {
          this.setSelectedFinancialInstitute(data);
          // this.hiddenModal = false;
        }
      })
    );

    this.subscription.push(this.mortgage_service.saveMortgageHeaderEvent.subscribe(data => {
        this.submitted = false;
        this.fixedInterestLoanStartDateError = false;
        this.fixedInterestLoanTermError = false;
        this.addPropertyMortgageSubmit();
      })
    );

    this.subscription.push( this.mortgage_service.openMortgageHeaderModelEvent.subscribe(data => {
        this.property_ref = data['property_ref'];
        this.mortgage_ref = data['mortgage_ref'];
        this.comeFrom = data['comeFrom'];

        this.getMortgageHeaderDetails();
        this.editMortgageHeaderDetailsModel.show();
      })
    );

    this.subscription.push(this.mortgage_service.hideMortgageModelsEvent.subscribe(data => {
        if(data['baseModel'] == 'mortgage'){
          this.hiddenModal = data['action'];
        }
      })
    );

    this.subscription.push(this.supplierGlobalService.customizeSupplierEvent.subscribe(data => {
        this.hiddenModal =data;
      })
    );
  }

  private initForm() {
    this.propertyMortgageForm = this.fb.group({
      property_ref: [this.property_ref],
      mortgage_type_id: [null, Validators.required],
      is_not_mortgage: [null],
      rate_type: [null],
      business_name: [null],
      loan_start_date: [null],
      // loan_term: [null, Validators.required],
      loan_end_date: [{value: null, disabled: true}],
      //interest_only_term: [null],
      interest_end_date: [null],
      loan_account: [null],
      loan_interest_rate: [null],
      total_loan_amount: [null],
      current_loan_balance: [null],
      amount_paid_off: [null],
      redraw: [null],
      lvr: [null],
      paid_off_rate: [null],
      monthly_repayments: [null],
      mortgage_ref: [null],
      redraw_offset_facility: [0, [Validators.required]],
      //fixed_interest_loan_term: [null],
      fixed_interest_loan_start_date: [null],
      fixed_interest_loan_end_date: [{value: null, disabled: true}],
      fixed_interest_loan_end_date_str: [null],
      fixed_interest_loan_start_date_str: [null],
      loan_start_date_str: [null],
      interest_only_end_date: [{value: null, disabled: true}],
      loan_end_date_str: [null],
      interest_only_end_date_str: [null],
      // loan_term: ['25', [Validators.required, this.validateRange.bind(this)]],
      loan_term: [null],
      interest_only_term: ['0', [this.validateRangeInterestOnly.bind(this)]],
      fixed_interest_loan_term: ['1', [ this.validateRangeFixedInterestOnly.bind(this)]],
      supplier_obj: [null],
    });

  }
  validateRange(control: FormControl) {
    const enteredValue = parseInt(control.value, 10);
    if (isNaN(enteredValue) || enteredValue < 1 || enteredValue > 50) {
      this.loanTermValues = [];
      this.loanTermValues = [{ value: '25' }, { value: '30' }, { value: '40' }];
      return { invalidRange: true };
    }

    return null;
  }
  validateRangeInterestOnly(control: FormControl) {
    const enteredValue = parseInt(control.value, 10);
    if (isNaN(enteredValue) || enteredValue < 0 || enteredValue > 10) {
      this.interestOnlyTermValues = [];
      this.interestOnlyTermValues = [{ value: '1' }, { value: '3' }, { value: '5' }];
      return { invalidRange: true };
    }

    return null;
  }
  validateRangeFixedInterestOnly(control: FormControl) {
    const enteredValue = parseInt(control.value, 10);
    if (isNaN(enteredValue) || enteredValue < 0 || enteredValue > 10) {
      this.fixedInterestTermValues = [];
      this.fixedInterestTermValues = [{ value: '1' }, { value: '3' }, { value: '5' }];
      return { invalidRange: true };
    }

    return null;
  }

  ngOnChanges(): void {
    if (this.mortgageHeader != null) {
      this.mortgage_ref = this.mortgageHeader['mortgage_ref'];
      this.propertyMortgageForm.patchValue(this.mortgageHeader)
      this.setTwoNumberDecimal('total_loan_amount');
      this.setTwoNumberDecimal('current_loan_balance');
      this.setTwoNumberDecimal('amount_paid_off');
      this.setTwoNumberDecimal('redraw');
      this.setTwoNumberDecimal('monthly_repayments');
      if (this.mortgageHeader['loan_start_date'] != null) {
        let date = this.helperService.formDateObject(this.mortgageHeader['loan_start_date']);
        this.propertyMortgageForm.patchValue({loan_start_date: date});
      }

      if (this.mortgageHeader['loan_end_date'] != null) {
        let date = this.helperService.formDateObject(this.mortgageHeader['loan_end_date']);
        this.propertyMortgageForm.patchValue({loan_end_date: date});
        this.propertyMortgageForm.get('loan_end_date_str').patchValue(date != null ? this.helperService.extractDateString(date) : null);
      }

      if (this.mortgageHeader['interest_only_end_date'] != null) {
        let date = this.helperService.formDateObject(this.mortgageHeader['interest_only_end_date']);
        this.propertyMortgageForm.patchValue({interest_only_end_date: date});
        this.propertyMortgageForm.get('interest_only_end_date_str').patchValue(date != null ? this.helperService.extractDateString(date) : null);
      }

      if (this.mortgageHeader['fixed_interest_loan_start_date'] != null) {
        let date = this.helperService.formDateObject(this.mortgageHeader['fixed_interest_loan_start_date']);
        this.propertyMortgageForm.patchValue({fixed_interest_loan_start_date: date});
      }

      if (this.mortgageHeader['fixed_interest_loan_end_date'] != null) {
        let date = this.helperService.formDateObject(this.mortgageHeader['fixed_interest_loan_end_date']);
        this.propertyMortgageForm.patchValue({fixed_interest_loan_end_date: date});
        this.propertyMortgageForm.get('fixed_interest_loan_end_date_str').patchValue(date != null ? this.helperService.extractDateString(date) : null);
      }
    }
  }

  ngOnDestroy() {
   this.subscription.forEach(sub => sub.unsubscribe());
  }
  // formLoad(){
  //   if (this.mortgageHeader != null) {
  //     this.mortgage_ref = this.mortgageHeader['mortgage_ref'];
  //     this.propertyMortgageForm.patchValue(this.mortgageHeader)
  //     if (this.mortgageHeader['loan_start_date'] != null) {
  //       let date = this.helperService.formDateObject(this.mortgageHeader['loan_start_date']);
  //       this.propertyMortgageForm.patchValue({loan_start_date: date});
  //     }
  //
  //     if (this.mortgageHeader['loan_end_date'] != null) {
  //       let date = this.helperService.formDateObject(this.mortgageHeader['loan_end_date']);
  //       this.propertyMortgageForm.patchValue({loan_end_date: date});
  //       this.propertyMortgageForm.get('loan_end_date_str').patchValue(date != null ? this.helperService.extractDateString(date) : null);
  //     }
  //
  //     if (this.mortgageHeader['interest_only_end_date'] != null) {
  //       let date = this.helperService.formDateObject(this.mortgageHeader['interest_only_end_date']);
  //       this.propertyMortgageForm.patchValue({interest_only_end_date: date});
  //       this.propertyMortgageForm.get('interest_only_end_date_str').patchValue(date != null ? this.helperService.extractDateString(date) : null);
  //     }
  //
  //     if (this.mortgageHeader['fixed_interest_loan_start_date'] != null) {
  //       let date = this.helperService.formDateObject(this.mortgageHeader['fixed_interest_loan_start_date']);
  //       this.propertyMortgageForm.patchValue({fixed_interest_loan_start_date: date});
  //     }
  //
  //     if (this.mortgageHeader['fixed_interest_loan_end_date'] != null) {
  //       let date = this.helperService.formDateObject(this.mortgageHeader['fixed_interest_loan_end_date']);
  //       this.propertyMortgageForm.patchValue({fixed_interest_loan_end_date: date});
  //       this.propertyMortgageForm.get('fixed_interest_loan_end_date_str').patchValue(date != null ? this.helperService.extractDateString(date) : null);
  //     }
  //   }
  // }


  get mHeader() {
    return this.propertyMortgageForm.controls;
  }


  async addPropertyMortgageSubmit() {
    try{
      this.fixedInterestLoanStartDateError = false;
      this.fixedInterestLoanTermError = false;
      this.submitted = true;

      if (!this.propertyMortgageForm.valid) {
        return;
      }

      if (this.propertyMortgageForm.value['mortgage_type_id'] == 2 && this.propertyMortgageForm.value['rate_type'] == 'Fixed') {
        let error_count = 0;
        if (this.propertyMortgageForm.value['fixed_interest_loan_term'] == null || this.propertyMortgageForm.value['fixed_interest_loan_term'] == '0') {
          this.fixedInterestLoanTermError = true;
          error_count++;
        }

        // if (this.propertyMortgageForm.value['fixed_interest_loan_start_date'] == null) {
        //   this.fixedInterestLoanStartDateError = true;
        //   error_count++;
        // }

        if (error_count > 0) {
          return;
        }
      }

      if (this.propertyMortgageForm.value['fixed_interest_loan_start_date'] != null) {
        this.propertyMortgageForm.value['fixed_interest_loan_start_date_str'] = this.helperService.extractDateString(this.propertyMortgageForm.value['fixed_interest_loan_start_date']);
      }

      if (this.propertyMortgageForm.value['loan_start_date'] != null) {
        this.propertyMortgageForm.value['loan_start_date_str'] = this.helperService.extractDateString(this.propertyMortgageForm.value['loan_start_date']);
      }


      this.propertyMortgageForm.value['property_ref'] = this.property_ref;
      this.propertyMortgageForm.value['split_loan_details'] = this.splitLoanDetails;

      this.spinner.show();

      if(this.comeFrom != 'add-mortgage-header'){
        this.spinner.show();
        await  this.propertyService.savePropertyMortgageHeaderDetails(this.propertyMortgageForm.value).subscribe(response => {
            this.spinner.hide();
            this.mortgage_ref = response['mortgage_header']['mortgage_ref'];
            // need to emitter for from success
            this.globalService.onSuccess('Mortgage details saved successfully');

            // this.addMortgageHeaderClose.emit({
            //   comeFrom: this.comeFrom,
            //   mortgage_ref: response['mortgage_header']['mortgage_ref'],
            //   mortgage_type : this.propertyMortgageForm.value['mortgage_type_id']
            // });

            this.closeModel();

            this.propertyMortgageForm.reset();
            this.propertyMortgageForm.get('redraw_offset_facility').setValue(0);
            this.propertyMortgageForm.get('interest_only_term').setValue('0');
            this.propertyMortgageForm.get('fixed_interest_loan_term').setValue('1');
            // this.mortgage_service.saveMortgageHeaderForm({
            //   comeFrom: this.comeFrom,
            //   mortgage_ref: response['mortgage_header']['mortgage_ref']
            // });

            this.mortgage_service.saveMortgageHeaderDetails({comeFrom: this.comeFrom,mortgage_ref:this.mortgage_ref});
          },
          error => {
            this.globalService.onError(error.message);
            this.spinner.hide();
          });
      }
      else {
        let data = {
          property_ref: this.property_ref,
          mortgage_details : this.propertyMortgageForm.value
        }
        this.spinner.show();
        await this.propertyService.saveMortgageForPropertyRegistration(data).subscribe(response => {
          this.spinner.hide();
          // this.addMortgageHeaderClose.emit({
          //   comeFrom: this.comeFrom,
          //   mortgage_ref: this.property_ref,
          //   mortgage_type : this.propertyMortgageForm.value['mortgage_type_id']
          // });
          this.closeModel();
          this.propertyMortgageForm.reset();
        }, error => {
          this.globalService.onError(error.message);
          this.spinner.hide();
        });
      }
    }
    catch (e) {
      this.spinner.hide();
      this.globalService.onError(e.message);
    }

  }

  calculateLoanEndDate(loan_term: any, start_date: Date) {
    if (this.propertyMortgageForm.value['loan_start_date'] != null && this.propertyMortgageForm.value['loan_term'] != null) {
      let endDate = this.helperService.calculateLoanEndDate(loan_term, start_date);
      this.propertyMortgageForm.get('loan_end_date').patchValue(endDate);
      this.propertyMortgageForm.get('loan_end_date_str').patchValue(endDate != null ? this.helperService.extractDateString(endDate) : null);
    }

    if(this.propertyMortgageForm.value['mortgage_type_id'] == 2 && this.propertyMortgageForm.value['rate_type'] == 'Fixed'){
      this.calculateFixedInterestLoanEndDate(this.propertyMortgageForm.value['fixed_interest_loan_term'], start_date);
    }
  }

  calculateInterestOnlyEndDate(interest_only_term: any, LoanDate: any) {
    if (interest_only_term != null && LoanDate != null) {
      let endDate = this.helperService.calculateLoanEndDate(interest_only_term, LoanDate);
      this.propertyMortgageForm.get('interest_only_end_date').patchValue(endDate);
      this.propertyMortgageForm.get('interest_only_end_date_str').patchValue(endDate != null ? this.helperService.extractDateString(endDate) : null);

      // this.propertyMortgageForm.patchValue({interest_only_end_date: endDate});
    }
  }

  calculateFixedInterestLoanEndDate(interest_only_term: any, LoanDate: any) {
    if(this.propertyMortgageForm.value['rate_type'] == 'Fixed'){
      if (interest_only_term != null && LoanDate != null) {
        let endDate = this.helperService.calculateLoanEndDate(interest_only_term, LoanDate);
        this.propertyMortgageForm.patchValue({fixed_interest_loan_end_date: endDate});
        this.propertyMortgageForm.get('fixed_interest_loan_end_date_str').patchValue(endDate != null ? this.helperService.extractDateString(endDate) : null);
      }
    }

  }

  async redrawFacilityChange(event) {
    if (this.propertyMortgageForm.get('redraw_offset_facility').value == 0) {
      this.propertyMortgageForm.get('redraw').setValue(0);
      await this.calculateCurrentLoanBalance();
    }
  }

  calculateCurrentLoanBalance() {
    let calculateResult = this.helperService.calculateCurrentLoanBalance(this.propertyMortgageForm.get('redraw_offset_facility').value, this.propertyMortgageForm.value['total_loan_amount'], this.propertyMortgageForm.value['redraw']);
    if (calculateResult != null) {
      this.propertyMortgageForm.get('current_loan_balance').setValue(parseFloat(calculateResult).toFixed(2));

      this.calculateMonthlyRepayment();
    }
  }

  calculateRedraw() {
    let result = this.helperService.calculateRedraw(this.propertyMortgageForm.get('redraw_offset_facility').value, this.propertyMortgageForm.value['total_loan_amount'], this.propertyMortgageForm.value['current_loan_balance']);
    this.propertyMortgageForm.get('redraw').setValue(parseFloat(result).toFixed(2));
    this.calculateMonthlyRepayment();
  }

  setTwoNumberDecimal(field) {
    if (this.propertyMortgageForm.get(field).value != null && this.propertyMortgageForm.get(field).value != '') {
      let resultValue = this.propertyMortgageForm.get(field).value;
      resultValue = this.globalService.validateNumberFormat(resultValue);
      this.propertyMortgageForm.get(field).setValue(resultValue);
    }
    //
    // let regexp = new RegExp('^(\\d{1,5}|\\d{0,5}\\.\\d{1,2})$');
    // let result =  regexp.test(this.propertyMortgageForm.get(field).value);
    // if(result == false){
    //   let repayment = (this.propertyMortgageForm.get(field).value).toFixed(2);
    //   this.propertyMortgageForm.get(field).patchValue(repayment);
    // }
  }

  openSupplierListModel() {
    let data = {
      'comeFrom': 'mortgage',
      'supplier_type': 7,
      'property_ref': this.property_ref,
    }
    this.supplierGlobalService.openSupplierListModel(data);
    // this.hiddenModal = true;
  }

  private setSelectedFinancialInstitute(data) {
    if (data['supplier'] != null) {
      let agent_data = {
        'company_name': data['supplier'].company_name,
        'user_supplier_id': data['supplier'].user_supplier_id,
        'supplier_id': data['supplier'].supplier_id,
        'supplier_type': 7
      }
      this.spinner.show();
      this.globalService.getSuppliersForMortgage(agent_data).subscribe(res => {
          this.financialInstitutes = res['suppliers'];
          this.propertyMortgageForm.patchValue({business_name: data['supplier'].company_name});
          if (this.financialInstitutes.length > 0) {
            this.financialInstitutes.forEach(element => {
              let index = this.financialInstitutes.findIndex(x => x['business_name'] == data['supplier'].company_name);
              if (index == -1) {
                let showingName = data['supplier'].company_name;
                if (data['supplier'].state != null) {
                  showingName = showingName + " (" + data['supplier'].state + ")";
                }

                let sData = {
                  'business_name': data['supplier'].company_name,
                  'user_supplier_id': data['supplier'].user_supplier_id,
                  'supplier_id': data['supplier'].supplier_id,
                  'showing_name': showingName,

                }
                this.propertyMortgageForm.patchValue({supplier_obj: data});
                this.financialInstitutes.push(sData);
              }
            });
          }
          // this.agents = res['suppliers'];
          // this.rentalHeader.supplier_id = data['supplier'].supplier_id;
          // this.rentalHeader.company_name = data['supplier'].company_name;
          // this.supplier_id = data['supplier'].supplier_id;
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
          this.globalService.onError(err.message);
        });

    } else {
      this.propertyMortgageForm.patchValue({business_name: null});
    }
  }

  addFinancialInstitutes(addedName) {
    return {business_name: addedName, showing_name: addedName, user_supplier_id: '', supplier_id: ''};
  }

  searchAgent(event) {
    if (event['srcElement']['value'] != "") {
      let data = {
        'searchKey': event['srcElement']['value'],
        'supplier_type': 'all',
      }
      this.propertyService.searchAgent(data).subscribe(response => {
        this.financialInstitutes = response['agents'];
        this.selectedSupplierMerge();
      }, err => {
        this.globalService.onError(err.message);
      });
    }
  }

  selectedSupplierMerge() {
    if (this.selectTemp.length > 0) {
      this.selectTemp.forEach(element => {
        let index = this.financialInstitutes.findIndex(x => x['business_name'] == element['business_name']);
        if (index == -1) {
          this.financialInstitutes.push(element);
        }
      });
    }
  }

  itemChange(event) {
    if (event != undefined) {
      if (event.supplier_id == "") {
        let data = {
          'comeFrom': 'mortgage',
          'supplier_type_id': 7,
          'supplier_type': 'Banks & Financial Institutions',
          'supplier_name': event['business_name'],
          'property_ref': this.property_ref,
        }
        this.supplierGlobalService.openNewSupplierModel(data);
        // this.hiddenModal = true;
      } else {
        this.propertyMortgageForm.patchValue({business_name: event['business_name']});
        this.propertyMortgageForm.patchValue({supplier_obj: event});
        this.selectTemp.push(event);
        this.selectedSupplierMerge();
      }
    }
  }

  async  openSpitLoanModel() {
    try{
      this.fixedInterestLoanStartDateError = false;
      this.fixedInterestLoanTermError = false;
      this.submitted = true;
      if (!this.propertyMortgageForm.valid) {
        return;
      }

      if (this.propertyMortgageForm.value['mortgage_type_id'] == 2 && this.propertyMortgageForm.value['rate_type'] == 'Fixed') {
        let error_count = 0;
        if (this.propertyMortgageForm.value['fixed_interest_loan_term'] == null || this.propertyMortgageForm.value['fixed_interest_loan_term'] == '0') {
          this.fixedInterestLoanTermError = true;
          error_count++;
        }

        if (this.propertyMortgageForm.value['fixed_interest_loan_start_date'] == null) {
          this.fixedInterestLoanStartDateError = true;
          error_count++;
        }

        if (error_count > 0) {
          return;
        }

      }

      if (this.propertyMortgageForm.value['fixed_interest_loan_start_date'] != null) {
        this.propertyMortgageForm.value['fixed_interest_loan_start_date_str'] = this.helperService.extractDateString(this.propertyMortgageForm.value['fixed_interest_loan_start_date']);
      }


      if (this.propertyMortgageForm.value['loan_start_date'] != null) {
        this.propertyMortgageForm.value['loan_start_date_str'] = this.helperService.extractDateString(this.propertyMortgageForm.value['loan_start_date']);
      }


      this.propertyMortgageForm.value['property_ref'] = this.property_ref;
      this.propertyMortgageForm.value['split_loan_details'] = this.splitLoanDetails;

      if (this.comeFrom != 'add-mortgage-header') {
        this.spinner.show();
        await this.propertyService.savePropertyMortgageHeaderDetails(this.propertyMortgageForm.value).subscribe(response => {
            this.spinner.hide();
            this.mortgage_ref = response['mortgage_header']['mortgage_ref'];
            this.propertyMortgageForm.get('mortgage_ref').setValue(response['mortgage_header']['mortgage_ref']);
            // need to emitter for from success
            this.globalService.onSuccess('Mortgage details saved successfully');

            let data = {
              'mortgage_ref': this.mortgage_ref,
              'property_ref': this.property_ref,
              'loan_start_date': this.propertyMortgageForm.get('loan_start_date').value,
              'mortgage_header_details': this.propertyMortgageForm.value,
              'comeFrom': this.comeFrom,
            };
            this.hiddenModal = true;
            this.mortgage_service.openSplitLoanModel(data);
            // this.mortgage_service.saveMortgageHeaderForm({
            //   comeFrom: this.comeFrom,
            //   mortgage_ref: response['mortgage_header']['mortgage_ref']
            // });

            // this.mortgage_service.saveMortgageHeaderDetails(true);
          },
          error => {
            this.globalService.onError(error.message);
            this.spinner.hide();
          });
      } else {
        let data = {
          property_ref: this.property_ref,
          mortgage_details: this.propertyMortgageForm.value
        }
        this.spinner.show();
        await this.propertyService.saveMortgageForPropertyRegistration(data).subscribe(response => {
          this.spinner.hide();
          this.globalService.onSuccess('Mortgage details saved successfully');
          let data = {
            'mortgage_ref': this.mortgage_ref,
            'property_ref': this.property_ref,
            'loan_start_date': this.propertyMortgageForm.get('loan_start_date').value,
            'mortgage_header_details': this.propertyMortgageForm.value,
            'comefrom': this.comeFrom,
          };
          this.mortgage_service.openSplitLoanModel(data);
        }, error => {
          this.globalService.onError(error.message);
          this.spinner.hide();
        });
      }
    }
    catch (e) {
      this.spinner.hide();
      this.globalService.onError(e.message);
    }
  }

  private splitLoanCalculations(data: any) {
    this.globalService.onSuccess('Mortgage details saved successfully')

    if (data['is_financial_institute_change'] == true && this.comeFrom != 'add-mortgage-header') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to update financial institute in Mortgage payments',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update it!'
      }).then((result) => {
        if (result.value) {
          let datails = {
            'mortgage_ref': data['mortgage_header']['mortgage_ref'],
            'supplier_id': data['mortgage_header']['supplier_id'],
          }
          this.changeMortgageSupplier(datails);
        }
      });
    }

    // this.mortgage_service.saveMortgageHeaderForm({
    //   comeFrom: this.comeFrom,
    //   mortgage_ref: this.mortgage_ref
    // });
    // this.mortgage_service.saveMortgageHeaderDetails(true);

    // this.addMortgageHeaderClose.emit({
    //   comeFrom: this.comeFrom,
    //   mortgage_ref: this.mortgage_ref,
    //   mortgage_type : this.propertyMortgageForm.value['mortgage_type_id']
    // });

  }

  changeMortgageSupplier(data) {
    this.spinner.show();
    this.propertyService.changeMortgageSupplier(data).subscribe(data => {
      this.spinner.hide();
      this.globalService.onSuccess('Update successful');

      this.mortgage_service.addMortgageDetailsModelClose({'comeFrom': this.comeFrom});
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err.message);
    });
  }

  protected readonly fakeAsync = fakeAsync;

  // reloadMortgageDetails(data) {
  // this.property_ref = data['property_ref'];
  // this.mortgage_ref = data['mortgage_ref'];
  // this.comeFrom = data['comeFrom'];
  // this.lastSplitLoanUpdated = data['last_updated_at'];
  // this.mortgageTypes = data['mortgage_types'];
  // // this.propertyDetails = data['property'];
  // this.mortgageHeader = data['mortgage_header'];
  // this.financialInstitutes = data['financial_institutes'];
  // }

  getMortgageHeaderDetails() {
    let data = {
      'property_ref': this.property_ref,
      'mortgage_ref': this.mortgage_ref,
    }
    this.spinner.show();
    this.propertyService.getMortgageHeaderDetailsForEdit(data).subscribe(response => {
      this.spinner.hide();
      this.mortgageHeader = response['mortgage_header'];

      this.financialInstitutes = response['agents'];
      this.mortgageTypes = response['mortgage_types'];
      this.propertyDetails = response['property_details'];
      this.lastSplitLoanUpdated = response['last_updated_at'];
      if(this.mortgage_ref == null){
        this.propertyMortgageForm.reset();
        this.initForm();
      }

      this.ngOnChanges();
    }, error => {
      this.spinner.hide();
      this.globalService.onError(error.message);
    });
  }

  closeModel() {
    this.editMortgageHeaderDetailsModel.hide();
    this.mortgage_service.saveMortgageHeaderForm({comeFrom: this.comeFrom});
    setTimeout(() => {
      this.onModalHidden();
    },1000);
  }
  onMortgageTypeChange(event: number): void {
    if(this.mortgageHeader != null){
      if (event == 3) {
        Swal.fire({
          title: 'Are you sure you want to change the Mortgage type?',
          text: 'Your previous data will be cleared',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, clear it!'
        }).then((isConfirm) => {
          if (isConfirm.value) {
            this.propertyMortgageForm.patchValue({
              interest_only_end_date_str: null,
              interest_only_term: '0',
              rate_type: null,
              loan_interest_rate: null,
              total_loan_amount: null,
              current_loan_balance: null,
              monthly_repayments: null,
              // fixed_interest_loan_term: '1',
            });
          }
          else {
            this.propertyMortgageForm.patchValue({
              mortgage_type_id: this.mortgageHeader.mortgage_type_id
            });
          }
        });
      }
      if((event == 1 && this.mortgageHeader.mortgage_type_id == 3) || (event == 2 && this.mortgageHeader.mortgage_type_id == 3)){
        Swal.fire({
          title: 'Are you sure you want to change the Mortgage type?',
          text: 'Your split lorn details will be cleared',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, clear it!'
        }).then((isConfirm) => {
          if (isConfirm.value) {
            this.propertyMortgageForm.patchValue({
              loan_interest_rate: null,
              total_loan_amount: null,
              current_loan_balance: null,
              monthly_repayments: null
            });
          }
          else {
            this.propertyMortgageForm.patchValue({
              mortgage_type_id: this.mortgageHeader.mortgage_type_id
            });
          }
        });
      }
    }
  }


  onModalHidden() {
    const modalBackdrops = document.querySelectorAll('.modal-backdrop');
    if (modalBackdrops) {
      document.body.classList.remove('modal-open');
      modalBackdrops.forEach(backdrop => {
        backdrop.remove();
      });
    }
  }

  calculateMonthlyRepayment() {
    let loanBalance = null;
    let interestRate = null;
    let mortgage_type = null;
    if((this.propertyMortgageForm.get('mortgage_type_id').value == 1 || this.propertyMortgageForm.get('mortgage_type_id').value == 2)){
      loanBalance = this.propertyMortgageForm.get('current_loan_balance').value;
      interestRate = this.propertyMortgageForm.get('loan_interest_rate').value;
      mortgage_type = this.propertyMortgageForm.get('mortgage_type_id').value;
      if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
        let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, this.propertyMortgageForm.get('mortgage_type_id').value,this.propertyMortgageForm.get('loan_term').value);
        if(monthlyRepayment != 0){
          this.propertyMortgageForm.patchValue({monthly_repayments:  this.globalService.validateNumberFormat(monthlyRepayment)});
        }
        else {
          this.propertyMortgageForm.patchValue({monthly_repayments:  null});
        }

      }
    }
  }

}
