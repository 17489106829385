<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #splitModel="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left pl-3">Split Loan Details</h4>
        <button type="button" class="close pull-right"
                (click)="closeModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="form" [formGroup]="splitMortgageForm"
            novalidate>
        <div class="modal-body">

          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <h4>Variable Loan Details</h4>
                <label class="form-group has-float-label has-right-icon-append">
                  <div class="input-group ">
                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="variable_rate_type" formControlName="variable_rate_type" readonly>
                  </div>
                  <span>Rate Type</span>
                </label>

                <label class="form-group has-float-label">
                  <ng-select appearance="outline" labelForId="loan_type_id" [searchable]="false" [clearable]="false" formControlName="variable_mortgage_type"  (blur)="calculateMonthlyRepayment('Variable')">
                    <ng-option *ngFor="let s_loan_type of split_loan_type" [value]="s_loan_type.loan_type_id">
                      {{s_loan_type.loan_type_name}}
                    </ng-option>
                  </ng-select>
                  <span>Mortgage Type <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.variable_mortgage_type.errors" class="invalid-tooltip">This field is required.</div>
                </label>

                <label class="form-group has-float-label">
                  <div class="input-group">
                    <input type="number" class="form-control"
                           name="variable_loan_account_number" formControlName="variable_loan_account_number">
                  </div>
                  <span>Loan Account Number (Variable) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.variable_loan_account_number.errors" class="invalid-tooltip">This field is required.</div>
                </label>

                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="total_variable_amount" formControlName="total_variable_amount" (change)="setTwoNumberDecimal('total_variable_amount')" (blur)="calculateVariableLoanCurrentBalance()">
                  </div>
                  <span>Total Loan Amount (Variable Split) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.total_variable_amount.errors" class="invalid-tooltip">This field is required.</div>
                </label>


<!--                -->
<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="total_variable_amount" formControlName="total_variable_amount" (change)="setTwoNumberDecimal('total_variable_amount')" (blur)="calculateVariableLoanCurrentBalance()">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Total Loan Amount (Variable Split) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.total_variable_amount.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->

                <label class="form-group has-float-label has-right-icon-append">
                  <div class="input-group ">
                    <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                           name="variable_interest_rate" formControlName="variable_interest_rate" (blur)="calculateMonthlyRepayment('Variable')">
                    <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                    </div>
                  </div>
                  <span>Interest Rate (Variable) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.variable_interest_rate.errors" class="invalid-tooltip">This field is required.</div>
                </label>

<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-right">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="variable_interest_rate" formControlName="variable_interest_rate">-->
<!--                      <div class="form-control-position">-->
<!--                        <span >%</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Interest Rate (Variable) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.variable_interest_rate.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->

                <div class="col-md-12 pl-0">
                  <div class="form-group ">
                    <label class="form-group mb-1">Have Redraw / Offset? <span class="text-danger">*</span></label>
                    <div class="row">
                      <div class="form-check ml-3">
                        <input class="form-check-input" type="radio" formControlName="variable_redraw_offset_facility" name="variable_redraw_offset_facility"
                               id="variable_redraw_offset_facility_1"   [value]="1" (click)="variableRedrawFacilityChange($event)">
                        <label class="form-check-label text-small" for="variable_redraw_offset_facility_1" style="margin-top: 0.1rem;">
                          Yes
                        </label>
                      </div>
                      <div class="form-check ml-3">
                        <input class="form-check-input" type="radio" formControlName="variable_redraw_offset_facility" name="variable_redraw_offset_facility"
                               id="variable_redraw_offset_facility_2"  [value]="0" (click)="variableRedrawFacilityChange($event)">
                        <label class="form-check-label text-small " for="variable_redraw_offset_facility_2" style="margin-top: 0.1rem;">
                          No
                        </label>
                      </div>
                    </div>
                    <div *ngIf="submitted && vSplit.variable_redraw_offset_facility.errors" class="invalid-tooltip">This field is required.</div>
                  </div>
                </div>

                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="variable_current_loan_balance" formControlName="variable_current_loan_balance"
                           (blur)="calculateVariableRedraw()" (change)="setTwoNumberDecimal('variable_current_loan_balance')">
                  </div>
                  <span>Current Loan balance (Variable) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.variable_current_loan_balance.errors" class="invalid-tooltip">This field is required.</div>
                </label>

<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="variable_current_loan_balance" formControlName="variable_current_loan_balance" (blur)="calculateVariableRedraw()" (change)="setTwoNumberDecimal('variable_current_loan_balance')">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Current Loan balance (Variable) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.variable_current_loan_balance.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->


                <label class="form-group has-float-label has-left-icon-append" *ngIf="splitMortgageForm.get('variable_redraw_offset_facility').value==1">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="variable_redraw_available" formControlName="variable_redraw_available" (blur)="calculateVariableLoanCurrentBalance()" (change)="setTwoNumberDecimal('variable_redraw_available')">
                  </div>
                  <span>Redraw Available (Variable)</span>
                </label>

<!--                <label class="form-group has-float-label" *ngIf="splitMortgageForm.get('variable_redraw_offset_facility').value==1">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="variable_redraw_available" formControlName="variable_redraw_available" (blur)="calculateVariableLoanCurrentBalance()" (change)="setTwoNumberDecimal('variable_redraw_available')">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Redraw Available (Variable)</span>-->
<!--                </label>-->

                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="variable_monthly_repayment" formControlName="variable_monthly_repayment" (change)="setTwoNumberDecimal('variable_monthly_repayment')">
                  </div>
                  <span>Monthly Repayment (Variable) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.variable_monthly_repayment.errors" class="invalid-tooltip">This field is required.</div>
                </label>

<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="variable_monthly_repayment" formControlName="variable_monthly_repayment" (change)="setTwoNumberDecimal('variable_monthly_repayment')">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Monthly Repayment (Variable) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.variable_monthly_repayment.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->

                <label class="form-group has-float-label" *ngIf="splitMortgageForm.get('variable_mortgage_type').value ==1">
                  <div class="input-group">
                    <input class="form-control" placeholder="dd/mm/YYYY"
                           name="variable_interest_only_loan_start" formControlName="variable_interest_only_loan_start" ngbDatepicker #variable_loan_start="ngbDatepicker" (click)="variable_loan_start.toggle();is_variable_interest_only_loan_start_error = false"
                           (blur)="calculateVariableInterestLoanEndDate(splitMortgageForm.get('variable_interest_only_loan_term').value,splitMortgageForm.get('variable_interest_only_loan_start').value)" >

                  </div>
                  <span>Interest Only Loan Start Date (Variable) <span class="text-danger">*</span></span>
                  <div *ngIf="is_variable_interest_only_loan_start_error" class="invalid-tooltip">This field is required.</div>
                </label>

                <label class="form-group has-float-label" *ngIf="splitMortgageForm.get('variable_mortgage_type').value == 1">
                  <ng-select
                    [items]="interestOnlyTermValues"
                    bindLabel="value"
                    bindValue="value"
                    formControlName="variable_interest_only_loan_term"
                    [addTag]="true"
                    [multiple]="false"
                    [hideSelected]="true"
                    [clearable]="true"
                    (change)="calculateVariableInterestLoanEndDate(splitMortgageForm.get('variable_interest_only_loan_term').value,splitMortgageForm.get('variable_interest_only_loan_start').value)"
                    (focus)="is_variable_interest_only_loan_term_error = false"
                  >
                  </ng-select>
                  <span>Interest Only Terms (years) <span class="text-danger">*</span></span>
                  <div *ngIf="splitMortgageForm.get('variable_interest_only_loan_term').hasError('invalidRange')" class="text-danger">
                    Invalid range. Please enter a number between 1 and 10.
                  </div>
                  <div *ngIf="is_variable_interest_only_loan_term_error" class="invalid-tooltip">This field is required.</div>
                </label>
                <label class="form-group has-float-label" *ngIf="splitMortgageForm.get('variable_mortgage_type').value==1">
                  <div class="input-group">
                    <input class="form-control" placeholder="dd/mm/YYYY"
                           name="variable_interest_only_loan_end" formControlName="variable_interest_only_loan_end" ngbDatepicker #variable_loan_end="ngbDatepicker" (click)="variable_loan_end.toggle()"
                           disabled>
                  </div>
                  <span>Interest Only End Date (Variable) <span class="text-danger">*</span></span>
                </label>
              </div>
              <div class="col-md-6">
                <h4>Fixed Loan Details</h4>

                <label class="form-group has-float-label has-right-icon-append">
                  <div class="input-group ">
                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_rate_type" formControlName="fixed_rate_type" readonly>
                  </div>
                  <span>Rate Type</span>
                </label>

                <label class="form-group has-float-label">
                  <ng-select appearance="outline" labelForId="loan_type_id" [searchable]="false" [clearable]="false" formControlName="fixed_mortgage_type" (blur)="calculateMonthlyRepayment('Fixed')">
                    <ng-option *ngFor="let s_loan_type of split_loan_type" [value]="s_loan_type.loan_type_id">
                      {{s_loan_type.loan_type_name}}
                    </ng-option>
                  </ng-select>
                  <span>Mortgage Type <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.fixed_mortgage_type.errors" class="invalid-tooltip">This field is required.</div>
                </label>

                <label class="form-group has-float-label">
                  <div class="input-group">
                    <input type="number" class="form-control" name="fixed_loan_account_number" formControlName="fixed_loan_account_number">
                  </div>
                  <span>Loan Account Number (Fixed)<span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.fixed_loan_account_number.errors" class="invalid-tooltip">This field is required.</div>
                </label>


                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="total_fixed_amount" formControlName="total_fixed_amount" (blur)="calculateFixedLoanCurrentBalance()" (change)="setTwoNumberDecimal('total_fixed_amount')">
                  </div>
                  <span>Total Loan Amount (Fixed Split) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.total_fixed_amount.errors" class="invalid-tooltip">This field is required.</div>
                </label>


<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="total_fixed_amount" formControlName="total_fixed_amount" (blur)="calculateFixedLoanCurrentBalance()" (change)="setTwoNumberDecimal('total_fixed_amount')">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Total Loan Amount (Fixed Split) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.total_fixed_amount.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->
                <label class="form-group has-float-label has-right-icon-append">
                  <div class="input-group ">
                    <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_interest_rate" formControlName="fixed_interest_rate" (blur)="calculateMonthlyRepayment('Fixed')">
                    <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                    </div>
                  </div>
                  <span>Interest Rate (Fixed) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.fixed_interest_rate.errors" class="invalid-tooltip">This field is required.</div>
                </label>

<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-right">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="fixed_interest_rate" formControlName="fixed_interest_rate">-->
<!--                      <div class="form-control-position">-->
<!--                        <span >%</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Interest Rate (Fixed) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.fixed_interest_rate.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->

                <div class="col-md-12 pl-0">
                  <div class="form-group ">
                    <label class="form-group mb-1">Have Redraw / Offset? <span class="text-danger">*</span></label>
                    <div class="row">
                      <div class="form-check ml-3">
                        <input class="form-check-input" type="radio" formControlName="fixed_redraw_offset_facility"
                               name="fixed_redraw_offset_facility" id="fixed_redraw_offset_facility_1"  [value]="1" (change)="fixedRedrawFacilityChange($event)">
                        <label class="form-check-label text-small " for="fixed_redraw_offset_facility_1" style="margin-top: 0.1rem;">
                          Yes
                        </label>
                      </div>
                      <div class="form-check ml-3">
                        <input class="form-check-input" type="radio" formControlName="fixed_redraw_offset_facility"
                               name="fixed_redraw_offset_facility" id="fixed_redraw_offset_facility_2"  [value]="0" (change)="fixedRedrawFacilityChange($event )">
                        <label class="form-check-label text-small" for="fixed_redraw_offset_facility_2" style="margin-top: 0.1rem;">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="submitted && vSplit.fixed_redraw_offset_facility.errors" class="invalid-tooltip">This field is required.</div>
                </div>


                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_current_loan_balance" formControlName="fixed_current_loan_balance" (blur)="calculateFixedRedraw()" (change)="setTwoNumberDecimal('fixed_current_loan_balance')">
                  </div>
                  <span>Current Loan balance (Fixed) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.fixed_current_loan_balance.errors" class="invalid-tooltip">This field is required.</div>
                </label>

<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="fixed_current_loan_balance" formControlName="fixed_current_loan_balance" (blur)="calculateFixedRedraw()" (change)="setTwoNumberDecimal('fixed_current_loan_balance')">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Current Loan balance (Fixed) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.fixed_current_loan_balance.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->

                <label class="form-group has-float-label has-left-icon-append" *ngIf="splitMortgageForm.get('fixed_redraw_offset_facility').value==1">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_redraw_available" formControlName="fixed_redraw_available" (blur)="calculateFixedLoanCurrentBalance()" (change)="setTwoNumberDecimal('fixed_redraw_available')">
                  </div>
                  <span>Redraw Available (Fixed)</span>
                </label>

<!--                <label class="form-group has-float-label" *ngIf="splitMortgageForm.get('fixed_redraw_offset_facility').value ==1">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="fixed_redraw_available" formControlName="fixed_redraw_available" (blur)="calculateFixedLoanCurrentBalance()" (change)="setTwoNumberDecimal('fixed_redraw_available')">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Redraw Available (Fixed)</span>-->
<!--                </label>-->

                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="fixed_monthly_repayment" formControlName="fixed_monthly_repayment" (change)="setTwoNumberDecimal('fixed_monthly_repayment')">
                  </div>
                  <span>Monthly Repayment (Fixed) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.fixed_monthly_repayment.errors" class="invalid-tooltip">This field is required.</div>
                </label>

<!--                <label class="form-group has-float-label">-->
<!--                  <div class="position-relative has-icon-left">-->
<!--                    <div class="input-group input-group-sm">-->
<!--                      <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                             name="fixed_monthly_repayment" formControlName="fixed_monthly_repayment" (change)="setTwoNumberDecimal('fixed_monthly_repayment')">-->
<!--                      <div class="form-control-position">-->
<!--                        <span class="input-field-currency-type-size">$</span>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <span>Monthly Repayment (Fixed) <span class="text-danger">*</span></span>-->
<!--                  <div *ngIf="submitted && vSplit.fixed_monthly_repayment.errors" class="invalid-tooltip">This field is required.</div>-->
<!--                </label>-->

                <label class="form-group has-float-label" >
                  <div class="input-group ">
                    <input class="form-control" placeholder="dd/mm/YYYY"
                           name="fixed_interest_only_loan_start" formControlName="fixed_interest_only_loan_start" ngbDatepicker #fixed_loan_str="ngbDatepicker" (click)="fixed_loan_str.toggle()"
                           (blur)="calculateFixedInterestLoanEndDate(splitMortgageForm.get('fixed_interest_only_loan_term').value,splitMortgageForm.get('fixed_interest_only_loan_start').value)">
                  </div>
                  <span>Fixed Interest Only Loan Start Date (Fixed) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.fixed_interest_only_loan_start.errors" class="invalid-tooltip">This field is required.</div>
                </label>

                <label class="form-group has-float-label">
                  <ng-select
                    [items]="fixedInterestTermValues"
                    bindLabel="value"
                    bindValue="value"
                    formControlName="fixed_interest_only_loan_term"
                    [addTag]="true"
                    [multiple]="false"
                    [hideSelected]="true"
                    [clearable]="true"
                    (blur)="calculateFixedInterestLoanEndDate(splitMortgageForm.get('fixed_interest_only_loan_term').value,splitMortgageForm.get('fixed_interest_only_loan_start').value)"
                  >
                  </ng-select>
                  <span>Fixed Interest Loan Terms (years) <span class="text-danger">*</span></span>
                  <div *ngIf="splitMortgageForm.get('fixed_interest_only_loan_term').hasError('invalidRange')" class="text-danger">
                    Invalid range. Please enter a number between 1 and 10.
                  </div>
                  <div *ngIf="submitted && vSplit.fixed_interest_only_loan_term.errors" class="invalid-tooltip">This field is required.</div>

                </label>
                <label class="form-group has-float-label" >
                  <div class="input-group">
                    <input class="form-control" placeholder="dd/mm/YYYY"
                           name="fixed_interest_only_loan_end" formControlName="fixed_interest_only_loan_end" ngbDatepicker #fixed_loan_end="ngbDatepicker" (click)="fixed_loan_end.toggle()"
                          disabled>
                  </div>
                  <span>Fixed Interest Only End Date (Fixed) <span class="text-danger">*</span></span>
                  <div *ngIf="submitted && vSplit.fixed_interest_only_loan_end.errors" class="invalid-tooltip">This field is required.</div>
                </label>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <div class="col-md-12 row pl-0">
            <div class="col-md-4 pull-left pl-0">
              <label class="form-group pull-left">Last Updated {{splitMortgageForm.value['updated_at'] | date : 'dd/MM/yyyy'}}</label>
            </div>
            <div class="offset-5 pull-right">
              <button type="button" class="btn btn-primary btn-xs custom-btn-sec pull-right"
                      (click)="saveSplitMortgageSubmit(splitMortgageForm)">Save</button>
              <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec pull-right mr-2"
                      (click)="closeModel()">Close</button>

              <!--            <button type="submit" class="btn btn-primary btn-xs custom-btn-sec pull-right mr-2">Save</button>-->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
