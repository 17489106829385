import {Component, OnInit, ViewChild} from '@angular/core';
import {SupplierGlobalService} from "../../../supplier-global.service";
import {GlobalService} from "../../../global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {FormBuilder} from "@angular/forms";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";
import {PlaceSuggestService} from "../../../place-suggest.service";

@Component({
  selector: 'app-supplier-view',
  templateUrl: './supplier-view.component.html',
  styleUrls: ['./supplier-view.component.scss']
})
export class SupplierViewComponent implements OnInit {
  comeFrom = null;
  property_ref = '';
  supplierDetails = [];
  hiddenModal = false;
  stars: number[] = [1, 2, 3, 4, 5];
  duplicate_view = false;
  key = null;
  @ViewChild('supplierViewModel') supplierViewModel: ModalDirective;



  constructor(private supplierGlobalService:SupplierGlobalService,
              private globalService: GlobalService,
              private spinner: NgxSpinnerService,
              private notifications: NotificationsService,
              private fb: FormBuilder,
              private modalService: BsModalService,
              private translate: TranslateService,
              private placeSuggestService: PlaceSuggestService) {
    // this.supplierGlobalService.openSupplierViewModelEvent.subscribe(data => {
    //   this.comeFrom = data['comeFrom'];
    //   this.property_ref = data['property_ref'];
    //   if('duplicate_view' in data){
    //     this.duplicate_view = data['duplicate_view'];
    //   }
    //   else {
    //     this.duplicate_view = false;
    //   }
    //
    //   if (data['key'] != null) {
    //     this.key = data['key'];
    //   }
    //   else {
    //     this.key = null;
    //   }
    //
    //   this.openSupplierViewModel(data);
    // });
  }

  ngOnInit(): void {
  }

  private openSupplierViewModel(data) {
    this.spinner.show();
    this.globalService.getSuppliersDetailsByRef(data['supplier_ref'], this.property_ref).subscribe(res => {
        this.spinner.hide();
        this.supplierDetails = res['supplier_details'];

        this.supplierViewModel.show();
      },
      err => {
        this.spinner.hide();
        this.onError(err.message.error);
      });
  }

  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  viewSupplierModelClose(data) {
    // this.supplierViewModel.hide();
    let details = {
      'supplier': null,
      'comeFrom': this.comeFrom,
      'duplicate_view':this.duplicate_view
      // 'isDirect': this.isDirect,
    }

    if(this.key != null){
      details['key'] = this.key;
    }

    this.supplierViewModel.hide();


  }

  openInWeb(web_address) {
    if(web_address != null){
      window.open(web_address);
    }
  }

  editSupplier() {
    let data = {
      'comeFrom':'supplier',
      'supplier_ref':this.supplierDetails['user_supplier_ref'],
      'property_ref':this.property_ref,
    }
    this.supplierGlobalService.openSupplierEditModel(data);
    this.supplierViewModel.hide();
  }

  selectSupplier() {
    let data = {
      'supplier': this.supplierDetails,
      'comeFrom': this.comeFrom,
      'duplicate_view':this.duplicate_view
      // 'isDirect': this.isDirect,
    }
    if(this.key != null){
      data['key'] = this.key;
    }

    this.supplierViewModel.hide();
    this.supplierGlobalService.selectSupplier(data);
    this.supplierGlobalService.customizeSupplier(false);
    this.supplierGlobalService.closeSupplierViewModel(data);
  }

  redirectToGoogleSearch(supplierDetail: any) {
    window.open(`https://www.google.com/search?q=${supplierDetail}`, '_blank');
  }
}
