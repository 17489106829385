import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PropertyService} from "../../../../views/app/properties/property.service";
import {GlobalService} from "../../../global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {PropertyGlobalService} from "../../../property-global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detal.component.html',
  styleUrls: ['./property-detal.component.scss']
})
export class PropertyDetalComponent implements OnInit,OnDestroy {
  @Input() propertyRef: any = 'new';
  @Input() comeFrom: any = null;
  hiddenModal = false;
  @ViewChild('propertyDetails')propertyDetails:ModalDirective;
  @Output() propertyEditViewClose= new EventEmitter<any>();
 private subscription: Subscription[] = [];
  constructor(
    public propertyService : PropertyService,
    public globalService : GlobalService,
    public propertyGlobalService : PropertyGlobalService,
    public spinner: NgxSpinnerService,
  ) {
  const subs =  this.propertyGlobalService.openPropertyDetailsViewModelEvent.subscribe((data:any)=>{
      this.propertyRef = data['propertyRef'];
      this.comeFrom = data['comeFrom'];
      this.openPropertyDetailsModel(data);
    });

    const subs2 =    this.propertyGlobalService.parentModelSwitchEvent.subscribe(data =>{
      this.hiddenModal = data;
    });
    this.subscription.push(subs);
    this.subscription.push(subs2);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.forEach(subscription => subscription.unsubscribe());
  }

  public openPropertyDetailsModel(data) {

    this.propertyDetails.show();
    this.propertyGlobalService.loadPropertyDetails(data);
  }

  propertyDetailsModelClose(data: any) {

    this.propertyDetails.hide();
    this.propertyRef = data['propertyRef'];
    let details = {
      propertyRef:data['propertyRef'],
      comeFrom: this.comeFrom
    }

    this.propertyEditViewClose.emit(details);
    // this.propertyGlobalService.closePropertyDetailsViewModel(details);
  }
}
