<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #mortgageModel="bs-modal" class="modal fade custom-modal" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left pl-2">List by Mortgage Totals</h4>
        <button type="button" class="close pull-right"
                (click)="closeModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="mortgageListForm" novalidate>
          <table class="table table-responsive">
            <thead>
            <tr>
              <th width="35%">Address</th>
              <th width="15%">Current Mortgage</th>
              <th  width="15%">Interest Rate </th>
              <th  width="15%">Monthly Payment</th>
              <th  width="20%" >Lender</th>
            </tr>
            </thead>
            <tbody>
            <ng-container formArrayName="mortgageList">
              <ng-container *ngFor="let control of addMortgageListList.controls; let index = index" [formGroupName]='index'>
                <tr *ngIf="control.get('mortgage_type_id').value != 3">
                  <td>{{control.get('property_address').value}}</td>
                  <td>
                    <div class="position-relative mb-0 reduce-width">
                      <label class="form-group has-left-icon-append">
                        <div class="input-group">
                          <div class="input-group-prepend custom-input-group-append-icon-right-border">
                            <span class="input-group-text custom-input-group-append-icon ">$</span>
                          </div>
                          <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="current_loan_balance"
                                 name="current_loan_balance" (blur)="calculateTotalLoanBalance(index);calculateMonthlyRepayment(index,null)">
                        </div>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class=" position-relative mb-0 reduce-width">
                      <label class="input form-group has-float-label mb-0">
                        <div class="position-relative has-icon-right" >
                          <div class="input-group">
                            <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="loan_interest_rate"
                                   name="loan_interest_rate" (blur)="calculateMonthlyRepayment(index,null)">
                            <div class="form-control-position">
                              <span>%</span>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div class=" position-relative mb-0 reduce-width">
                      <label class="form-group has-float-label has-left-icon-append">
                        <div class="input-group">
                          <div class="input-group-prepend custom-input-group-append-icon-right-border">
                            <span class="input-group-text custom-input-group-append-icon ">$</span>
                          </div>
                          <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="monthly_repayments"
                                 name="monthly_repayments" (blur)="calculateTotalRepayment(index)">
                        </div>
                      </label>

                    </div>
                  </td>
                  <td>{{control.get('business_name').value}}</td>
                </tr>
                <ng-container *ngIf="control.get('mortgage_type_id').value == 3">
                  <tr >
                    <td>{{control.get('property_address').value}} &nbsp;&nbsp;&nbsp; F</td>
                    <td>
                      <div class="position-relative mb-0 reduce-width">
                        <label class="form-group has-left-icon-append">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border" >
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="fixed_current_balance"
                                   name="fixed_current_balance" (blur)="calculateTotalLoanBalance(index);calculateMonthlyRepayment(index,'Fixed')">
                          </div>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class=" position-relative mb-0 reduce-width">
                        <label class="input form-group has-float-label mb-0">
                          <div class="position-relative has-icon-right" >
                            <div class="input-group">
                              <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="fixed_interest_rate"
                                     name="fixed_interest_rate" (blur)="calculateMonthlyRepayment(index,'Fixed')">
                              <div class="form-control-position">
                                <span>%</span>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class=" position-relative mb-0 reduce-width">
                        <label class="form-group has-float-label has-left-icon-append">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border" >
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="fixed_monthly_repayment"
                                   name="fixed_monthly_repayment" >
                          </div>
                        </label>

                      </div>
                    </td>
                    <td>{{control.get('business_name').value}}</td>
                  </tr>
                  <tr >
                    <td>{{control.get('property_address').value}} &nbsp;&nbsp;&nbsp; V</td>
                    <td>
                      <div class="position-relative mb-0 reduce-width">
                        <label class="form-group has-left-icon-append">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border" >
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="variable_current_balance"
                                   name="variable_current_balance" (blur)="calculateTotalLoanBalance(index);calculateMonthlyRepayment(index,'Variable')">
                          </div>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class=" position-relative mb-0 reduce-width">
                        <label class="input form-group has-float-label mb-0">
                          <div class="position-relative has-icon-right" >
                            <div class="input-group">
                              <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="variable_interest_rate"
                                     name="variable_interest_rate" (blur)="calculateMonthlyRepayment(index,'Variable')">
                              <div class="form-control-position">
                                <span>%</span>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </td>
                    <td>
                      <div class=" position-relative mb-0 reduce-width">
                        <label class="form-group has-float-label has-left-icon-append">
                          <div class="input-group">
                            <div class="input-group-prepend custom-input-group-append-icon-right-border" >
                              <span class="input-group-text custom-input-group-append-icon ">$</span>
                            </div>
                            <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="variable_monthly_repayment"
                                   name="variable_monthly_repayment" >
                          </div>
                        </label>

                      </div>
                    </td>
                    <td>{{control.get('business_name').value}}</td>
                  </tr>
                </ng-container>





<!--                <tr *ngIf="control.get('mortgage_type_id').value == 3">-->
<!--                  <td >-->
<!--                    <table class="table table-borderless tb-0 mb-0">-->
<!--                      <td width="90%" class="p-0">-->
<!--                        {{control.get('property_address').value}}-->
<!--                      </td>-->
<!--                      <td width="10%" class="p-0">-->
<!--                        <table class="table table-borderless tb-0 mb-0">-->
<!--                          <tr >-->
<!--                            <td class="p-0" style="height: 53px;">V</td>-->
<!--                          </tr>-->
<!--                          <tr >-->
<!--                            <td class="p-0">F</td>-->
<!--                          </tr>-->
<!--                        </table>-->
<!--                      </td>-->
<!--                    </table>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <table class="table table-borderless tb-0">-->
<!--                      <tr>-->
<!--                        <td class="pr-0 pl-0 pt-0 pb-0">-->
<!--                          <div class=" position-relative mb-0 reduce-width">-->
<!--                            <label class="form-group has-float-label has-left-icon-append">-->
<!--                              <div class="input-group">-->
<!--                                <div class="input-group-prepend custom-input-group-append-icon-right-border">-->
<!--                                  <span class="input-group-text custom-input-group-append-icon ">$</span>-->
<!--                                </div>-->
<!--                                <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="variable_current_balance"-->
<!--                                       name="variable_current_balance" disabled>-->
<!--                              </div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                        <td class="pr-0 pl-0 pt-0 pb-0">-->
<!--                          <div class=" position-relative mb-0 reduce-width">-->
<!--                            <label class="form-group has-float-label has-left-icon-append">-->
<!--                              <div class="input-group">-->
<!--                                <div class="input-group-prepend custom-input-group-append-icon-right-border">-->
<!--                                  <span class="input-group-text custom-input-group-append-icon ">$</span>-->
<!--                                </div>-->
<!--                                <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="fixed_current_balance"-->
<!--                                       name="fixed_current_balance" disabled>-->
<!--                              </div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                    </table>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <table class="table table-borderless tb-0">-->
<!--                      <tr>-->
<!--                        <td class="pr-0 pl-0 pt-0 pb-0">-->
<!--                          <div class=" position-relative mb-0 reduce-width">-->
<!--                            <label class="input form-group has-float-label">-->
<!--                              <div class="position-relative has-icon-right" >-->
<!--                                <div class="input-group">-->
<!--                                  <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="variable_interest_rate"-->
<!--                                         name="variable_interest_rate" disabled>-->
<!--                                  <div class="form-control-position">-->
<!--                                    <span>%</span>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                        <td class="pr-0 pl-0 pt-0 pb-0">-->
<!--                          <div class=" position-relative mb-0 reduce-width">-->
<!--                            <label class="input form-group has-float-label mb-0">-->
<!--                              <div class="position-relative has-icon-right" >-->
<!--                                <div class="input-group">-->
<!--                                  <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="fixed_interest_rate"-->
<!--                                         name="fixed_interest_rate" disabled>-->
<!--                                  <div class="form-control-position">-->
<!--                                    <span>%</span>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                    </table>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <table class="table table-borderless tb-0">-->
<!--                      <tr>-->
<!--                        <td class="pr-0 pl-0 pt-0 pb-0">-->
<!--                          <div class=" position-relative mb-0 reduce-width">-->
<!--                            <label class="form-group has-float-label has-left-icon-append">-->
<!--                              <div class="input-group">-->
<!--                                <div class="input-group-prepend custom-input-group-append-icon-right-border">-->
<!--                                  <span class="input-group-text custom-input-group-append-icon ">$</span>-->
<!--                                </div>-->
<!--                                <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="variable_monthly_repayment"-->
<!--                                       name="variable_monthly_repayment" disabled>-->
<!--                              </div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                      <tr>-->
<!--                        <td class="pr-0 pl-0 pt-0 pb-0">-->
<!--                          <div class=" position-relative mb-0 reduce-width">-->
<!--                            <label class="form-group has-float-label has-left-icon-append">-->
<!--                              <div class="input-group">-->
<!--                                <div class="input-group-prepend custom-input-group-append-icon-right-border">-->
<!--                                  <span class="input-group-text custom-input-group-append-icon ">$</span>-->
<!--                                </div>-->
<!--                                <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="fixed_monthly_repayment"-->
<!--                                       name="fixed_monthly_repayment" disabled>-->
<!--                              </div>-->
<!--                            </label>-->
<!--                          </div>-->
<!--                        </td>-->
<!--                      </tr>-->
<!--                    </table>-->
<!--                  </td>-->
<!--                  <td style="text-align: center">{{control.get('business_name').value}}</td>-->
<!--                </tr>-->
              </ng-container>
            </ng-container>
            </tbody>
          </table>

          <table class="table">
            <tbody>
            <tr>
              <td width="35%" class="font-weight-bold">Total</td>
              <td width="15%">{{mortgageListForm.get('total_current_loan').value | currency}}</td>
              <td width="15%" ></td>
              <td width="15%" >{{mortgageListForm.get('total_monthly_repayment').value | currency}}</td>
              <td width="20%"></td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-xs custom-btn-sec"
                (click)="closeModel()">Close</button>
        <app-state-button btnClass="btn btn-primary btn-xs custom-btn-primary" [currentState]="responseWaiting" message="false"
                          (click)="mortgageValueSubmit()" [isDisabled]="buttonDisabled">Save
        </app-state-button>
      </div>
    </div>
  </div>
</div>

