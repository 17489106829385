import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {NgxSpinnerService} from "ngx-spinner";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PropertyCalculationService} from "../../../../shared/property-calculation.service";
import {GlobalService} from "../../../../shared/global.service";
import {HelperService} from "../../../../shared/helper.service";
import {PropertyService} from "../../../../views/app/properties/property.service";

declare var $: any;
@Component({
  selector: 'app-rental-income-list',
  templateUrl: './rental-income-list.component.html',
  styleUrls: ['./rental-income-list.component.scss']
})
export class RentalIncomeListComponent implements OnInit {
  rentalListForm: FormGroup;
  responseWaiting = '';
  buttonDisabled = false;
  @ViewChild('rentalListModel') rentalListModel: ModalDirective;

  constructor(private propertyCalculationService:PropertyCalculationService,
              private modalService: BsModalService,
              private notifications: NotificationsService,
              private spinner: NgxSpinnerService,
              private propertyService : PropertyService,
              private fb: FormBuilder,
              private globalService : GlobalService,
              private helperService : HelperService) {
    this.initForm();
    this.propertyCalculationService.openRentalIncomeModelEvent.subscribe(data => {
      this.getRentalIncomeList();
    });
  }

  ngOnInit(): void {
    $(document).ready(function () {
      let modalContent: any = $('.modal-content');
      modalContent.draggable({
        handle: '.modal-body, .modal-header, .modal-footer',
        revert: false,
        revertDuration: 300
      });
    });
  }

  initForm() {
    this.rentalListForm = this.fb.group({
      total_rent_pw: [''],
      total_rent_pm: [''],
      rentalList: this.fb.array([]),
    })
  }

  get rentalList(): any {
    return this.rentalListForm.get('rentalList') as FormArray;
  }

  private getRentalIncomeList() {
    this.spinner.show();
    this.propertyService.getRentalIncomeList().subscribe(response=>{
        this.spinner.hide();
        var rental_list = response['rental_list'];
        rental_list.forEach((data) => {
          this.rentalList.push(this.createRentalIncomeList(data));
        });
        this.rentalListForm.patchValue(response);
        this.rentalListModel.show();
      },
      error => {
        this.spinner.hide();
        this.onError(error);
      });
  }

  createRentalIncomeList(data){
    return this.fb.group({
      'company_name': [data.company_name],
      'lease_end_date': [data.lease_end_date != null ?this.helperService.formDateObject(data.lease_end_date):null],
      'lease_end_date_str': [data.lease_end_date_str],
      'estimated_value_now': [data.estimated_value_now],
      'property_address': [data.property_address],
      'property_ref': [data.property_ref],
      'rent_per_month': [this.globalService.validateNumberFormat(data.rent_per_month)],
      'rent_per_week': [this.globalService.validateNumberFormat(data.rent_per_week)],
      'rental_ref': [data.rental_ref],
    });
  }

  onSuccess(content) {
    this.notifications.create('Success', content, NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  closeModel() {
    this.rentalList.clear();
    this.rentalListForm.reset();
    this.rentalListModel.hide();
    const data = {
      default:true,
      submit:false
    }
    this.propertyCalculationService.closeListOfPropertyValueModel(data);
  }

  rentalValueSubmit() {
    this.spinner.show();
    this.buttonDisabled = false;
    this.responseWaiting = 'show-spinner';
    let list = this.rentalListForm.value.rentalList;

    this.propertyService.rentalValueSubmit(this.rentalListForm.value).subscribe(response=>{
        this.responseWaiting = '';
        this.spinner.hide();
        this.onSuccess("saved successfully");
        this.closeModel();
        const data = {
          default:true,
          submit:true
        }
        this.propertyCalculationService.closeListOfPropertyValueModel(data);
      },
      error => {
        this.spinner.hide();
        this.onError(error);
      });
  }

  calculateRentPM(key) {
    if(this.rentalListForm.value.rentalList[key].rent_per_week != null || this.rentalListForm.value.rentalList[key].rent_per_week != ''){
      let rent_per_month =  (this.rentalListForm.value.rentalList[key].rent_per_week*52)/12;
      ((this.rentalListForm.get('rentalList') as FormArray).at(key) as FormGroup).get('rent_per_month').patchValue(this.globalService.validateNumberFormat(rent_per_month));

      this.calculateTotal();
    }
  }

  calculateRentPW(key) {
    if(this.rentalListForm.value.rentalList[key].rent_per_month != null || this.rentalListForm.value.rentalList[key].rent_per_month != ''){
      let rent_per_week =  (this.rentalListForm.value.rentalList[key].rent_per_month*12)/52;
      ((this.rentalListForm.get('rentalList') as FormArray).at(key) as FormGroup).get('rent_per_week').patchValue(this.globalService.validateNumberFormat(rent_per_week));

      this.calculateTotal();
    }
  }

  private calculateTotal() {
    let total_rent_pw = 0;
    let total_rent_pm = 0;
    this.rentalListForm.value.rentalList.forEach((data) => {
      total_rent_pw += data.rent_per_week;
      total_rent_pm += data.rent_per_month;
    });

    this.rentalListForm.patchValue({
      total_rent_pw: total_rent_pw,
      total_rent_pm: total_rent_pm
    });
  }
}
