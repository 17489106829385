<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #mortgageModel="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 *ngIf="!isEdit && !isImport" class="modal-title pull-left col-md-8">Add New Mortgage</h4>
        <h4 *ngIf="isEdit && !isImport" class="modal-title pull-left col-md-8">Edit Mortgage</h4>
        <h4 *ngIf="isImport" class="modal-title pull-left col-md-8">Upload Mortgage</h4>

        <a href="javascript:void(0)" class="pull-right col-md-3 text-danger" *ngIf="comeFrom == 'Mortgage'" (click)="openIssueModel('mortgage')" style="margin-top: 5px;font-weight: bold;">Report an Issue</a>
        <button type="button" class="close pull-right col-md-1"
                (click)="closeModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pl-2 pr-2">
        <form class="form" [formGroup]="mortgageHeaderForm" novalidate>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="!isEdit">
            <div class="row">
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12">
                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="current_loan_balance" formControlName="current_loan_balance" (blur)="calculateRedraw()">
                  </div>
                  <span>Current Loan balance</span>
                </label>
              </div>
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12" *ngIf="mortgageHeaderForm.value['redraw_offset_facility']==1">
                <label class="form-group has-float-label has-left-icon-append">
                  <div class="input-group">
                    <div class="input-group-prepend custom-input-group-append-icon-right-border">
                      <span class="input-group-text custom-input-group-append-icon ">$</span>
                    </div>
                    <input type="number" class="form-control" aria-describedby="inputGroup-sizing-sm"
                           name="redraw" formControlName="redraw" (blur)="calculateCurrentBalance()">
                  </div>
                  <span>Redraw / Offset</span>
                </label>
              </div>
              <div class="col-xl-4 col-lg-6 co-md-12 col-sm-12 col-xs-12">
                <label class="form-group has-float-label has-right-icon-append">
                  <div class="input-group ">
                    <input type="number" class="form-control border-right-0" aria-describedby="inputGroup-sizing-sm"
                           name="loan_interest_rate" formControlName="loan_interest_rate">
                    <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon">
                    <i class="fa fa-percent f-12 " ></i></span>
                    </div>
                  </div>
                  <span>Interest Rate</span>
                </label>
              </div>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="isImport">
            <div class="overflow-unset" style="height: 55px;" [multiple]="false"  ngx-dropzone [accept]="'.xls,.xlsx,.csv'" #drop3 (change)="importExcel($event)">
              <ngx-dropzone-label>
                <div class="drozone-label">
                  <h4>Drop File  Here</h4>
                </div>
              </ngx-dropzone-label>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">

          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button type="button" *ngIf="!isEdit" class="btn btn-primary pull-right mr-2" (click)="addNewRow(documentsControl.controls.length)">New Mortgage Row</button>
              </div>
              <!--          <div class="row">-->
              <!--            <div class="offset-8 pull-right">-->
              <!--                <button type="button" *ngIf="!isEdit" class="btn btn-primary pull-right mr-2" (click)="addNewRow(documentsControl.controls.length)">New Mortgage Row</button>-->
              <!--            </div>-->
              <!--            <form [formGroup]="mortgageDetailsForm" >-->
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="table-container">
                  <div class="table-header">
                    <table class="table table-borderless mb-0">
                      <thead>
                      <tr>
                        <th>Paid Date</th>
                        <th>Paid</th>
                        <th></th>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="table-body" style="max-height: 300px; overflow-y: scroll;overflow-x: clip !important;">
                    <table class="table table-borderless mb-0">
                      <tbody>
                      <ng-container formArrayName="mortgageRow">
                        <tr *ngFor="let item of documentsControl.controls; let index = index" [formGroupName]='index'>
                          <td>
                            <label class="form-group has-float-label">
                              <div class="input-group">
                                <input class="form-control" placeholder="dd/mm/YYYY" name="date_s" formControlName="date_s"
                                       #date_s="ngbDatepicker" ngbDatepicker #date_s="ngbDatepicker" (click)="date_s.toggle()">
                              </div>
                              <div *ngIf="submitted && documentsControl.controls[index].get('date_s').errors" class="text-danger">This field is required</div>
                            </label>
                          </td>
                          <td>
                            <label class="form-group has-float-label has-left-icon-append">
                              <div class="input-group">
                                <div class="input-group-prepend custom-input-group-append-icon-right-border">
                                  <span class="input-group-text custom-input-group-append-icon ">$</span>
                                </div>
                                <input type="number" class="form-control"
                                       name="amount" formControlName="amount"
                                       (ngModelChange)="amountChange($event,index)">
                              </div>
                              <div *ngIf="submitted && documentsControl.controls[index].get('amount').errors" class="text-danger">This field is required</div>
                            </label>
                          </td>
                          <td class="pl-3 pb-0">
                            <div class="row" *ngIf="!isEdit">
                              <a *ngIf="documentsControl.controls.length > 1" href="javascript:;" class="badge badge-primary mb-1 mt-2 mr-1" ngbTooltip="Add Row" (click)="addNewRowAfter(index)">
                                <i class="fa fa-plus f-15" style="font-weight: 500;"></i>
                              </a>
                              <a *ngIf="documentsControl.controls.length > 1" href="javascript:;" class="badge badge-danger mb-1 mt-2 mr-1" ngbTooltip="Delete" (click)="removeRecord(index)">
                                <i class="glyph-icon simple-icon-trash f-15"></i>
                              </a>
                            </div>
                            <div class="row" *ngIf="isImport">
                              <a *ngIf="documentsControl.controls.length > 1" href="javascript:;" class="badge badge-danger mb-1 mt-2 mr-1" ngbTooltip="Delete" (click)="removeRecord(index)">
                                <i class="glyph-icon simple-icon-trash f-15"></i>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                      <ng-container>
                        <tr *ngIf="isImageLoadingBarShow">
                          <img class="list-thumbnail border-0 pointer fixedimage" *ngIf="isImageLoadingBarShow" src="/assets/gif/1.gif" alt="Loading" style="padding-left: 340px;width: 70%;height: 64%;">
                        </tr>
                        <tr *ngIf="showMsg">
                          <span class="text-danger">This file is invalid</span>
                        </tr>
                      </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!--            </form>-->
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-7 ">
              <div class="form-group row mb-0">
                <button type="button" class="btn btn-outline-primary  mr-2" *ngIf="isMissingMortgage"
                        (click)="createMissingMortgage()">Create Missing Mortgage</button>

                <!--            <button type="submit" class="btn btn-primary btn-xs custom-btn-sec pull-right mr-2">Save</button>-->
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group row pull-right mb-0">
                <!--                  <button type="button" class="btn btn-primary btn-xs custom-btn-sec pull-right mr-2" (click)="isCreateExpense = true;saveMortgageDetails()">Save & Create Expense</button>-->
                <button type="button" class="btn btn-outline-primary  pull-right mr-2"
                        (click)="closeModel()">Close</button>
                <button type="button" class="btn btn-primary  pull-right " (click)="isCreateExpense = false;saveMortgageDetails()">Save</button>

                <!--                <app-state-button btnClass="btn btn-primary btn-xs custom-btn-sec pull-right mr-2" [currentState]="responseWaiting" message="false"-->
                <!--                                  [isDisabled]="buttonDisabled" >Save</app-state-button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
