<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>

<div bsModal #supplierViewModel="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <div class="col-md-12 pl-0 pr-0 row">
          <h4 class="modal-title pull-left {{duplicate_view ? 'col-md-11' : 'col-md-10'}}">Supplier Details - {{supplierDetails['property_address']}}</h4>
          <button type="button" class=" col-md-1 close pull-right" *ngIf="!duplicate_view"
                  (click)="editSupplier()" aria-label="Close">
            <span aria-hidden="true"><i class="fa fa-pencil" style="font-size: 20px!important;"></i></span>
          </button>
          <button type="button" class="col-md-1  close pull-right"
                  (click)="viewSupplierModelClose(null)" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-md-12 pull-right pr-0" *ngIf="comeFrom != 'supplier'">
          <button type="button" class=" btn btn-primary btn-xs pull-right" *ngIf="duplicate_view"
                  (click)="selectSupplier()" aria-label="Close">Select
          </button>
        </div>
        <div class="custom-list">
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Company Name</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['company_name']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Type</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['supplier_type']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Company Address</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-6 col-6">
              <span class="cursor-pointer" (click)="redirectToGoogleSearch(supplierDetails['company_address'])">{{supplierDetails['company_address']}}</span>
            </div>
            <div class="col-md-1 col-1" *ngIf="supplierDetails['company_address'] != null">
              <i class="fa fa-map-marker text-info cursor-pointer" style="font-size: 25px;margin-top: 3px;"></i>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Company Phone</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['company_phone']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Web Address</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-6 col-6">
              <span>{{supplierDetails['web_address']}}</span>
            </div>
            <div class="col-md-1 col-1" *ngIf="supplierDetails['web_address'] != null">
              <img  src="assets/png/internet.png" class="cursor-pointer" width="80%" style="margin-left: -7px;" (click)="openInWeb(supplierDetails['web_address']);">
            </div>
          </div>

          <hr>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Contact Name</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['main_contact_name']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Phone</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['contact_phone']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Email</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-6 col-6">
              <span>{{supplierDetails['contact_email']}}</span>
            </div>
            <div class="col-md-1 col-1" *ngIf="supplierDetails['contact_email'] != null">
              <a href="mailto:{{supplierDetails['contact_email']}}" class="fa fa-envelope f-18 pr-3 text-info cursor-pointer"></a>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Contact Name</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['contact_2_name']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Phone</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['contact_2_phone']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Email</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-6 col-6">
              <span>{{supplierDetails['contact_2_email']}}</span>
            </div>
            <div class="col-md-1 col-1" *ngIf="supplierDetails['contact_2_email'] != null">
              <a href="mailto:{{supplierDetails['contact_2_email']}}" class="fa fa-envelope f-18 pr-3 text-info cursor-pointer"></a>
            </div>
          </div>

          <hr>

          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">My Account Number with Supplier (If Applicable)</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['account_number']}}</span>
            </div>
          </div>

          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Amount Spent So Far</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span *ngIf="supplierDetails['total_expenses'] != null">$ {{supplierDetails['total_expenses']}}</span>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold mb-0">Rate</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <label class="form-group  has-float-label">
                <ul class="list-inline rating-list"
                    *ngFor="let star of stars" style="display: inline-block" >
                  <li
                    [ngClass]="{'selected': (star <= supplierDetails['rate'])}">
                    <i class="fa fa-star"></i>
                  </li>
                </ul>
              </label>
            </div>
          </div>
          <div class=" form-group row mb-0">
            <div class="col-md-4 col-4">
              <label class="font-weight-bold">Rating Comment</label>
            </div>
            <div class="col-md-1 col-1">
              <span class="dots">:</span>
            </div>
            <div class="col-md-7 col-7">
              <span>{{supplierDetails['rating_comment']}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="row">
          <div class="col-md-6 col-6">
            <label class="font-weight-bold" *ngIf="supplierDetails['is_black_list']">Supplier has been blacklisted</label>
          </div>
          <div class="col-md-6 pull-right">
            <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec pull-right"
                    (click)="viewSupplierModelClose(null)">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

