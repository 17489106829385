import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {PropertyService} from "../../../../views/app/properties/property.service";
import {NgxSpinnerService} from "ngx-spinner";
import {GlobalService} from "../../../global.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {Router} from "@angular/router";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {PropertyGlobalService} from "../../../property-global.service";
import {ModalDirective} from "ngx-bootstrap/modal";
import {NgForm} from "@angular/forms";
import {Subscription} from "rxjs";
import {HelperService} from "../../../helper.service";
import {SidebarService} from "../../../../containers/layout/sidebar/sidebar.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-property-list-tile-view',
  templateUrl: './property-list-tile-view.component.html',
  styleUrls: ['./property-list-tile-view.component.scss']
})
export class PropertyListTileViewComponent implements OnInit, OnChanges {
  optionsUpdates = {};
  @Input() rows: any[] = [];
  @Input() hiddenProperties: any[] = [];
  @Input() isSold: boolean = false;
  @Output() changeSortOrder = new EventEmitter();
  @ViewChild('unshare_model') unshareModel: ModalDirective;
  @ViewChild('editShareForm') editShareForm: NgForm;
  restoreList: any = [];
  isShare = false;
  propertyRef = null;
  sharedProperties: any = [];
  showSelectAllView = false;
  showSelectAllEdit = true;
  showSelectAllDelete = true;
  isOpenShareModal = false;
  permissionList: any = [];
  items: any = [];
  selectedPermissionList: any = [];
  userEmail = null;
  userName = null;
  shareButtonDisabled = false;
  responseWaiting = '';
  propertyDetails: any = [];
  routeParamsSubscription: Subscription;
  images: any = [];
  propertyNameOnTitles: any = [];
  volumeOrFolios: any = [];
  errorList: any = [];
  sharedPropertiesTemp: any = [];
  email = '';
  propertyCount = 0;
  quickFormData: any = [];
  quickFormButtonDisabled = false;
  tableArray: any[] = [];
  columnSize: number = 4;
  listOfItem: any = [];
  selectedSharedPropertyRef = null;
  quickFormUserPermissions= [
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Property Details"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Rent"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Mortgage"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Expenses"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Document"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Depreciation"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Issues"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Insurances"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Pictures"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Suppliers"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: " To Do List"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Other Info"
    },
    {
      is_delete:1,
      is_edit: 1,
      is_view: 1,
      permission_form: "Assets"
    },
  ];

  imageItems: any[] = [
    {
      "id": "large-0",
      "img": "https://strgpmsdevelopment.blob.core.windows.net/pmsimages/53/2018654772_13_3_230719_065046-w2480-h3508.jpeg"
    },
  ];

  imageItemsThumbs: any[]  = [
    {
      "id": "thumb-0",
      "img": "https://strgpmsdevelopment.blob.core.windows.net/pmsimages/53/2018654772_13_3_230719_065046-w2480-h3508.jpeg"
    },
  ];
  showDetails = true;
  hiddenModal = false;
  isDraggable =  false;
  constructor(
    private propertyService: PropertyService,
    private spinner: NgxSpinnerService,
    private globalService: GlobalService,
    private router: Router,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private propertyGlobalService: PropertyGlobalService,
    public globalServie: GlobalService,
    private sidebarService: SidebarService,
    private helperService: HelperService,
    private deviceService: DeviceDetectorService,
  ) {

    this.propertyGlobalService.openSharedModelEvent.subscribe(data => {
      if(data.shared_users != '') {
        this.sharedProperties = data.shared_users;
      }
      this.hiddenModal = false;
    });

    let localData = localStorage.getItem('viewType');

   let isTablet = this.deviceService.isTablet();
   console.log('isTablet',isTablet);
   if (isTablet && localData == 'default') {
     this.columnSize = 2;
   }
   else {
     this.columnSize = 4;
   }

   this.globalServie.viewMoodChangeEvent.subscribe(data => {
     if (isTablet && data == 'default') {
       this.columnSize = 2;
     }
     else {
       this.columnSize = 4;
     }
   });

  }

  ngOnInit(): void {
    this.onResize();
  }

 @HostListener('window:resize', ['$event'])
 onResize():void {
   setTimeout(() => {
     this.isDraggable = document.body.classList.contains('desktop') ? false : true;
   }, 1000);

 }
  ngOnChanges(changes: SimpleChanges) {

    if (changes.rows && changes.rows.currentValue) {
        this.listOfItem =this.arrayReOrder(this.rows);
        this.tableArray = this.initTable(this.listOfItem);
    }
  }

  initTable(array: any) {
    let tableArray = [];
    array.filter((_, outerIndex) => outerIndex % this.columnSize == 0)
      .forEach((_, rowIndex) => {
          let tempArray = array.slice(
            rowIndex * this.columnSize,
            rowIndex * this.columnSize + this.columnSize
          );
          tableArray.push(tempArray)
        }
      );


    return tableArray;
  }

  async drop(event: CdkDragDrop<string[]>, array: any[]) {
    // this.spinner.show();
    let sortArray:any [] =[]
    await moveItemInArray(array, event.previousIndex, event.currentIndex);

    await array.map((el, index) => {
      let data={
        "propertyRef":el.property_ref,
        "sort_order" : index
      };
      sortArray.push(data);
    });


    await this.propertyService.sortOrderProperty(sortArray).subscribe(response => {
      // this.spinner.hide();
      this.changeSortOrder.emit(true);
      // this.onSuccess('Sort Successful!');
    }, err => {
      this.globalService.onError(err.message);
      // this.spinner.hide();
    });
  }

  reorderDroppedItem(event: CdkDragDrop<number[]>) {

    // same row/container? => move item in same row

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    const newArrayWithPositions = this.tableArray.map((row) => [...row]);
    // combine all rows into one array
    const allItems = newArrayWithPositions.reduce((previous, current) => previous.concat(current), []);
    // this.tableArrayOther = this.initTable(allItems);
    this.updateOrdering(allItems);
  }

  updateOrdering(dataArray: any) {
    let sortArray:any [] =[]
    if(dataArray.length > 0){
      for (let i = 0; i < dataArray.length; i++) {
        dataArray[i].sort_order = i;
        let data = {
          "sort_order": i,
          "propertyRef": dataArray[i].property_ref,
        };
        sortArray.push(data);
      }
      this.tableArray = this.initTable(dataArray);
    }

    this.propertyService.sortOrderProperty(sortArray).subscribe(response => {
      // this.changeSortOrder.emit(true);
    }, err => {
      this.onError(err.message);
      this.globalService.unauthorizedRequest(err.status);
    });
  }

  arrayReOrder(array: any) {

    let outputArray = array.reduce(
      (previous, current) => previous.concat(current),
      []
    );

    outputArray.map((el, index) => {
      el.sort_order = index;
    });

    return outputArray;
  }

  async navigateRoute(row: any) {
    this.spinner.show();
    await this.propertyService.getSnapshotValidation(row.property_ref).subscribe(response => {
      this.globalService.notificationChange(response['result']);
      localStorage.setItem('snapShotValidation', response['result']);
      let data ={
        permission_list:response['user_permissions'],
        snapShotVisibility:response['result']
      }
      this.globalService.permissionChange(data);
      this.sidebarService.propertyListMenuShowHide(true);
      var url = '';
      if (response['result'] == true) {
        url = '/app/properties/snapshot/' + row.property_ref;
        // url = '/app/properties/details/' + row.property_ref;
        this.router.navigateByUrl(url);
      } else {
        url = '/app/properties/details/' + row.property_ref;
        this.router.navigateByUrl(url);
      }
      this.spinner.hide();
    }, err => {
      this.globalService.onError(err.status);
      this.spinner.hide();
    });
  }

  hideProperty(propertyRef) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want hide this property!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, hide it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.propertyService.hideProperty(propertyRef).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Your property is hidden ..');

            this.propertyGlobalService.propertyListChange(res);
          } else {
            this.onError(res['message']);
          }
          this.restoreList = [];
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
          this.onError(err.status);
        });
      }
    }, function (desmiss) {
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  openHiddenPropertyModal() {
    let data = {
      hiddenProperties: this.hiddenProperties,
    }
    this.propertyGlobalService.openHiddenPropertyModal(data);
  }

  shareProperty(row) {
    this.isShare = true;
    this.propertyRef = row.property_ref;
    this.selectedSharedPropertyRef = row.property_ref
    this.propertyGlobalService.getPermissionList(row.property_ref).subscribe(response => {
      this.permissionList = response['permission_list'];
    },error =>{
      this.onError(error.message);
    })
    //this.getPropertyDetails();
    this.unshareModel.show();
    if (row.shared_users.length > 0) {
      this.sharedProperties = row.shared_users;
    } else {

      this.sharedProperties = [];
    }
  }

  closeunShareModel(unshare_model: ModalDirective, unshareForm: NgForm) {
    unshare_model.hide();
    this.onModalHidden();
    this.propertyGlobalService.getPropertyReload(true);
  }

  openPropertyShareModal() {
    console.log(this.propertyRef)
    // this.isOpenShareModal = true;
    this.hiddenModal = true;
    this.showSelectAllView = false;
    this.showSelectAllEdit = true;
    this.showSelectAllDelete = true;
    this.permissionList.forEach(val => {
      val.view = true, val.edit = false , val.delete == false
    });
    this.items = [];
    this.isShare = true;

    this.rows.forEach(item=>{
      if(this.selectedSharedPropertyRef == item.property_ref){
        item.property_selected = true;
      }else{
        item.property_selected = false;
      }
    });
    let data = {
      'propertyRef': this.selectedSharedPropertyRef,
      'isShare': true,
      'permissionList': this.permissionList,
      'comeFrom': 'property-list-tile-view',
      'propertyList': this.rows,
    }
    this.propertyGlobalService.openSharePropertyModel(data);
  }

  openEditSharePermissions(edit_share_model: ModalDirective, Permissions, data) {
    edit_share_model.show();
    this.selectedPermissionList = Permissions;
    this.userName = data.first_name;
    this.userEmail = data.email;
    this.isOpenShareModal = true;
    this.hiddenModal =  true;
  }


  async  getPropertyDetails() {
    this.spinner.show();
    await this.propertyService.getPropertyDetails(this.propertyRef,'propertyEdit').subscribe(response => {
        this.spinner.hide();
        try{
          this.propertyDetails = response['properties'];
          this.images = response['images_lightbox'];
          this.imageItems = response['imageItems'];
          this.imageItemsThumbs = response['imageItemsThumbs'];
          this.propertyNameOnTitles = response['name_on_titles'];
          this.volumeOrFolios = response['volume_or_folios'];
          this.permissionList = response['permission_list'];
          this.validatePropertyPurpose();
        } catch (e) {
          this.globalService.onError(e.message) ;
        }

      },
      err => {
        this.spinner.hide();
      });
  }

  validatePropertyPurpose() {
    if (this.propertyDetails['purpose'] != null) {
      if (this.propertyDetails['purpose'] != 'Land Bank') {
        this.showDetails = true;
      }
      else {
        this.showDetails = false;
      }
    }
    else {
      this.showDetails = true;
    }
  }


  unshareTo(unshare_model: ModalDirective, form: NgForm, data) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to unshare this property!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, unshare it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.propertyService.unshareTo(data).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Property unshared successful');
            unshare_model.hide();
            this.propertyGlobalService.getPropertyReload(true);
          } else {
            this.onError(res['message']);
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
          this.onError(err.status);
          this.shareButtonDisabled = false;
        });
      }
    }, function (desmiss) {
    });
  }

  tickAllCheckBoxViewInEdit() {
    if (this.selectedPermissionList.every(val => val.view == true))
    {
      this.selectedPermissionList.forEach(val => { val.view = false, val.edit = false , val.delete = false});
      this.showSelectAllView = true;
      this.showSelectAllEdit = true;
      this.showSelectAllDelete = true;
    }
    else{
      this.selectedPermissionList.forEach(val => { val.view = true });
      this.showSelectAllView = false;
    }

  }

  tickAllCheckBoxDelete(isSelectAll) {
    let count = 0;
    this.permissionList.forEach(val => {
      if (val.view) {
        count++;
      }
    });
    if (count > 0) {
      if (isSelectAll) {
        this.showSelectAllDelete = false;
        this.permissionList.forEach(val => {
          if (val.view) {
            val.delete = true;
          }
        });
      } else {
        this.showSelectAllDelete = true;
        this.permissionList.forEach(val => {
          val.delete = false
        });
      }
    } else {
      this.permissionList.forEach(val => {
        val.delete = true, val.view = true
      });
      this.showSelectAllView = false;
      this.showSelectAllDelete = false;
    }
  }

  tickAllCheckBoxEditInEdit(isSelectAll) {
    let count = 0;
    this.selectedPermissionList.forEach(val => {
      if(  val.view){
        count++;
      }
    });
    if(count>0) {
      if (isSelectAll) {
        this.showSelectAllEdit = false;
        this.selectedPermissionList.forEach(val => {
          if (val.view) {
            val.edit = true;
          }
        });
      } else {
        this.showSelectAllEdit = true;
        this.selectedPermissionList.forEach(val => {
          val.edit = false
        });
      }
    }
    else{
      this.selectedPermissionList.forEach(val => { val.delete = true, val.view = true});
      this.showSelectAllView = false;
      this.showSelectAllEdit = false;
    }
  }

  tickAllCheckBoxDeleteInEdit(isSelectAll) {
    let count = 0;
    this.selectedPermissionList.forEach(val => {
      if(  val.view){
        count++;
      }
    });
    if(count>0) {
      if (isSelectAll) {
        this.showSelectAllDelete = false;
        this.selectedPermissionList.forEach(val => {
          if (val.view) {
            val.delete = true;
          }
        });
      } else {
        this.showSelectAllDelete = true;
        this.selectedPermissionList.forEach(val => {
          val.delete = false
        });
      }
    }
    else{
      this.selectedPermissionList.forEach(val => { val.delete = true, val.view = true});
      this.showSelectAllView = false;
      this.showSelectAllDelete = false;
    }
  }


  tickAllCheckBoxEdit(isSelectAll) {
    let count = 0;
    this.permissionList.forEach(val => {
      if (val.view) {
        count++;
      }
    });

    if (count > 0) {
      if (isSelectAll) {
        this.showSelectAllEdit = false;
        this.permissionList.forEach(val => {
          if (val.view) {
            val.edit = true;
          }
        });
      } else {
        this.showSelectAllEdit = true;
        this.permissionList.forEach(val => {
          val.edit = false
        });
      }
    } else {
      this.permissionList.forEach(val => {
        val.edit = true, val.view = true
      });
      this.showSelectAllView = false;
      this.showSelectAllEdit = false;
    }
  }

  permissionViewClick(checked, i) {
    if(checked == false){
      this.selectedPermissionList[i]['edit'] = false;
      this.selectedPermissionList[i]['delete'] = false;
    }
  }

  newPermissionClick(checked, i) {
    if(checked == true){
      this.permissionList[i]['view'] = true;
    }
  }

  newPermissionViewClick(checked, i) {
    if(checked == false){
      this.permissionList[i]['edit'] = false;
      this.permissionList[i]['delete'] = false;
    }
  }
  shareToEdit(edit_share_model: ModalDirective, form: NgForm) {
    if (!this.editShareForm.valid || this.shareButtonDisabled) {
      return;
    }
    this.shareButtonDisabled = true;
    let data = {
      'propertyRef': this.propertyRef,
      'permissionList': this.selectedPermissionList,
    };
    this.responseWaiting = 'show-spinner';
    this.propertyService.shareToEdit(data).subscribe(res => {
      this.spinner.hide();
      this.responseWaiting = '';
      this.shareButtonDisabled = true;
      if (res['status'] == 200) {
        this.onSuccess('Property Shared successful');
        this.isShare = false;
        this.shareButtonDisabled = false;
        edit_share_model.hide();
        this.hiddenModal = false;
        this.errorList = res['errors_list'];
        this.errorList.forEach((val, index) => {
          this.onError(val['message']);
        });
        this.propertyGlobalService.getPropertyReload(true);
        this.sharedPropertiesTemp = res['shared_users'];
        this.sharedProperties = this.sharedPropertiesTemp;

      } else {
        this.shareButtonDisabled = false;
        this.onError(res['message']);
        this.propertyGlobalService.getPropertyReload(true);
      }
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
      this.shareButtonDisabled = false;
    });
  }

  permissionClick(checked, i) {
    if(checked == true){
      this.selectedPermissionList[i]['view'] = true;
    }
  }
  closeShareModel(modal: ModalDirective, form: NgForm) {
    this.isShare = false;
    // form.resetForm();
    modal.hide();
    this.propertyRef = null;
    this.email = '';
    this.hiddenModal = false;

  }
  removeProperty(propertyRef) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want delete this property!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.propertyService.deleteProperty(propertyRef).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.propertyCount = res['propCount'];
            this.globalServie.updatePropertyCount(this.propertyCount,res['not_my_house_count']);
            this.onSuccess('Delete successful');
            this.propertyGlobalService.getPropertyReload(true);
          } else {
            this.onError(res['message']);
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
          this.onError(err.status);
        });
      }
    }, function (desmiss) {
    });
  }

  navigateToRentPage(property_ref) {

    var url = '/app/properties/rental/new/' + property_ref;
    this.router.navigateByUrl(url);
  }

  navigateToMortgagePage(property_ref) {
    var url = '/app/properties/mortgage/new/' + property_ref;
    this.router.navigateByUrl(url);
  }

  navigateToExpensePage(property_ref) {
    var url = '/app/properties/expense/new/' + property_ref;
    this.router.navigateByUrl(url);
  }

 async openQuickModal(quickModel: ModalDirective, property_ref: any) {
    this.spinner.show();
  await  this.globalService.setPropertyRef(property_ref);
    this.propertyService.getQuickUpdateDataByPropertyRef(property_ref).subscribe(response => {
      this.quickFormData = response['quick_form_data'];
      if(this.quickFormData['estimated_value_now'] != undefined){
        this.quickFormData['estimated_value_now'] = this.globalServie.validateNumberFormat(this.quickFormData['estimated_value_now']);
      }
      if (this.quickFormData['rent_per_week'] != undefined) {
        this.quickFormData['rent_per_week'] = this.globalServie.validateNumberFormat(this.quickFormData['rent_per_week']);
      }
      if (this.quickFormData['current_loan_balance'] != undefined) {
        this.quickFormData['current_loan_balance'] = this.globalServie.validateNumberFormat(this.quickFormData['current_loan_balance']);
      }
      if (this.quickFormData['monthly_repayments'] != undefined) {
        this.quickFormData['monthly_repayments'] = this.globalServie.validateNumberFormat(this.quickFormData['monthly_repayments']);
      }
      if (this.quickFormData['variable_monthly_repayment'] != undefined) {
        this.quickFormData['variable_monthly_repayment'] = this.globalServie.validateNumberFormat(this.quickFormData['variable_monthly_repayment']);
      }
      if (this.quickFormData['fixed_current_loan_balance'] != undefined) {
        this.quickFormData['fixed_current_loan_balance'] = this.globalServie.validateNumberFormat(this.quickFormData['fixed_current_loan_balance']);
      }
      if (this.quickFormData['fixed_monthly_repayment'] != undefined) {
        this.quickFormData['fixed_monthly_repayment'] = this.globalServie.validateNumberFormat(this.quickFormData['fixed_monthly_repayment']);
      }
      if (this.quickFormData['variable_current_loan_balance'] != undefined) {
        this.quickFormData['variable_current_loan_balance'] = this.globalServie.validateNumberFormat(this.quickFormData['variable_current_loan_balance']);
      }





      if(this.quickFormData['lease_start_date'] != null || this.quickFormData['lease_start_date'] != undefined){
        var newDate_string1 = this.helperService.formDateObject(this.quickFormData['lease_start_date']);
        var d1 = new Date(this.helperService.extractDateString(newDate_string1));
        this.quickFormData['lease_start_date'] = new Date(d1.getFullYear() + '/' + (d1.getMonth() + 1) + '/' + (d1.getDate()));
      }
      this.quickFormUserPermissions = response['quick_form_user_permissions'];
      quickModel.show();
      this.spinner.hide();
      // this.onSuccess('Sort Successful!');
    }, err => {
      this.onError(err.message);
      this.spinner.hide();
    });
  }

  validateInterestRates(value: any) {
    if (value > 100 || value < 0) {
      this.onError('Please enter between 1 to 100 number');
    }
  }

  menuClick(event: MouseEvent,url) {
    this.globalServie.formNavigation(event,url)
  }

  quickFormSubmit(form: NgForm,quickModel: ModalDirective) {
    form.value['has_rent'] = this.quickFormData['has_rent'];
    form.value['has_mortgage'] = this.quickFormData['has_mortgage'];
    form.value['property_ref'] = this.quickFormData['property_ref'];
    form.value['rental_ref'] = this.quickFormData['rental_ref'];
    form.value['mortgage_ref'] = this.quickFormData['mortgage_ref'];
    form.value['has_split_loan'] = this.quickFormData['has_split_loan'];
    if(this.quickFormData['has_split_loan']){
      form.value['split_loan_ref'] = this.quickFormData['split_loan_ref'];
    }

    form.value['mortgage_ref'] = this.quickFormData['mortgage_ref'];
    this.propertyService.saveQuickUpdateData(form.value).subscribe(res => {
      quickModel.hide();
      this.onSuccess('Save successful!');
      this.propertyGlobalService.getPropertyReload(true);
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
      this.onError(err.status);
    });
  }

  onModalHidden() {
    setTimeout(() => {
      const modalBackdrops = document.querySelectorAll('.modal-backdrop');
      if (modalBackdrops) {
        document.body.classList.remove('modal-open');
        modalBackdrops.forEach(backdrop => {
          backdrop.remove();
        });
      }
    },1000);

  }

  calculateMonthlyRepayment(permissionElement: number, mortgage_type_id: any,rateType) {
    if (permissionElement == 1) {
      if (mortgage_type_id != null && mortgage_type_id != 3) {
        let loanBalance = null;
        let interestRate = null;
        let mortgage_type = null;
        if((this.quickFormData['mortgage_type_id'] == 1 || this.quickFormData['mortgage_type_id'] == 2)){
          loanBalance = this.quickFormData['current_loan_balance'];
          interestRate = this.quickFormData['loan_interest_rate'];
          mortgage_type = this.quickFormData['mortgage_type_id'];
          if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
            let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, mortgage_type == 'I/O'?1:2,this.quickFormData['terms']);
            this.quickFormData['monthly_repayments'] = monthlyRepayment == 0?null:this.globalServie.validateNumberFormat(monthlyRepayment);
          }
        }
      }
      if (mortgage_type_id != null && mortgage_type_id == 3) {

        if(rateType == 'variable'){
          if((this.quickFormData['variable_mortgage_type'] == 1 || this.quickFormData['variable_mortgage_type'] == 2) ){
            let loanBalance = this.quickFormData['variable_current_loan_balance'];
            let interestRate = this.quickFormData['variable_interest_rate'];
            let mortgage_type = this.quickFormData['variable_mortgage_type'];
            if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
              let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, 3,this.quickFormData['terms']);
              this.quickFormData['variable_monthly_repayment'] = monthlyRepayment == 0?null:this.globalServie.validateNumberFormat(monthlyRepayment);
            }
          }
        }
        if(rateType == 'fixed'){
          if((this.quickFormData['fixed_mortgage_type'] == 1 || this.quickFormData['fixed_mortgage_type'] == 2)){
            let loanBalance = this.quickFormData['fixed_current_loan_balance'];
            let interestRate = this.quickFormData['fixed_interest_rate'];
            let mortgage_type = this.quickFormData['fixed_mortgage_type'];
            if((loanBalance != null && loanBalance != '') && (interestRate != null && interestRate != '') && (mortgage_type != null && mortgage_type != '')){
              let monthlyRepayment = this.globalService.calculateMonthlyRepayment(loanBalance, interestRate, 3,this.quickFormData['terms']);
              this.quickFormData['fixed_monthly_repayment'] = monthlyRepayment == 0?null:this.globalServie.validateNumberFormat(monthlyRepayment);
            }
          }
        }
      }
    }
  }
}
