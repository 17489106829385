import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GlobalService} from "../../../shared/global.service";
@Injectable({
  providedIn: 'root'
})
export class ReportIssuesService {
  @Output() reportIssueData = new EventEmitter<any>();
  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) { }

  saveNewIssue(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/proppel-issues/save-issue', data, this.globalService.getHttpOptions());
  }

  getIssueList(filter) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/proppel-issues/get-issues-list', filter, this.globalService.getHttpOptions());
  }

  updateIssue(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/proppel-issues/update-issues', data, this.globalService.getHttpOptions());
  }

  getSimilarIssues(data: any) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/proppel-issues/similar-issues-get', data, this.globalService.getHttpOptions());
  }

  getProppelLearningArticleView(data) {
    return this.http.post(this.globalService.getAPIUrl() + 'client/learning-article/get-learning-article-list', data, this.globalService.getHttpOptions());
  }

  passReportIssueData(data: any) {
    this.reportIssueData.emit(data);
  }
}
