<!--Property Transfer to & Property Share to Model-->
<ngx-spinner [fullScreen]="false" [name]="'propertyShareSpinner'" [color]="'#fff'" [type]="'ball-scale-multiple'"></ngx-spinner>
<div bsModal #share_model="bs-modal" class="modal fade" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 *ngIf="!isShare" class="modal-title pull-left">Property Transfer to</h4>
        <h4 *ngIf="isShare" class="modal-title pull-left">Property Share to</h4>
        <button type="button" class="close pull-right"
                (click)="closeShareModel(share_model,shareForm);isOpenShareModal=false" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #shareForm="ngForm">
          <div class="col-md-12 col-sm-12">
            <div class="row mb-3" *ngIf="isShare">
              <div class="col-md-9">
                <div *ngIf="isShare" class="container mb-2" >
                  <ngx-tags-input [limit]="limit" [header]="header" [placeholder]="placeholder" [mode]="'primary'"
                                  (tagsChange)="displayTags($event)"></ngx-tags-input>
                </div>
              </div>
              <div class="col-md-3 "  style="margin-top: 2.4rem;">
                <app-state-button *ngIf="isShare" btnClass="btn btn-primary btn-xs "  style="margin-top: 2.1rem;" [currentState]="responseWaiting"
                                  message="false" (click)="checkEmails();isOpenShareModal=true;" [isDisabled]="shareButtonDisabled ">
                  Check Name
                </app-state-button>
              </div>
            </div>
            <div class="row" *ngIf="!isShare">
              <div class="col-md-10">
                <label *ngIf="!isShare" class="form-group has-float-label">
                  <div class="input-group input-group-sm">
                    <input type="email" class="form-control form-control-sm" aria-label="Small"
                           aria-describedby="inputGroup-sizing-sm" name="email" [(ngModel)]="email" #email_to="ngModel"
                           pattern="[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*" required>
                  </div>
                  <span>Email</span>
                  <div *ngIf="!email_to.valid && shareForm.submitted" class="invalid-tooltip">Please Enter Valid Email.
                  </div>
                </label>
              </div>
              <div class="col-md-2 " *ngIf="isShare"
                   style=" margin-top: 2.5rem !important; margin-left: -22px !important;">

                <div class="pull-right">
                </div>
              </div>
              <div class="col-md-2" *ngIf="!isShare">
                <div class="pull-right">
                  <app-state-button *ngIf="!isShare" btnClass="btn btn-primary btn-xs" [currentState]="isWaiting"
                                    message="false" (click)="transferTo(share_model,shareForm)" [isDisabled]="buttonDisabled">Transfer
                  </app-state-button>
                </div>
              </div>
            </div>

            <div *ngIf="isPropertyListTileView" class="container">
              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-4 " *ngFor="let property of propertyList">
                  <div class="d-flex mb-3 property-select  img-box position-relative" >
                    <img [src]="property.headerImage" class="list-thumbnail border-0 pointer" style="width: 120px!important"/>
                    <label  class="custom-control custom-checkbox position-absolute pull-right pointer">
                      <input type="checkbox" class="custom-control-input" [id]="property.property_ref"
                             [(ngModel)]="property.property_selected" name="selected{{property.property_ref}}" (click)="addPropertyShare($event.target.checked,property.property_ref);">
                      <label class="custom-control-label"
                             [for]="property.property_ref"></label>
                    </label>
                  </div>
                  <label class="cursor-pointer font-weight-bold" (click)="property.property_selected = !property.property_selected;addPropertyShare(property.property_selected,property.property_ref);">{{ property.property_address }}</label>
                </div>
              </div>
            </div>

            <div *ngIf="isShare" class="col-md-12 col-sm-12">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th rowspan="1">Menu</th>
                  <th colspan="3">Permissions</th>
                </tr>
                <tr>
                  <th></th>
                  <th>View</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <a *ngIf="showSelectAllView" class="cursor-pointer text-primary " href='javascript:void(0)'
                         (click)="tickAllCheckBoxView()">Select All</a>
                    <a *ngIf="!showSelectAllView" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxView()">Deselect All</a>
                  </th>
                  <th>
                    <a *ngIf="showSelectAllEdit" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxEdit(true)">Select All</a>
                    <a *ngIf="!showSelectAllEdit" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxEdit(false)">Deselect All</a>
                  </th>
                  <th>
                    <a *ngIf="showSelectAllDelete" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxDelete(true)">Select All</a>
                    <a *ngIf="!showSelectAllDelete" class="cursor-pointer text-primary " href='javascript:void(0)'
                       (click)="tickAllCheckBoxDelete(false)">Deselect All</a>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let data of permissionList; index as i">
                  <td>{{ data.permission_form }}</td>
                  <td>
                    <div class="custom-control custom-checkbox align-contact">
                      <input type="checkbox" class="custom-control-input" name="is_view{{ data.random }}"
                             (click)="newPermissionViewClick($event.target.checked, i)" [id]="'view' + i" [(ngModel)]="data.view">
                      <label class="custom-control-label pt-0" [for]="'view' + i"
                             style="color: lightslategrey!important; font-size: 95%">View</label>
                    </div>
                  </td>
                  <td>
                    <div class="custom-control custom-checkbox align-contact">
                      <input type="checkbox" class="custom-control-input" name="is_edit{{ data.random }}"
                             (click)="newPermissionClick($event.target.checked, i)" [id]="'edit' + i" [(ngModel)]="data.edit">
                      <label class="custom-control-label pt-0" [for]="'edit' + i"
                             style="color: lightslategrey!important; font-size: 95%">Edit</label>
                    </div>
                  </td>
                  <td>
                    <div class="custom-control custom-checkbox align-contact">
                      <input type="checkbox" class="custom-control-input" name="is_delete{{ data.random }}"
                             (click)="newPermissionClick($event.target.checked, i)" [id]="'delete' + i" [(ngModel)]="data.delete">
                      <label class="custom-control-label pt-0" [for]="'delete' + i"
                             style="color: lightslategrey!important; font-size: 95%">Delete</label>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-xs"
                (click)="closeShareModel(share_model,shareForm);isOpenShareModal=false">Close</button>
        <button type="button" *ngIf="isShare" class="btn btn-primary btn-xs"
                (click)="shareTo(share_model,shareForm);isOpenShareModal=true;" [disabled]="shareButtonDisabled ">Save
        </button>
      </div>
    </div>
  </div>
</div>
