<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #propertyValueModel="bs-modal" class="modal fade custom-modal" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left">List by Property Value</h4>
        <button type="button" class="close pull-right"
                (click)="closeModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="propertyListForm" novalidate>
          <table class="table table-responsive">
            <thead>
            <tr>
              <th width="30%">Address</th>
              <th width="20%">Value</th>
              <th class="text-right" width="15%">Purchase </th>
              <th class="text-right" width="15%">Gain $  </th>
              <th class="text-right" width="10%">Gain %</th>
            </tr>
            </thead>
            <tbody>
            <ng-container formArrayName="propertyList">
              <tr *ngFor="let control of addPropertyList.controls; let index = index" [formGroupName]='index'>
                <td>{{control.get('property_address').value}}</td>
                <td>
                  <div class=" position-relative mb-0 reduce-width">
                    <label class="form-group has-float-label has-left-icon-append">
                      <div class="input-group">
                        <div class="input-group-prepend custom-input-group-append-icon-right-border">
                          <span class="input-group-text custom-input-group-append-icon ">$</span>
                        </div>
                        <input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="estimated_value_now"
                               name="estimated_value_now" (blur)="calculatePropertyValues(index)">
                      </div>
                    </label>
                  </div>
                </td>
                <td class="text-right">{{control.get('purchase_price').value | currency}}</td>
                <td class="text-right">{{control.get('capital_growth').value | currency}}</td>
                <td class="text-right">{{control.get('capital_growth_percentage').value | number:'1.2-2'}}%</td>
              </tr>
            </ng-container>
            </tbody>
          </table>

          <table class="table">
            <tbody>
            <tr>
              <td width="30%" class="font-weight-bold">Total</td>
              <td width="20%" class="text-right">{{propertyListForm.get('total_estimate_value').value | currency}}</td>
              <td width="15%" class="text-right">{{propertyListForm.get('total_purchase_value').value | currency}}</td>
              <td width="15%" class="text-right">{{propertyListForm.get('total_capital_gain').value | currency}}</td>
              <td width="10%" class="text-right">{{propertyListForm.get('total_capital_gain_percentage').value | number:'1.2-2'}}%</td>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-xs custom-btn-sec"
                (click)="closeModel()">Close</button>
        <app-state-button btnClass="btn btn-primary btn-xs custom-btn-primary" [currentState]="responseWaiting" message="false"
                          (click)="propertyValueSubmit( )" [isDisabled]="buttonDisabled">Save
        </app-state-button>
      </div>
    </div>
  </div>
</div>
