

<div bsModal #IssuesModal="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1" [config]="{backdrop: 'static', ignoreBackdropClick: true}"
     role="dialog" aria-labelledby="dialog-sizes-name1" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">Report New Issue</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeIssueModal()">

          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-dark ">
        <form [formGroup]="reportNewIssueSectionForm" (ngSubmit)="formSubmit(reportNewIssueSectionForm)">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <label class="form-group has-float-label">
                <ng-select class="w-100"  aria-describedby="inputGroup-sizing-sm" [typeahead]="false"
                           appearance="outline"
                           labelForId="form_code"
                           [searchable]="false"
                           [clearable]="true"
                           formControlName="form_code">
                  <ng-option *ngFor="let list of formList" [value]="list.form_code">
                    {{list.form_name}}
                  </ng-option>
                </ng-select>

                <span>Form Name<span class="text-danger">*</span></span>
                <div *ngIf="submitted && f.form_code.errors" class="invalid-tooltip">This field is required.</div>
              </label>

              <label class="form-group has-float-label has-left-icon-append" >
                <div class="input-group input-group-sm">
                  <input type="text" class="form-control form-control-sm" (keypress)="keyPress()"
                         formControlName="summary_description" name="summary_description">
                  <div class="input-group-append">
                    <button class="btn btn-primary default " style="border-radius: unset!important;" type="button"
                            (click)="searchSimilarIssues()"><i class=" glyph-icon simple-icon-magnifier  text-white"></i></button>
                  </div>
                </div>
                <span>Summary Description <span class="text-danger">*</span></span>
                <div *ngIf="submitted && f.summary_description.errors" class="invalid-tooltip">This field is required.</div>
                <div *ngIf="validateSimillerIssue" class="invalid-tooltip">Please enter atleast 4 characters.</div>
              </label>

              <label class="form-group has-float-label text-area">
                <div class="input-group ">
                  <textarea class="form-control textarea" rows="5" formControlName="issue_detail"  style="max-height: unset !important;"></textarea>
                </div>
                <span>Issue Details<span class="text-danger">*</span></span>
                <div *ngIf="submitted && f.issue_detail.errors" class="invalid-tooltip">This field is required.</div>
              </label>

            </div>
          </div>

          <ng-container *ngIf="similar_issues.length > 0 ">
            <hr/>
            <h5  class="pull-left">Similar Issues Raised By Other User</h5>
            <p class="pull-left">
              <strong>Please Review the list of known issues Below. if your issue is the same, then add yourself to the existing issue.</strong>
            </p>
            <div class="table-responsive mt-3">
              <table class="table table-striped">
                <thead class="th-b-color">
                <tr>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                </tr>
                </thead>
                <tbody >
                <tr *ngFor="let issue of similar_issues; let key = index"  >
                  <td ><a class="issue-link " (click)="viewIssueModal(viewIssuesModal,issue)" href="javaScript:void(0);">{{ issue.issue_detail }}</a></td>
                  <td>{{ issue.proppel_issue_status_name }} </td>
                </tbody>
                <tfoot *ngIf="similar_issues.length == 0">
                <tr>
                  <td colspan="8" class="no-record-text">
                    No records found..
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </ng-container>


          <div class="modal-footer">
            <app-state-button  btnClass="btn btn-primary btn-xs custom-btn-primary" [currentState]="responseWaiting" message="false"  [isDisabled]="buttonDisabled">Save
            </app-state-button>
            <button type="button" class="btn btn-outline-secondary btn-xs" (click)="closeIssueModal()">Close</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>


<!-- View issue-->
<div bsModal #viewIssuesModal="bs-modal" class="modal fade " tabindex="-1" [config]="{backdrop: 'static', ignoreBackdropClick: true}"
     role="dialog" aria-labelledby="dialog-sizes-name1" >
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name2" class="modal-title pull-left">View Issue</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeViewIssueModal()">

          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="reportIssueSectionViewForm" (ngSubmit)="viewformSubmit(reportIssueSectionViewForm)">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <label class="form-group has-float-label text-area">
                <div class="input-group ">
                  <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm" formControlName="form_name" name="form_name" >
                </div>
                <span>Form Name</span>
              </label>

              <label class="form-group has-float-label text-area">
                <div class="input-group ">
                  <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm" formControlName="summary_description" name="summary_description" >
                </div>
                <span>Summary Description</span>
              </label>

              <label class="form-group has-float-label has-left-icon-append" >
                <div class="input-group input-group-sm">
                  <input type="text" class="form-control form-control-sm" style="background: #e9ecef!important;"
                         formControlName="issue_detail" name="issue_detail">
                  <div class="input-group-append">
                    <button class="btn btn-primary default " style="border-radius: unset!important;" type="button" ngbTooltip="View Large"
                            (click)="viewLarge('issue details',reportIssueSectionViewForm)"><i class=" glyph-icon simple-icon-size-fullscreen  text-white"></i></button>
                  </div>
                </div>
                <span>Issue Details</span>
              </label>

              <label class="form-group has-float-label text-area">
                <div class="input-group ">
                  <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm" formControlName="proppel_issue_status_name" name="proppel_issue_status_name" >
                </div>
                <span>Status</span>
              </label>

              <label class="form-group has-float-label has-left-icon-append" >
                <div class="input-group input-group-sm">
                  <input type="text" class="form-control form-control-sm" style="background: #e9ecef!important;"
                         formControlName="resolution" name="resolution">
                  <div class="input-group-append">
                    <button class="btn btn-primary default " style="border-radius: unset!important;" type="button" ngbTooltip="View Large"
                            (click)="viewLarge('resolution',reportIssueSectionViewForm)"><i class=" glyph-icon simple-icon-size-fullscreen  text-white"></i></button>
                  </div>
                </div>
                <span>Resolution</span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <label class="form-group has-float-label text-area">
                <div class="input-group ">
                  <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm" formControlName="effected_user_count" name="effected_user_count" >
                </div>
                <span># Users with this Issue</span>
              </label>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <label class="form-group has-float-label text-area">
                <div class="input-group ">
                  <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm" formControlName="created_at" name="created_at" >
                </div>
                <span>Date Added</span>
              </label>

              <label class="form-group has-float-label text-area">
                <div class="input-group ">
                  <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm" formControlName="resolved_date" name="resolved_date" >
                </div>
                <span>Date Resolved</span>
              </label>
            </div>
            <input type="hidden" name="proppel_issue_ref" formControlName="proppel_issue_ref">
            <input type="hidden" name="effected_user_count_hidden" formControlName="effected_user_count_hidden">
          </div>


          <div class="modal-footer">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 pull-left" >
                  <button *ngIf="issue_user_ref != user_ref" type="submit" class="btn btn-outline-primary btn-xs custom-btn-primary" title="I have the same issue" >I have the same issue</button>
                </div>
                <div class="col-md-6 pull-right">
                  <button type="button" class="btn btn-outline-secondary btn-xs pull-right" (click)="closeViewIssueModal()">Close</button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<app-report-issues-window></app-report-issues-window>
