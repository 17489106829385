import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';
// const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
// const EXCEL_EXTENSION = '.csv';


//application/vnd.ms-excel
@Injectable()

export class ExcelService {

    options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        filename: 'generated',
        // title: 'My Awesome CSV',
        useTextFile: false,
        useBom: false,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    constructor() {

    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {

        this.options.filename = excelFileName + '_export_' + new Date().getTime();
        const csvExporter = new ExportToCsv(this.options);
        csvExporter.generateCsv(json);

        // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // // //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        // this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    // private saveAsExcelFile(buffer: any, fileName: string): void {
    //     const data: Blob = new Blob([buffer], {
    //         type: EXCEL_TYPE
    //     });
    //     FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    // }

}
