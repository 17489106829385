import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {GlobalService} from "../global.service";
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {NotificationsService, NotificationType} from "angular2-notifications";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private globalService: GlobalService,
              private router: Router,
              private notifications : NotificationsService,) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.onError('Session Expired, Please Login Again');
        localStorage.clear();
        this.router.navigate(['/user/login']);
      }
      if (err.status === 403) {
        // auto logout if 401 response returned from api
        this.onError('You are not authorized to access this page');
        localStorage.clear();
        this.router.navigate(['/user/login']);
      }
      const error = err.error.message || err.statusText;
      return throwError(error);
    }))
  }

  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }
}
