<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div bsModal #insuranceListModel="bs-modal" class="modal fade custom-modal" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left">List by Active Insurance</h4>
        <button type="button" class="close pull-right"
                (click)="closeModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="insuranceListForm" novalidate>
          <table class="table table-responsive">
            <thead>
            <tr>
              <th width="30%">Address</th>
              <th width="20%">Insurance Type</th>
              <th width="20%">Insurer </th>
              <th width="15%" class="text-right">Premium</th>
              <th width="15%" class="text-right">Expiry Date</th>
            </tr>
            </thead>
            <tbody>
            <ng-container formArrayName="insuranceList">
              <tr *ngFor="let control of addInsuranceList.controls; let index = index" [formGroupName]='index'>
                <td> <a class="pro-title cursor-pointer" (click)="navigateToInsurance(control.get('property_ref').value)">
                  {{control.get('property_address').value}}</a></td>
                <td>{{control.get('insurance_type').value}}</td>
                <td>{{control.get('company_name').value}}</td>
                <td class="text-right">{{control.get('premium_payment').value | currency}}</td>
                <td class="text-right">{{control.get('expiry_date').value | date:'dd/MM/yyyy'}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <table class="table">
            <tbody>
            <tr>
              <th width="30%">Total</th>
              <th width="20%">{{insuranceListForm.get('number_of_insurance').value}}</th>
              <th width="20%"></th>
              <th width="15%" class="text-right">{{insuranceListForm.get('total_premium').value | currency}}</th>
              <th width="15%" class="text-right"></th>
            </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary btn-xs custom-btn-sec"
                (click)="closeModel()">Close</button>
      </div>
    </div>
  </div>
</div>
