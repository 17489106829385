import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReportIssuesService} from "../../../views/app/report-issues/report-issues.service";



@Component({
  selector: 'app-report-issues-window',
  templateUrl: './report-issues-window.component.html',
  styleUrls: ['./report-issues-window.component.scss']
})
export class ReportIssuesWindowComponent implements OnInit, AfterViewInit {
  private myWindow: any;

  constructor(
    private reportIssuesService : ReportIssuesService,
  ) {
    this.reportIssuesService.reportIssueData.subscribe((data) => {
     let detail = data[1] != null ? data[1] : '';

      //open new window
      this.myWindow = window.open("", "MsgWindow", "width=600,height=600",);

      this.myWindow.document.write(
        `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>${data[0].toUpperCase()}</title>
   <style>
        .pl-2 {
            padding-left: .2rem;
        }
        .mb-3{
            margin-bottom:.1rem !important
        }
        .pl-2{
            padding-left:.5rem !important
        }
        .mt-5{
            margin-top:3rem !important
        }
        .pr-2{
            padding-right:.5rem !important
        }
    </style>
</head>
<body>
<div class="col-md-12 mt-5" style="margin-left: 10px">
    <h2 style="color: #145388;">${data[0].toUpperCase()}</h2>
</div>
<div class="col-md-12 " style="margin-left: 10px">
    <p>${detail}</p>

</div>
</body>
</html>`);
      this.myWindow.document.close();
    });

  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {

  }
}
