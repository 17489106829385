import {Component, OnInit, ViewChild} from '@angular/core';
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {NgxSpinnerService} from "ngx-spinner";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {PropertyCalculationService} from "../../../../shared/property-calculation.service";
import {GlobalService} from "../../../../shared/global.service";
import {HelperService} from "../../../../shared/helper.service";
import {PropertyService} from "../../../../views/app/properties/property.service";
declare var $: any;

@Component({
  selector: 'app-property-insurance-list',
  templateUrl: './property-insurance-list.component.html',
  styleUrls: ['./property-insurance-list.component.scss']
})
export class PropertyInsuranceListComponent implements OnInit {
  insuranceListForm: FormGroup;
  responseWaiting = '';
  buttonDisabled = false;
  @ViewChild('insuranceListModel') insuranceListModel: ModalDirective;
  constructor(private propertyCalculationService:PropertyCalculationService,
              private modalService: BsModalService,
              private notifications: NotificationsService,
              private spinner: NgxSpinnerService,
              private propertyService : PropertyService,
              private fb: FormBuilder,
              private globalService : GlobalService,
              private helperService : HelperService,
              private router: Router) {
    this.initForm();
    this.propertyCalculationService.openActiveInsuranceModelEvent.subscribe(data => {
      this.getActiveInsuranceList();
    });
  }

  ngOnInit(): void {
    $(document).ready(function () {
      let modalContent: any = $('.modal-content');
      modalContent.draggable({
        handle: '.modal-body, .modal-header, .modal-footer',
        revert: false,
        revertDuration: 300
      });
    });
  }

  private initForm() {
    this.insuranceListForm = this.fb.group({
      number_of_insurance: [''],
      total_premium: [''],
      insuranceList: this.fb.array([]),
    });
  }

  get addInsuranceList(): any {
    return this.insuranceListForm.get('insuranceList') as FormArray;
  }

  private getActiveInsuranceList() {
    this.spinner.show();
    this.propertyService.getActiveInsuranceList().subscribe(response => {
      var insurance_list = response['insurance_list'];
      insurance_list.forEach((data) => {
        this.addInsuranceList.push(this.createInsuranceList(data));
      });
      this.insuranceListForm.patchValue(response)

      this.spinner.hide();
      this.insuranceListModel.show();
    },err=>{
      this.spinner.hide();
      this.onError(err.message);
    });
  }

  onSuccess(content) {
    this.notifications.create('Success', content, NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  onError(content) {
    this.notifications.create('Error', content, NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
  }

  closeModel() {
    this.addInsuranceList.clear();
    this.insuranceListForm.reset();
    this.insuranceListModel.hide();
    const data = {
      default:true,
      submit:false
    }
    this.propertyCalculationService.closeListOfPropertyValueModel(data);
  }

  private createInsuranceList(data) {
    return this.fb.group({
      'company_name': [data.company_name],
      'expiry_date': [data.expiry_date],
      'insurance_ref': [data.insurance_ref],
      'insurance_type': [data.insurance_type],
      'premium_payment': [data.premium_payment],
      'property_address': [data.property_address],
      'property_ref': [data.property_ref],
    });
  }

  navigateToInsurance(value) {
    this.addInsuranceList.clear();
    this.insuranceListForm.reset();
    this.insuranceListModel.hide();
    var routeUrl = this.router.url.split('/');
    var data = {
      'property_ref': value,
      'routeName': routeUrl[routeUrl.length - 2],
    }
    this.router.navigateByUrl('/app/properties/property-insurances/' + value);
    this.propertyCalculationService.reloardInsurance(data);
  }
}
