<ngx-spinner></ngx-spinner>
<div class="row mb-3">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <div>
        <h2 class="font-weight-bold">Welcome Back {{userName}}!</h2>
        <p class="text-dark">Your last login:  {{lastLoginAt | date: 'MMM d y h:mma'}}</p>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>

<div class="card p-4">
  <div class="row">
    <div class="col-12 mb-1">
      <div>
        <h2 class="font-weight-bold">Coming Soon</h2>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="filterForm" (ngSubmit)="getNewInDevelopmentNotes()">
        <div class="row ">
          <div class="col-md-6">
            <div class="position-relative has-icon-right ">
              <div class="input-group mb-3">
                <input type="text" class="form-control br-25" formControlName="keyword" name="keyword"
                       placeholder="Search Keyword in Name">
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <button type="submit" class="btn btn-primary mr-2" (click)="getNewInDevelopmentNotes()">Search</button>
            <button type="button" class="btn btn-outline-dark" (click)="clearFilter()">Clear</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead class="th-b-color">
          <tr>
            <th scope="col">Name</th>
            <th scope="col" class="text-center">Category</th>
            <th scope="col" class="text-center">% Complete</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let inDevelopmentNote of inDevelopmentNotes; let key = index">
            <td><a class="issue-link " (click)="viewDevelopmentModal(viewModel,inDevelopmentNote)" href="javaScript:void(0);">{{ inDevelopmentNote.name }}</a></td>
            <td class="text-center">{{ inDevelopmentNote.development_category }} </td>
            <td class="text-center">{{ inDevelopmentNote.complete }}% </td>
          </tr>
          </tbody>
          <tfoot *ngIf="inDevelopmentNotes.length == 0">
          <tr>
            <td colspan="8" class="no-record-text">
              No records found..
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-md-4">
      <button type="submit" *ngIf="!is_view" class="btn btn-primary btn-sm mr-2" (click)="viewCompleted()">View Completed</button>
      <button type="submit" *ngIf="is_view" class="btn btn-primary btn-sm mr-2" (click)="hideCompleted()">Hide Completed</button>
    </div>
  </div>

  <div class="row" *ngIf="is_view">
    <div class="col-12 mb-1">
      <div>
        <h2 class="font-weight-bold">Completed 100%</h2>
      </div>
    </div>
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead class="th-b-color">
          <tr>
            <th scope="col">Name</th>
            <th scope="col" class="text-center">Category</th>
            <th scope="col" class="text-center">% Complete</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let inCompletedNote of inCompletedNotes; let key = index">
            <td><a class="issue-link " (click)="viewIssueModal(viewModel,inCompletedNote)" href="javaScript:void(0);">{{ inCompletedNote.name }}</a></td>
            <td class="text-center">{{ inCompletedNote.development_category }} </td>
            <td class="text-center">{{ inCompletedNote.complete }}% </td>
          </tr>
          </tbody>
          <tfoot *ngIf="inCompletedNotes.length == 0">
          <tr>
            <td colspan="8" class="no-record-text">
              No records found..
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>


<ng-template #viewModel let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{name}} - Complete {{percentage}}%</h5>
    <button type="button" class="close pull-right"
            (click)="modal.dismiss('Cross click');" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
<!--    {{memo}}-->
      <div [innerHTML]="memo"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary btn-sm"
            (click)="modal.close('by: close button');">Cancel</button>
  </div>
</ng-template>
