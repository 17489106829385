<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>

<div bsModal #supplierListModel="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left col-md-8">Find Your Supplier</h4>
        <button class="col-md-3 btn btn-primary pull-right btn-xs" (click)="openAddNewSupplierModel()">Add New Supplier</button>
        <button type="button" class="close pull-right col-md-1 "
                (click)="closeSupplierListModel()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <form class="form" [formGroup]="supplierFilterForm" novalidate>
            <div class="row">
              <div class="col-xl-3 col-md-4">
                <label class="form-group has-float-label">
                  <div class="position-relative has-icon-left">
                    <div class="input-group">
                      <input type="text" class="form-control br-25" aria-describedby="inputGroup-sizing-sm"
                             name="company_name" formControlName="company_name">
                    </div>
                  </div>
                  <span>Supplier name</span>
                </label>
              </div>
              <div class="col-xl-3 col-md-4">
                <label class="form-group has-float-label multi-select br-25">
                  <ng-select appearance="outline" labelForId="supplier_type_id"
                             [searchable]="true" [clearable]="false"
                             formControlName="supplier_type" [multiple]="true"
                             (change)="categoryChange()">
                    <ng-option value="all">All</ng-option>
                    <ng-option *ngFor="let list of supplierTypes" [value]="list.supplier_type_id">
                      {{list.supplier_type}}
                    </ng-option>
                  </ng-select>
                  <span>Categories</span>
                </label>
              </div>
              <div class="col-xl-3 col-md-4">
                <label class="form-group has-float-label br-25">
                  <ng-select class="w-100" appearance="outline" labelForId="location" [searchable]="false" [clearable]="false"  formControlName="location" >
                    <ng-option value="all">All</ng-option>
                    <ng-option value="National">National</ng-option>
                    <ng-option *ngFor="let list of locations" [value]="list.state">
                      {{list.state}}
                    </ng-option>
                  </ng-select>
                  <span>State</span>
                </label>
              </div>
              <div class="col-xl-3">
                  <button class="btn btn-outline-dark mr-2"
                          (click)="clearFilter()">Clear
                  </button>
                  <button class="btn btn-primary"
                          (click)="loadSupplierList()">Search
                  </button>
                </div>
            </div>
          </form>
        </div>

        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="25%">Supplier Type</th>
                <th width="50%">Supplier Name</th>
                <th width="15%">Location</th>
                <th width="10%"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let supplier of suppliers;let key=index;">
                <td>
                  <ul class="mb-0" *ngFor="let item of supplier.supplier_types;let i=index;" style="font-size: 12px;padding-left: 20px;">
                  <li class="mb-0">{{item}}</li>
                  </ul>
                </td>
                <td>{{supplier.company_name}}</td>
                <td>{{supplier.state}}</td>
                <td>
                  <a href="javaScript:void(0);" style="color: #0f3d64; " (click)="selectSupplier(supplier)"><i class="fa fa-hand-pointer-o"> <u>Select</u></i></a>
                </td>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12" *ngIf="totalCount >0">
          <div class="row mt-3">
            <div class="col-12 d-flex justify-content-between">
              <div>
                <label class="form-group has-float-label pagination">
                  <div class="input-group input-group-sm">
                    <ng-select class="custom-p-select" appearance="outline" [searchable]="false" [clearable]="false"
                               name="archiveMortgagePageSize"
                               [(ngModel)]="pageSize" required #p_purpose_type="ngModel"
                               (ngModelChange)="pageSizeChange($event)">
                      <ng-option value="all">All</ng-option>
                      <ng-option value="10">10</ng-option>
                      <ng-option value="20">20</ng-option>
                      <ng-option value="30">30</ng-option>
                      <ng-option value="40">40</ng-option>
                    </ng-select>
                  </div>
                  <span>Page Size</span>
                </label>
              </div>
              <div>
                <pagination class="pagination-sm float-right" [totalItems]="totalCount" [rotate]="true" [maxSize]="5"
                            [(ngModel)]="pageNo" [itemsPerPage]="pageSize" (pageChanged)="pageChanged($event)"
                            #size="ngModel" name="pagination_size"
                            [customNextTemplate]="nextTemplate" [customPreviousTemplate]="prevTemplate">
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-right'></i>
</ng-template>

<ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
  <i class='simple-icon-arrow-left'></i>
</ng-template>
