import {Component, Input, OnInit} from '@angular/core';
import {PropertyService} from "../../../views/app/properties/property.service";
import {GlobalService} from "../../global.service";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-learning-article',
  templateUrl: './learning-article.component.html',
  styleUrls: ['./learning-article.component.scss']
})
export class LearningArticleComponent implements OnInit {
  @Input() formId = null;
  @Input() comeFrom = null;
  articles: any = [];
  myWindow:any;
  constructor(
    private propertyService: PropertyService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {
    this.getLearningArticleList();
  }

  public getLearningArticleList() {
    this.spinner.show();
    let articleData = {
      'keyWord': null,
      'form_id': this.formId,
      'article_ref':null,
      'from_form': this.comeFrom,
    };

    this.propertyService.countProppelLearningArticleView(articleData).subscribe(res => {
      this.articles =  res['learning_articles'];
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err);
    });
  }

  alertWindowOpen(data) {
    // this.articleModel.hide();
    this.myWindow = window.open("", data['title'], "width=800,height=600");
    this.myWindow.document.write(data['content']);
    let articleData = {
      'keyWord': null,
      'form_id':this.formId,
      'article_ref':data['article_ref'],
      'from_form':'form',
    };

    this.propertyService.countProppelLearningArticleView(articleData).subscribe(res => {
      this.articles =  res['learning_articles'];
    }, err => {
      this.spinner.hide();
      this.globalService.onError(err);
    });
  }
}
