<nav class="navbar fixed-top">
  <div id="nav_right_full" class="display-none d-block">
    <div class="d-flex align-items-center navbar-left justify-content-between pl-3 pr-3" >
      <img src="assets/img/logo_white.png" height="35px">

<!--      <div class="ml-4">-->
<!--        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"-->
<!--           href="javascript:;" class="">-->
<!--          <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">-->
<!--            <rect x="0.48" y="0.5" width="7" height="1" />-->
<!--            <rect x="0.48" y="7.5" width="7" height="1" />-->
<!--            <rect x="0.48" y="15.5" width="7" height="1" />-->
<!--          </svg></a>-->
<!--      </div>-->

      <div class="ml-4">
        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
           href="javascript:;" class="menu-button d-none d-md-block">
          <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
            <rect x="0.48" y="0.5" width="7" height="1" />
            <rect x="0.48" y="7.5" width="7" height="1" />
            <rect x="0.48" y="15.5" width="7" height="1" />
          </svg> {{" "}}
          <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </a>
        <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
           class="menu-button-mobile d-xs-block d-sm-block d-md-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div id="nav_right_half" class="display-none">
    <div class="d-flex align-items-center navbar-left collapse-nav display-none" >
      <div class="logo-div">
        <img src="assets/img/logo_half.png" style="height: 22px;padding: 0 6px;">
      </div>
      <div class="ml-4 menu-icon">
        <a (click)="menuCollapseClick();"
           href="javascript:;" class=""><i class="fa fa-bars"></i></a>
      </div>

      <div class="menu-collaps ml-4">
        <a (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); false;"
           href="javascript:;" class="menu-button d-none d-md-block">
          <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
            <rect x="0.48" y="0.5" width="7" height="1" />
            <rect x="0.48" y="7.5" width="7" height="1" />
            <rect x="0.48" y="15.5" width="7" height="1" />
          </svg> {{" "}}
          <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </a>
        <a (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)" href="javascript:;"
           class="menu-button-mobile d-xs-block d-sm-block d-md-none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </a>
      </div>
    </div>
  </div>

  <div class="navbar-right-section d-flex justify-content-between pl-2 pr-2" >

    <div class="top-nav-menu align-self-center">
      <ul class="list-unstyled mb-0 d-inline-flex">
        <ng-container *ngIf="topNavMenusShow.length > 0">
          <ng-container *ngFor="let topMenu of topNavMenusShow">
<!--            <ng-container *ngIf="topNavMenusShow.length > 0">-->
            <ng-container *ngIf="topMenu.name !== 'New In Developmentsw'">
            <li *ngIf="topMenu.is_active">
              <a href="javaScript:void(0);" (click)="routerNavigateTo(topMenu.url )">{{ topMenu.name }}</a>
            </li>
            </ng-container>
            <li *ngIf="topMenu.name == 'New In Development'">
              <a href="javaScript:void(0);" (click)="openNewInDevelopmentModal()">{{ topMenu.name }}</a>
            </li>
          </ng-container>

        </ng-container>
        <li *ngIf="topNavMenusMore.length > 0" dropdown>
        <div class="btn-group" dropdown>
          <a href="javascript:void(0)" id="button-basic-menu" dropdownToggle type="button" class="dropdown-toggle"
                  aria-controls="dropdown-basic-menu">
            Menu <span class="caret"></span>
          </a>
          <ul id="dropdown-basic-menu" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic-menu">
            <ng-container *ngFor="let topMenu of topNavMenusMore">
              <ng-container *ngIf="topMenu.name !== 'New In Development'">
              <li role="menuitem mb-0" *ngIf="topMenu.is_active">
                <a href="javascript:void(0)" class="dropdown-item pb-0 pt-0" (click)="routerNavigateTo(topMenu.url)">{{ topMenu.name }}</a>
              </li>
              </ng-container>
              <li role="menuitem mb-0" *ngIf="topMenu.name === 'New In Development'">
                <a href="javascript:void(0)" class="dropdown-item pb-0 pt-0" (click)="openNewInDevelopmentModal()">{{ topMenu.name }}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        </li>

<!--        <li *ngIf="topNavMenusMore.length > 0" dropdown>-->
<!--          <a id="menu-more" dropdownToggle>Menu <i class="fa fa-angle-double-down f-15"></i></a>-->
<!--          <ul id="menu-more-1" *dropdownMenu class="dropdown-menu " role="menu" for="menu-more"-->
<!--              aria-labelledby="menu-more" [ngStyle]="{-->
<!--    'inset': '100% auto auto auto !important;'}" >-->
<!--            <ng-container *ngFor="let topMenu of topNavMenusMore">-->
<!--              <li role="menuitem mb-0" *ngIf="topMenu.name !== 'New In Development'">-->
<!--                <a class="dropdown-item pb-0 pt-0" (click)="routerNavigateTo(topMenu.url)">{{ topMenu.name }}</a>-->
<!--              </li>-->
<!--              <li role="menuitem mb-0" *ngIf="topMenu.name === 'New In Development'">-->
<!--                <a class="dropdown-item pb-0 pt-0" (click)="openNewInDevelopmentModal()">{{ topMenu.name }}</a>-->
<!--              </li>-->
<!--            </ng-container>-->
<!--          </ul>-->
<!--        </li>-->

<!--        <li *ngIf="topNavMenusMore.length > 0" dropdown  >-->
<!--          <a id="menu-more" dropdownToggle>Menu <i class="fa fa-angle-double-down f-15"></i></a>-->
<!--          <ul  id="menu-more" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" for="menu-more"-->
<!--               aria-labelledby="menu-more">-->
<!--            <ng-container *ngFor="let topMenu of topNavMenusMore">-->
<!--            <li role="menuitem mb-0"  *ngIf="topMenu.name != 'New In Development'"><a class="dropdown-item pb-0 pt-0" (click)="routerNavigateTo(topMenu.url )">{{ topMenu.name }}</a></li>-->
<!--            <li role="menuitem mb-0" *ngIf="topMenu.name == 'New In Development'"><a class="dropdown-item pb-0 pt-0" (click)="openNewInDevelopmentModal()">{{ topMenu.name }}</a></li>-->
<!--            </ng-container>-->
<!--          </ul>-->
<!--        </li>-->

        <!--        <li><a href="javaScript:void(0);" (click)="routerNavigateTo('app/my-suppliers/suppliers')">My Suppliers</a><li>-->
        <!--        <li><a href="javaScript:void(0);" (click)="routerNavigateTo('app/my-to-do/to-do')">My To Do's</a><li>-->
        <!--&lt;!&ndash;        <li class="" *ngIf="propertyList.length> 0"><a href="javaScript:void(0);" (click)="routerNavigateTo('app/eofy-reports/eofy-reports')">EOFY Tax Report</a><li>&ndash;&gt;-->
        <!--        <li><a href="javascript:void(0);" (click)="openNewInDevelopmentModal()">New In Development</a><li>-->
        <!--        <li><a href="javaScript:void(0);" (click)="routerNavigateTo('app/contact-us/contact-us')">Contact Us</a><li>-->
        <!--&lt;!&ndash;        <li><a  tooltip="Technical issues experienced in Proppel"  href="javaScript:void(0);" (click)="routerNavigateTo('app/proppel-issue/proppel-issue')">Proppel Issues</a><li>&ndash;&gt;-->
        <!--&lt;!&ndash;        <li><a href="javaScript:void(0);" (click)="routerNavigateTo('app/proppel-policies/proppel-policies')">Proppel Policies</a><li>&ndash;&gt;-->
      </ul>
    </div>


    <div class="">
      <div class="header-icons d-inline-block align-middle">

        <div class="d-none d-md-inline-block align-text-bottom mr-1">
          <div class="custom-control custom-switch" tooltip="Dark Mode" placement="left">
            <input type="checkbox" class="custom-control-input" id="darkModeSwitch" [checked]="isDarkModeActive"
                   (change)="onDarkModeChange($event)">
            <label class="custom-control-label" for="darkModeSwitch"></label>
          </div>
        </div>

<!--        <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" >-->
<!--          <i  class="glyph-icon simple-icon-settings"></i>-->
<!--        </button>-->

<!--        <div class="user d-inline-block" dropdown>-->
<!--          <button id="button-basic" dropdownToggle type="button" id="colorChangeButton"-->
<!--                  class="btn btn-empty p-1" (click)="showSetting==true?showSetting=false:showSetting=true;toggleSwitcher()">-->
<!--            <i  class="glyph-icon simple-icon-settings text-primary"></i>-->
<!--          </button>-->
<!--          <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3 theme-piker" id="colorChangeDropdown">-->
<!--            <div class="p-4">-->
<!--              <p class="text-muted mb-2">Light Theme</p>-->
<!--              <div class="d-flex flex-row justify-content-between mb-3 d">-->
<!--                <a *ngFor="let color of colors.slice(0,5)"  (click)="changeColor('light.'+color)"  class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='light.'+color ? ' active':'')}}" ></a>-->
<!--              </div>-->
<!--              <div class="d-flex flex-row justify-content-between mb-4">-->
<!--                <a *ngFor="let color of colors.slice(5,10)"  (click)="changeColor('light.'+color)"  class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='light.'+color ? ' active':'')}}" ></a>-->
<!--              </div>-->
<!--              <p class="text-muted mb-2">Dark Theme</p>-->
<!--              <div class="d-flex flex-row justify-content-between mb-3">-->
<!--                <a *ngFor="let color of colors.slice(0,5)" (click)="changeColor('dark.'+color)" class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='dark.'+color ? ' active':'')}}" ></a>-->
<!--              </div>-->
<!--              <div class="d-flex flex-row justify-content-between">-->
<!--                <a *ngFor="let color of colors.slice(5,10)" (click)="changeColor('dark.'+color)" class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='dark.'+color ? ' active':'')}}" ></a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="p-4">-->
<!--              <p class="text-muted mb-2">Border Radius</p>-->
<!--              <div class="custom-control custom-radio custom-control-inline">-->
<!--                <input type="radio" id="ltrRadio" name="directionRadio" value="rounded" class="custom-control-input direction-radio" [checked]="radius === 'rounded' ? true : false" (click)="changeRadius('rounded')">-->
<!--                <label class="custom-control-label" for="ltrRadio">Rounded</label>-->
<!--              </div>-->
<!--              <div class="custom-control custom-radio custom-control-inline">-->
<!--                <input type="radio" id="rtlRadio" name="directionRadio" value="flat" class="custom-control-input direction-radio" [checked]="radius === 'rounded' ? false : true" (click)="changeRadius('flat')">-->
<!--                <label class="custom-control-label" for="rtlRadio">Flat</label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

        <div class="user d-inline-block p-0" dropdown dropdownToggle (click)="showSetting==true?showSetting=false:showSetting=true;toggleSwitcher()">
          <button class="btn btn-empty p-1" type="button" >
            <i id="color_picker" class="glyph-icon simple-icon-settings text-muted"></i>
          </button>

          <div  *dropdownMenu >
            <div class="{{toggleClass}}" >
              <div class="p-4">
                <p class="text-muted mb-2">Light Theme</p>
                <div class="d-flex flex-row justify-content-between mb-3 d">
                  <a *ngFor="let color of colors.slice(0,5)"  (click)="changeColor('light.'+color)"  class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='light.'+color ? ' active':'')}}" ></a>
                </div>
                <div class="d-flex flex-row justify-content-between mb-4">
                  <a *ngFor="let color of colors.slice(5,10)"  (click)="changeColor('light.'+color)"  class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='light.'+color ? ' active':'')}}" ></a>
                </div>
                <p class="text-muted mb-2">Dark Theme</p>
                <div class="d-flex flex-row justify-content-between mb-3">
                  <a *ngFor="let color of colors.slice(0,5)" (click)="changeColor('dark.'+color)" class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='dark.'+color ? ' active':'')}}" ></a>
                </div>
                <div class="d-flex flex-row justify-content-between">
                  <a *ngFor="let color of colors.slice(5,10)" (click)="changeColor('dark.'+color)" class="{{'c-pointer theme-color theme-color-'+color + (selectedColor==='dark.'+color ? ' active':'')}}" ></a>
                </div>
              </div>
              <div class="p-4">
                <p class="text-muted mb-2">Border Radius</p>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="ltrRadio" name="directionRadio" value="rounded" class="custom-control-input direction-radio" [checked]="radius === 'rounded' ? true : false" (click)="changeRadius('rounded')">
                  <label class="custom-control-label" for="ltrRadio">Rounded</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="rtlRadio" name="directionRadio" value="flat" class="custom-control-input direction-radio" [checked]="radius === 'rounded' ? false : true" (click)="changeRadius('flat')">
                  <label class="custom-control-label" for="rtlRadio">Flat</label>
                </div>
              </div>

<!--              <a (click)="toggleSwitcher($event)" class="c-pointer theme-button"> <i class="simple-icon-magic-wand"></i> </a>-->
            </div>
          </div>
        </div>

        <div class="position-relative d-inline-block">
          <div dropdown>
            <button id="button-basic" dropdownToggle type="button" id="notificationButton"
                    class="header-icon btn btn-empty">
              <i class="simple-icon-bell"></i>
              <span class="count" *ngIf="notificationCount != 0">{{notificationCount}}</span>
            </button>
            <div id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right mt-3"
                 id="notificationDropdown">
              <div class="d-flex flex-row">
                <h5 class="text-primary font-weight-bold gap-3" style="margin-top: -15px; margin-bottom: 15px; margin-right: 20px;">Notification</h5>
                <i class="simple-icon-bell pull-right f-16 font-weight-bold" style="margin-top: -12px; margin-bottom: 15px"></i>
              </div>

              <perfect-scrollbar>
                <div class="d-flex flex-row mb-3 pb-3 border-bottom" *ngFor="let notification of notificationList">
                  <div>
                    <a href="javascript:;" (click)="notificationClick(notification)">
                      <p class="font-weight-medium mb-1 text-success">{{notification.property_address}} - {{notification.description}}</p>
                      <p class="text-muted mb-0 text-small">{{ notification.created_at | date:'mediumDate' }}  -  {{ notification.created_at | date:'shortTime'  }}</p>
                    </a>
                  </div>
                </div>
                <div class="text-center mt-2 mb-2" *ngIf="notificationCount != 0">
                  <a href="javascript:;" (click)="clearAllNotifications()">Clear All</a>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>

        <button class="header-icon btn btn-empty d-none d-sm-inline-block" type="button" id="fullScreenButton"
                (click)="fullScreenClick()">
          <i *ngIf="!isFullScreen" class="simple-icon-size-fullscreen"></i>
          <i *ngIf="isFullScreen" class="simple-icon-size-actual"></i>
        </button>

      </div>

      <div class="user d-inline-block" dropdown>
        <button class="btn btn-empty p-0" type="button" dropdownToggle>
          <div class="user-topbar d-flex justify-content-between align-items-center">
            <!--            <img alt="Profile Picture" src="{{profile_image}}" />-->
            <img alt="avatar Profile-Picture" src="{{profile_image}}" style="width: 45px !important; height: 45px !important;object-fit: cover;">
            <div *ngIf="screenSize > 800">
              <h4 class="text-muted fs-md">{{displayName}}</h4>
            </div>
            <i *ngIf="screenSize > 800" style="font-size: 14px" class="glyph-icon simple-icon-arrow-down float-right text-muted"></i>
          </div>
        </button>

        <div class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
          <a class="dropdown-item c-pointer" (click)="myProfile()">Account</a>
          <!--          <a class="dropdown-item c-pointer">Features</a>-->
          <!--          <a class="dropdown-item c-pointer">History</a>-->
          <!--          <a class="dropdown-item c-pointer">Support</a>-->
          <!--          <li class="divider dropdown-divider"></li>-->
          <a class="dropdown-item c-pointer" (click)="onSignOut()">Sign out</a>
        </div>
      </div>

    </div>
  </div>

</nav>


<app-supplier-edit></app-supplier-edit>
<app-property-share-model></app-property-share-model>
<app-property-value></app-property-value>
<app-rental-income-list></app-rental-income-list>
<app-property-insurance-list></app-property-insurance-list>
<app-property-cashflow></app-property-cashflow>
<app-mortgage-total></app-mortgage-total>
<app-new-in-development></app-new-in-development>
<app-property-supplier-details-view-model></app-property-supplier-details-view-model>

