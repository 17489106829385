<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>
<div class="modal-dialog modal-xl">
  <div class="modal-content">
    <div class="modal-header">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10">
            <h4 *ngIf="propertyRef =='new'" class="modal-title pull-left">Add New Property</h4>
            <ng-container *ngIf="propertyRef !='new'">
              <h4  class="col-md-12 modal-title pull-left pl-0">Update Property Information </h4>
              <h6  class="col-md-12 modal-title pull-left pl-0"> {{propertyDetailsForm.get('property_address').value}}</h6>
            </ng-container>
          </div>
          <div class="col-md-2">
            <button type="button" class="close pull-right" data-dismiss="modal" aria-label="Close" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-body text-dark property-details">
      <form class="form" [formGroup]="propertyDetailsForm" novalidate>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
            <h5 class="font-weight-bold mb-3">My Property</h5>
            <label class="form-group has-float-label">
              <div class="input-group ">
                <input type="text" class="form-control form-control-sm" formControlName="property_address">
              </div>
              <span>Property Address <span class="text-danger">*</span></span>
            </label>

            <div class="form-group d-flex pt-2" style="padding-bottom: 5px;">
              <div class="form-check mr-2">
                <input type="radio" id="gridRadios1" formControlName="isSold" value="0" class="form-check-input" checked (click)="isSold = '0';propertyDetailsForm.get('sold').setValue(0)">
                <label for="gridRadios1" class="form-check-label"> My Property </label>
              </div>
              <div class="form-check mr-2">
                <input type="radio" id="gridRadios2" formControlName="isSold" value="1" class="form-check-input" (click)="isSold = '1';propertyDetailsForm.get('sold').setValue(1)">
                <label for="gridRadios2" class="form-check-label"> Sold </label>
              </div>
            </div>
            <ng-container *ngIf="isSold=='1'">
            <label class="form-group has-float-label has-left-icon-append ">
              <div class="input-group bg-yellow">
                <div class="input-group-prepend custom-input-group-append-icon-right-border">
                  <span class="input-group-text custom-input-group-append-icon ">$</span>
                </div>
                <input type="number" class="form-control form-control-sm bg-yellow" formControlName="sold_price" (keyup)="calculateProfit()" >
              </div>
              <span>Sold Price</span>
            </label>

            <label class="form-group has-float-label" >
              <div class="input-group ">
                <input class="form-control bg-yellow" placeholder="dd/mm/YYYY" formControlName="settlement_date_obj" ngbDatepicker #settlement_date_obj="ngbDatepicker"
                       (click)="settlement_date_obj.toggle()" >
              </div>
              <span>Settlement Date</span>
            </label>

            <label class="form-group has-float-label has-left-icon-append">
              <div class="input-group bg-yellow">
                <div class="input-group-prepend custom-input-group-append-icon-right-border">
                  <span class="input-group-text custom-input-group-append-icon ">$</span>
                </div>
                <input type="number" class="form-control form-control-sm bg-yellow" formControlName="sell_cost" (blur)="calculateProfit()">
                <div class="input-group-append">
                  <button class="btn btn-primary default " style="border-radius: unset!important;" type="button"
                          (click)="openExpenseModel()" ><i class=" glyph-icon simple-icon-link  text-white"></i></button>
                </div>
              </div>
              <span>Sell Costs</span>
            </label>

            <label class="form-group has-float-label has-left-icon-append">
              <div class="input-group bg-yellow">
                <div class="input-group-prepend custom-input-group-append-icon-right-border bg-yellow">
                  <span class="input-group-text custom-input-group-append-icon ">$</span>
                </div>
                <input type="number" class="form-control form-control-sm bg-yellow" formControlName="profit"  readonly>
              </div>
              <span>Profit</span>
            </label>
            </ng-container>

            <label class="form-group has-float-label">
              <ng-select [items]="propertyTypes" class="w-100" [searchable]="false"
                         [addTagText]="" aria-describedby="inputGroup-sizing-sm" [typeahead]="false"
                         formControlName="property_type" [hideSelected]="true" [multiple]="false" bindLabel="property_type"
                         bindValue="property_type">
              </ng-select>
              <span>Property Type<span class="text-danger">*</span></span>
              <div class="invalid-tooltip" *ngIf="propertyDetailsFormControls.property_type.errors && submitted">This field is required.</div>
            </label>

            <label class="form-group has-float-label">
              <ng-select [items]="propertyPurposeTypes" class="w-100" [addTag]="addPropertyPurpose"
                         [addTagText]="'Hit Enter to Add Item'" aria-describedby="inputGroup-sizing-sm"
                         formControlName="property_purpose" [hideSelected]="true" [multiple]="false" bindLabel="purpose"
                         bindValue="purpose">
              </ng-select>
              <span>Property Purpose<span class="text-danger">*</span>  <a href="javascript:;" (click)="openPropertyPurposeModel()"><i class="fa fa-pencil text-primary ml-1"></i></a> </span>
              <div class="invalid-tooltip" *ngIf="propertyDetailsFormControls.property_purpose.errors && submitted">This field is required.</div>
            </label>

            <label class="form-group has-float-label has-left-icon-append">
              <div class="input-group">
                <div class="input-group-prepend custom-input-group-append-icon-right-border">
                  <span class="input-group-text custom-input-group-append-icon ">$</span>
                </div>
                <input type="number" class="form-control form-control-sm" formControlName="purchase_price"   (keyup)="calculateProfit()">
              </div>
              <div *ngIf="submitted && propertyDetailsFormControls.purchase_price.errors" class="invalid-tooltip">This field is required.</div>
              <span>Purchase Price<span class="text-danger">*</span></span>
            </label>

            <label class="form-group has-float-label has-both-side-icon-append">
              <div class="input-group ">
                <div class="input-group-prepend ">
                  <span class="input-group-text custom-input-group-append-icon">$</span>
                </div>
                <input type="number" class="form-control form-control-sm" formControlName="capital_improvement"   (blur)="isCapitalImprovementAddToPurchasePrice()">
                <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Capital Improvement')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                </div>
              </div>
              <span>Capital Improvement</span>
            </label>

            <label class="form-group has-float-label">
              <div class="input-group ">
                <input class="form-control" placeholder="dd/mm/YYYY"  formControlName="contract_s_date" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()" >
              </div>
              <span>Contract Sale Date</span>
            </label>

            <label class="form-group has-float-label">
              <div class="input-group ">
                <input class="form-control" placeholder="dd/mm/YYYY"  formControlName="p_settlement_date" ngbDatepicker #d_settlement_date="ngbDatepicker" (click)="d_settlement_date.toggle()" >
              </div>
              <span>Settlement Date</span>
            </label>

            <label class="form-group has-float-label has-both-side-icon-append">
              <div class="input-group ">
                <div class="input-group-prepend ">
                  <span class="input-group-text custom-input-group-append-icon">$</span>
                </div>
                <input type="number" class="form-control form-control-sm" formControlName="estimated_value_now" >
                <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Market Value')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                </div>
              </div>
              <div *ngIf="submitted && propertyDetailsFormControls.estimated_value_now.errors" class="invalid-tooltip">This field is required.</div>
              <span>Market Value<span class="text-danger">*</span></span>
            </label>

            <label class="form-group has-float-label has-both-side-icon-append">
              <div class="input-group ">
                <div class="input-group-prepend ">
                  <span class="input-group-text custom-input-group-append-icon">$</span>
                </div>
                <input type="number" class="form-control form-control-sm" formControlName="unimproved_land_value" >
                <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Unimproved Value')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                </div>
                <div *ngIf="submitted && propertyDetailsFormControls.unimproved_land_value.errors" class="invalid-tooltip">This field is required.</div>
              </div>

              <span>Unimproved Land Value</span>
            </label>

            <ng-container formArrayName="name_on_titles_data">
              <div class="row" *ngFor="let item of nameOnTitleControlle.controls; let key = index" [formGroupName]='key'>
                <div class="col-md-7">
                  <label class="form-group has-float-label ">

                    <ng-select [items]="ownerList" class="w-100"
                               [addTag]="addOwner" [addTagText]="'Hit Enter to Add Item'"
                               aria-describedby="inputGroup-sizing-sm" [hideSelected]="true"
                               [multiple]="false" bindLabel="name_on_title" bindValue="name_on_title"
                               (keyup)="searchOwner($event);"
                               (clear)="clearOwner()"
                               (blur)="setNameOnTitleShare()"
                               formControlName="name_on_title"
                               (change)="setNameOnTitleShare()">
                    </ng-select>
                    <span> Owner\Trusts <span *ngIf="nameOnTitleControlle.controls.length > 1">{{key+1}}</span></span>

                  </label>
                </div>
                <div class="col-md-5">
                  <label class="form-group has-float-label ">
                    <div class="input-group input-group-sm">
                      <input type="number" class="form-control form-control-sm" [maxLength]="2"
                             formControlName="share"
                             (keyup)="validateInterestRate($event.target.value, key)">
                      <div *ngIf="nameOnTitleControlle.controls[key].get('error').value != null" class="invalid-tooltip">
                        {{nameOnTitleControlle.controls[key].get('error').value}}</div>
                      <div class="input-group-append" *ngIf="nameOnTitleControlle.controls.length -1 == key">
                        <button class="btn btn-primary default " style="border-radius: unset!important;" type="button" (click)="addNameOnTitleRow()"><i
                          class=" fa fa-plus  text-white"></i></button>
                      </div>
                      <div class="input-group-append"
                           *ngIf="nameOnTitleControlle.controls.length -1 == key && nameOnTitleControlle.controls.length !=1">
                        <button class="btn btn-danger default " type="button" style="border-radius: unset!important;"
                                (click)="removeNameOnTitle(key)"><i class=" glyph-icon simple-icon-trash  text-white"></i></button>
                      </div>

                      <div class="input-group-append" *ngIf="nameOnTitleControlle.controls.length -1 != key">
                        <button class="btn btn-danger default " style="border-radius: unset!important;" type="button"
                                (click)="removeNameOnTitle(key)"><i class=" glyph-icon simple-icon-trash  text-white"></i></button>
                      </div>
                    </div>
                    <span>Share %</span>
                  </label>
                </div>
              </div>
            </ng-container>
            <ng-container formArrayName="volume_or_folio_data">
              <div class="row " *ngFor="let item of volumeOrFoliosControlle.controls; let key = index" [formGroupName]='key'>
                <div class="col-md-12"  *ngIf="volumeOrFoliosControlle.controls.length == 1">
                  <label class="form-group has-float-label has-right-icon-append">
                    <div class="input-group ">
                      <input type="text" class="form-control form-control-sm border-right-0" formControlName="volume_or_folio" >
                      <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Volume/Folio Number')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                        <button class="btn btn-primary default " style="border-radius: unset!important;padding-left: 8px;padding-right: 8px;" type="button" (click)="addVolumeOfFolioRow()"><i
                          class=" fa fa-plus  text-white"></i></button>
                      </div>
                    </div>
                    <span>Volume / Folio #<span></span></span>
                  </label>
                </div>
                <div class="col-md-12"  *ngIf="volumeOrFoliosControlle.controls.length > 1">
                  <label class="form-group has-float-label has-right-icon-append">
                    <div class="input-group">
                      <input type="text" class="form-control form-control-sm "
                             formControlName="volume_or_folio" >
                      <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Volume/Folio Number')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                      </div>
                      <div *ngIf="volumeOrFoliosControlle.controls[key].get('error').value != null" class="invalid-tooltip">
                        {{volumeOrFoliosControlle.controls[key].get('error').value}}</div>
                      <div class="input-group-append" *ngIf="volumeOrFoliosControlle.controls.length -1 == key">
                        <button class="btn btn-primary default btn-sm" style="border-radius: unset!important;" type="button" (click)="addVolumeOfFolioRow()"><i
                          class=" fa fa-plus  text-white"></i></button>
                      </div>
                      <div class="input-group-append"
                           *ngIf="volumeOrFoliosControlle.controls.length -1 == key && volumeOrFoliosControlle.controls.length !=1">
                        <button class="btn btn-danger default btn-sm" type="button" style="border-radius: unset!important;"
                                (click)="removeVolumeOfFolio(key)"><i class=" glyph-icon simple-icon-trash  text-white"></i></button>
                      </div>

                      <div class="input-group-append" *ngIf="volumeOrFoliosControlle.controls.length -1 != key">
                        <button class="btn btn-danger default btn-sm" style="border-radius: unset!important;" type="button"
                                (click)="removeVolumeOfFolio(key)"><i class=" glyph-icon simple-icon-trash  text-white"></i></button>
                      </div>

                    </div>
                    <span>Volume / Folio # {{key+1}}</span>
                  </label>
                </div>
              </div>
            </ng-container>

          </div>
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
            <h5 class="font-weight-bold mb-3">About the Property</h5>
            <label class="form-group has-float-label" [hidden]="propertyDetailsForm.get('bedrooms').value == 'Land Bank'">
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm" formControlName="bedrooms">
              </div>
              <span># Bedrooms</span>
            </label>

            <label class="form-group has-float-label" [hidden]="propertyDetailsForm.get('bedrooms').value == 'Land Bank'">
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm" formControlName="bathrooms">
              </div>
              <span># Bathrooms</span>
            </label>

            <label class="form-group has-float-label" >
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm" formControlName="car_spaces">
              </div>
              <span># Car Spaces</span>
            </label>

            <label class="form-group has-float-label" >
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm" formControlName="living_spaces">
              </div>
              <span># Living Spaces</span>
            </label>

            <label class="form-group has-float-label" *ngIf="propertyDetailsForm.get('bedrooms').value != 'Apartment'">
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm" formControlName="land_size">
              </div>
              <span>Land Size (M2)</span>
            </label>

            <label class="form-group has-float-label" >
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm" formControlName="building_size">
              </div>
              <span>Building Size (M2)</span>
            </label>

            <label class="form-group has-float-label" >
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm" formControlName="distance_to_city">
              </div>
              <span>Distance to City (KM)</span>
            </label>

            <label class="form-group has-float-label has-right-icon-append">
              <div class="input-group ">
                <input type="number" class="form-control form-control-sm border-right-0" formControlName="year_built" >
                <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Year Built')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                </div>
              </div>
              <span>Year Built</span>
            </label>

            <label class="form-group has-float-label has-right-icon-append">
              <div class="input-group property-edit-material-select-box">
                <ng-select [items]="buildingMaterials" class="w-91-5"  [addTag]="addBuildingMaterial"
                           [addTagText]="'Hit Enter to Add Item'" aria-describedby="inputGroup-sizing-sm" [typeahead]="false"
                           formControlName="building_material" [hideSelected]="true" [multiple]="false" bindLabel="building_material"
                           bindValue="building_material"
                >
                </ng-select>
                <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Year Built')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                </div>
              </div>
              <span>Building Material <a href="javascript:;" (click)="openBuildingMaterialModel()"><i class="fa fa-pencil text-primary ml-1"></i></a> </span>
            </label>

            <label class="form-group has-float-label has-right-icon-append">
              <div class="input-group property-edit-material-select-box">
                <ng-select [items]="roofMaterials" class="w-91-5"  [addTag]="addRoofMaterial"
                           [addTagText]="" aria-describedby="inputGroup-sizing-sm" [typeahead]="false"
                           formControlName="roof_material" [hideSelected]="true" [multiple]="false" bindLabel="roof_material"
                           bindValue="roof_material">
                </ng-select>
                <div class="input-group-prepend ">
                  <span class="input-group-text input-group-text-right-icon custom-input-group-append-icon"><a href="javascript:void(0)" (click)="openMyModal('Roof Material')">
                    <i class="glyph-icon simple-icon-question f-20 font-weight-bold" ></i></a></span>
                </div>
              </div>
              <span>Roof Material <a href="javascript:;" (click)="openRoofMaterialModel()"><i class="fa fa-pencil text-primary ml-1"></i></a> </span>
            </label>

            <label class="form-group has-float-label has-right-icon-append">
              <div class="input-group ">
                <input type="text" class="form-control form-control-sm" formControlName="local_council_name" >
              </div>
              <span>Local Council</span>
            </label>

            <label class="form-group has-float-label text-area">
              <div class="input-group ">
                <textarea class="form-control textarea" rows="5" formControlName="other_features"  style="max-height: unset !important;padding-top: 11px;"></textarea>
              </div>
              <span>Other Features</span>
            </label>

          </div>
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12">
            <h5 class="font-weight-bold mb-3">Property Images</h5>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                <div class="view-property-image cursor-pointer">
                  <div class="custom-dropzone overflow-unset custom-drop" ngx-dropzone [accept]="'image/*'" multiple="false"
                       (change)="saveHeaderImage($event)" style="overflow: unset; border: 2px dashed #d9d9d9;">
                    <ngx-dropzone-label >
                      <div *ngIf="headerImageSrc != ''" class="drop-zone-folder">
                        <img [src]="headerImageSrc" style="width: 100%;
                                                background-size: cover;max-height: 180px">
                        <div class="text-small" style="position: absolute;
                                                top: 59%;
                                                left: 54%;
                                                transform: translate(-63%, -51%);
                                                font-size: 16px;
                                                color: white;">
                          <p>Update Header Image</p>
                        </div>
                      </div>
                      <div *ngIf="headerImageSrc == ''" class="text-small" style="position: absolute;
                             top: 59%;
                             left: 54%;
                            transform: translate(-63%, -51%);
                            color: black;
                            font-size: 16px;
                            font-weight:500 ">  <p>Update Header Image</p>
                      </div>
                    </ngx-dropzone-label>
                  </div>
                </div>
              </div>

              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                <div class="view-property-image cursor-pointer">
                  <div class="custom-dropzone overflow-unset custom-drop" ngx-dropzone [accept]="'image/*'"
                       (change)="saveOtherImage($event)" style=" border: 2px dashed #d9d9d9;">
                    <ngx-dropzone-label>
                      <div class="drop-zone-folder">
                        <div class="text-small" style="position: absolute;
                            top: 59%;
                            left: 54%;
                            transform: translate(-63%, -51%);
                            color: black;
                            font-weight:500 ;
                            font-size: 16px"><p>Add New Photos</p></div>
                      </div>
                    </ngx-dropzone-label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="row property-detail-image pl-3">
                <ng-container *ngFor="let item of tempImages; index as i" >
                  <ng-container *ngIf="i <= 10 ">
                    <div class=" mt-2 mr-2" *ngIf="(item.type != 'header' && item.is_delete == false)" style="display: inline-block !important;" >
                      <div class="d-flex img-box position-relative" *ngIf="item.src != 'loading'">
                        <img src="{{item.src}}" class="list-thumbnail border-0 img-responsive pointer cursor-pointer" style=" height: 95px !important;" alt="Smiley face"   (click)="processImageClick(item,i)"/>
                        <span class="badge badge-pill position-absolute badge-top-right image badge-danger border-right-radius-0 cursor-pointer"  (click)="deleteImage(item,i)"><i class="glyph-icon simple-icon-trash" ></i> </span>
                        <span  class="badge badge-pill position-absolute badge-top-left badge-info pointer  border-left-radius-0">{{item.title == 'Cover Pic'? 'header' :item.title }}</span>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="(!imageLoading && showingImageCount > 12)">
                  <div class=" mt-2 mr-2"  style="display: inline-block !important;" >
                    <div class="d-flex img-box position-relative border show-more-div" style="display: inline-block !important;" (click)="vewImages()">
                      <span class="centered cursor-pointer font-weight-bold">+ {{showingImageCount - 12}}</span>
                      <img src="{{tempImages[(tempImages.length)-1].src}}" class="list-thumbnail border-0 img-responsive pointer show-more"  alt="Smiley face"   />
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="(!imageLoading && showingImageCount == 12)">
                  <div class=" mt-2 mr-2" *ngIf="(tempImages[(tempImages.length)-1].type != 'header' && tempImages[(tempImages.length)-1].is_delete == false)" style="display: inline-block !important;" >
                    <div class="d-flex img-box position-relative" *ngIf="tempImages[(tempImages.length)-1].src != 'loading'">
                      <img src="{{tempImages[(tempImages.length)-1].src}}" class="list-thumbnail border-0 img-responsive pointer cursor-pointer" style=" height: 95px !important;" alt="Smiley face"   (click)="processImageClick(tempImages[(tempImages.length)-1],(tempImages.length)-1)"/>
                      <span class="badge badge-pill position-absolute badge-top-right image badge-danger border-right-radius-0 cursor-pointer"  (click)="deleteImage(tempImages[(tempImages.length)-1],11)"><i class="glyph-icon simple-icon-trash" ></i> </span>
                      <span  class="badge badge-pill position-absolute badge-top-left badge-info pointer  border-left-radius-0">{{tempImages[(tempImages.length)-1].title == 'blueprint'? 'plan' :tempImages[(tempImages.length)-1].title }}</span>
                    </div>
                  </div>
                  <!--                  <div class=" mt-2 mr-2"  style="display: inline-block !important;" >-->
                  <!--                    <div class="d-flex img-box position-relative border show-more-div" style="display: inline-block !important;" (click)="vewImages()">-->
                  <!--                      <span class="centered cursor-pointer">+ {{showingImageCount - 8}}</span>-->
                  <!--                      <img src="{{tempImages[(tempImages.length)-1].src}}" class="list-thumbnail border-0 img-responsive pointer show-more"  alt="Smiley face"   />-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </ng-container>
                <div class="mt-2 mr-3" *ngIf="imageLoading" style="display: inline-block !important;" >
                  <div class="d-flex img-box position-relative">
                    <div class="loadingio-spinner-spin-767img2n58c"><div class="ldio-nm1rz3j5ew" style="margin-left: 19px; margin-top: 20px;">
                      <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
                    </div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div class="col-md-12 pull-right">
          <div class="row pull-right" >

            <button type="button"  class="btn btn-outline-secondary pull-left btn-xs mr-3" (click)="close()">Close</button>
            <app-state-button btnClass="btn btn-primary btn-xs custom-btn-sec pull-right" [currentState]="responseWaiting" message="false"
                              (click)="updatePropertyDetails()">Save</app-state-button>
        </div>
      </div>
    </div>
  </div>
</div>
