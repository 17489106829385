<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>

<div class="col-md-12 p-0">
  <table class="table table-striped">
    <thead>
    <tr>
      <th>Tutorial Name</th>
      <th>Module</th>
      <th># Views</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of articles;let key=index">
      <td > <a href="javascript:void(0)"  class="text-primary" (click)="alertWindowOpen(item)"> {{item.content_title}}</a></td>
      <td > {{item.form_name}}</td>
      <td >  <span class="badge badge-pill badge-primary" style="min-width: 75px;"><i class="fa fa-eye pr-2"
                                                                                   title="{{ item.views_count }} views"></i>{{ item.views_count }}</span></td>
    </tr>
    </tbody>
  </table>
</div>
