import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import Swal from "sweetalert2";
import {SupplierGlobalService} from "../../../supplier-global.service";
import {GlobalService} from "../../../global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {TranslateService} from "@ngx-translate/core";
import {PlaceSuggestService} from "../../../place-suggest.service";


import {Address} from "ngx-google-places-autocomplete/objects/address";
import {GooglePlaceDirective} from "ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";

@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.scss']
})
export class SupplierEditComponent implements OnInit {
  responseWaiting =  '';
  buttonDisabled = false;
  saveError = false;
  supplierDetailsForm:FormGroup;
  property_ref = null;
  supplierTypes = [];
  supplierTypeKey = null;
  hiddenModal = false;
  tempSupplierType = [];
  updateError = false;
  isSupplierTypeModelOpen = false;
  submitted = false;
  options: any = {
    types: [],
  };
  comeFrom = null;
  key = null;
  stars: number[] = [1, 2, 3, 4, 5];
  isRated = false;
  isMultiSelect = false;
  locations:any = [];
  supplier_type_backup:any = [];
  isCustomizeSupplierType = false;
  @ViewChild('editSupplierModel') editSupplierModel: ModalDirective;
  @ViewChild('supplierTypeModel') supplierTypeModel: ModalDirective;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  constructor(private supplierGlobalService:SupplierGlobalService,
              private globalService: GlobalService,
              private spinner: NgxSpinnerService,
              private notifications: NotificationsService,
              private fb: FormBuilder,
              private modalService: BsModalService,
              private translate: TranslateService,
              private placeSuggestService: PlaceSuggestService) {
    this. initSupplierDetailsForm();
    this.supplierGlobalService.openSupplierEditModelEvent.subscribe(data => {
      this.comeFrom = data['comeFrom'];
      this.property_ref = data['property_ref'];
      this.openSupplierEditModel(data);
    });

  }

  initSupplierDetailsForm(){
    this.supplierDetailsForm = this.fb.group({
      supplier_type_id: [null],
      user_supplier_ref: null,
      company_name: [null,[Validators.required]],
      company_address: null,
      company_phone: null,
      web_address: null,
      abn: null,
      main_contact_name: null,
      contact_phone: null,
      contact_email: null,
      contact_2_name: null,
      contact_2_phone: null,
      contact_2_email: null,
      street_number: null,
      street: null,
      city: null,
      state: [null],
      zip: null,
      country: null,
      formatted_address: null,
      lan: null,
      lot: null,
      place_id: null,
      subpremise: null,
      is_black_list: false,
      rating_comment: null,
      total_expenses : null,
      account_number : null,
      supplier_type:[null,[Validators.required]],
      rate : 0
    });
  }

  get supplierDetailsFormControls() {
    return this.supplierDetailsForm.controls;
  }

  ngOnInit(): void {
  }

  openSupplierTypeModel() {
    this.supplierTypeKey = null;
    this.tempSupplierType = [];
    for (let a = 0; a < this.supplierTypes.length; a++) {
      if (this.supplierTypes[a]['user_id'] != null) {
        this.tempSupplierType.push(this.supplierTypes[a]);
      }
    }
    this.saveError = false;
    this.updateError = false;
    this.isSupplierTypeModelOpen = false;
    this.hiddenModal = true
    this.supplierTypeModel.show();
  }

  handleAddressChange(address: Address) {
    let address_data = this.placeSuggestService.placeToAddress(address);

    this.supplierDetailsForm.patchValue({
      company_address: address_data.formatted_address
    });
    this.supplierDetailsForm.patchValue({formatted_address: address_data.formatted_address});
    this.supplierDetailsForm.patchValue({street_number: address_data.street_number});
    this.supplierDetailsForm.patchValue({street: address_data.street});
    this.supplierDetailsForm.patchValue({city: address_data.city});
    // this.supplierDetailsForm.patchValue({state: address_data.state});
    this.supplierDetailsForm.patchValue({country: address_data.country});
    this.supplierDetailsForm.patchValue({zip: address_data.zip});
    this.supplierDetailsForm.patchValue({lan: address_data.lan});
    this.supplierDetailsForm.patchValue({lot: address_data.lot});
    this.supplierDetailsForm.patchValue({place_id: address_data.place_id});
    this.supplierDetailsForm.patchValue({subpremise: address_data.subpremise});

    if((this.supplierDetailsForm.value['state'] == null) || (this.supplierDetailsForm.value['state'].length == 0)) {
      this.isMultiSelect = true;
      let state_array = [];
      state_array.push(address_data.state);
      this.supplierDetailsForm.patchValue({state: state_array});
    }

  }

  openInWeb() {
    window.open(this.supplierDetailsForm.value['web_address']);
  }

  saveSupplierDetails() {
    this.submitted = true;
    if (!this.supplierDetailsForm.valid) {
      return;
    }

    // if(this.isRated){
    //   if (this.supplierDetailsForm.value['rating_comment'] == null){
    //     return;
    //   }
    // }

    this.responseWaiting = 'show-spinner';
    this.supplierDetailsForm.get('supplier_type_id').setValue(null);

    let data = {
      'form_data':this.supplierDetailsForm.value,
      'property_ref':this.property_ref,
      'comeFrom':this.comeFrom,
    }
    this.spinner.show();
    this.globalService.editSupplierDetails(data).subscribe(res => {
        this.responseWaiting = '';
        this.spinner.hide();
        if(res['is_duplicate']!= true){
          this.editSupplierModel.hide();
          let sup_data = {
            'supplier' : res['supplier_details'],
            'comeFrom':this.comeFrom,
          }
          this.supplierGlobalService.selectSupplier(sup_data);
          this.onSuccess('Saved Successfully');
        }
        else{
          this.onError('Duplicate Supplier');
        }

        this.supplierGlobalService.getSupplierData(true);

      },
      error => {
        this.spinner.hide();
        this.onError(error);
      });

  }

  addSupplierType(supplierTypeForm: NgForm) {
    if(supplierTypeForm.value['supplier_type'] == null || supplierTypeForm.value['supplier_type'] =='')
      return;

    let data = {
      'supplier_type':supplierTypeForm.value['supplier_type'],
      'supplier_type_id':"",
      'property_ref':this.property_ref,
    }
    this.spinner.show();
    this.globalService.saveSupplierType(data).subscribe(res => {
      let materialValue = supplierTypeForm.value['supplier_type'];
      this.spinner.hide();
      if (res['status'] == 200) {
        this.onSuccess('Supplier type add successful');
        this.supplierTypes = res['supplier_types'];
        Swal.fire({
          title: 'Are you sure?',
          text: "you want to change current supplier type to '"+ supplierTypeForm.value['supplier_type']+"'",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
        }).then((isConfirm) => {
          if (isConfirm.value) {
            this.supplierDetailsForm.patchValue({supplier_type:supplierTypeForm.value['supplier_type']});
            this.supplierTypeModel .hide();
            this.hiddenModal = false;
          }
        }, function (dismiss) { });

        supplierTypeForm.reset();
        this.supplierTypeKey = null;
        this.tempSupplierType = [];
        for (let a = 0; a < this.supplierTypes.length; a++) {
          if (this.supplierTypes[a]['user_id'] != null) {
            this.tempSupplierType.push(this.supplierTypes[a]);
          }
        }
      }
      else {
        this.saveError = true;
      }
    }, err => {
      this.spinner.hide();
      this.onError(err);
    });
  }

  editSupplierModelClose(supplierData) {
    // if(this.comeFrom == 'lookup'){
    //   this.supplierGlobalService.newSupplierModelClose(true);
    // }

    // if(this.isDirect){
    let supplier = null;
    if(supplierData!=null){
      supplier = supplierData;
    }

    let data = {
      'supplier': supplier,
      'comeFrom': this.comeFrom,
    }
    if(this.key != null){
      data['key'] = this.key;
    }

    this.supplierGlobalService.selectSupplier(data);
    this.supplierGlobalService.customizeSupplier(false);
    // }

    this.editSupplierModel.hide()
  }

  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  selectSupplierType(item: any) {
    this.isCustomizeSupplierType = false;
    this.supplierDetailsForm.patchValue({supplier_type:item['supplier_type']});
    this.supplierTypeModel.hide();
    this.supplierTypeKey = null;
    this.tempSupplierType = [];
    this.saveError = false;
    this.updateError = false;
    this.isSupplierTypeModelOpen = false;
    this.hiddenModal = false;
  }

  updateSupplierType(item: any, form: NgForm) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to save this Supplier Type changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((isConfirm) => {

      if (isConfirm.value) {
        let data = {
          'supplier_type':form.value['supplier_type'],
          'supplier_type_id':item['supplier_type_id'],
          'property_ref':this.property_ref,
        }
        this.spinner.show();
        this.globalService.saveSupplierType(data).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Supplier Type Edit successful');
            this.supplierTypes = res['supplier_types'];
            // if(this.supplierDetailsForm.value['supplier_type_id'] == data['supplier_type_id'])
            //   this.selectedSupplier['supplier_type'] = supplierTypeForm.value['supplier_type'];
            this.supplierTypeKey = null;
            this.tempSupplierType = [];
            for (let a = 0; a < this.supplierTypes.length; a++) {
              if (this.supplierTypes[a]['user_id'] != null) {
                this.tempSupplierType.push(this.supplierTypes[a]);
              }
            }
          }
          else {
            this.updateError = true;
          }
        }, err => {
          this.spinner.hide();
          this.onError(err);
        });
      }
    }, function (desmiss) { });
  }

  supplierTypeCheck(supplier_type_id: any) {
    this.spinner.show();
    this.globalService.checkSupplierType(supplier_type_id, this.property_ref).subscribe(res => {
      this.spinner.hide();
      if (res['status'] == 200) {
        if(res['validate']){
          this.deleteSupplierType(supplier_type_id);
        }
        else{
          Swal.fire({
            title: 'You can not delete this supplier type?',
            text: 'This supplier type used in another component!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }

      }
    }, err => {
      this.spinner.hide();
      this.onError(err);
    });
  }

  private deleteSupplierType(supplier_type_id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete this supplier type !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.globalService.deleteSupplierType(supplier_type_id, this.property_ref).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Supplier Type Delete successful');
            this.supplierTypes = res['supplier_types'];
            // if(this.selectedSupplier['supplier_type_id'] == supplier_type_id)
            //   this.selectedSupplier['supplier_type'] = null;
            this.supplierTypeKey = null;
            this.tempSupplierType = [];
            for (let a = 0; a < this.supplierTypes.length; a++) {
              if (this.supplierTypes[a]['user_id'] != null) {
                this.tempSupplierType.push(this.supplierTypes[a]);
              }
            }
          }
        }, err => {
          this.spinner.hide();
          this.onError(err);
        });
      }
    }, function (desmiss) { });
  }

  closeSupplierTypeModel(supplierTypeModel: ModalDirective) {
    this.supplierTypeModel.hide();
    this.supplierTypeKey = null;
    this.tempSupplierType = [];
    this.saveError = false;
    this.updateError = false;
    this.isSupplierTypeModelOpen = false;
    this.hiddenModal = false;
  }

  private openSupplierEditModel(data) {
    this.spinner.show();
    this.globalService.getSuppliersDetailsByRef(data['supplier_ref'], this.property_ref).subscribe(res => {
        this.spinner.hide();
        this.supplierDetailsForm.patchValue(res['supplier_details']);
        this.supplierDetailsForm.patchValue({
            supplier_type : res['supplier_details']['supplier_types'],
          })
      console.log(this.supplierDetailsForm.value);
        this.supplierTypes = res['supplier_types'];
        this.supplier_type_backup = res['supplier_types'];
        this.locations = res['locations'];
        this.isMultiSelect = res['is_multi_select'];

        this.editSupplierModel.show();
      },
      err => {
        this.spinner.hide();
        this.onError(err);
      });
  }

  countStar(star: number) {
    this.isRated = true;
    this.supplierDetailsForm.patchValue({rate:star});
  }

  selectLocationFromMultiSelect(value) {


    if(value[value.length-1] == 'National') {
      this.supplierDetailsForm.patchValue({state:null});
      this.supplierDetailsForm.patchValue({state:'National'});
      this.isMultiSelect = false;
    }
    else {
      this.isMultiSelect = true;
    }
  }

  selectLocationFromSelectBox(value) {
    if(value!= 'National') {
      this.isMultiSelect = true;
      this.supplierDetailsForm.patchValue({state:[value]});
    }
    else {
      this.isMultiSelect = false;
    }
  }

  pushSupplierType(addedName) {
    return { supplier_type: addedName, supplier_type_id: "",user_id: "" };
  }

  itemSelect(event) {
    // if (event != undefined) {
    //   if (event['supplier_type_id'] == "") {
    //     Swal.fire({
    //       title: 'Are you sure?',
    //       text: 'you want to add this Supplier Type to Database?',
    //       icon: 'question',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Yes',
    //       cancelButtonText: 'No',
    //     }).then((isConfirm) => {
    //       if (isConfirm.value) {
    //         // this.new_supplier = event['supplier_type'];
    //         this.isCustomizeSupplierType = true;
    //         this.openSupplierTypeModel();
    //       }
    //       else {
    //         this.supplierDetailsForm.patchValue({supplier_type_id:null});
    //       }
    //     }, function (desmiss) {
    //     });
    //   }
    // }
  }

  clearSupplierType() {
    this.isCustomizeSupplierType = false;
    this.supplierTypes = [...this.supplier_type_backup];
  }

}
