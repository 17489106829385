import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SupplierGlobalService} from "../../../supplier-global.service";
import {BsModalService, ModalDirective} from "ngx-bootstrap/modal";
import {FormBuilder, FormGroup, NgForm, Validators} from "@angular/forms";
import {GlobalService} from "../../../global.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService, NotificationType} from "angular2-notifications";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {PlaceSuggestService} from "../../../place-suggest.service";
import {Subscription} from "rxjs";
import {Address} from "ngx-google-places-autocomplete/objects/address";
import {GooglePlaceDirective} from "ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";

@Component({
  selector: 'app-supplier-create',
  templateUrl: './supplier-create.component.html',
  styleUrls: ['./supplier-create.component.scss']
})
export class SupplierCreateComponent implements OnInit,OnDestroy {
  comeFrom = '';
  hiddenModal = false;
  supplierDetailsForm:FormGroup;
  supplierTypes:any = [];
  submitted = false;
  supplierTypeKey = null;
  tempSupplierType = [];
  saveError = false;
  updateError = false;
  isSupplierTypeModelOpen = false;
  property_ref = null;
  responseWaiting = '';
  buttonDisabled = false;
  options: any = {
    types: [],
    componentRestrictions:{
      country:["AU"]
    }
  };
  key: any = null;
  isDirect = false;
  isDuplicateCompanyName = false;
  needToMerge = false;
  mergeSupplier = [];
  duplicateSupplierRef = null;
  duplicateCompanyName = null;
  duplicateSupplier = [];
  locations = null;
  isMultiSelect = true;
  new_supplier: any = null;
  supplier_type_backup:any = [];
  isCustomizeSupplierType = false;
  supplier_list:any = [];
  supplier_search = false;
  subsVar: Subscription;
  @ViewChild('newSupplierModel') newSupplierModel: ModalDirective;
  @ViewChild('supplierTypeModel') supplierTypeModel: ModalDirective;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild("placesRefs") placesRefs : GooglePlaceDirective;
  @ViewChild('cName') company_name: ElementRef;

  constructor(private supplierGlobalService:SupplierGlobalService,
              private globalService: GlobalService,
              private spinner: NgxSpinnerService,
              private notifications: NotificationsService,
              private fb: FormBuilder,
              private modalService: BsModalService,
              private translate: TranslateService,
              private placeSuggestService: PlaceSuggestService,
              private elementRef: ElementRef
  ) {
    this.initSupplierDetailsForm();
    this.supplierGlobalService.openAddNewSupplierModelEvent.subscribe(data => {
      this.isDirect = false;
      if('key' in data){
        this.key = data['key'];
      }
      else {
        this.key = null;
      }

      this.supplier_search = false;
      this.submitted = false
      this.comeFrom = data['comeFrom'];
      this.property_ref = data['property_ref'];
      this.isDuplicateCompanyName = false;
      this.needToMerge = false;
      this.duplicateCompanyName = null;
      this.duplicateSupplierRef = null;
      this.mergeSupplier = [];
      this.getNewSupplierFormData(data);

    });

    this.subsVar = this.supplierGlobalService.newSupplierModelByDirectEvent.subscribe(data => {
      this.submitted = false;
      this.comeFrom = data['comeFrom'];
      this.isDirect = true;
      if('key' in data){
        this.key = data['key'];
      }
      else {
        this.key = null;
      }

      this.supplierDetailsForm.reset();
      this.property_ref = data['property_ref'];
      this.isDuplicateCompanyName = false;
      this.needToMerge = false;
      this.duplicateCompanyName = null;
      this.duplicateSupplierRef = null;
      this.mergeSupplier = [];
      this.supplier_search = false;
      this.getSupplierTypes(data);
      this.supplierGlobalService.customizeSupplier(true);
    });

    this.supplierGlobalService.closeSupplierViewModelEvent.subscribe(data => {
      if(data['duplicate_view'] == true && data['supplier'] == null){
        this.newSupplierModel.show();
      }
    });
  }


  initSupplierDetailsForm(){
    this.supplierDetailsForm = this.fb.group({
      supplier_type: [null,[Validators.required]],
      supplier_type_id: [null],
      company_name: [null,[Validators.required]],
      company_address: null,
      company_phone: null,
      web_address: null,
      // abn: null,
      street_number: null,
      street: null,
      city: null,
      state: [null],
      zip: null,
      country: null,
      formatted_address: null,
      lan: null,
      lot: null,
      place_id: null,
      subpremise: null,
    });
  }

  get supplierDetailsFormControls() {
    return this.supplierDetailsForm.controls;
  }

  ngOnInit(): void {
  }
ngOnDestroy() {
    if (this.subsVar){
      this.subsVar.unsubscribe();
    }

}

  // ngAfterViewInit() {
  //   const inputElement = this.elementRef.nativeElement.querySelector('.ngx-google-places-autocomplete-input');
  //   if (inputElement) {
  //     inputElement.focus();
  //   }
  // }
  getNewSupplierFormData(data){
    console.log('data11111',data);
   try{
     this.supplierDetailsForm.reset();
     this.spinner.show();
     let sData = {
        'property_ref':this.property_ref,
       'supplier_type':data['supplier_type']

     }
     this.globalService.getSupplierCreateFormInitData(sData).subscribe(response => {
           this.supplierTypes = response['supplier_types'];
           this.supplier_type_backup = response['supplier_types'];
           this.locations = response['locations'];

           if(response['state'] != null){

             this.isMultiSelect = true;
             let state_array = [];
             state_array.push(response['state']);
             this.supplierDetailsForm.patchValue({state: state_array});
           }

           if(data['supplier_type'] != null){
             this.supplierDetailsForm.patchValue({supplier_type:data['supplier_type']});
           }

           this.newSupplierModel.show();
           this.supplierGlobalService.customizeSupplier(true);
           this.spinner.hide();
         },
         error => {
           this.spinner.hide();
           this.globalService.onError(error.message);
         });
   }
   catch (e) {
     this.spinner.hide();
     this.globalService.onError(e.message);
   }
  }

  newSupplierModelClose(supplierData) {
    let supplier = null;
    if(supplierData!=null){
      supplier = supplierData;
    }

    let data = {
      'supplier': supplier,
      'comeFrom': this.comeFrom,
      'isDirect': this.isDirect,
    }
    if(this.key != null){
      data['key'] = this.key;
    }

    this.supplierGlobalService.selectSupplier(data);
    this.supplierGlobalService.customizeSupplier(false);
    // }

    this.newSupplierModel.hide()
    this.newSupplierModel.hide()
  }

  closeSupplierTypeModel(supplierTypeModel: ModalDirective) {
    this.supplierTypeModel.hide();
    this.supplierTypeKey = null;
    this.tempSupplierType = [];
    this.saveError = false;
    this.updateError = false;
    this.isSupplierTypeModelOpen = false;
    this.hiddenModal = false;
    if(this.isCustomizeSupplierType){
      this.supplierDetailsForm.patchValue({supplier_type_id:null});
      this.supplierDetailsForm.patchValue({supplier_type:null});
      this.clearSupplierType();
    }
  }

  updateSupplierType(item: any, form: NgForm) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to save this Supplier Type changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((isConfirm) => {

      if (isConfirm.value) {
        let data = {
          'supplier_type':form.value['supplier_type'],
          'supplier_type_id':item['supplier_type_id'],
          'property_ref':this.property_ref,
        }
        this.spinner.show();
        this.globalService.saveSupplierType(data).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Supplier Type Edit successful');
            this.supplierTypes = res['supplier_types'];
            this.supplier_type_backup = res['supplier_types'];
            this.supplierTypeKey = null;
            this.tempSupplierType = [];
            for (let a = 0; a < this.supplierTypes.length; a++) {
              if (this.supplierTypes[a]['user_id'] != null) {
                this.tempSupplierType.push(this.supplierTypes[a]);
              }
            }
          }
          else {
            this.updateError = true;
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }

  supplierTypeCheck(supplier_type_id: any) {
    this.spinner.show();
    this.globalService.checkSupplierType(supplier_type_id, this.property_ref).subscribe(res => {
      this.spinner.hide();
      if (res['status'] == 200) {
        if(res['validate']){
          this.deleteSupplierType(supplier_type_id);
        }
        else{
          Swal.fire({
            title: 'You can not delete this supplier type?',
            text: 'This supplier type used in another component!',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          });
        }

      }
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
    });
  }

  openSupplierTypeModel() {
    this.supplierTypeKey = null;
    // this.tempSupplierType = [...this.supplierTypes]
    this.saveError = false;
    this.updateError = false;
    this.isSupplierTypeModelOpen = false;
    this.hiddenModal = true
    this.tempSupplierType = [];
    for (let a = 0; a < this.supplierTypes.length; a++) {
      if (this.supplierTypes[a]['user_id'] != null) {
        this.tempSupplierType.push(this.supplierTypes[a]);
      }
    }

    this.supplierTypeModel.show();
  }

  addSupplierType(supplierTypeForm: NgForm) {
    if(supplierTypeForm.value['supplier_type'] == null || supplierTypeForm.value['supplier_type'] =='')
      return;

    let data = {
      'supplier_type':supplierTypeForm.value['supplier_type'],
      'supplier_type_id':"",
      'property_ref':this.property_ref,
    }
    this.spinner.show();
    this.globalService.saveSupplierType(data).subscribe(res => {
      let materialValue = supplierTypeForm.value['supplier_type'];
      this.spinner.hide();
      if (res['status'] == 200) {
        this.onSuccess('Supplier type add successful');
        this.supplierTypes = res['supplier_types'];
        this.supplier_type_backup = res['supplier_types'];
        Swal.fire({
          title: 'Are you sure?',
          text: "you want to change current supplier type to '"+ supplierTypeForm.value['supplier_type']+"'",
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change it!'
        }).then((isConfirm) => {
          if (isConfirm.value) {
            this.supplierDetailsForm.patchValue({supplier_type:supplierTypeForm.value['supplier_type']});
            this.supplierTypeModel .hide();
            this.hiddenModal = false;
            this.isCustomizeSupplierType = true;
          }
          else {
            this.supplierDetailsForm.patchValue({supplier_type:null});
          }
        }, function (dismiss) {
        });

        supplierTypeForm.reset();
        this.supplierTypeKey = null;
        this.tempSupplierType = [];
        for (let a = 0; a < this.supplierTypes.length; a++) {
          if (this.supplierTypes[a]['user_id'] != null) {
            this.tempSupplierType.push(this.supplierTypes[a]);
          }
        }
      }
      else {
        this.saveError = true;
      }
    }, err => {
      this.spinner.hide();
      this.onError(err.message);
    });
  }

  onInfo(msg) {
    this.notifications.create(this.translate.instant('alert.info'), msg, NotificationType.Info, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onWarning(msg) {
    this.notifications.create(this.translate.instant('alert.warning'), msg, NotificationType.Warn, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onError(msg) {
    this.notifications.create(this.translate.instant('alert.error'), msg, NotificationType.Error, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  onSuccess(msg) {
    this.notifications.create(this.translate.instant('alert.success'), msg, NotificationType.Success, {
      timeOut: 3000,
      showProgressBar: true
    });
  }

  selectSupplierType(item: any) {
    this.isCustomizeSupplierType = false;
    this.supplierDetailsForm.patchValue({supplier_type:item['supplier_type']});
    this.supplierTypes = [...this.supplier_type_backup];
    this.supplierTypeModel.hide();
    this.supplierTypeKey = null;
    this.tempSupplierType = [];
    this.saveError = false;
    this.updateError = false;
    this.isSupplierTypeModelOpen = false;
    this.hiddenModal = false;
  }

  saveSupplierDetails(isMerge = false) {
    this.spinner.show('verifySupplier');
    this.submitted = true;
    if (!this.supplierDetailsForm.valid) {
      this.spinner.hide('verifySupplier');
      return;
    }


    if(this.isDuplicateCompanyName){
      this.spinner.hide('verifySupplier');
      return;
    }

    this.responseWaiting = 'show-spinner';

    // this.supplierDetailsForm.get('supplier_type_id').setValue(null);

    let data = {
      'form_data':this.supplierDetailsForm.value,
      'property_ref':this.property_ref,
      'comeFrom':this.comeFrom,
      'is_merge':isMerge,
    }

    this.globalService.saveSupplierDetails(data).subscribe(res => {
      this.spinner.hide('verifySupplier');
      this.responseWaiting = '';
      if(res['is_duplicate'] == true) {
        this.responseWaiting = 'show-error';
        this.onError('Duplicate supplier');
      }else {
        // this.supplierGlobalService.newSupplierModelClose(true);
        this.onSuccess('Supplier details saved successfully');
        this.newSupplierModelClose(res['supplier_details']);
        this.supplierGlobalService.customizeSupplier(false);
      }
    },error=>{
      this.spinner.hide('verifySupplier');
      this.responseWaiting = '';
      this.notifications.error('Error', error['error']['message']);
    });
  }

  private deleteSupplierType(supplier_type_id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'you want to delete this supplier type !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.spinner.show();
        this.globalService.deleteSupplierType(supplier_type_id, this.property_ref).subscribe(res => {
          this.spinner.hide();
          if (res['status'] == 200) {
            this.onSuccess('Supplier Type Delete successful');
            this.supplierTypes = res['supplier_types'];
            this.supplier_type_backup = res['supplier_types'];
            // if(this.selectedSupplier['supplier_type_id'] == supplier_type_id)
            //   this.selectedSupplier['supplier_type'] = null;
            this.supplierTypeKey = null;
            this.tempSupplierType = [];
            for (let a = 0; a < this.supplierTypes.length; a++) {
              if (this.supplierTypes[a]['user_id'] != null) {
                this.tempSupplierType.push(this.supplierTypes[a]);
              }
            }
          }
        }, err => {
          this.spinner.hide();
          this.onError(err.message);
        });
      }
    }, function (desmiss) { });
  }

  openInWeb() {
    if(this.supplierDetailsForm.get("web_address").value != null){
      window.open(this.supplierDetailsForm.value['web_address']);
    }

  }

  handleAddressChange(address: Address) {
    let address_data = this.placeSuggestService.placeToAddress(address);
    this.supplierDetailsForm.patchValue({
      company_address: address_data.formatted_address
    });
    this.supplierDetailsForm.patchValue({formatted_address: address_data.formatted_address});
    this.supplierDetailsForm.patchValue({street_number: address_data.street_number});
    this.supplierDetailsForm.patchValue({street: address_data.street});
    this.supplierDetailsForm.patchValue({city: address_data.city});
    this.supplierDetailsForm.patchValue({country: address_data.country});
    this.supplierDetailsForm.patchValue({zip: address_data.zip});
    this.supplierDetailsForm.patchValue({lan: address_data.lan});
    this.supplierDetailsForm.patchValue({lot: address_data.lot});
    this.supplierDetailsForm.patchValue({place_id: address_data.place_id});
    this.supplierDetailsForm.patchValue({subpremise: address_data.subpremise});

    if((this.supplierDetailsForm.value['state'] == null) || (this.supplierDetailsForm.value['state'].length == 0)) {
      this.isMultiSelect = true;
      let state_array = [];
      let data = {
        state: address_data.state,
      }
      state_array.push(address_data.state);
      this.supplierDetailsForm.patchValue({state: state_array});
    }
    else {
      let state_array = this.supplierDetailsForm.value['state'];
      let index = state_array.findIndex(x => x['state'] == address_data.state);
      if(index == -1){
        let data = {
          state: address_data.state,
        }
        state_array.push(address_data.state);
        this.supplierDetailsForm.patchValue({state: state_array});
      }
    }
  }

  private getSupplierTypes(data) {

    console.log("hit me 1",data)
    this.spinner.show();
    let sData = {
      'property_ref':this.property_ref,
      'supplier_type':data['supplier_type']
    }
    this.globalService.getSupplierCreateFormInitData(sData).subscribe(response => {
        this.supplierTypes = response['supplier_types'];
        this.supplier_type_backup = response['supplier_types'];
        this.locations = response['locations'];
        if(data['supplier_type'] != null){
          this.supplierDetailsForm.patchValue({supplier_type:data['supplier_type']});
        }

          // if(response['state'] != null){
          //
          //   this.isMultiSelect = true;
          //   let state_array = [];
          //   state_array.push(response['state']);
          //   this.supplierDetailsForm.patchValue({state: state_array});
          // }

        this.supplierDetailsForm.patchValue({company_name:data['supplier_name']});

        // this.company_name.nativeElement.click();
        this.company_name.nativeElement.focus();

        // this.company_name.nativeElement.dispatchEvent(event);
        // console.log('key up hit')

        this.supplier_list = [{
          business_name: data['supplier_name'],
          showing_name: data['supplier_name'],
          supplier_id: "",
          user_supplier_id: "",
        }];
        this.newSupplierModel.show();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        this.notifications.error('Error', error.message);
      });
  }

  verifyCompanyName(event) {

    setTimeout(() => {
      if(this.supplierDetailsForm.value['company_name'] != null && this.supplierDetailsForm.value['company_name'] != '') {
        this.spinner.show('verifySupplier');
        let data = {
          'company_name':this.supplierDetailsForm.value['company_name'],
          'property_ref':this.property_ref,
          'come_from':this.comeFrom,
        }
        this.globalService.verifySupplierCompanyName(data).subscribe(res => {

          this.isDuplicateCompanyName = res['is_duplicate'];
          this.needToMerge = res['need_to_merge'];
          if(this.isDuplicateCompanyName == true) {
            this.duplicateSupplierRef = res['supplier_ref'];
            this.duplicateCompanyName = res['company_name'];
          }
          if(this.needToMerge == true) {
            this.mergeSupplier = [{
              supplier_ref: res['supplier_ref'],
              company_name: res['company_name']
            }];
            this.supplierDetailsForm.addControl('is_main_table', this.fb.control(null));
            this.supplierDetailsForm.addControl('supplier_ref', this.fb.control(null));
            this.supplierDetailsForm.patchValue({
              supplier_type:res['supplier_details']['supplier_type'],
              supplier_type_id:res['supplier_details']['supplier_type_id'],
              company_address:res['supplier_details']['company_address'],
              company_phone:res['supplier_details']['company_phone'],
              web_address:res['supplier_details']['web_address'],
              is_main_table:res['is_main_table'],
              supplier_ref:res['supplier_ref']
            });
             console.log(this.supplierDetailsForm.value)
          }
          this.duplicateSupplier = res['supplier_details'];
          this.spinner.hide('verifySupplier');
        },error=>{
          this.notifications.error('Error', error.message);
          this.spinner.hide('verifySupplier');
        });
      }
    },1000);

  }

  viewDuplicateSupplierDetails() {
    let data = {
      'comeFrom':this.comeFrom,
      'supplier_ref':this.duplicateSupplierRef,
      'property_ref':this.property_ref,
      'duplicate_view':true,
      'supplier_details':this.duplicateSupplier,
    }

    if(this.key != null) {
      data['key'] = this.key;
    }

    this.newSupplierModel.hide();
    this.supplierGlobalService.openSupplierViewModel(data);
  }

  selectLocationFromMultiSelect(value) {
    if(value[value.length-1] == 'National') {
      this.supplierDetailsForm.patchValue({state:null});
      this.supplierDetailsForm.patchValue({state:'National'});
      this.isMultiSelect = false;
    }
    else {
      this.isMultiSelect = true;
    }
  }

  selectLocationFromSelectBox(value) {
    if(value!= 'National') {
      this.isMultiSelect = true;
      this.supplierDetailsForm.patchValue({state:[value]});
    }
    else {
      this.isMultiSelect = false;
    }
  }

  pushSupplierType(addedName) {
    return { supplier_type: addedName, supplier_type_id: "",user_id: "" };
  }

  itemSelect(event) {
    // if (event != undefined) {
    //   if (event['supplier_type_id'] == "") {
    //     Swal.fire({
    //       title: 'Are you sure?',
    //       text: 'you want to add this Supplier Type to Database?',
    //       icon: 'question',
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       confirmButtonText: 'Yes',
    //       cancelButtonText: 'No',
    //     }).then((isConfirm) => {
    //       if (isConfirm.value) {
    //         this.new_supplier = event['supplier_type'];
    //         this.isCustomizeSupplierType = true;
    //         this.openSupplierTypeModel();
    //       }
    //       else {
    //         this.supplierDetailsForm.patchValue({supplier_type_id:null});
    //       }
    //     }, function (desmiss) {
    //     });
    //   }
    // }
  }

  clearSupplierType() {
    this.isCustomizeSupplierType = false;
    this.supplierTypes = [...this.supplier_type_backup];
  }

  addSupplier(addedName) {
    return { business_name: addedName,showing_name: addedName ,supplier_id: "",user_supplier_id: "" };
  }

  clearSupplier() {

  }

  searchSupplier(event: KeyboardEvent) {
    this.supplier_search = true;
    if (event['srcElement']['value'] != "") {
      this.globalService.getSuppliers(event['srcElement']['value']).subscribe(response => {
        this.supplier_list = response['suppliers'];
      }, err => {
        this.onError(err.message);
      });
    }
  }

  supplierChange(event) {
    if(event != undefined){
      if(this.supplier_search == true && event.user_supplier_id != ""){
        let data = {
          company_name: event.business_name,
          supplier_id: event.supplier_id,
          user_supplier_id: event.user_supplier_id,
        }

        if(this.comeFrom == 'supplier'){
          this.spinner.show();

          let details = {
            'supplier_data':event,
            'property_ref':this.property_ref,
          }
          this.globalService.addSupplierToProperty(details).subscribe(response => {
            this.spinner.hide();
            this.onSuccess('Supplier added successfully');
            this.newSupplierModelClose(event);
            this.supplierGlobalService.customizeSupplier(false);
          },error =>{
            this.spinner.hide();
            this.onError(error);
          });
        }

        // else{

          this.newSupplierModelClose(data);
          this.supplierGlobalService.customizeSupplier(false);
        // }
      }
    }
  }

  companyChange(address: Address) {
    let address_data = this.placeSuggestService.placeToAddress(address);
    this.supplierDetailsForm.patchValue({
      company_address: address_data.formatted_address
    });

    console.log(address_data);
    this.supplierDetailsForm.patchValue({formatted_address: address_data.formatted_address});
    this.supplierDetailsForm.patchValue({street_number: address_data.street_number});
    this.supplierDetailsForm.patchValue({street: address_data.street});
    this.supplierDetailsForm.patchValue({city: address_data.city});
    this.supplierDetailsForm.patchValue({country: address_data.country});
    this.supplierDetailsForm.patchValue({zip: address_data.zip});
    this.supplierDetailsForm.patchValue({lan: address_data.lan});
    this.supplierDetailsForm.patchValue({lot: address_data.lot});
    this.supplierDetailsForm.patchValue({place_id: address_data.place_id});
    this.supplierDetailsForm.patchValue({subpremise: address_data.subpremise});
    this.supplierDetailsForm.patchValue({company_name: address_data.name});
    this.supplierDetailsForm.patchValue({company_phone: address_data.phone_number});
    this.supplierDetailsForm.patchValue({web_address: address_data.website});

    if((this.supplierDetailsForm.value['state'] == null) || (this.supplierDetailsForm.value['state'].length == 0)) {
      this.isMultiSelect = true;
      let state_array = [];
      state_array.push(address_data.state);
      this.supplierDetailsForm.patchValue({state: state_array});
    }
    // if((this.supplierDetailsForm.value['state'] == null) || (this.supplierDetailsForm.value['state'].length == 0)) {
    //   this.isMultiSelect = true;
    //   console.log(address_data.address_array)
    //   if(address_data.address_array != null){
    //     const adminLevel1 = address_data.address_array.find(item =>
    //       item.types.includes("administrative_area_level_1")
    //     );
    //
    //     let state_array = [];
    //     state_array.push(adminLevel1.short_name);
    //     this.supplierDetailsForm.patchValue({state: adminLevel1.short_name});
    //
    //     console.log(adminLevel1.short_name)
    //   }
    // }
  }

  handleInput(event: Event) {
    if (event.target['value'] === '') {
      this.supplierDetailsForm.reset();
      this.needToMerge = false;
      this.isDuplicateCompanyName = false;
    }
  }

  checkTitleCase() {
    setTimeout(() => {
      const companyName = this.supplierDetailsForm.value['company_name'];
      const isNullOrEmpty = companyName != null && companyName != '';
      console.log(companyName)
      console.log(isNullOrEmpty)
      // if((this.supplierDetailsForm.value['company_name'] != null) || (this.supplierDetailsForm.value['company_name']!= '')) {
      if(isNullOrEmpty) {
        this.supplierDetailsForm.patchValue({
          company_name:this.supplierDetailsForm.value['company_name'].replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
        });
      }
    }, 1000);

  }

  triggerCompanyName() {
    this.company_name.nativeElement.focus();
  }

  searchSupplierType(event) {
    if (event['srcElement']['value'] != "") {
      this.globalService.searchSupplierType(event['srcElement']['value']).subscribe(response => {
        this.supplierTypes = response['supplier_types'];
      }, err => {
        this.onError(err.message);
      });
    }
  }
}
