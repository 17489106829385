<simple-notifications></simple-notifications>
<ngx-spinner></ngx-spinner>

<div bsModal #newSupplierModel="bs-modal" class="modal fade {{hiddenModal ? 'hidden' : ''}}" tabindex="-1"
     [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}" role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header pb-1">
        <h4 class="modal-title pull-left ">New Supplier</h4>
        <button type="button" class="close pull-right"
                (click)="newSupplierModelClose(null)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="form" [formGroup]="supplierDetailsForm" novalidate>
      <div class="modal-body">
        <ngx-spinner
          [name]="'verifySupplier'"
          [fullScreen]="false"
          type="ball-scale-multiple"
          size="medium">
          <p style="color: white" >Verify Supplier </p>
        </ngx-spinner>
          <div class="row">
            <div class="col-md-12">
              <label class="form-group has-float-label">
                <div class="input-group">
                    <input class="form-control company_name-w-96" ngx-google-places-autocomplete [options]='options' #placesRefs="ngx-places" (input)="handleInput($event)" placeholder="Enter Business Name"
                           formControlName="company_name" (onAddressChange)="companyChange($event)" [ngClass]="{ 'is-invalid': submitted && supplierDetailsFormControls.company_name.errors }"
                           (blur)="checkTitleCase()" #cName (change)="verifyCompanyName($event)"
                    />



<!--                    <ng-select [items]="supplier_list" class="w-100 explanation-link"-->
<!--                               [addTag]="addSupplier" [addTagText]="'Hit Enter to Add Item'"-->
<!--                               aria-describedby="inputGroup-sizing-sm" [hideSelected]="true"-->
<!--                               [multiple]="false" bindLabel="showing_name" bindValue="business_name"-->
<!--                               (change)="supplierChange($event)" (clear)="clearSupplier()" (keyup)="searchSupplier($event);"-->
<!--                               formControlName="company_name" >-->
<!--                    </ng-select>-->
<!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                           name="company_name" formControlName="company_name" (change)="verifyCompanyName($event)">-->
                    <div class="input-group-append">
                      <button class="btn btn-primary default pr-2" style="border-radius: unset!important;padding-left: 10px;" (click)="triggerCompanyName()"><i class="fa fa-google"></i></button>
                    </div>
                  </div>
                <span>Company Name <span class="text-danger">*</span></span>
                <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>
              </label>
            </div>
            <div class="col-md-12" *ngIf="isDuplicateCompanyName">
              <div class="alert alert-danger glowing-border" role="alert">
                <h5 class="text-danger">Duplicate Company Name</h5>
                <p class="mb-0">
                  The company name <strong>{{duplicateCompanyName}}</strong> is already in use.
                  To view details or take action,
                  <br>
                  <a href="javascript:void(0)" class="text-info" (click)="viewDuplicateSupplierDetails()">
                    View Supplier Details
                  </a>.
                </p>
              </div>
            </div>
            <div class="col-md-12" *ngIf="needToMerge">
              <div class="alert alert-warning glowing-border" role="alert">
                <h5 class="text-danger">Supplier Already Exists</h5>
                <p class="mb-0">
                  The supplier <strong>{{mergeSupplier['company_name']}}</strong> has already been added.
                  If you would like to link this supplier to the current property, please click Merge below.
                </p>
              </div>
            </div>




            <div class="col-md-12">
              <label class="form-group has-float-label">
                <div class="input-group input-group-sm">
                  <!--                  <ng-select appearance="outline" labelForId="supplier_type_id" class="h-25" [searchable]="false" [clearable]="false" formControlName="supplier_type_id" style="width: 95.8%!important;">-->
                  <!--                    <ng-option value="" disabled>All</ng-option>-->
                  <!--                    <ng-option *ngFor="let list of supplierTypes" [value]="list.supplier_type_id">-->
                  <!--                      {{list.supplier_type}}-->
                  <!--                    </ng-option>-->
                  <!--                  </ng-select>-->
                  <ng-select [items]="supplierTypes" class="w-95-8 explanation-link supplier-type"
                             [addTag]="pushSupplierType" [addTagText]="'Hit Enter to Add Item'"
                             aria-describedby="inputGroup-sizing-sm" [hideSelected]="true"
                             [multiple]="true" bindLabel="supplier_type" bindValue="supplier_type"
                             (change)="itemSelect($event)" (clear)="clearSupplierType()"
                             formControlName="supplier_type" (keyup)="searchSupplierType($event);"
                  [readonly]="needToMerge">
                  </ng-select>
                  <div class="input-group-append">
                    <button class="btn btn-primary default pl-2 pr-2" style="border-radius: unset!important;" [disabled]="needToMerge" (click)="openSupplierTypeModel()"><i class="fa fa-binoculars"></i></button>
                  </div>
                  <div *ngIf="submitted && supplierDetailsFormControls.supplier_type.errors" class="invalid-tooltip">This field is required.</div>
                </div>
                <span>Type <span class="text-danger">*</span></span>
              </label>
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-right">
                  <div class="input-group">
                    <input class="form-control" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places"
                           formControlName="company_address" (onAddressChange)="handleAddressChange($event)" [ngClass]="{ 'is-invalid': submitted && supplierDetailsFormControls.company_address.errors }"/>
                    <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
                    <!--                           name="company_address" formControlName="company_address" >-->
                  </div>
                  <!--                    <div class="form-control-position">-->
                  <!--                  <a href="{{supplierDetailsFormControls.company_address.value}}" target="_blank"> <i class="fa fa-map-marker text-info" style="font-size: 25px;margin-top: 3px;"></i></a>-->
                  <!--                  </div>-->

                </div>
                <span>Company Address </span>
                <!--                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>-->
              </label>

              <!--  this field hide as per client requirement -->
               <label class="form-group has-float-label" *ngIf="isMultiSelect" hidden>
                 <div class="input-group">
                   <ng-select appearance="outline" labelForId="state" class="h-25 w-100" [multiple]="isMultiSelect" [searchable]="false" [clearable]="false" formControlName="state" (change)="selectLocationFromMultiSelect($event)">
<!--                     <ng-option value="National">National</ng-option>-->
                     <ng-option *ngFor="let list of locations" [value]="list.state">
                       {{list.state}}
                     </ng-option>
                   </ng-select>
                 </div>
                 <span>Location <span class="text-danger">*</span></span>
                 <div *ngIf="submitted && supplierDetailsFormControls.state.errors" class="invalid-tooltip">This field is required.</div>
               </label>

              <!-- // this field hide as per client requirement //-->
               <label class="form-group has-float-label" *ngIf="!isMultiSelect" hidden>
                 <div class="input-group">
                   <ng-select appearance="outline" labelForId="state" class="h-25 w-100" [searchable]="false" [clearable]="false" formControlName="state" (change)="selectLocationFromSelectBox($event)">
<!--                     <ng-option value="National">National</ng-option>-->
                     <ng-option *ngFor="let list of locations" [value]="list.state">
                       {{list.state}}
                     </ng-option>
                   </ng-select>
                 </div>
                 <span>Location <span class="text-danger">*</span></span>
                 <div *ngIf="submitted && supplierDetailsFormControls.state.errors" class="invalid-tooltip">This field is required.</div>
               </label>

               <label class="form-group has-float-label">
                 <div class="position-relative has-icon-left">
                   <div class="input-group">
                     <input type="tel" class="form-control" aria-describedby="inputGroup-sizing-sm"
                            name="company_phone" formControlName="company_phone" maxlength="15">
                   </div>
                 </div>
                 <span>Company Phone </span>
                                   <div *ngIf="submitted && supplierDetailsFormControls.company_phone.errors" class="invalid-tooltip">This field is required.</div>
              </label>
              <label class="form-group has-float-label">
                <div class="position-relative has-icon-right">
                  <div class="input-group">
                    <textarea type="text"  aria-describedby="inputGroup-sizing-sm" class="w-100"
                           name="web_address" formControlName="web_address" rows="3" style="height: auto !important;">
                    </textarea>
                  </div>
                  <div class="form-control-position">
                    <img src="assets/png/internet.png" class="cursor-pointer" width="100%" (click)="openInWeb();">
                    <!--                    <i class="fa fa-map-marker text-info" style="font-size: 25px;margin-top: 3px;"></i>-->
                  </div>
                </div>
                <span>Web Address </span>
                <!--                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>-->
              </label>
<!--              <label class="form-group has-float-label">-->
<!--                <div class="position-relative has-icon-left">-->
<!--                  <div class="input-group input-group-sm">-->
<!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                           name="abn" formControlName="abn" >-->
<!--                  </div>-->
<!--                </div>-->
<!--                <span>ABN / ACN #</span>-->
<!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
<!--              </label>-->
            </div>
            <!--            <div class="col-md-6">-->
            <!--              <label class="form-group has-float-label">-->
            <!--                <div class="position-relative has-icon-left">-->
            <!--                  <div class="input-group input-group-sm">-->
            <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
            <!--                           name="main_contact_name" formControlName="main_contact_name" >-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <span>Main Contact name</span>-->
            <!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.main_contact_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
            <!--              </label>-->
            <!--              <label class="form-group has-float-label">-->
            <!--                <div class="position-relative has-icon-left">-->
            <!--                  <div class="input-group input-group-sm">-->
            <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
            <!--                           name="contact_phone" formControlName="contact_phone" >-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <span>Contact Phone </span>-->
            <!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
            <!--              </label>-->
            <!--              <label class="form-group has-float-label">-->
            <!--                <div class="position-relative has-icon-right">-->
            <!--                  <div class="input-group input-group-sm">-->
            <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
            <!--                           pattern="[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"  name="contact_email" formControlName="contact_email" >-->
            <!--                  </div>-->
            <!--                  <div class="form-control-position">-->
            <!--                    <a href="mailto:{{supplierDetailsForm.value['contact_email']}}" class="fa fa-envelope f-18 pr-3 text-info cursor-pointer"></a>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <span>Contact Email</span>-->
            <!--                <div *ngIf="submitted && supplierDetailsFormControls.contact_email.errors" class="invalid-tooltip">Invalid Email.</div>-->
            <!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
            <!--              </label>-->
            <!--              <label class="form-group has-float-label">-->
            <!--                <div class="position-relative has-icon-left">-->
            <!--                  <div class="input-group input-group-sm">-->
            <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
            <!--                           name="contact_2_name" formControlName="contact_2_name" >-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <span>Contact 2 name</span>-->
            <!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.main_contact_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
            <!--              </label>-->
            <!--              <label class="form-group has-float-label">-->
            <!--                <div class="position-relative has-icon-left">-->
            <!--                  <div class="input-group input-group-sm">-->
            <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
            <!--                           name="contact_2_phone" formControlName="contact_2_phone" >-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <span>Contact 2 Phone </span>-->
            <!--                &lt;!&ndash;                  <div *ngIf="submitted && supplierDetailsFormControls.company_name.errors" class="invalid-tooltip">This field is required.</div>&ndash;&gt;-->
            <!--              </label>-->
            <!--              <label class="form-group has-float-label">-->
            <!--                <div class="position-relative has-icon-right">-->
            <!--                  <div class="input-group input-group-sm">-->
            <!--                    <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
            <!--                           pattern="[a-zA-Z0-9!#$%&amp;'*+\/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*"  name="contact_2_email" formControlName="contact_2_email" >-->
            <!--                  </div>-->
            <!--                  <div class="form-control-position">-->
            <!--                    <a href="mailto:{{supplierDetailsForm.value['contact_2_email']}}" class="fa fa-envelope f-18 pr-3 text-info cursor-pointer"></a>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <span>Contact 2 Email</span>-->
            <!--                <div *ngIf="submitted && supplierDetailsFormControls.contact_2_email.errors" class="invalid-tooltip">Invalid Email.</div>-->
            <!--              </label>-->
            <!--            </div>-->
          </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-xs custom-btn-sec pull-right"
                (click)="newSupplierModelClose(null)">Close</button>
        <button *ngIf="!needToMerge" class="btn btn-primary btn-xs custom-btn-sec pull-right mr-1"
                          (click)="saveSupplierDetails()">Save</button>

        <button *ngIf="needToMerge" class="btn btn-primary btn-xs custom-btn-sec pull-right mr-1"
                          (click)="saveSupplierDetails(true)">Merge</button>

        <!--        <button type="button" class="btn btn-primary btn-xs custom-btn-sec pull-right"-->
        <!--                (click)="saveSupplier()">Save</button>-->

      </div>
      </form>
    </div>
  </div>
</div>

<div bsModal #supplierTypeModel="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="dialog-sizes-name1"  [config]="{ backdrop: 'static' ,ignoreBackdropClick: true,keyboard: false}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Supplier Types</h4>
        <button type="button" class="close pull-right" (click)="closeSupplierTypeModel(supplierTypeModel)"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" #supplierTypeForm="ngForm">
          <div class="col-md-12 col-sm-12 pl-0 pr-0">
            <div class="row">
              <div class="table-responsive">
                <label class="form-group has-float-label mt-2">
<!--                  <div class="input-group">-->
<!--                    <input type="text" class="form-control pt-2 pb-2" name="supplier_type" [(ngModel)]="new_supplier" placeholder="Add New Supplier Type"-->
<!--                           ngModel required (focus)="saveError = false">-->
<!--                    <div class="input-group-append">-->
<!--                      <button class="btn btn-primary default " style="border-radius:0px 11px 11px 0px !important;" type="button" (click)="addSupplierType(supplierTypeForm)" >-->
<!--                        <i class="glyph-icon simple-icon-check f-15  text-white"></i></button>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="input-group input-group-sm">
                    <input type="text" class="form-control" name="supplier_type" maxlength="70"  placeholder="Add New Supplier Type"  ngModel required (focus)="saveError = false" style="height: 100%">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button"  (click)="addSupplierType(supplierTypeForm)" style="border-radius:0px 11px 11px 0px !important;">
                        <i class="glyph-icon simple-icon-check f-15  text-white"></i></button>
                    </div>
                  </div>
                  <span>Supplier type</span>
                  <div *ngIf="saveError" class="invalid-tooltip">Supplier type can't be duplicate.</div>
                </label>
                <table class="table table-striped">
                  <tbody>
                  <!--                  <tr>-->
                  <!--&lt;!&ndash;                    <td colspan="2" class="pl-3 pr-3">&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                      <div class="input-group input-group-sm">&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                        <input type="text" class="form-control pt-2 pb-2" name="supplier_type" placeholder="Add New Supplier Type"&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                               ngModel required (focus)="saveError = false">&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                        <div class="input-group-append">&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                          <button class="btn btn-primary default " type="button" (click)="addSupplierType(supplierTypeForm)" >&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                            <i class=" fa fa-plus  text-white"></i></button>&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                        </div>&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                      </div>&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;&lt;!&ndash;                      <span class="text-danger" *ngIf="saveError">Supplier type can't be duplicate.</span>&ndash;&gt;&ndash;&gt;-->
                  <!--&lt;!&ndash;                    </td>&ndash;&gt;-->
                  <!--                  </tr>-->
                  <tr *ngFor="let item of tempSupplierType;let key = index;">
                    <td *ngIf="supplierTypeKey != key">{{item.supplier_type}}</td>
                    <td *ngIf="supplierTypeKey != key"><a href="javaScript:void(0)" (click)="selectSupplierType(item)">Select</a> </td>
                    <td *ngIf="supplierTypeKey == key">
<!--                      <label class="form-group has-float-label">-->
<!--                        <div class="input-group ">-->
<!--                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"-->
<!--                                 name="supplier_type" [ngModel]="item.supplier_type"-->
<!--                                 #roof_material_edit="ngModel" (focus)="updateError = false">-->
<!--                        </div>-->
<!--                        <span>Supplier Type</span>-->
<!--                      </label>-->
                      <label class="form-group has-float-label mb-1">
                        <div class="input-group input-group-sm">
                          <input type="text" class="form-control" aria-describedby="inputGroup-sizing-sm"
                                 name="supplier_type" maxlength="70" [ngModel]="item.supplier_type"
                                 #roof_material_edit="ngModel" (focus)="updateError = false" style="height: 100%">
                        </div>
                        <span>Supplier Type</span>
                      </label>
                      <span class="text-danger" *ngIf="updateError">Supplier type can't be duplicate.</span>
                    </td>
                    <td class="text-right pr-1">
                      <a *ngIf="supplierTypeKey == key" href="javascript:;" class="badge badge-danger mb-1 mr-1" (click)="supplierTypeKey = null">
                        <i  class="glyph-icon simple-icon-close text-white f-15 "></i>
                      </a>
                      <a *ngIf="supplierTypeKey == key" href="javascript:;" class="badge badge-primary mr-1 mb-1"  (click)="updateSupplierType(item,supplierTypeForm)">
                        <i  class="glyph-icon simple-icon-check f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-danger mb-1 mr-1"   (click)="supplierTypeCheck(item.supplier_type_id)">
                        <i *ngIf="supplierTypeKey != key" class="glyph-icon simple-icon-trash f-15"></i>
                      </a>
                      <a href="javascript:;" class="badge badge-primary mb-1" (click)="supplierTypeKey = key">
                        <i *ngIf="supplierTypeKey != key" class="glyph-icon simple-icon-note text-white f-15"></i>
                      </a>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot *ngIf="tempSupplierType.length == 0">
                  <tr>
                    <td colspan="3">
                      No records found..
                    </td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-primary btn-sm"
                (click)="closeSupplierTypeModel(supplierTypeModel)">Close</button>
      </div>
    </div>
  </div>
</div>

